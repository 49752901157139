<div class="agentLogin">
    <div class="header">
        <h1>{{headerText}}</h1>
    </div>
    <div class="isNotLoggedIn" *ngIf="chooseLoginSignup && source != 'Admin'">
        <button (click)="loginActivated=true;chooseLoginSignup=false" class="btn auth-buttons">Login</button>
        <span *ngIf="registrationUrl">OR</span>
        <button *ngIf="registrationUrl" class="btn auth-buttons" (click)="redirectAndClose()">Register</button>
    </div>
    <div class="loginActivated" *ngIf="loginActivated || source == 'Admin'">
        <div class="form-container">
            <div class="form-field">
                <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">Phone No. :<span class="text-danger">*</span>
                </div>
                <mat-form-field class="example-full-width">
                    <span matPrefix>+91 &nbsp;</span>
                    <input type="tel" matInput placeholder="Phone Number" *ngIf="!otpSent; else phoneNumberDisplay" [(ngModel)]="phoneNumber" maxlength="10"
                        appNumericOnly>
                        <ng-template #phoneNumberDisplay>
                            <input type="text" matInput [value]="phoneNumber" disabled>
                        </ng-template>
                </mat-form-field>
            </div>
            <div class="form-field" *ngIf="otpSent && source != 'Admin'">
                <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">OTP :<span class="text-danger">*</span></div>
                <div class="col-sm-8 input-field input-container d-flex flex-row justify-content-center">
                    <input class="m-1 form-control rounded opt_input" type="tel" appNumericOnly maxlength="1" style="width: 2.5rem;"
                        [(ngModel)]="digit1" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 2)">
                    <input class="m-1 form-control rounded opt_input" type="tel" appNumericOnly maxlength="1" style="width: 2.5rem;"
                        [(ngModel)]="digit2" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 3)">
                    <input class="m-1 form-control rounded opt_input" type="tel" appNumericOnly maxlength="1" style="width: 2.5rem;"
                        [(ngModel)]="digit3" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 4)">
                    <input class="m-1 form-control rounded opt_input" type="tel" appNumericOnly maxlength="1" style="width: 2.5rem;"
                        [(ngModel)]="digit4" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 5)">
                    <input class="m-1 form-control rounded opt_input" type="tel" appNumericOnly maxlength="1" style="width: 2.5rem;"
                        [(ngModel)]="digit5" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 6)">
                    <input class="m-1 form-control rounded opt_input" type="tel" appNumericOnly maxlength="1" style="width: 2.5rem;"
                        [(ngModel)]="digit6" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, null)">
                </div>
            </div>
            <div class="form-field" *ngIf="source == 'Admin'">
                <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">Password :<span class="text-danger">*</span>
                </div>
                <mat-form-field class="example-full-width">
                    <input type="password" matInput placeholder="Password" [(ngModel)]="password">
                </mat-form-field>
                </div>
                </div>
                <mat-dialog-actions align="end" *ngIf="!otpSent && source != 'Admin'">
            <button class="btn send_otp" (click)="SendOTP()" [disabled]="!isMobileNumberValid()">Send OTP</button>
        </mat-dialog-actions>
        <mat-dialog-actions align="end" *ngIf="source == 'Admin'">
            <button class="btn send_otp" (click)="login()" [disabled]="!isMobileNumberValid()">Login</button>
        </mat-dialog-actions>
        <mat-dialog-actions align="end" *ngIf="otpSent">
            <button class="btn login" [disabled]="!activateLoginBtn" (click)="login()">Login</button>
            <button class="btn login" (click)="ResendOTP()">Resend OTP</button>
            <button class="btn login" (click)="Cancel()">Cancel</button>
        </mat-dialog-actions>

    </div>
</div>