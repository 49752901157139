import { Component, Input } from '@angular/core';
import { DriverService } from '../../services/driver.service';
import { CustomerService } from '../../services/customer.service';
import { LoginSignupComponent } from '../../login-signup/login-signup.component';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-driver-home',
  templateUrl: './driver-home.component.html',
  styleUrls: ['./driver-home.component.scss']
})
export class DriverHomeComponent {

  @Input() driver_scheduled_rides: any;
  @Input() driver_scheduled_column: any;
  @Input() driver_ongoing_rides: any;
  showLoader = true;
  orderId: any;
  phoneNumber: number = 0;
  digit1: string = '';
  digit2: string = '';
  digit3: string = '';
  digit4: string = '';
  digit5: string = '';
  digit6: string = '';
  driverId: string = '';
  constructor(public driverService: DriverService, private router: Router, private dialog: MatDialog, private customerService: CustomerService, private adminservice: AdminService, private snackBar: MatSnackBar) {
    this.checkLoginStatus();
  }

  ngOnInit() {
    setTimeout(() => {
      this.showLoader = false;
    }, 2000)

  }

  odo_meter_start_reading = 0;
  odo_meter_end_reading = 0;
  disable_start_ride = false;
  disable_end_ride = false;
  async start_ride(type: any) {
    console.log(this.driver_ongoing_rides)
    // await this.SendOTP();
    const dialog = this.dialog.open(LoginSignupComponent, {
      data: {
        title: type,
        orderId: this.orderId,
        // phoneNumber: this.driver_ongoing_rides[0].customerPhoneNumber,
        rideData: this.driver_ongoing_rides[0]
        // odoMeterReadingAtStart: this.driver_ongoing_rides[0].odoMeterReadingAtStart,
        // totalDistance:this.driver_ongoing_rides[0].distance
      }
    });
    dialog.afterClosed().subscribe((response: any) => {
      if (response) {
        if (type === 'start_ride') {
          this.driver_ongoing_rides[0]['odoMeterReadingAtStart'] = response.reading;
        } else {
          this.driver_ongoing_rides[0]['odoMeterReadingAtEnd'] = response.odoMeterReadingAtEnd;
        }
        this.customerService.start_ride(this.driver_ongoing_rides[0]['booking_id'], type === 'start_ride' ? response.reading : response.odoMeterReadingAtEnd, type).subscribe((res: any) => {
          if (res['result']) {
            if (type === 'start_ride') {
              this.disable_start_ride = true;
              this.disable_end_ride = false;
            } else {
              this.customerService.updateCustomerBooking(this.driver_ongoing_rides[0]['booking_id'], response).subscribe((res: any) => {
                console.log(res['result']);
              })
              this.disable_end_ride = true;
              this.driver_ongoing_rides[0] = null;
            }
          }
        });
      }
    });
  }
  // async SendOTP() {
  //   try {
  //     const res: any = await this.adminservice.getOTPValue(this.driver_ongoing_rides[0].customerPhoneNumber).toPromise();
  //     this.orderId = res.orderId;
  //   } catch (err: any) {
  //     console.log("Error Message : ", err);
  //     this.snackBar.open(err.error.error, 'Close', { duration: 3000 });
  //   }
  // }
  // async verifyOtp(): Promise<boolean> {
  //   const otp: number = parseInt(this.digit1 + this.digit2 + this.digit3 + this.digit4 + this.digit5 + this.digit6, 10);

  //   return this.adminservice.getOTPVerified(this.orderId, otp, this.phoneNumber).toPromise()
  //     .then((res: any) => {
  //       return res.isOTPVerified;
  //     })
  //     .catch(error => {
  //       console.error("Error verifying OTP:", error);
  //       return false;
  //     });
  // }

  changeStatus(event: any) {
    if (event == 'goBack') {
      window.location.reload();
    }
  }

  checkLoginStatus() {
    // localStorage.setItem("admin_auth", res.phoneNumber);
    // localStorage.setItem("admin_authExpiration", expirationDate.toString());  
    const auth = localStorage.getItem("driver_auth");
    const time = localStorage.getItem("driver_authExpiration");
    if (time && auth) {
      const date = new Date(time);
      if (date <= new Date()) {
        localStorage.removeItem('driver_auth');
        localStorage.removeItem('driver_authExpiration');
      }
      else {
        this.driverId = auth;
      }
    }
    else {
      this.router.navigate(['/']);
    }
  }
}
