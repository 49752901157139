import { ChangeDetectorRef, Component, ElementRef, HostListener, NgModule, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomerService } from '../services/customer.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Observable, map, startWith } from 'rxjs';
import { AdminService } from '../services/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { LoginSignupComponent } from '../login-signup/login-signup.component';
import { DatePipe } from '@angular/common';
import { GooglePlacesService } from '../services/google-places.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent {

  openDatePicker(picker: MatDatepicker<Date>): void {
    picker.open();
  }

  one_way_form: FormGroup
  local_form: FormGroup
  round_form: FormGroup
  airport_form: FormGroup
  bulk_form: FormGroup
  final_details: FormGroup
  locations_data = [];
  ride_km = 0;
  orderId: any;
  phoneNumber: string = ""
  digit1: string = '';
  digit2: string = '';
  digit3: string = '';
  digit4: string = '';
  digit5: string = '';
  digit6: string = '';
  otpSent: boolean = false;
  isOTPVerified: boolean = false;
  phoneDisable: boolean = false
  minReturnDate: Date;
  activateLoginBtn: boolean = false;

  PickupDateChange(date: any) {
    this.minReturnDate = new Date(date);
  }

  constructor(private google_service: GooglePlacesService, private datePipe: DatePipe, private dialog: MatDialog, private snackBar: MatSnackBar, private adminservice: AdminService, private changeDetectorRef: ChangeDetectorRef, private elementRef: ElementRef, private fb: FormBuilder, public customerService: CustomerService) {

    const currentYear = new Date().getFullYear();
    this.minReturnDate = new Date(currentYear, 0, 1);
    this.one_way_form = this.fb.group({
      trip_type: ['One Way'],
      from: ['', Validators.required],
      to: ['', Validators.required],
      pickUpDate: ['', Validators.required],
      pickUpTime: ['', Validators.required],
      kmsOfRide: ['']
    })

    this.local_form = this.fb.group({
      trip_type: ['LOCAL TRIP'],
      from: ['', Validators.required],
      to: ['', Validators.required],
      pickUpDate: ['', Validators.required],
      pickUpTime: ['', Validators.required],
      kmsOfRide: ['']
    })

    this.round_form = this.fb.group({
      trip_type: ['ROUND TRIP'],
      from: ['', Validators.required],
      to: ['', Validators.required],
      pickUpDate: ['', Validators.required],
      pickUpTime: ['', Validators.required],
      returnDate: ['', Validators.required],
      returnTime: ['', Validators.required],
      kmsOfRide: ['']
    })

    this.airport_form = this.fb.group({
      trip_type: ['AIRPORT'],
      trip: [this.airport_trip[0], Validators.required],
      from: ['', Validators.required],
      to: ['', Validators.required],
      terminal_name: ['', Validators.required],
      pickUpDate: ['', Validators.required],
      pickUpTime: ['', Validators.required],
      kmsOfRide: ['']
    })

    this.bulk_form = this.fb.group({
      trip_type: ['BULK BOOKING'],
      user_name: ['', Validators.required],
      user_email_id: ['', Validators.required],
      user_mobile: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      from: ['', Validators.required],
      to: ['', Validators.required],
      pickUpDate: ['', Validators.required],
      pickUpTime: ['', Validators.required],
      kmsOfRide: ['']
    })

    this.final_details = this.fb.group({});
    this.adminservice.getServiceLocation().subscribe((res: any) => {
      if (res) {
        this.locations_data = res;
        this.from_cities = res.fromList;
        this.to_cities = res.toList;

        // From cities
        this.filterOneWayFromCity();
        this.filterLocalWayFromCity();
        this.filterRoundWayFromCity();
        this.filterAiportWayFromCity();
        this.filterBulkWayFromCity();

        // To cities
        this.filterOneWayToCity();
        this.filterLocalWayToCity();
        this.filterRoundWayToCity();
        this.filterAiportWayToCity();
        this.filterBulkWayToCity();
      }
    });
  }

  toTitleCase(value: string): string {
    return value.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  smallScreen: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.smallScreen = window.innerWidth < 768 ? true : false;
  }
  user_data: any;
  ngOnInit() {
    this.onResize(event!);
    this.checkLoginStatus();
    let auth = localStorage.getItem('customer_auth');
    if (auth) {
      this.customerService.fetchProfile(auth).subscribe((res) => {
        this.user_data = res.result;
      })
    }
  }
  ngOnDestroy(): void {
  }

  dateControl = new FormControl();

  trip_type = [
    { label: 'ONE WAY', mat_icon: 'directions_one_way' },
    // { label: 'LOCAL TRIP', mat_icon: 'directions_car' },
    { label: 'ROUND TRIP', mat_icon: 'sync' },
    { label: 'AIRPORT', mat_icon: 'flight' },
    // { label: 'BULK BOOKING', mat_icon: 'business_center' }
  ]
  pickUpTime = ["00.00 AM", "00.15 AM", "00.30 AM", "00.45 AM", "01.00 AM", "01.15 AM", "01.30 AM", "01.45 AM", "02.00 AM", "02.15 AM", "02.30 AM", "02.45 AM", "03.00 AM", "03.15 AM", "03.30 AM", "03.45 AM", "04.00 AM", "04.15 AM", "04.30 AM", "04.45 AM", "05.00 AM", "05.15 AM", "05.30 AM", "05.45 AM", "06.00 AM", "06.15 AM", "06.30 AM", "06.45 AM", "07.00 AM", "07.15 AM", "07.30 AM", "07.45 AM", "08.00 AM", "08.15 AM", "08.30 AM", "08.45 AM", "09.00 AM", "09.15 AM", "09.30 AM", "09.45 AM", "10.00 AM", "10.15 AM", "10.30 AM", "10.45 AM", "11.00 AM", "11.15 AM", "11.30 AM", "11.45 AM", "12.00 PM", "12.15 PM", "12.30 PM", "12.45 PM", "01.00 PM", "01.15 PM", "01.30 PM", "01.45 PM", "02.00 PM", "02.15 PM", "02.30 PM", "02.45 PM", "03.00 PM", "03.15 PM", "03.30 PM", "03.45 PM", "04.00 PM", "04.15 PM", "04.30 PM", "04.45 PM", "05.00 PM", "05.15 PM", "05.30 PM", "05.45 PM", "06.00 PM", "06.15 PM", "06.30 PM", "06.45 PM", "07.00 PM", "07.15 PM", "07.30 PM", "07.45 PM", "08.00 PM", "08.15 PM", "08.30 PM", "08.45 PM", "09.00 PM", "09.15 PM", "09.30 PM", "09.45 PM", "10.00 PM", "10.15 PM", "10.30 PM", "10.45 PM", "11.00 PM", "11.15 PM", "11.30 PM", "11.45 PM"];
  // pickUpTime = ["00.00 AM", "04.00 AM", "08.00 AM", "12.00 PM", "16.00 PM", "20.00 PM"];
  airport_trip = ['PICKUP FROM AIRPORT', 'DROP TO AIRPORT']
  @HostListener('window:scroll', ['$event'])

  // disable the previous date in calender 
  dateFilter(date: Date | null): boolean {
    const currentDate = moment().startOf('day');
    return date !== null && moment(date).isSameOrAfter(currentDate);
  }

  // disable the time 
  isDisable(time: string): boolean {
    let selectDate = '';
    if (this.selected_trip === 'ONE WAY') {
      selectDate = this.one_way_form.get('pickUpDate')?.value;
    } else if (this.selected_trip === 'LOCAL TRIP') {
      selectDate = this.local_form.get('pickUpDate')?.value;
    } else if (this.selected_trip === 'ROUND TRIP') {
      selectDate = this.round_form.get('pickUpDate')?.value;
    } else if (this.selected_trip === 'AIRPORT') {
      selectDate = this.airport_form.get('pickUpDate')?.value;
    } else if (this.selected_trip === 'BULK BOOKING') {
      selectDate = this.bulk_form.get('pickUpDate')?.value;
    }

    if (!selectDate) {
      return true;
    }
    time = this.parseTime(time)
    // console.log(time)
    // console.log(typeof(time));

    const currentTime = moment();
    const selectedDate = moment(selectDate).startOf('day');
    const timeMoment = moment(time, "hh");

    const hour = currentTime.hour();
    if (selectedDate.isSame(currentTime, 'day')) {
      if (hour >= 20) {
        return true;
      } else if (hour < 8) {
        return time < '10';
      } else {
        const bufferTime = currentTime.clone().add(3, 'hours');
        return timeMoment.isBefore(bufferTime);
      }
    }

    else if (selectedDate.isSame(currentTime.clone().add(1, 'day'), 'day') && hour >= 20) {
      return time < '10';
    }

    return false;
  }


  parseTime(time: string): string {
    const [hourString, minuteString] = time.split(/[:\s]/);
    const period = time.split(" ")[1];
    let hours = parseInt(hourString, 10);
    const minutes = parseInt(minuteString, 10);

    if (period === "PM" && hours < 12) {
      hours += 12;
    }
    if (period === "AM" && hours === 12) {
      hours = 0;
    } else if (period === "AM" && hours < 10) {
      return '0' + hours
    }

    // const date = new Date();
    // date.setHours(hours, minutes, 0, 0);
    return hours.toString();
  }


  airportType = 'PICKUP FROM AIRPORT'
  onAirportTripTypeChange(type: any) {
    this.airportType = type.value;
  }

  selected_trip = 'ONE WAY'

  OnSelect(event: MatTabChangeEvent) {
    this.selected_trip = this.trip_type[event.index].label;
  }

  getCabs: boolean = false;
  availableCabs: any;

  one_way_form_data: any;
  local_form_data: any;
  round_form_data: any;
  airport_form_data: any;
  bulk_form_data: any;
  all_form_data: any;

  exploreCabs(form_value: any) {
    // this.locations_data.forEach((location: any) => {
    //   if (location.fromLocation == form_value['from'] && location.toLocation == form_value['to']) {
    //     this.all_form_data['kmsOfRide'] = location['kilometers']
    //   }
    // })
    this.customerService.explore_cabs().subscribe((res) => {
      this.availableCabs = res.result;
      this.getCabs = true;
    })
  }

  exploreOneWayCabs() {
    console.log(this.one_way_form.value)
    this.markFormGroupTouched(this.one_way_form)
    this.one_way_form.get('trip_type')?.setValue('One Way');
    this.all_form_data = this.one_way_form.value;
    if (this.one_way_form.valid) {
      this.exploreCabs(this.all_form_data);
    }
    this.getCoordinates(this.one_way_form.get('from')?.value || '', 'Pickup');
    this.getCoordinates(this.one_way_form.get('to')?.value || '', 'Drop');
  }
  exploreLocalCabs() {
    console.log(this.local_form.value)
    this.markFormGroupTouched(this.local_form)
    this.local_form.get('trip_type')?.setValue('LOCAL TRIP');
    this.all_form_data = this.local_form.value;
    if (this.local_form.valid) {
      this.exploreCabs(this.all_form_data);
    }
  }
  exploreRoundCabs() {
    console.log(this.round_form.value)
    this.markFormGroupTouched(this.round_form)
    this.round_form.get('trip_type')?.setValue('ROUND TRIP');
    this.all_form_data = this.round_form.value;
    if (this.round_form.valid) {
      this.exploreCabs(this.all_form_data);
    }
  }
  exploreAirportCabs() {
    console.log(this.airport_form.value)
    this.markFormGroupTouched(this.airport_form)
    this.airport_form.get('trip_type')?.setValue('AIRPORT');
    this.all_form_data = this.airport_form.value;
    if (this.airport_form.valid) {
      this.exploreCabs(this.all_form_data);
    }
  }
  exploreBulkCabs() {
    console.log(this.bulk_form.value)
    this.markFormGroupTouched(this.bulk_form)
    this.bulk_form.get('trip_type')?.setValue('BULK BOOKING');
    this.all_form_data = this.bulk_form.value;
    if (this.bulk_form.valid) {
      this.exploreCabs(this.all_form_data);
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    })
  }

  selectedCar: boolean = false;
  selected_cab_details: any;
  trip_details: any;
  total_fare: any;
  from_locations: any[] = [];
  to_locations: any[] = [];

  getDetails(data: any) {
    // console.log(this.user_data);
    if (data) {
      this.selected_cab_details = data['cab'];
      this.trip_details = data['trip'];
      this.total_fare = data['total_fare'];
      // this.customerService.getCityLocations(this.trip_details['from'], this.trip_details['to']).subscribe((res) => {
      //   console.log(res);
      // })
      this.final_details = this.fb.group({
        userName: [this.user_data ? this.user_data.name : '', Validators.required],
        mobileNo: [this.user_data ? this.user_data.phoneNumber : '', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
        pickUpAddress: ['', Validators.required],
        dropAddress: ['', Validators.required],
        kmsOfRide: [Math.floor(this.trip_details['kmsOfRide'])],
      })
      this.selectedCar = true;
      this.getCabs = false;
    }
  }

  modifyTrip(data: any) {
    if (data) {
      this.selectedCar = false;
      this.getCabs = false;
    }
  }
  seletedTabIndex = 0;
  seletedTrip: any;
  modifyTripType(data: any) {
    this.seletedTrip = data;
    this.trip_type.forEach((item: any, i) => {
      if (item.label == data) {
        this.seletedTabIndex = i;
      }
    })
  }

  final_data: any;
  final_booking_data: any[] = [];
  final_details_submit() {
    this.markFormGroupTouched(this.final_details);
    if (this.final_details.valid) {
      this.paymentOption = true;
      this.getCabs = false;
      this.final_data = this.final_details.value;
      let obj: any = {};
      let booking_from: any = {}
      let booking_to: any = {}
      booking_from['city'] = this.trip_details['from']
      booking_from['landmark'] = this.final_data['pickUpAddress']
      booking_to['city'] = this.trip_details['to']
      booking_to['landmark'] = this.final_data['dropAddress']
      obj.booking_from = booking_from;
      obj.booking_to = booking_to;
      obj.tripType = this.trip_details['trip_type'];
      obj.customer_id = this.user_data ? this.user_data['customer_id'] : '';
      obj.customer_number = this.final_data['mobileNo'];
      obj.pickup_date = this.datePipe.transform(this.trip_details['pickUpDate'], 'yyyy-MM-dd') + 'T00:00:00.000Z';
      obj.pickup_time = this.trip_details['pickUpTime'];
      obj.return_date = this.trip_details['returnDate'] ? this.datePipe.transform(this.trip_details['returnDate'], 'yyyy-MM-dd') + 'T00:00:00.000Z' : '';
      obj.return_time = this.trip_details['returnTime'] || '';
      obj.terminal = this.trip_details['terminal_name'] || '';
      obj.status = 'Requested';
      obj.kmsOfRide = this.final_data['kmsOfRide'];
      obj.cabCategoryId = this.selected_cab_details['cabCategoryId'];
      obj.cabCategoryName = this.selected_cab_details['categoryName'];
      obj.totalAmount = this.total_fare;
      obj.customer_name = this.final_data['userName'];
      obj.booking_id = '';
      this.final_booking_data.push(obj);
    }
    this.otpSent = false;
    this.isOTPVerified = false;
    this.digit1 = '';
    this.digit2 = '';
    this.digit3 = '';
    this.digit4 = '';
    this.digit5 = '';
    this.digit6 = '';
  }

  paymentOption = false;


  // From cities
  filterOneWayFromCity() {
    if (this.from_cities) {
      this.filteredFromCitiesOneWay = this.fromCityControlOneWay.valueChanges.pipe(
        startWith(''),
        map(value => this._filterFromCitiesOneWay(value))
      );
    }
  }
  filterRoundWayFromCity() {
    if (this.from_cities) {
      this.filteredFromCitiesRoundWay = this.fromCityControlRoundWay.valueChanges.pipe(
        startWith(''),
        map(value => this._filterFromCitiesRoundWay(value))
      );
    }
  }
  filterLocalWayFromCity() {
    if (this.from_cities) {
      this.filteredFromCitiesLocalWay = this.fromCityControlLocalWay.valueChanges.pipe(
        startWith(''),
        map(value => this._filterFromCitiesLocalWay(value))
      );
    }
  }
  filterAiportWayFromCity() {
    if (this.from_cities) {
      this.filteredFromCitiesAirportWay = this.fromCityControlAirportWay.valueChanges.pipe(
        startWith(''),
        map(value => this._filterFromCitiesAirportWay(value))
      );
    }
  }
  filterBulkWayFromCity() {
    if (this.from_cities) {
      this.filteredFromCitiesBulkWay = this.fromCityControlBulkWay.valueChanges.pipe(
        startWith(''),
        map(value => this._filterFromCitiesBulkWay(value))
      );
    }
  }



  // To cities
  filterOneWayToCity() {
    if (this.to_cities) {
      this.filteredToCitiesOneWay = this.toCityControlOneWay.valueChanges.pipe(
        startWith(''),
        map(value => this._filterToCitiesOneWay(value))
      );
    }
  }
  // filterOneWayToCity() {
  //   const selectedFromCity = this.fromCityControlOneWay.value;
  //   if (this.to_cities && selectedFromCity) {
  //     this.filteredToCitiesOneWay = this.to_cities.filter(city => city !== selectedFromCity);
  //   }
  // }
  filterRoundWayToCity() {
    if (this.to_cities) {
      this.filteredToCitiesRoundWay = this.toCityControlRoundWay.valueChanges.pipe(
        startWith(''),
        map(value => this._filterToCitiesRoundWay(value))
      );
    }
  }
  filterLocalWayToCity() {
    if (this.to_cities) {
      this.filteredToCitiesLocalWay = this.toCityControlLocalWay.valueChanges.pipe(
        startWith(''),
        map(value => this._filterToCitiesLocalWay(value))
      );
    }
  }
  filterAiportWayToCity() {
    if (this.to_cities) {
      this.filteredToCitiesAirportWay = this.toCityControlAirportWay.valueChanges.pipe(
        startWith(''),
        map(value => this._filterToCitiesAirportWay(value))
      );
    }
  }
  filterBulkWayToCity() {
    if (this.to_cities) {
      this.filteredToCitiesBulkWay = this.toCityControlBulkWay.valueChanges.pipe(
        startWith(''),
        map(value => this._filterToCitiesBulkWay(value))
      );
    }
  }



  // From cities
  from_cities: any[] = [];
  fromCityControlOneWay = new FormControl();
  fromCityControlRoundWay = new FormControl();
  fromCityControlLocalWay = new FormControl();
  fromCityControlAirportWay = new FormControl();
  fromCityControlBulkWay = new FormControl();

  filteredFromCitiesOneWay: Observable<string[]> | undefined;
  filteredFromCitiesRoundWay: Observable<string[]> | undefined;
  filteredFromCitiesLocalWay: Observable<string[]> | undefined;
  filteredFromCitiesAirportWay: Observable<string[]> | undefined;
  filteredFromCitiesBulkWay: Observable<string[]> | undefined;


  // To cities
  to_cities: any[] = [];
  toCityControlOneWay = new FormControl();
  toCityControlRoundWay = new FormControl();
  toCityControlLocalWay = new FormControl();
  toCityControlAirportWay = new FormControl();
  toCityControlBulkWay = new FormControl();

  filteredToCitiesOneWay: Observable<string[]> | undefined;
  filteredToCitiesRoundWay: Observable<string[]> | undefined;
  filteredToCitiesLocalWay: Observable<string[]> | undefined;
  filteredToCitiesAirportWay: Observable<string[]> | undefined;
  filteredToCitiesBulkWay: Observable<string[]> | undefined;



  // From cities
  private _filterFromCitiesOneWay(value: string): string[] {
    this.one_way_form.patchValue({ from: this.fromCityControlOneWay.value });
    const toCity = this.one_way_form.get('to')?.value;
    const filterValue = value.toLowerCase();
    if (toCity != "")
      return this.from_cities.filter((city: string) => city.toLowerCase().includes(filterValue) && !city.toLowerCase().includes(toCity?.toLowerCase()));
    else
      return this.from_cities.filter((city: string) => city.toLowerCase().includes(filterValue))
  }
  private _filterFromCitiesRoundWay(value: string): string[] {
    this.round_form.patchValue({ from: this.fromCityControlRoundWay.value });
    const toCity = this.round_form.get('to')?.value;
    const filterValue = value.toLowerCase();
    if (toCity != "")
      return this.from_cities.filter((city: string) => city.toLowerCase().includes(filterValue) && !city.toLowerCase().includes(toCity?.toLowerCase()));
    else
      return this.from_cities.filter((city: string) => city.toLowerCase().includes(filterValue))
  }
  private _filterFromCitiesLocalWay(value: string): string[] {
    this.local_form.patchValue({ from: this.fromCityControlLocalWay.value });
    const filterValue = value.toLowerCase();
    return this.from_cities.filter((city: string) => city.toLowerCase().includes(filterValue));
  }
  private _filterFromCitiesAirportWay(value: string): string[] {
    this.airport_form.patchValue({ from: this.fromCityControlAirportWay.value });
    const filterValue = value.toLowerCase();
    return this.from_cities.filter((city: string) => city.toLowerCase().includes(filterValue));
  }
  private _filterFromCitiesBulkWay(value: string): string[] {
    this.bulk_form.patchValue({ from: this.fromCityControlBulkWay.value });
    const filterValue = value.toLowerCase();
    return this.from_cities.filter((city: string) => city.toLowerCase().includes(filterValue));
  }

  // To cities
  private _filterToCitiesOneWay(value: string): string[] {
    this.one_way_form.patchValue({ to: this.toCityControlOneWay.value });
    const fromCity = this.one_way_form.get('from')?.value;
    const filterValue = value.toLowerCase();
    if (fromCity != "")
      return this.to_cities.filter((city: string) => city.toLowerCase().includes(filterValue) && !city.toLowerCase().includes(fromCity?.toLowerCase()));
    else
      return this.to_cities.filter((city: string) => city.toLowerCase().includes(filterValue));
  }
  private _filterToCitiesRoundWay(value: string): string[] {
    this.round_form.patchValue({ to: this.toCityControlRoundWay.value });
    const fromCity = this.round_form.get('from')?.value;
    const filterValue = value.toLowerCase();
    if (fromCity != "")
      return this.to_cities.filter((city: string) => city.toLowerCase().includes(filterValue) && !city.toLowerCase().includes(fromCity?.toLowerCase()));
    else
      return this.to_cities.filter((city: string) => city.toLowerCase().includes(filterValue));
  }
  private _filterToCitiesLocalWay(value: string): string[] {
    this.local_form.patchValue({ to: this.toCityControlLocalWay.value });
    const filterValue = value.toLowerCase();
    return this.to_cities.filter((city: string) => city.toLowerCase().includes(filterValue));
  }
  private _filterToCitiesAirportWay(value: string): string[] {
    this.airport_form.patchValue({ to: this.toCityControlAirportWay.value });
    const fromCity = this.airport_form.get('from')?.value;
    const filterValue = value.toLowerCase();
    return this.to_cities.filter((city: string) => city.toLowerCase().includes(filterValue) && !city.toLowerCase().includes(fromCity?.toLowerCase()));
  }
  private _filterToCitiesBulkWay(value: string): string[] {
    this.bulk_form.patchValue({ to: this.toCityControlBulkWay.value });
    const filterValue = value.toLowerCase();
    return this.to_cities.filter((city: string) => city.toLowerCase().includes(filterValue));
  }


  //Indra maps autocomplete
  pickupAddressOption: string[] = [];
  pickupCoords: Coordinates = new Coordinates();
  drpoAddressOption: string[] = [];
  dropCoords: Coordinates = new Coordinates();

  getPredictions(addressType: string) {
    if (addressType == 'Pickup') {
      let pickup = this.final_details.get('pickUpAddress')?.value || '';
      this.google_service.getPrediction(pickup, this.pickupCoords.lat.toString(), this.pickupCoords.lng.toString()).subscribe(
        (res) => {
          console.log("Result: ", res);
          this.pickupAddressOption = res.predictions?.map((value: any) => value.description);
          console.log(this.pickupAddressOption);
        }
      )
    }
    else if (addressType == 'Drop') {
      let drop = this.final_details.get('dropAddress')?.value || '';
      this.google_service.getPrediction(drop, this.dropCoords.lat.toString(), this.dropCoords.lng.toString()).subscribe(
        (res) => {
          console.log("Result: ", res);
          this.drpoAddressOption = res.predictions?.map((value: any) => value.description);
          console.log(this.drpoAddressOption);
        }
      )
    }
  }

  getCoordinates(cityName: string, type: string) {
    console.log("Get Coordinates: ", cityName, type);
    if (type == 'Pickup')
      this.google_service.getCoordinates(cityName).subscribe(
        (res: any) => {
          console.log(res);
          let location = res.results[0].geometry.location;
          this.pickupCoords.lat = location.lat;
          this.pickupCoords.lng = location.lng;
          console.log("pickupCoords: ", this.pickupCoords.lat, " : ", this.pickupCoords.lng);
        }
      )
    else if (type == 'Drop')
      this.google_service.getCoordinates(cityName).subscribe(
        (res: any) => {
          console.log(res);
          let location = res.results[0].geometry.location;
          this.dropCoords.lat = location.lat;
          this.dropCoords.lng = location.lng;
          console.log("dropCoords: ", this.dropCoords.lat, " : ", this.dropCoords.lng);
        }
      )
  }

  //end Autocomplete



  paymentDetails() {
    let data = { trip_details: this.trip_details, cab_details: this.selected_cab_details, final_trip_details: this.final_data };
    this.customerService.book_ride(data).subscribe((res) => {
      console.log(res.result);
    })
  }

  goBack() {
    if (!this.paymentOption) {
      this.selectedCar = false;
      this.getCabs = true;
    } else {
      this.paymentOption = false;
    }
  }
  booking_data: any;
  book_ride() {
    console.log(this.final_booking_data)
    this.customerService.book_ride(this.final_booking_data).subscribe((res) => {
      if (res.result) {
        this.booking_data = res.result;
        this.getCabs = false;
        this.selectedCar = false;
        this.paymentOption = false;
        this.booking_data['customer_name'] = this.final_booking_data[0]['customer_name'];
        this.booking_data['customer_number'] = this.final_booking_data[0]['customer_number'];
        this.one_way_form.reset();
        this.local_form.reset();
        this.round_form.reset();
        this.airport_form.reset();
        this.bulk_form.reset();
        this.fromCityControlOneWay = new FormControl();
        this.fromCityControlRoundWay = new FormControl();
        this.fromCityControlLocalWay = new FormControl();
        this.fromCityControlAirportWay = new FormControl();
        this.fromCityControlBulkWay = new FormControl();
        this.toCityControlOneWay = new FormControl();
        this.toCityControlRoundWay = new FormControl();
        this.toCityControlLocalWay = new FormControl();
        this.toCityControlAirportWay = new FormControl();
        this.toCityControlBulkWay = new FormControl();
        this.trip_details = null;
        this.one_way_form_data = null;
        this.local_form_data = null;
        this.round_form_data = null;
        this.airport_form_data = null;
        this.bulk_form_data = null;
        this.all_form_data = null;
        this.final_booking_data = [];
        const dialog = this.dialog.open(LoginSignupComponent, {
          data: {
            title: "book_ride",
            booking_details: this.booking_data
          }
        });
        console.log(this.one_way_form.value);
      }
    })
  }

  checkLoginStatus() {
    const auth = localStorage.getItem("customer_auth");
    const time = localStorage.getItem("customer_authExpiration");
    console.log("checked auth: ", auth, time);
    if (time && auth) {
      const date = new Date(time);
      console.log("time: ", time)
      if (date <= new Date()) {
        console.log("time: ", time)
        localStorage.removeItem('customer_auth');
        localStorage.removeItem('customer_authExpiration');
      }
      else {
        this.phoneNumber = auth;
        // this.isOTPVerified = true;
      }
    }
  }

  //OTP Section
  SendOTP() {
    this.otpSent = true;
    this.phoneDisable = true
    this.adminservice.getOTPValue(parseInt(this.phoneNumber)).subscribe((res: any) => {
      this.orderId = res.orderId;
      this.snackBar.open("OTP sent successfully !", 'Close', { duration: 3000 });
    }, (err) => {
      this.snackBar.open(err.error.error, 'Close', { duration: 3000 });
    })
  }
  async verifyOtp(): Promise<boolean> {
    const otp: number = parseInt(this.digit1 + this.digit2 + this.digit3 + this.digit4 + this.digit5 + this.digit6, 10);

    return this.adminservice.getOTPVerified(this.orderId, otp, parseInt(this.phoneNumber)).toPromise()
      .then((res: any) => {
        this.isOTPVerified = res.isOTPVerified
        this.snackBar.open("OTP verified successfully !", 'Close', { duration: 3000 });
        return res.isOTPVerified;
      })
      .catch(error => {
        this.snackBar.open(error, 'Close', { duration: 3000 });
        return false;
      });
  }
  ResendOTP() {
    this.adminservice.getReSendOTP(this.orderId).subscribe((res: any) => {
      this.orderId = res.orderId;
      this.snackBar.open("OTP resend successfull !", 'Close', { duration: 3000 });
    }, (err) => {
      this.snackBar.open(err.error.error, 'Close', { duration: 3000 })
    })
  }
  isMobileNumberValid(): boolean {
    // Convert to string
    return this.phoneNumber.length === 10;
  }

  moveToNext(event: any, nextInputIndex: number | null): void {
    const input = event.target as HTMLInputElement;
    const maxLength = parseInt(input.getAttribute('maxlength') || '1', 10);
    const currentLength = input.value.length;

    if (currentLength >= maxLength && nextInputIndex !== null) {
      const nextInput = document.querySelectorAll('.opt_input')[nextInputIndex - 1] as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    }
    else {
      this.activateLoginBtn = true;
    }
  }
}

class Coordinates {
  lat: string = '';
  lng: string = '';
}