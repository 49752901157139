<h2 mat-dialog-title>Add Car</h2>
<mat-dialog-content>
    <div class="row" [formGroup]="carFormGroup">
        <mat-form-field class="col-md-6">
            <mat-label>Car Brand</mat-label>
            <input matInput placeholder="Car brand" formControlName="CarBrand">
        </mat-form-field>
        <mat-form-field class="col-md-6">
            <mat-label>Car Name</mat-label>
            <input matInput placeholder="Car Name" formControlName="CarName">
        </mat-form-field>
        <mat-form-field class="col-md-6">
            <mat-label>Car Number</mat-label>
            <input matInput placeholder="Car Number" formControlName="CarNumber">
        </mat-form-field>
        <mat-form-field class="col-md-6">
            <mat-label>Fuel Type</mat-label>
            <!-- <input matInput placeholder="Car brand" formControlName="FuelType"> -->
            <mat-select formControlName="FuelType">
                <mat-option *ngFor="let FuelType of cabFuelTypeList" [value]="FuelType">{{FuelType}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="col-md-6">
            <mat-label>No Of Seats</mat-label>
            <input matInput type="number" placeholder="Number Of Seats" appNumericOnly formControlName="NumberOfSeats">
        </mat-form-field>
        <mat-form-field class="col-md-6">
            <mat-label>Cab Category</mat-label>
            <mat-select formControlName="cabCategoryId">
                <mat-option *ngFor="let category of cabCategoryList"
                    [value]="category.cabCategoryId">{{category.categoryName}}</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="form-doc-fields">
            <span>Registration Certificate</span>
            <div class="info">
                <div style="display: flex;">
                    <input class="form-control" formControlName="registrationCertificateUpload" type="file"
                        (change)="onFileSelected($event,'registrationCertificateUpload')"
                        [(ngModel)]="data['registrationCertificateUpload']">
                    <div>
                        <!-- <button (click)="onUpload()" class="btn btn-success">
                            Upload
                        </button> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="form-doc-fields">
            <span>Travel Permit</span>

            <div class="info">
                <div style="display: flex;">
                    <input class="form-control" formControlName="PermitUpload" type="file"
                        (change)="onFileSelected($event,'PermitUpload')" [(ngModel)]="data['PermitUpload']">
                    <!-- <div>
                        <button (click)="onUpload()" class="btn btn-success">
                            Upload
                        </button>
                    </div> -->
                </div>
            </div>
        </div>



        <div class="form-doc-fields">
            <span>Car Photo with Owner</span>

            <div class="info">
                <div style="display: flex;">
                    <input class="form-control" formControlName="CarPhotoWithOwnerUploaod" type="file"
                        (change)="onFileSelected($event,'CarPhotoWithOwnerUploaod')"
                        [(ngModel)]="data['CarPhotoWithOwnerUploaod']">
                    <!-- <div>
                        <button (click)="onUpload()" class="btn btn-success">
                            Upload
                        </button>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="form-doc-fields">
            <span>Owner Pan Card</span>
            <div class="info">
                <div style="display: flex;">
                    <input class="form-control" formControlName="CarOwnerPanCardUpload" type="file"
                        (change)="onFileSelected($event,'CarOwnerPanCardUpload')"
                        [(ngModel)]="data['CarOwnerPanCardUpload']">
                    <!-- <div>
                        <button (click)="onUpload()" class="btn btn-success">
                            Upload
                        </button>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="form-doc-fields">
            <span>Owner Aadhar Card</span>
            <div class="info">
                <div style="display: flex;">
                    <input class="form-control" formControlName="CarOwnerAadharUpload" type="file"
                        (change)="onFileSelected($event,'registrationCertificateUpload')"
                        [(ngModel)]="data['registrationCertificateUpload']">
                    <!-- <div>
                        <button (click)="onUpload()" class="btn btn-success">
                            Upload
                        </button>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="form-doc-fields">
            <span>Insurance</span>
            <div class="info">
                <div style="display: flex;">
                    <input class="form-control" formControlName="InsuranceUpload" type="file"
                        (change)="onFileSelected($event,'InsuranceUpload')" [(ngModel)]="data['InsuranceUpload']">

                </div>
            </div>

        </div>

        <!-- <mat-form-field class="col-md-6">
            <mat-label>Insurance Validity</mat-label>
            <input matInput type="date" placeholder="Insurance Validity" formControlName="InsuranceValidity">
        </mat-form-field> -->
        
            <mat-form-field class="col-md-6 input-field mt-2">
                <mat-label> Insurance Validity <span class="text-danger">*</span></mat-label>
                <input matInput [matDatepicker]="picker" formControlName="InsuranceValidity">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

        <mat-form-field class="col-md-6 mt-2" *ngIf="!ownerName">
            <mat-label>Car Owner Name</mat-label>
            <input matInput placeholder="Car Owner Name" formControlName="CarOwnerName">
        </mat-form-field>
        <mat-form-field class="col-md-6" *ngIf="!ownerPhoneNumber">
            <mat-label>Car Owner Phone Number</mat-label>
            <input matInput placeholder="Owner Phone Number" formControlName="CarOwnerPhoneNumber"
              required maxlength="10" appNumericOnly>
        </mat-form-field>
    </div>
    <div class="col-sm-4 fw-bold label" *ngIf="!ownerPhoneNumber && !otpSent">
        <button class="btn login" (click)="SendOTP()" [disabled]="!isMobileNumberValid()">Send OTP</button>
    </div>

    <div class="d-flex main-div mt-4" *ngIf="otpSent && !isOtpVerified">

        <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
            <div class="col-sm-4 fw-bold label">Enter OTP :<span class="text-danger">*</span></div>
            <mat-form-field class="col-sm-8 input-field">
                <input matInput placeholder="Enter 6 digit OTP" [(ngModel)]="otp" appNumericOnly maxlength="6">
            </mat-form-field>


            <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                <button class="btn login" (click)="verifyOtp()" [disabled]="!activateLoginBtn()">Verify</button>
                <button class="btn login" (click)="ResendOTP()">Resend OTP</button>
                <button class="btn login" (click)="Cancel()">Cancel</button>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button class="btn login" (click)="onNoClick()">No Thanks</button>

    <button mat-button class="btn login" (click)="addCar()" [disabled]="carFormGroup.invalid || !isOtpVerified"
        cdkFocusInitial>Add
        Car</button>
</mat-dialog-actions>