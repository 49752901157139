import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { TransactionDetailsComponent } from 'src/app/transaction-details/transaction-details.component';
import { ConfirmationWindowComponent } from '../confirmation-window/confirmation-window.component';
import { AwsUploadService } from 'src/app/services/aws-upload.service';
import { CabServiceService } from 'src/app/services/cab-service.service';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent {

  documentList: any[] = [];
  UID: string = '';
  source: string = '';
  userType: string = '';
  constructor(public dialogRef: MatDialogRef<TransactionDetailsComponent>, @Inject(MAT_DIALOG_DATA) private data: any, private _service: AdminService, private dialog: MatDialog,public docUpload:AwsUploadService,private cabservice:CabServiceService) {
    this.source = data.source;
    this.userType = data.userType;
    console.log(data, this.source);
    this.UID = data.UID;
    this.documentList = data.content;
  }

  approve(document: any) {
    const dialog = this.dialog.open(ConfirmationWindowComponent, {
      data: {
        type: "approveDoc"
      }
    })
    dialog.afterClosed().subscribe(result => {
      console.log("Result:",result)
      if (result == 'Approved') {
        this._service.approveDoc(this.source, document.documentName, this.UID).subscribe((res) => {
          alert(res);
          this.documentList.forEach(element => { if (element.documentName == document.documentName) element.status = "Approved" })
        })
      }
      console.log("DOC LIst : ",this.documentList)
    })
  }

  reject(document: any){
    const dialog = this.dialog.open(ConfirmationWindowComponent, {
      data: {
        type: "rejectDoc"
      }
    })
    dialog.afterClosed().subscribe(result => {
      if (result == 'Rejected') {
        this._service.rejectDoc(this.source, document.documentName, this.UID).subscribe((res) => {
        alert(res);
        this.documentList.forEach(element => { if (element.documentName == document.documentName) element.status = "Rejected" })
        })
  }})
  }
  onFileSelected(event: any, source: string) {
    const file = event.target.files[0].name;
    if (file) {
      
      this.docUpload.onFileSelected(event).then((res: any) => {
        let reqObj = {
          cabNumber: this.UID,
          registrationCertificateUpload: source == 'Registration Certificate' ? res.fileUrl:null,
          registrationCertificateStatus:source == 'Registration Certificate' ? 'Unapproved':null,
          InsuranceUpload:source == 'Insurance Certificate' ? res.fileUrl:null,
          InsuranceStatus:source == 'Insurance Certificate' ? 'Unapproved':null,
          PermitUpload:source == 'Permit Certificate' ? res.fileUrl:null,
          PermitStatus:source == 'Permit Certificate' ? 'Unapproved':null,
          CarOwnerAadharUpload:source == "Owner's Aadhar Card" ? res.fileUrl:null,
          CarOwnerAadharStatus:source == "Owner's Aadhar Card" ? 'Unapproved':null,
          CarOwnerPanCardUpload:source == "Owner's Pan Card" ? res.fileUrl:null,
          CarOwnerPanCardStatus:source == "Owner's Pan Card" ? 'Unapproved':null,
          CarPhotoWithOwnerUploaod:source == 'Photos with owner' ? res.fileUrl:null,
          CarPhotoWithOwnerStatus:source == 'Photos with owner' ? 'Unapproved':null,
        }
        this.cabservice.cabDocumentsUpdate(reqObj).subscribe((res)=>{
          this.documentList.forEach((element: any) => element.documentName == source ? element.status = 'Unapproved':'' )

        })
      }).catch((error) => {
        console.log("Error in ts: ", error);
      });
    }
  }
}
