<div class="container-fluid w-90 pt-2">
    <mat-card class="card pt-4">
        <div class="row">
            <div class="col-md-2 profile-container">
                <div class="profile-image">
                    <img [src]="driver_data.profilePic">
                </div>
            </div>
            <div class="col-md-2 profile-detail">
                <h1>{{driverProfile.driverName}}</h1>
            </div>
            <div class="col-md-4 contact-details">
                <span class="" *ngIf="driver_data.emailId">
                    <mat-icon class="details-heading">mail</mat-icon>
                    <span class="customer-detail ml-1">{{driverProfile.emailId}}</span>
                </span>
                <span class=""><mat-icon class="details-heading">call</mat-icon><span
                        class="customer-detail ml-1">{{driverProfile.mobileNumber}}</span></span>
            </div>
            <div class="col-md-2 contact-details logout">
                <button mat-icon-button style="color: #b38405;" (click)="logout()">
                    <mat-icon>logout</mat-icon>
                </button>
            </div>
            <div class="col-md-2 edit-details">
                <button mat-stroked-button (click)="edit_profile()">Edit</button>
            </div>
        </div>
    </mat-card>
</div>
<div class="container-fluid pt-2 w-90">
    <mat-card class="card">
        <div class="edit-allfield">
            <p class="h2">Driver Details</p>
            <mat-icon class="icon" *ngIf="!toggleButton" (click)="editDocument()">edit</mat-icon>
            <div class="btn" *ngIf="toggleButton">
                <button mat-stroked-button (click)="saveProfile()">Save</button>
            </div>
        </div>
        <mat-divider></mat-divider>
        <br>
        
        <mat-card class="pt-4 doc-card">
            <div class="document">
                <p style="font-size: 1.2rem; font-weight: 500;">General Details</p>
                </div>
                <mat-divider></mat-divider>
                <div class="info">
                    <span>Driver ID</span>
                    <div class="d-flex">
                        <span>{{driverProfile.driver_id}}</span>
                    </div>
                </div>
                
                <div class="info">
                    <span>Profile Status</span>
                    <div class="d-flex">
                        <span *ngIf="driverProfile.status == 'Approved'" class="ApprovedStatus">Approved</span>
                        <span *ngIf="driverProfile.status == 'Rejected'" class="RejectedStatus">Rejected</span>
                        <span *ngIf="driverProfile.status == 'Unapproved'" class="UnApprovedStatus">Under Review</span>
                    </div>
                </div>
                <div class="info">
                    <span>Connected With</span>
                    <div class="d-flex">
                        <span>{{driverProfile.connectedWith}}</span>
                    </div>
                </div>
                </mat-card>
                <mat-card class="pt-4 doc-card">
                    <div class="document">
                        <p style="font-size: 1.2rem; font-weight: 500;">Document Details</p>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="info">
                        <span>Aadhar Number</span>
                        <div class="d-flex">
                            <span>{{driverProfile.aadharNumber }}</span>
                        </div>
                    </div>
                    <div class="info">
                        <span>Uploaded Aadhar Card</span>
                        <div style="display: flex;">
                            <input class="form-control" type="file" (change)="onFileSelected($event, 'aadharUpload')"
                                [(ngModel)]="driverProfile.aadharUpload"
                                *ngIf="toggleButton && driverProfile.aadharStatus != 'Approved'">
                            <span *ngIf="!toggleButton && driverProfile.aadharStatus == 'Rejected'"
                                class="RejectedStatus">Rejected</span>
                            <span *ngIf="!toggleButton && driverProfile.aadharStatus == 'Unapproved' && driverProfile.aadharUpload"
                                class="UnApprovedStatus">Under
                                Review</span>
                            <span *ngIf="!toggleButton && driverProfile.aadharStatus == 'Unapproved' && !driverProfile.aadharUpload"
                                class="UnApprovedStatus">Not Uploaded</span>
                            <div class="container text-center jumbotron" *ngIf="driverProfile.aadharStatus == 'Approved'">
                                <a [href]="driverProfile.aadharUpload" target="_blank">View</a>
                            </div>
                        </div>
                    </div>
                    <div class="info">
                        <span>PAN Card</span>
                        <div class="d-flex">
                            <span>{{driverProfile.panNumber}}</span>
                        </div>
                    </div>
                    <div class="info">
                        <span>Uploaded Pan Card</span>
                        <div style="display: flex;">
                            <input class="form-control" type="file" (change)="onFileSelected($event, 'panUpload')"
                                [(ngModel)]="driverProfile.panUpload" *ngIf="toggleButton && driverProfile.panStatus != 'Approved'">
                            <span *ngIf="!toggleButton && driverProfile.panStatus == 'Rejected'" class="RejectedStatus">Rejected</span>
                            <span *ngIf="!toggleButton && driverProfile.panStatus == 'Unapproved' && driverProfile.panUpload"
                                class="UnApprovedStatus">Under Review</span>
                            <span *ngIf="!toggleButton && driverProfile.panStatus == 'Unapproved' && !driverProfile.panUpload"
                                class="RejectedStatus">Not Uploaded</span>
                            <div class="container text-center jumbotron" *ngIf="driverProfile.panStatus == 'Approved'">
                                <a [href]="driverProfile.panUpload" target="_blank">View</a>
                            </div>
                        </div>
                    </div>
                    <div class="info">
                        <span>Driving License Number</span>
                        <div class="d-flex">
                            <span>{{driverProfile.dlNumber }}</span>
                        </div>
                    </div>
                    <div class="info">
                        <span>Uploaded Driving License Card</span>
                        <div style="display: flex;">
                            <input class="form-control" type="file" (change)="onFileSelected($event, 'dlUpload')"
                                [(ngModel)]="driverProfile.dlUpload" *ngIf="toggleButton && driverProfile.dlStatus != 'Approved'">
                            <span *ngIf="!toggleButton && driverProfile.dlStatus == 'Rejected'" class="RejectedStatus">Rejected</span>
                            <span *ngIf="!toggleButton && driverProfile.dlStatus == 'Unapproved' && driverProfile.dlUpload"
                                class="UnApprovedStatus">Under
                                Review</span>
                            <span *ngIf="!toggleButton && driverProfile.dlStatus == 'Unapproved' && !driverProfile.dlUpload"
                                class="UnApprovedStatus">Not Uploaded</span>
                            <div class="container text-center jumbotron" *ngIf="driverProfile.dlStatus == 'Approved'">
                                <a [href]="driverProfile.dlUpload" target="_blank">View</a>
                            </div>
                        </div>
                    </div>
                    <div class="info">
                        <span>Police Verificatoin certificate</span>
                        <div style="display: flex;">
                            <input class="form-control" type="file" (change)="onFileSelected($event,'policeVerification')"
                                [(ngModel)]="driverProfile.policeVerification"
                                *ngIf="toggleButton && driverProfile.policeVerificationStatus != 'Approved'">
                            <span *ngIf="!toggleButton && driverProfile.policeVerificationStatus == 'Rejected'"
                                class="RejectedStatus">Rejected</span>
                            <span
                                *ngIf="!toggleButton && driverProfile.policeVerificationStatus == 'Unapproved' && driverProfile.policeVerification"
                                class="UnApprovedStatus">Under Review</span>
                            <span
                                *ngIf="!toggleButton && driverProfile.policeVerificationStatus == 'Unapproved' && !driverProfile.policeVerification"
                                class="RejectedStatus">Not Uploaded</span>
                            <div class="container text-center jumbotron" *ngIf="driverProfile.policeVerificationStatus == 'Approved'">
                                <a [href]="driverProfile.policeVerification" target="_blank">View</a>
                            </div>
                        </div>
                    </div>
                
                </mat-card>
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                <!-- <mat-card class="pt-4 doc-card">
                                                                                                                                            <div class="document">
                <p style="font-size: 1.2rem; font-weight: 500;">Document Details</p>
                <mat-icon class="icon" (click)="editDocument(true)">edit</mat-icon>
            </div>
            <mat-divider></mat-divider>
            <form [formGroup]="upload_document_details_from">
                <div class="info">
                    <span>Aadhar Card</span>
                    <div class="d-flex" *ngIf="upload_img">
                        <input class="form-control" formControlName="aadharUpload" type="file" [(ngModel)]="driverProfile['aadharUpload']"
                            (change)="onFileSelected($event,'aadharUpload')">
                    </div>
                    <div class="d-flex" *ngIf="!upload_img">
                    <a [href]="driverProfile.aadharUpload" target="_blank">Click here</a>

                    </div>
                </div>
                <div class="info">
                    <span>PAN Card</span>
                    <div class="d-flex" *ngIf="upload_img">
                        <input class="form-control" type="file" formControlName="panUpload" (change)="onFileSelected($event,'panUpload')"
                            [(ngModel)]="driverProfile['panUpload']">
                    </div>
                    <div class="d-flex" *ngIf="!upload_img">
= <a [href]="driverProfile.panUpload" target="_blank">Click here</a>
                      </div>
                </div>
                <div class="info">
                    <span>Driving Licence</span>
                    <div class="d-flex" *ngIf="upload_img">
                        <input class="form-control" type="file" formControlName="dlUpload" (change)="onFileSelected($event,'dlUpload')"
                            [(ngModel)]="driverProfile['dlUpload']">
                    </div>
                    <div class="d-flex" *ngIf="!upload_img">
                        <a [href]="driverProfile.dlUpload" target="_blank">Click here</a>
                      </div>
                </div>
                <div class="info">
                    <span>Police Verification</span>
                    <div class="d-flex" *ngIf="upload_img">
                        <input class="form-control" type="file" formControlName="policeVerification"
                            [(ngModel)]="driverProfile['policeVerification']"
                            (change)="onFileSelected($event,'policeVerification')">
                    </div>
                    <div class="d-flex" *ngIf="!upload_img">
                        <a [href]="driverProfile.policeVerification" target="_blank">Click here</a>
                      </div>
                </div>
                <div class="info">
                    <span>Aadhar Number</span>
                    <textarea class="textarea-inline-control" formControlName="aadharNumber"
                        placeholder="XXXX XXXX 2345" *ngIf="upload_img"  maxlength="12" appNumericOnly type="tel" [(ngModel)]="driverProfile.aadharNumber"></textarea>
                        <div class="d-flex" *ngIf="!upload_img" >
                            <span>{{driverProfile.aadharNumber}}</span>
                         </div>
                </div>
                <div class="info">
                    <span>Driving Licence Number</span>
                    <textarea class="textarea-inline-control" formControlName="dlNumber"
                        placeholder="XXXX XXXX 2345" *ngIf="upload_img" [(ngModel)]="driverProfile['dlNumber']"></textarea>
                        <div class="d-flex" *ngIf="!upload_img" maxlength="13">
                            <span>{{driverProfile.dlNumber}}</span>
                         </div>  
                </div>
                <div class="info">
                    <span>PAN Number</span>
                    <textarea class="textarea-inline-control" formControlName="panNumber"
                        placeholder="XXXX XXXX 2345" *ngIf="upload_img" maxlength="10" [(ngModel)]="driverProfile['panNumber']"></textarea>
                        <div class="d-flex" *ngIf="!upload_img">
                            <span>{{driverProfile.panNumber}}</span>
                         </div>
                </div>
                <div class="info justify-content-end" *ngIf="upload_img">
                    <button (click)="onUpload()" mat-stroked-button>
                        Save
                    </button>
                    <button (click)="editDocument(false)" mat-stroked-button>Cancel</button>
                </div> 
            </form>
        </mat-card>
        <mat-card class="pt-4 doc-card">
            <div class="document">
                <p style="font-size: 1.2rem; font-weight: 500;">Document Details</p>
                <mat-icon class="icon" (click)="editDocument(true)">edit</mat-icon>
            </div>
            <mat-divider></mat-divider>
            <form [formGroup]="upload_document_details_from">
                <div class="info">
                    <span>Aadhar Card</span>
                    <div class="d-flex" *ngIf="upload_img">
                        <input class="form-control" formControlName="aadharUpload" type="file"
                            [(ngModel)]="driverProfile['aadharUpload']" (change)="onFileSelected($event,'aadharUpload')">
                    </div>
                    <div class="d-flex" *ngIf="!upload_img">
                        <a [href]="driverProfile.aadharUpload" target="_blank">Click here</a>
        
                    </div>
                </div>
                <div class="info">
                    <span>PAN Card</span>
                    <div class="d-flex" *ngIf="upload_img">
                        <input class="form-control" type="file" formControlName="panUpload"
                            (change)="onFileSelected($event,'panUpload')" [(ngModel)]="driverProfile['panUpload']">
                    </div>
                    <div class="d-flex" *ngIf="!upload_img">
                        <a [href]="driverProfile.panUpload" target="_blank">Click here</a>
                    </div>
                </div>
                <div class="info">
                    <span>Driving Licence</span>
                    <div class="d-flex" *ngIf="upload_img">
                        <input class="form-control" type="file" formControlName="dlUpload"
                            (change)="onFileSelected($event,'dlUpload')" [(ngModel)]="driverProfile['dlUpload']">
                    </div>
                    <div class="d-flex" *ngIf="!upload_img">
                        <a [href]="driverProfile.dlUpload" target="_blank">Click here</a>
                    </div>
                </div>
                <div class="info">
                    <span>Police Verification</span>
                    <div class="d-flex" *ngIf="upload_img">
                        <input class="form-control" type="file" formControlName="policeVerification"
                            [(ngModel)]="driverProfile['policeVerification']"
                            (change)="onFileSelected($event,'policeVerification')">
                    </div>
                    <div class="d-flex" *ngIf="!upload_img">
                        <a [href]="driverProfile.policeVerification" target="_blank">Click here</a>
                    </div>
                </div>
                <div class="info">
                    <span>Aadhar Number</span>
                    <textarea class="textarea-inline-control" formControlName="aadharNumber" placeholder="XXXX XXXX 2345"
                        *ngIf="upload_img" maxlength="12" appNumericOnly type="tel"
                        [(ngModel)]="driverProfile.aadharNumber"></textarea>
                    <div class="d-flex" *ngIf="!upload_img">
                        <span>{{driverProfile.aadharNumber}}</span>
                    </div>
                </div>
                <div class="info">
                    <span>Driving Licence Number</span>
                    <textarea class="textarea-inline-control" formControlName="dlNumber" placeholder="XXXX XXXX 2345"
                        *ngIf="upload_img" [(ngModel)]="driverProfile['dlNumber']"></textarea>
                    <div class="d-flex" *ngIf="!upload_img" maxlength="13">
                        <span>{{driverProfile.dlNumber}}</span>
                    </div>
                </div>
                <div class="info">
                    <span>PAN Number</span>
                    <textarea class="textarea-inline-control" formControlName="panNumber" placeholder="XXXX XXXX 2345"
                        *ngIf="upload_img" maxlength="10" [(ngModel)]="driverProfile['panNumber']"></textarea>
                    <div class="d-flex" *ngIf="!upload_img">
                        <span>{{driverProfile.panNumber}}</span>
                    </div>
                </div>
                <div class="info justify-content-end" *ngIf="upload_img">
                    <button (click)="onUpload()" mat-stroked-button>
                        Save
                    </button>
                    <button (click)="editDocument(false)" mat-stroked-button>Cancel</button>
                </div>
            </form>
        </mat-card> -->
        <!-- <mat-card class="pt-4 bank-card">
            <div class="bank">
                <p style="font-size: 1.2rem; font-weight: 500;">Bank Details</p>
                <mat-icon class="icon" (click)="editBankDetail()">edit</mat-icon>
            </div>
            <mat-divider></mat-divider>
            <form [formGroup]="account_details_from">
                <div class="info">
                    <span>Account Number</span>
                    <textarea class="textarea-inline-control" formControlName="bankAccountNumber"
                        placeholder="XXXX XXXX 2345" *ngIf='showbankbtn'></textarea>
                </div>
                <div class="info">
                    <span>Bank Name</span>
                    <textarea class="textarea-inline-control" formControlName="bankName" placeholder="XXXX XXXX 2345"
                        *ngIf='showbankbtn'></textarea>
                </div>
                <div class="info">
                    <span>IFSC Code</span>
                    <textarea class="textarea-inline-control" formControlName="ifsc_Code" placeholder="XXXX XXXX 2345"
                        *ngIf='showbankbtn'></textarea>
                </div>
                <div class="info">
                    <span>UPI</span>
                    <textarea class="textarea-inline-control" formControlName="upi" placeholder="XXXX XXXX 2345"
                        *ngIf='showbankbtn'></textarea>
                </div>
                <div class="info">
                    <span>PhonePe Number</span>
                    <textarea class="textarea-inline-control" formControlName="phonePay" placeholder="XXXX XXXX 2345"
                        *ngIf='showbankbtn'></textarea>
                </div>
                <div class="info">
                    <span>G-Pay Number</span>
                    <textarea class="textarea-inline-control" formControlName="gPay" placeholder="XXXX XXXX 2345"
                        *ngIf='showbankbtn'></textarea>
                </div>
                <div class="info">
                    <span>PayTM Number</span>
                    <textarea class="textarea-inline-control" formControlName="paytm" placeholder="XXXX XXXX 2345"
                        *ngIf='showbankbtn'></textarea>
                </div>
            </form>

            <div class="btn" *ngIf="showbankbtn">
                <button mat-stroked-button (click)="update_account()">Save</button>
            </div>

        </mat-card> -->
    </mat-card>
</div>