import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgentRegistrationComponent, carDetails } from '../agent-registration.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CabServiceService } from 'src/app/services/cab-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminService } from 'src/app/services/admin.service';
import { AwsUploadService } from 'src/app/services/aws-upload.service';


@Component({
  selector: 'app-add-car-dialog',
  templateUrl: './add-car-dialog.component.html',
  styleUrls: ['./add-car-dialog.component.scss']
})
export class AddCarDialogComponent {

  phoneNumber:string = ''
  otpSent: boolean = false;
  otp: string='';
  orderId: any;
  cabCategoryList: any[] = [];
  ownerName: string = "";
  ownerPhoneNumber: string = "";
  cabFuelTypeList = ["Petrol", "Diesel", "CNG", "Electric"];
  isOtpVerified = false;
  constructor(private formBuilder: FormBuilder,private cabService : CabServiceService,
    public dialogRef: MatDialogRef<AgentRegistrationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ,private snackBar: MatSnackBar,public adminservice:AdminService
    , public docUpload: AwsUploadService
  ) {
    console.log(data);
    this.cabCategoryList = data.cabCategoryList;
    this.ownerName = data.ownerName;
    this.ownerPhoneNumber = data.ownerPhoneNumber;
  }
  carFormGroup!: FormGroup;

  ngOnInit(): void {
    this.carFormGroup = this.formBuilder.group({
      CarBrand: ['', [Validators.required]],//, Validators.required],
      CarName: ['', [Validators.required]],//, Validators.required],
      CarNumber: ['', [Validators.required]],//, Validators.required],
      FuelType: ['', [Validators.required]],//, Validators.required],
      NumberOfSeats: ['', [Validators.required]],//, Validators.required],
      cabCategoryId: ['', [Validators.required]],
      registrationCertificateUpload: ['', [Validators.required]],//, Validators.required],
      InsuranceValidity: ['', [Validators.required]],
      InsuranceUpload: ['', [Validators.required]],//, Validators.required],
      PermitUpload: ['', [Validators.required]],//, Validators.required],
      CarOwnerName: [this.ownerName ? this.ownerName : '', [Validators.required]],//, Validators.required],
      CarOwnerPhoneNumber: [this.ownerPhoneNumber ? this.ownerPhoneNumber : '', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],//, Validators.required],
      CarOwnerAadharUpload: ['', [Validators.required]],//, Validators.required],
      CarOwnerPanCardUpload: ['', [Validators.required]],//, Validators.required],
      CarPhotoWithOwnerUploaod: ['', [Validators.required]],//, Validators.required],

      // Add more form controls for other fields and document uploads
    });
  }
  

  addCar() {
    this.carFormGroup.get('CarOwnerPhoneNumber')?.enable();
    console.log("FormData: ",this.carFormGroup);
    if (this.carFormGroup.valid) {
      // Add logic to save car details
      this.cabService.cab_registration(this.carFormGroup.value  ).subscribe(
        (res) => {
          console.log(res)
          this.dialogRef.close({cabID: res.cabid,cabDetails: this.carFormGroup.value});
        }
        ,
        (error)=>{
        console.log(error)
         this.carFormGroup.get('CarOwnerPhoneNumber')?.disable();}
      );
    } else {
      // Handle form validation errors
         this.carFormGroup.get('CarOwnerPhoneNumber')?.disable();
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  //OTP section 
  SendOTP() {
    this.otpSent = true;    
    this.adminservice.getOTPValue(parseInt(this.carFormGroup.value.CarOwnerPhoneNumber)).subscribe((res: any) => {
      this.orderId = res.orderId;
      this.snackBar.open("OTP sent successfully !", 'Close', { duration: 3000 });
      this.carFormGroup.get('CarOwnerPhoneNumber')?.disable()
    }, (err) => {
      this.snackBar.open(err.error.error, 'Close', { duration: 3000 });
    })
  }
  async verifyOtp(): Promise<boolean> {
    this.carFormGroup.get('CarOwnerPhoneNumber')?.enable();
    let phNumber = this.carFormGroup.value.CarOwnerPhoneNumber;
    this.carFormGroup.get('CarOwnerPhoneNumber')?.disable();
    return this.adminservice.getOTPVerified(this.orderId, parseInt(this.otp), parseInt(phNumber)).toPromise()
        .then((res: any) => {
          this.isOtpVerified = true;
          this.snackBar.open("OTP verified successfully !", 'Close', { duration: 3000 });
            return res.isOTPVerified;
        })
        .catch(error => {
          this.snackBar.open(error, 'Close', { duration: 3000 });
            return false;
        });
  }
  ResendOTP(){
    this.adminservice.getReSendOTP(this.orderId).subscribe((res:any)=>{
      this.orderId = res.orderId;
      this.snackBar.open("OTP resend successfull !", 'Close', { duration: 3000 });
    },(err)=>{
      this.snackBar.open(err.error.error, 'Close', { duration: 3000 })
    })
  }
  Cancel(){
    this.carFormGroup.get('CarOwnerPhoneNumber')?.enable();
    this.otpSent=false;
  }
  isMobileNumberValid(): boolean {
    return this.carFormGroup.value.CarOwnerPhoneNumber.length === 10;
  }

  activateLoginBtn():boolean{
    return this.otp.length ===6
  }
  //fileupload
  onFileSelected(event: any, source: string) {
    const file = event.target.files[0].name
    if (file) {
      this.docUpload.onFileSelected(event).then((res: any) => {
        if (this.data.hasOwnProperty(source)) {
          this.data[source] = res.fileUrl;
          console.log("Driver Info: ", this.data);
        } else {
          console.error("File URL is not available.");
        }
      }).catch((error) => {
        console.log("Error in ts: ", error);
      });
    }
  }
}
