import { Component } from '@angular/core';

@Component({
  selector: 'app-customer-bookings',
  templateUrl: './customer-bookings.component.html',
  styleUrls: ['./customer-bookings.component.scss']
})
export class CustomerBookingsComponent {
  column1Data = [
    { field: "from", label: "From" },
    { field: "to", label: "To" },
    { field: "date", label: "Date" },
    { field: "driverName", label: "Driver Name" },
    { field: "vehicleNo", label: "Vehicle Number" },
    { field: "driverContact", label: "Driver Contact" },
    { field: "action", label: "Action" }
  ];
  table1Data = [
    {
      from: "City A",
      to: "City B",
      date: "2023-01-15",
      driverName: "John Doe",
      vehicleNo: "ABC123",
      driverContact: "123-456-7890",
      action: ""
    },
    {
      from: "City B",
      to: "City C",
      date: "2023-02-20",
      driverName: "Jane Smith",
      vehicleNo: "XYZ789",
      driverContact: "987-654-3210",
      action: ""
    },
    {
      from: "City C",
      to: "City D",
      date: "2023-03-25",
      driverName: "Robert Johnson",
      vehicleNo: "MNO456",
      driverContact: "555-123-4567",
      action: ""
    },
    {
      from: "City D",
      to: "City E",
      date: "2023-04-10",
      driverName: "Emily Davis",
      vehicleNo: "PQR789",
      driverContact: "111-222-3333",
      action: ""
    },
    {
      from: "City E",
      to: "City A",
      date: "2023-05-15",
      driverName: "Michael Brown",
      vehicleNo: "STU001",
      driverContact: "333-444-5555",
      action: ""
    }
  ];
  column2Data = [
    { field: "DateTime", label: "DATE/TIME" },
    { field: "From", label: "From" },
    { field: "To", label: "TO" },
    { field: "Distance", label: "Distance" },
    { field: "ReceiptAmountExtraCharges", label: "Receipt" }
  ];
  

 table2Data = [
    { DateTime: "2023-12-20 08:30 AM", From: "Home", To: "Work", Distance: "10 miles", ReceiptAmountExtraCharges: "$15.00"},
    { DateTime: "2023-12-21 12:45 PM", From: "Airport", To: "Hotel", Distance: "25 miles", ReceiptAmountExtraCharges: "$30.50"},
    { DateTime: "2023-12-22 05:15 PM", From: "Train Station", To: "Conference Center", Distance: "5 miles", ReceiptAmountExtraCharges: "$8.75"}
    // Add more dummy data as needed
  ];
  column3Data = [
    { field: "DateTime", label: "DATE/TIME" },
    { field: "From", label: "From" },
    { field: "To", label: "TO" },
    { field: "Distance", label: "Distance" },
    { field: "Penalities", label: "Penalities" },
    {field:"Cancellation_Charge",label:"Cancellation Charge"}
  ];
  

 table3Data = [
    { DateTime: "2023-12-25 10:15 AM", From: "Work", To: "Home", Distance: "12 miles", Penalities: "$5.00", Cancellation_Charge: "$10.00"},
    { DateTime: "2023-12-26 02:30 PM", From: "Hotel", To: "Airport", Distance: "20 miles", Penalities: "$8.50", Cancellation_Charge: "$15.00"},
    { DateTime: "2023-12-27 06:45 PM", From: "Conference Center", To: "Home", Distance: "8 miles", Penalities: "$3.00", Cancellation_Charge: "$7.50"}
    // Add more dummy data as needed
  ];
}
