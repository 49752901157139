import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TableComponent } from 'src/app/admin/table/table.component';
import { AdminService } from 'src/app/services/admin.service';
import { ConfirmationWindowComponent } from '../confirmation-window/confirmation-window.component';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss']
})
export class BookingDetailsComponent {

  bookingId: string = "";
  bookingDetails: any;
  bookingDetailsFromTable: any = "";

  cancelRequested: boolean = false;
  cancelRequestMessage: string = "";

  refundRequested: boolean = false;
  transactionID: string = '';
  amount: number = 0;
  modeOfTransaction: string = '';

  constructor(public dialogRef: MatDialogRef<TableComponent>, private dialog: MatDialog, private snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) private data: any, private _service: AdminService) {
    console.log("From Booking Details: ",data);
    this.bookingId = data.content.ride_id;
    this.bookingDetailsFromTable = data.content;
    this._service.getBookingDetails(this.bookingId).subscribe((res) => {
      // console.log('Collect payment', res);
      this.bookingDetails = res;
    })
  }

  cancelRide(){
    console.log(this.cancelRequestMessage);
    const dialog = this.dialog.open(ConfirmationWindowComponent, {
      data: {
        type: "confirmation"
      }
    })
    let adminId = this.fetchAdminData();
    dialog.afterClosed().subscribe(result => {
      if (result == 'ConfirmedCancellation')
        this._service.cancelRide(this.bookingId, this.cancelRequestMessage, adminId).subscribe(
          (res) => {
            console.log(res);
            this.snackBar.open("Ride cancelled successfully", 'Close');
            this.dialogRef.close();
          })
      console.log("Cancellation confirmed");
    })
  }

  fetchAdminData(): string {
    let auth = localStorage.getItem('admin_auth');
    return auth ? auth : "";
  }

  addTransaction(){
    this._service.addTransaction({
      transactionId: this.transactionID,
      bookingId: this.bookingId, 
      customerId: this.bookingDetails.customer_id,
      paidTo: "Customer",
      amount: this.amount,
      paymentMode: this.modeOfTransaction,
      paymentStatus: "Paid",
      paymentType: "Refund Payment",
      transactionDate: (new Date).toString()
    }).subscribe((res) => {
      console.log("Transcation Added: ",res.transaction);
      this.snackBar.open("Transaction added successfully!", 'Close', { duration: 3000 })
      this.dialogRef.close();
      // this.amountCollected.emit()
    },((error) => {
      console.log(error);
      this.snackBar.open("Some error occured!", 'Close', { duration: 3000 })
    }))
  }

}
