
        <div class="container">
            <div class="card col-md-8">
                <form [formGroup]="registrationForm">
                    <div style="display: flex; justify-content: center;">
                        <h1 style="font-size: 2rem;">Registration Form</h1>
                    </div>
        
                    <!-- Personal Information -->
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Personal Details
                            </mat-panel-title>
                        </mat-expansion-panel-header>
        
                        <mat-form-field class="matInput-wd100">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="name" required>
                        </mat-form-field>
                        <mat-form-field class="matInput-wd100">
                            <mat-label>Phone Number</mat-label>
                            <input matInput formControlName="phoneNumber" type="tel" required  maxlength="10"
                                appNumericOnly>
                            </mat-form-field>
                            <div class="verify-button" *ngIf="!otpSent && !phoneVerified">
                            <button (click)="sendOtp()" [disabled]="!registrationForm.get('phoneNumber')?.valid"
                                class="btn verify-btn">Send OTP</button>
                        </div>
                        <div *ngIf="otpSent">
                            <mat-form-field class="matInput-wd100">
                                <mat-label>OTP</mat-label>
                                <input matInput formControlName="otp" type="tel" [(ngModel)]="otpValue" appNumericOnly>
                            </mat-form-field>
                            <div class="verify-button">
                                <button (click)="verifyOtp('ProfileDetails')" class="btn verify-btn">Verify</button>
                                <button class="btn verify-btn ms-2" (click)="ResendOTP()">Resend OTP</button>
                                <button class="btn verify-btn ms-2" (click)="Cancel()">Cancel</button>
                            </div>
                        </div>
                        <mat-form-field class="matInput-wd100">
                            <mat-label>EmailID</mat-label>
                            <input matInput formControlName="emailId">
                        </mat-form-field>
                        <div class="form-doc-fields">
                            <span>Profile Picture</span>
                            <div class="info">
                                <div style="display: flex;">
                                    <input class="form-control" type="file" (change)="onFileSelected($event,'profilePic')" formControlName="profilePic"
                                        [(ngModel)]="AgentObj.profilePic">
                                    <div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
        
                    <!-- Registration Type -->
                    <div class="radioButton">
                        <label>Register As: </label>
                        <mat-radio-group formControlName="type" [disabled]="!phoneVerified">
                            <mat-radio-button value="TravelAgent" [disabled]="!phoneVerified">Travel Agent</mat-radio-button>
                            <mat-radio-button value="Owner" [disabled]="!phoneVerified">Owner</mat-radio-button>
                        </mat-radio-group>
                    </div>
        
                    <!-- Agency Details  -->
                    <!-- Documents Details  -->
                    <mat-expansion-panel [disabled]="!phoneVerified">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Documents
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <!-- Add form controls here -->
                        <div *ngIf="registrationForm.get('type')?.value === 'TravelAgent'">
                            <mat-form-field class="matInput-wd100">
                                <mat-label>Agency Name <span class="text text-danger">*</span></mat-label>
                                <input matInput formControlName="agencyName">
                            </mat-form-field>
                            <mat-form-field class="matInput-wd100">
                                <mat-label>Agency Address <span class="text text-danger">*</span></mat-label>
                                <input matInput formControlName="agencyAddress">
                            </mat-form-field>
        
                            <div class="form-doc-fields">
                                <span>GST</span>
                                <mat-form-field class="matInput-wd100">
                                    <mat-label>GST Number <span class="text text-danger">*</span></mat-label>
                                    <input matInput formControlName="GST" maxlength="15">
                                </mat-form-field>
                                <div class="info">
                                    <div style="display: flex;">
                                        <input class="form-control" type="file" (change)="onFileSelected($event,'GSTUpload')" formControlName="GSTUpload"
                                            [(ngModel)]="AgentObj['GSTUpload']">
                                        <div>
                                            <!-- <button (click)="onUpload()" class="btn btn-success">
                                                Upload
                                            </button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <mat-form-field class="matInput-wd100">
                                                    <mat-label>Work Location</mat-label>
                                                    <input matInput formControlName="workLocation"> -->
                            <mat-form-field class="matInput-wd100">
                                <mat-label>Work Location </mat-label>
                                <mat-select formControlName="workLocation">
                                    <mat-option *ngFor="let location of locations" [value]="location">{{location}}</mat-option>
                                </mat-select>
                                
                                </mat-form-field>
                        <!-- </mat-form-field> -->
                        <div class="form-doc-fields">
                            <span>Pan Card</span>
                            <mat-form-field class="matInput-wd100">
                                <mat-label>Pan Card Number</mat-label>
                                <input matInput maxlength="10" formControlName="panCard">
                            </mat-form-field>
                            <div class="info">
                                <div style="display: flex;">
                                    <input class="form-control" type="file" (change)="onFileSelected($event,'panCardUpload')"
                                        formControlName="panCardUpload" [(ngModel)]="AgentObj['panCardUpload']">
                                    <div>
                                        <!-- <button (click)="onUpload()" class="btn btn-success">
                                            Upload
                                        </button> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-doc-fields">
                            <span>Aadhar Card</span>
                            <mat-form-field class="matInput-wd100">
                                <mat-label>Aadhar Card Number</mat-label>
                                <input matInput formControlName="aadharCard" appNumericOnly maxlength="12">
                            </mat-form-field>
                            <div class="info">
                                <div style="display: flex;">
                                    <input class="form-control" type="file" (change)="onFileSelected($event,'aadharCardUpload')"
                                        formControlName="aadharCardUpload" [(ngModel)]="AgentObj['aadharCardUpload']">
                                    <div>
                                        <!-- <button (click)="onUpload()" class="btn btn-success">
                                            Upload
                                        </button> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <mat-form-field class="matInput-wd100">
                            <mat-label>Account Number</mat-label>
                            <input matInput formControlName="accountNumber" maxlength="16" appNumericOnly>
                        </mat-form-field>
                        <mat-form-field class="matInput-wd100">
                            <mat-label>Bank Name</mat-label>
                            <!-- <input matInput formControlName="bankName"> -->
                            <mat-select formControlName="bankName">
                                <mat-option *ngFor="let bank of banks" [value]="bank">{{bank}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="matInput-wd100">
                            <mat-label>IFSC Code</mat-label>
                            <input matInput formControlName="IFSCCode" maxlength="11">
                        </mat-form-field>
        
                        <mat-form-field class="matInput-wd100">
                            <mat-label>UPI ID</mat-label>
                            <input matInput formControlName="upiId">
                        </mat-form-field>
        
                        <mat-form-field class="matInput-wd100">
                            <mat-label>PhonePay Number</mat-label>
                            <input matInput formControlName="phonePeNumber" maxlength="10" appNumericOnly>
                        </mat-form-field>
<!--         
                        <div class="verify-button mt-2">
                            <button class="btn verify-btn">Save</button>
                        </div> -->
                    </mat-expansion-panel>
        
                    <!-- If owner  -->
                    <div class="radioButton" *ngIf="registrationForm.get('type')?.value === 'Owner'">
                        <label>Are you a driver: </label>
                        <mat-radio-group formControlName="ownerAsDriver" [disabled]="!phoneVerified">
                            <mat-radio-button value="Yes" [disabled]="!phoneVerified">Yes</mat-radio-button>
                            <mat-radio-button value="No" [disabled]="!phoneVerified">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
        
                    <!-- Add Driver -->
                    <mat-expansion-panel
                        *ngIf="registrationForm.get('type')?.value === 'TravelAgent' || (registrationForm.get('type')?.value === 'Owner' && registrationForm.get('ownerAsDriver')?.value == 'No')"
                        [disabled]="!phoneVerified">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Add Driver
                            </mat-panel-title>
                        </mat-expansion-panel-header>
        
                        <div *ngIf="listOfDrivers.length > 0">
                            <H1>List Of Drivers</H1>
                            <table mat-table [dataSource]="listOfDrivers">
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef> Name</th>
                                    <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                                </ng-container>
                                <ng-container matColumnDef="PhoneNumber">
                                    <th mat-header-cell *matHeaderCellDef> Phone Number </th>
                                    <td mat-cell *matCellDef="let element"> {{element.PhoneNumber}} </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="driverColumnData"></tr>
                                <tr mat-row *matRowDef="let row; columns: driverColumnData;"></tr>
                            </table>
                        </div>
        
                        <div class="verify-button">
                            <button mat-button (click)="openAddDriverDialog()">+ Add Driver</button>
                        </div>
        
                    </mat-expansion-panel>
        
                    <!-- Add Driving Details if OwnerAsDriver -->
                    <mat-expansion-panel
                        *ngIf="registrationForm.get('type')?.value === 'Owner' && registrationForm.get('ownerAsDriver')?.value == 'Yes'"
                        [disabled]="!phoneVerified">
                        <!-- Add form controls here -->
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Add Driving Details
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field class="matInput-wd100">
                            <mat-label>Driving License Number</mat-label>
                            <input matInput formControlName="drivingLicense">
                        </mat-form-field>
                        <div class="info">
                            <span>Driving License</span>
                            <div style="display: flex;padding-top: 1rem;">
                                <input class="form-control" type="file" (change)="onFileSelected($event,'drivingLicenseUpload')"
                                    formControlName="drivingLicenseUpload" [(ngModel)]="AgentObj['drivingLicenseUpload']">
                                <div>
                                    <!-- <button (click)="onUpload()" class="btn btn-success">
                                        Upload
                                    </button> -->
                                </div>
                            </div>
                        </div>
                        <div class="form-doc-fields">
                            <span>Police Verification</span>
        
                            <div class="info">
                                <div style="display: flex;">
                                    <input class="form-control" type="file" (change)="onFileSelected($event,'policeVerificationUpload')"
                                        formControlName="policeVerificationUpload" [(ngModel)]="AgentObj['policeVerificationUpload']">
                                    <div>
                                        <!-- <button (click)="onUpload()" class="btn btn-success">
                                            Upload
                                        </button> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
        
                    <mat-expansion-panel [disabled]="!phoneVerified">
                        <!-- Add form controls here -->
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Add Car
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngIf="listOfCars.length > 0">
                            <H1>List Of Cars</H1>
                            <table mat-table [dataSource]="listOfCars">
                                <ng-container matColumnDef="CarBrand">
                                    <th mat-header-cell *matHeaderCellDef> Car Brand</th>
                                    <td mat-cell *matCellDef="let element"> {{element.CarBrand}} </td>
                                </ng-container>
                                <ng-container matColumnDef="CarName">
                                    <th mat-header-cell *matHeaderCellDef> Car Name </th>
                                    <td mat-cell *matCellDef="let element"> {{element.CarName}} </td>
                                </ng-container>
                                <ng-container matColumnDef="CarNumber">
                                    <th mat-header-cell *matHeaderCellDef> Car Number </th>
                                    <td mat-cell *matCellDef="let element"> {{element.CarNumber}} </td>
                                </ng-container>
                                <ng-container matColumnDef="SeeMore">
                                    <th mat-header-cell *matHeaderCellDef> Veiw Details </th>
                                    <td mat-cell *matCellDef="let element"> <a> See more </a> </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="carColumnData"></tr>
                                <tr mat-row *matRowDef="let row; columns: carColumnData;"></tr>
                            </table>
                        </div>
                <div class="verify-button">
                    <button (click)="openAddCarDialog()" class="btn verify-btn">+ Add Car</button>
                </div>
            </mat-expansion-panel>

            <div class="save-btn-div">
                <button class="btn save-btn" [disabled]="!phoneVerified || registrationForm.invalid"
                    (click)="saveAgent()">Save</button>
            </div>
            </form>
            </div>
</div>