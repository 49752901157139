import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../environment';

@Injectable({
  providedIn: 'root'
})
export class DriverService {

  backendURL: string = environment.apiUrl;

  constructor(public http: HttpClient) { }

  login(login_data: any): Observable<any> {
    return this.http.post(this.backendURL + `/driver/login`, login_data)
  }

  register(register_data: any, type:any): Observable<any> {
    return this.http.post(this.backendURL + `/driver/driver_register/${type}`, register_data)
  }

  update(update_data: any): Observable<any> {
    return this.http.post(this.backendURL + `/driver/profileUpdate`, update_data)
  }

  fetchData(driver_id: any): Observable<any> {
    return this.http.get(this.backendURL + `/driver/profile/${driver_id}`)
  }

  updateDocuments(data: any): Observable<any> {
    return this.http.put(this.backendURL + `/driver/driverProfile`, data)
  }

  // updateBankDetails(data: any): Observable<any> {
  //   return this.http.post(this.backendURL + `/driver/uploadBankDeatils`, data)
  // }

  driverScheduleRides(driver_id:any): Observable<any> {
    return this.http.get(this.backendURL + `/driver/driverScheduleRides/${driver_id}`)
  }
}
