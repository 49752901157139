<ng-container *ngIf="showLoader; else content" class="loader-container">
  <div class="row">
    <div class="loader"></div>
  </div>
</ng-container>
<ng-template #content>
  <mat-card class="example-card">
    <mat-card-header class="justify-content-around">
      <mat-card-title>My Bookings</mat-card-title>
    </mat-card-header>
  </mat-card>

  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">electric_car</mat-icon>
        Completed Trips
      </ng-template>
      <app-agent-table
        [data]="completedRides"
        [columnData]="column1Data"
        [flag]="'completed'"
      ></app-agent-table>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">electric_car</mat-icon>
        Cancelled Rides
      </ng-template>
      <app-agent-table
        [data]="cancelledRides"
        [columnData]="column2Data"
        [flag]="'Cancelled'"
      ></app-agent-table>
    </mat-tab>
  </mat-tab-group>
</ng-template>
