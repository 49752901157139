<mat-card class="example-card">
  <mat-card-header class="justify-content-around ">
      <mat-card-title>Customer Bookings</mat-card-title>
  </mat-card-header>
</mat-card>
<mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">electric_car</mat-icon>
       UpComing Trips
      </ng-template>
      <app-agent-table [data]="table1Data" [columnData]="column1Data" [flag]="'custUpcoming'"></app-agent-table>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">electric_car</mat-icon>
          completed Trips
        </ng-template>
        <app-agent-table [data]="table2Data" [columnData]="column2Data" [flag]="'custcompleted'"></app-agent-table>
      </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">electric_car</mat-icon>
        Cancelled Rides
      </ng-template>
      <app-agent-table [data]="table3Data" [columnData]="column3Data" [flag]="'custCancelled'"></app-agent-table>
    </mat-tab>
</mat-tab-group>
