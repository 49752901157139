import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableComponent } from 'src/app/admin/table/table.component';
import { AdminService } from 'src/app/services/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-forward-ride',
  templateUrl: './forward-ride.component.html',
  styleUrls: ['./forward-ride.component.scss']
})
export class ForwardRideComponent {

  bookingId = "";
  bookingDetails : any;
  bookingDetailsFromTable: any;
  selectedAgent : any;
  agentList : any;  
  agentListFetched: boolean = false;
  locations : any;
  selectedLocations : any;

  @Output() rideForwarded = new EventEmitter<void>();


  constructor(public dialogRef: MatDialogRef<TableComponent>, private snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) private data: any, private _service : AdminService) {
    //get all locations
    //get all agents there
    this.bookingId = data.content.ride_id;
    this.bookingDetailsFromTable = data.content;
    this._service.getBookingDetails(this.bookingId).subscribe((res) => {
      console.log('Collect payment', res);
      this.bookingDetails = res;
    })
    this._service.getServiceLocation().subscribe((res: any) => {
      console.log("Service Locations from forward ride: ", res);
      let loc = [...res.toList, ...res.fromList]
      // let response : any = res;
      this.locations = new Set<string>(loc);
    })
  }

  preference = '';
  fetchAgentList(){
    this.agentListFetched = false;
    console.log("Selected Locations: ", this.selectedLocations, this.preference);
    this._service.getchAgentWithLocation(this.selectedLocations, this.preference).subscribe((res) => {
      console.log(res);
      let response : any = res.ListOfAgents;
      this.agentList = response.map((element: any) => element.id + ", " + element.name + ", " + element.location);
      this.agentListFetched = true;
    })
  }


  onLocationSelectionChange(event: MatSelectChange) {
    const selectedValue = event.value;
    console.log("selectedValue: ",selectedValue.includes('All'))
    if(selectedValue.length == this.locations.length){
      this.selectedLocations = ['All',...this.locations];
    }
}

toggleSelectAllLocation() {
    console.log("toggleSelectAllLocation",this.selectedLocations)
    if (this.selectedLocations.includes('All')) {
        this.selectedLocations = ['All',...this.locations];
    } else {
        this.selectedLocations = [];
    }
}

onAgentSelectionChange(event: MatSelectChange) {
  const selectedValue = event.value;
  console.log("selectedValue: ",selectedValue.includes('All'))
  if(selectedValue.length == this.agentList.length){
    this.selectedAgent = ['All',...this.agentList];
  }
}

toggleSelectAllAgent() {
  console.log("toggleSelectAllLocation",this.selectedAgent)
  if (this.selectedAgent.includes('All')) {
      this.selectedAgent = ['All',...this.agentList];
  } else {
      this.selectedAgent = [];
  }
}

  forwardRide(){
    console.log("selected Agent: ", this.selectedAgent)
    let selectedAgentIds = this.selectedAgent.map((element: any) => element.split(",")[0].trim())
    console.log("SlectedAgentIds: ", selectedAgentIds, this.bookingId);
    this._service.forwardRide(this.bookingId, selectedAgentIds).subscribe((res) => {
      console.log(res);
      this.snackBar.open("Ride Forwarded successfully!", 'Close', { duration: 3000 });
      this.rideForwarded.emit();
      this.dialogRef.close();
    },((error) => {
      console.log(error);
      this.snackBar.open("Some error occured!", 'Close', { duration: 3000 });
    }))
  }


}
