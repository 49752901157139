<ng-container *ngIf="showLoader; else content" class="loader-container">
    <div class="row">
        <div class="loader"></div>
    </div>
</ng-container>
<ng-template #content>

<!-- <form class="add_driver_form"> -->
    <div class="form-group col-sm-12">
        <mat-card class="example-card">
            <mat-card-header class="justify-content-around ">
                <mat-card-title>Manage Driver</mat-card-title>
            </mat-card-header>
            <mat-card class="m-2 p-4">
                <h1>Add Driver</h1>
                <div class="row align-items-center">
                    <div class="d-flex col-sm-5 flex-column flex-md-row align-items-baseline justify-content-start">
                        <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">Phone Number :<span class="text-danger">*</span></div>
                        <mat-form-field class="col-sm-8 input-field">
                            <input matInput type="tel" [(ngModel)]="phoneNumber" appNumericOnly maxlength="10">
                        </mat-form-field>
                    </div>
                    <div class="d-flex col-md-5 col-sm-6 col-xs-12 flex-column flex-md-row align-items-baseline justify-content-start"
                        *ngIf="otpSent">
                        <div class="col-sm-2 fw-bold label">OTP :<span class="text-danger">*</span></div>
                        <mat-form-field class="col-sm-8 input-field">
                            <!-- <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
                             -->
                            <!-- <div class="input-container d-flex flex-row justify-content-center">
                                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                                    style="width: 2.5rem;">
                                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                                    style="width: 2.5rem;">
                                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                                    style="width: 2.5rem;">
                                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                                    style="width: 2.5rem;">
                                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                                    style="width: 2.5rem;">
                            </div> -->
                            <input type="tel" matInput [(ngModel)]="otp" maxlength="6" appNumericOnly>
                        </mat-form-field>
                    </div>
                    <div class="btn1 col-md-2 col-sm-4 col-xs-6" *ngIf="!otpSent">
                        <button type="submit" class="btn login" (click)="toggleOtpSent(true)" [disabled]="!isMobileNumberValid()">Send
                            OTP</button>
                    </div>
                    <div class="d-flex justify-content-center btn1 col-md-2 col-sm-4 col-xs-6" *ngIf="otpSent">
                        <button type="submit" class="btn login col-sm m-4" (click)="AddDriver()">Add</button>
                        <button class="btn login" (click)="ResendOTP()">Resend OTP</button>
                        <button class="btn login" (click)="Cancel()">Cancel</button>
                    </div>
                </div>

            </mat-card>
        </mat-card>
    </div>
<!-- </form> -->

<mat-card class="m-2 p-4">
    <app-agent-table (notifyParent)="removeDriver($event)" [data]="tableData" [columnData]="columnData"
        [flag]="'ManageDriver'"></app-agent-table>
</mat-card>
</ng-template>