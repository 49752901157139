<div class="container">
  <div *ngIf="formData">
    <div class="example-ripple-container mat-elevation-z4 p-2" matRipple>
      <div class="d-flex align-items-center modify_details">
        <div class="col-lg-5 d-grid align-items-center">
          <h4 class="fw-bold m-0">
            {{ formData["from"] }} > {{ formData["to"] }}({{
              formData["trip_type"]
            }})
          </h4>
        </div>
        <div class="col-lg-5 form_data">
          <div class="pickup">
            <p class="fw-bold">PICK UP DATE</p>
            <h4>{{ formData["pickUpDate"] | date }}</h4>
          </div>
          <div class="time">
            <p class="fw-bold">PICK UP TIME</p>
            <h4>{{ formData["pickUpTime"] }}</h4>
          </div>
          <div class="pickup" *ngIf="formData['trip_type'] == 'ROUND TRIP'">
            <p class="fw-bold">RETURN DATE</p>
            <h4>{{ formData["returnDate"] | date }}</h4>
          </div>
          <div class="time" *ngIf="formData['trip_type'] == 'ROUND TRIP'">
            <p class="fw-bold">RETURN TIME</p>
            <h4>{{ formData["returnTime"] }}</h4>
          </div>
        </div>
        <div class="col-lg-2 text-center">
          <button
            class="w-100"
            mat-stroked-button
            (click)="modifyTrip(formData, selected_trip)"
          >
            Modify
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="showLoader; else content" class="loader-container">
    <div class="row">
      <div class="loader"></div>
    </div>
  </ng-container>
  <ng-template #content>
    <mat-card class="car-card" *ngFor="let cabs of availableCabs">
      <div class="row align-items-center justify-content-around">
        <div class="col-lg-9 col-md-12">
          <div class="row">
            <div class="col-lg-5 car-details p-0">
              <img mat-card-avatar [src]="cabs.categoryImage" alt="Car Image" />
              <div class="cab_category text-center">
                <mat-card-title>{{ cabs.categoryName }}</mat-card-title>
                <mat-card-subtitle
                  class="d-flex align-items-center justify-content-center"
                  >or equivalent
                  <button
                    mat-icon-button
                    [matTooltip]="
                      'Includes AC Cabs like ' + cabs.varients.join(', ')
                    "
                  >
                    <mat-icon>info</mat-icon>
                  </button></mat-card-subtitle
                >
              </div>
            </div>
            <div class="col-lg-4 car-features">
              <div class="d-grid" style="justify-items: center">
                <mat-icon>speaker_notes</mat-icon>
                <p class="text-center">Includes Toll, State Tax & GST</p>
              </div>
              <div class="d-grid" style="justify-items: center">
                <mat-icon>verified_user</mat-icon>
                <p class="text-center">Top Rated Cabs & Chauffeurs</p>
              </div>
            </div>
            <div class="col-lg-3 ride-pricing">
              <!-- <div class="d-grid" style="justify-items: center;">
                            <p class="original-price">₹5440</p>
                            <p class="save-price">Save ₹440</p>
                        </div> -->
              <div class="d-grid" style="justify-items: center">
                <ng-container
                  *ngIf="formData['trip_type'] !== 'ROUND TRIP'; else roundTrip"
                >
                  <p class="final-price">
                    {{
                      floorTheValue(
                        cabs["perKmCharge"] *
                          rideDistance *
                          2 *
                          ((1 + pricing["driverAllowance"] / 100) *
                            (1 + pricing["tax"] / 100))
                      )
                    }}
                  </p>
                </ng-container>
                <ng-template #roundTrip>
                  <p class="final-price">
                    {{ twoWayRide(cabs["perKmCharge"]) }}
                  </p>
                </ng-template>
                <p class="km">Upto {{ floorTheValue(rideDistance) }}</p>
                <button mat-button (click)="toggleDetails(cabs._id)">
                  Details
                  <mat-icon *ngIf="!expandCarDetails.get(cabs._id)"
                    >keyboard_arrow_down</mat-icon
                  >
                  <mat-icon *ngIf="expandCarDetails.get(cabs._id)"
                    >keyboard_arrow_up</mat-icon
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-12">
          <button class="btn btn-primary" (click)="selectCar(cabs)">
            Select Car
          </button>
        </div>
        <div *ngIf="expandCarDetails.get(cabs._id)" class="details-content">
          <mat-tab-group
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            class="custom-tab-group mt-1"
          >
            <mat-tab>
              <ng-template mat-tab-label>
                <ng-container *ngIf="smallScreen; else INCLUSIONS">
                  <mat-icon class="detils_icon">done_all</mat-icon>
                </ng-container>
                <ng-template #INCLUSIONS> INCLUSIONS </ng-template>
              </ng-template>
              <div class="inclusions">
                <h5 *ngIf="smallScreen" class="text-center">INCLUSIONS</h5>
                <div class="details">
                  <img
                    src="../../../assets/3952770-removebg-preview.png"
                    class="img-fluid"
                    alt=""
                  />
                  <p class="text">Night Charges</p>
                </div>
                <div class="details">
                  <img
                    src="../../../assets/transport-driver-conductor-peak-cap-600nw-143693560-removebg-preview.png"
                    class="img-fluid"
                    alt=""
                  />
                  <p class="text">Driver Allowance</p>
                </div>
                <div class="details">
                  <img
                    src="../../../assets/gst-vector-icon-white-gst-vector-icon-white-eps-file-easy-to-edit-139556691-removebg-preview.png"
                    class="img-fluid"
                    alt=""
                  />
                  <p class="text">GST</p>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <ng-container *ngIf="smallScreen; else EXCLUSIONS">
                  <mat-icon class="detils_icon">block</mat-icon>
                </ng-container>
                <ng-template #EXCLUSIONS> EXCLUSIONS </ng-template>
              </ng-template>
              <div class="exclusions">
                <h5 *ngIf="smallScreen" class="text-center">EXCLUSIONS</h5>
                <div class="details">
                  <img
                    src="../../../assets/free-toll-1817203-1538074-removebg-preview.png"
                    class="img-fluid"
                    alt=""
                  />
                  <p class="text">Toll and State Tax</p>
                </div>
                <div class="details">
                  <img
                    src="../../../assets/5388823-removebg-preview.png"
                    class="img-fluid"
                    alt=""
                  />
                  <p class="text">Parking</p>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <ng-container *ngIf="smallScreen; else FACILITIES">
                  <mat-icon class="detils_icon">check_circle</mat-icon>
                </ng-container>
                <ng-template #FACILITIES> FACILITIES </ng-template>
              </ng-template>
              <div class="facilities">
                <h5 *ngIf="smallScreen" class="text-center">FACILITIES</h5>
                <div class="details">
                  <img
                    src="../../../assets/pngtree-car-seat-png-image_1511320-removebg-preview.png"
                    class="img-fluid"
                    alt=""
                  />
                  <p class="text">{{ cabs.NumberOfSeats }} Seaters</p>
                </div>
                <div class="details">
                  <img
                    src="../../../assets/travel-suitcase-icon-cartoon-bag-removebg-preview.png"
                    class="img-fluid"
                    alt=""
                  />
                  <p class="text">2 Bags</p>
                </div>
                <div class="details">
                  <img
                    src="../../../assets/kisspng-illustration-air-conditioning-air-conditioners-vec--5b7c67a1334159.25137320153487964921-removebg-preview.png"
                    class="img-fluid"
                    alt=""
                  />
                  <p class="text">AC</p>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <ng-container *ngIf="smallScreen; else TC">
                  <mat-icon class="detils_icon">description</mat-icon>
                </ng-container>
                <ng-template #TC> T&C </ng-template>
              </ng-template>
              <ul class="mt-2">
                <h5 *ngIf="smallScreen" class="text-center">T&C</h5>
                <li>
                  Your Trip has a KM limit as well as an Hours limit. If your
                  usage exceeds these limits, you will be charged for the excess
                  KM and/or hours used.
                </li>
                <li>
                  The KM and Hour(s) usage will be calculated starting from your
                  pick-up point and back to the pick-up point.
                </li>
                <li>
                  All road toll fees, parking charges, state taxes etc. if
                  applicable will be charged extra and need to be paid to the
                  concerned authorities as per actuals.
                </li>
                <li>
                  The Airport entry charge, if applicable, is not included in
                  the fare and will be charged extra.
                </li>
              </ul>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </mat-card>
  </ng-template>
</div>
