<div [class]="data['title'] ==='book_ride' ? 'book_ride' :'login_signup_popup'">
  <!-- customer section -->
  <div *ngIf="data['title'] == 'login'">
    <ng-container *ngIf="!login_comp">
      <div class="card">
        <h2 mat-dialog-title>Customer Login</h2>
        <mat-card>
          <form [formGroup]="customer_login_form" class="d-grid justify-content-center">
            <div class="d-flex flex-column flex-md-row align-items-baseline justify-content-start">
              <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">Phone No. :<span class="text-danger">*</span>
              </div>
              <mat-form-field class="col-sm-8 input-field">
                <input matInput formControlName="phoneNumber" type="tel" appNumericOnly [(ngModel)]="phoneNumber" maxlength="10"
                  *ngIf="!otpSent">
                <input matInput [placeholder]="phoneNumber" *ngIf="otpSent" [disabled]="true">
              </mat-form-field>
            </div>
            <mat-dialog-actions align="end" class="login_btn" *ngIf="!otpSent">
              <button class="btn login" (click)="SendOTP('')" [disabled]="!isMobileNumberValid()">Send OTP</button>
            </mat-dialog-actions>
            <div class="d-flex flex-column flex-md-row align-items-baseline justify-content-start" *ngIf="otpSent && !isOTPVerified">
              <div class="col-md-5 col-sm-6 col-xs-12 fw-bold label">OTP :<span class="text-danger">*</span></div>
              <div class="col-sm-8 input-field input-container d-flex flex-row justify-content-center">
                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem;"
                  [(ngModel)]="digit1" type="tel" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 2)"
                  appNumericOnly>
                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem;"
                  [(ngModel)]="digit2" type="tel" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 3)"
                  appNumericOnly>
                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem;"
                  [(ngModel)]="digit3" type="tel" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 4)"
                  appNumericOnly>
                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem;"
                  [(ngModel)]="digit4" type="tel" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 5)"
                  appNumericOnly>
                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem;"
                  [(ngModel)]="digit5" type="tel" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 6)"
                  appNumericOnly>
                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem;"
                  [(ngModel)]="digit6" type="tel" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, null)"
                  appNumericOnly>
              </div>
            </div>

          </form>
        </mat-card>
        <mat-dialog-actions align="end" class="login_btn">
          <div *ngIf="otpSent && !isOTPVerified">
            <div class="flex p-2">
              <button class="btn register" (click)="customer_register()">Register</button>
              <button class="btn login" (click)="customer_login()" [disabled]="!activateLoginBtn">Login</button>
            </div>
            <div class="flex">
              <button class="btn login" (click)="ResendOTP()">Resend OTP</button>
              <button class="btn login" (click)="Cancel()">Cancel</button>
            </div>
          </div>
        </mat-dialog-actions>
        <!-- <mat-dialog-actions align="end" class="login_btn">
                <button class="btn register" (click)="login_comp=true;register_comp=true">Register</button>
                <button class="btn login" (click)="customer_login()">Login</button>
            </mat-dialog-actions> -->
      </div>
    </ng-container>

    <ng-container *ngIf="register_comp">
      <h2 mat-dialog-title>Register</h2>
      <mat-card>
        <!-- <form [formGroup]="customer_register_form" class="d-grid justify-content-center">
                <div class="d-flex flex-column flex-md-row align-items-baseline justify-content-start">
                    <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">Name :<span class="text-danger">*</span>
                    </div>
                    <mat-form-field class="col-sm-8 input-field">
                        <input matInput formControlName="name">
                    </mat-form-field>
                </div>
                <div class="d-flex flex-column flex-md-row align-items-baseline justify-content-start">
                    <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">Email :<span class="text-danger">*</span>
                    </div>
                    <mat-form-field class="col-sm-8 input-field">
                        <input matInput formControlName="emailid">
                    </mat-form-field>
                </div>
                <div class="d-flex flex-column flex-md-row align-items-baseline justify-content-start">
                    <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">Phone No. :<span class="text-danger">*</span>
                    </div>
                    <mat-form-field class="col-sm-8 input-field">
                        <input matInput formControlName="phoneNumber">
                    </mat-form-field>
                </div>
                <div class="d-flex flex-column flex-md-row align-items-baseline justify-content-start">
                    <div class="col-md-5 col-sm-6 col-xs-12 fw-bold label">OTP :<span class="text-danger">*</span>
                    </div>
                    <div class="col-sm-8 input-field input-container d-flex flex-row justify-content-center">
                        <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                            style="width: 2.3rem;">
                        <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                            style="width: 2.3rem;">
                        <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                            style="width: 2.3rem;">
                        <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                            style="width: 2.3rem;">
                        <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                            style="width: 2.3rem;">
                    </div>
                </div>
                <div class="d-flex flex-column flex-md-row align-items-baseline justify-content-start mb-3">
                    <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">Profile Photo :<span
                            class="text-danger">*</span>
                    </div>
                    <mat-form-field class="col-sm-8 input-field">
                        <input matInput readonly>
                        <input type="file" accept="image/*" (change)="customer_register_img($event)">
                    </mat-form-field>
                </div>
            </form> -->
        <form [formGroup]="customer_register_form" class="d-grid justify-content-center">
          <div class="d-flex flex-column flex-md-row align-items-baseline justify-content-start">
            <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">Phone No. :<span class="text-danger">*</span>
            </div>
            <mat-form-field class="col-sm-8 input-field">
              <input matInput formControlName="phoneNumber" appNumericOnly type="tel" [(ngModel)]="phoneNumber" maxlength="10"
                *ngIf="!otpSent">
              <input matInput [placeholder]="phoneNumber" *ngIf="otpSent" [disabled]="true">
              </mat-form-field>
          </div>
          <div class="d-flex flex-column flex-md-row align-items-baseline justify-content-start" *ngIf="otpSent && !isOTPVerified">
            <div class="col-md-3 col-sm-6 col-xs-12 fw-bold label">OTP :<span class="text-danger">*</span>
            </div>
            <div class="col-sm-8 input-field input-container d-flex flex-row justify-content-center">
              <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.3rem;"
                [(ngModel)]="digit1" type="tel" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 2)"
                appNumericOnly>
              <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.3rem;"
                [(ngModel)]="digit2" type="tel" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 3)"
                appNumericOnly>
              <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.3rem;"
                [(ngModel)]="digit3" type="tel" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 4)"
                appNumericOnly>
              <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.3rem;"
                [(ngModel)]="digit4" type="tel" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 5)"
                appNumericOnly>
              <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.3rem;"
                [(ngModel)]="digit5" type="tel" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 6)"
                appNumericOnly>
              <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.3rem;"
                [(ngModel)]="digit6" type="tel" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, null)"
                appNumericOnly>
            </div>
          </div>
        </form>
      </mat-card>
      <mat-dialog-actions align="end" class="register_btn">
        <button class="btn login" (click)="login_comp=false;register_comp=false">Cancel</button>
        <button class="btn register" (click)="customer_register()">Register</button>
      </mat-dialog-actions>
    </ng-container>
  </div>

  <div *ngIf="data['title'] == 'profile'">
    <div class="card">
      <h2 mat-dialog-title>Edit Profile</h2>
      <mat-card>
        <form [formGroup]="customer_edit_form" class="d-grid justify-content-center">
          <div class="d-flex align-items-baseline justify-content-start">
            <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">
              Name :<span class="text-danger">*</span>
            </div>
            <mat-form-field class="col-sm-8 input-field">
              <input matInput formControlName="name" />
            </mat-form-field>
          </div>
          <div class="d-flex align-items-baseline justify-content-start">
            <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">
              Email :<span class="text-danger">*</span>
            </div>
            <mat-form-field class="col-sm-8 input-field">
              <input matInput formControlName="emailid" />
            </mat-form-field>
          </div>
          <!-- <div class="d-flex align-items-baseline justify-content-start">
            <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">
              Phone No. :<span class="text-danger">*</span>
            </div>
            <mat-form-field class="col-sm-8 input-field">
              <input matInput formControlName="phoneNumber" [(ngModel)]="phoneNumber" maxlength="10" *ngIf="!otpSent">
              <input matInput [placeholder]="phoneNumber" *ngIf="otpSent" [disabled]="true">
            </mat-form-field>
          </div> -->
          <!-- <div class="d-flex align-items-baseline justify-content-start">
            <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">
              OTP :<span class="text-danger">*</span>
            </div>
            <div class="col-sm-8 input-field input-container d-flex flex-row">
              <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem"
                appNumericOnly />
              <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem"
                appNumericOnly />
              <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem"
                appNumericOnly />
              <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem"
                appNumericOnly />
              <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem"
                appNumericOnly />
            </div>
          </div> -->
          <div class="d-flex align-items-baseline justify-content-start mb-3">
            <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">
              Profile :<span class="text-danger">*</span>
            </div>
            <mat-form-field class="col-sm-8 input-field">
              <input matInput readonly />
              <input type="file" accept="image/*" (change)="customer_edit_img($event)" style="font-size: 0.8rem" />
            </mat-form-field>
          </div>
        </form>
      </mat-card>
      <mat-dialog-actions align="end" class="login_btn">
        <button class="btn register" mat-button mat-dialog-close>Cancle</button>
        <button class="btn login" (click)="customer_update()">Update</button>
      </mat-dialog-actions>
    </div>
  </div>

  <!-- driver section -->
  <div *ngIf="data['title'] == 'driver_login'">
    <ng-container *ngIf="!login_comp && !register_comp">
      <div class="card">
        <h2 mat-dialog-title>Login As Driver</h2>
        <mat-card style="display: flex; justify-content: center; align-items: center;">
          <button class="btn auth-buttons" (click)="showDriverLoginForm()">Login</button>
          <span>OR</span>
          <button class="btn auth-buttons" (click)="showDriverRegisterForm()">Register</button>
        </mat-card>
      </div>
    </ng-container>
    
    <ng-container *ngIf="login_comp">
      <div class="card">
        <h2 mat-dialog-title>Login As Driver</h2>
        <mat-card>
          <form [formGroup]="driver_login_form" class="d-grid justify-content-center">
            <div class="d-flex flex-column flex-md-row align-items-baseline justify-content-start">
              <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">Phone No. :<span class="text-danger">*</span>
              </div>
              <mat-form-field class="col-sm-8 input-field">
                <input matInput formControlName="phoneNumber" type="tel" [(ngModel)]="phoneNumber" maxlength="10" appNumericOnly
                  *ngIf="!otpSent">
                <input matInput [placeholder]="phoneNumber" *ngIf="otpSent" [disabled]="true">
              </mat-form-field>
            </div>
            <mat-dialog-actions align="end" class="login_btn" *ngIf="!otpSent">
              <button class="btn login" (click)="SendOTP('')" [disabled]="!isMobileNumberValid()">Send OTP</button>
            </mat-dialog-actions>
            <div class="d-flex flex-column flex-md-row align-items-baseline justify-content-start" *ngIf="otpSent && !isOTPVerified">
              <div class="col-md-3 col-sm-6 col-xs-12 fw-bold label">OTP :<span class="text-danger">*</span>
              </div>
              <div class="col-sm-8 input-field input-container d-flex flex-row justify-content-center">
                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.3rem;"
                  [(ngModel)]="digit1" type="tel" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 2)"
                  appNumericOnly>
                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.3rem;"
                  [(ngModel)]="digit2" type="tel" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 3)"
                  appNumericOnly>
                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.3rem;"
                  [(ngModel)]="digit3" type="tel" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 4)"
                  appNumericOnly>
                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.3rem;"
                  [(ngModel)]="digit4" type="tel" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 5)"
                  appNumericOnly>
                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.3rem;"
                  [(ngModel)]="digit5" type="tel" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 6)"
                  appNumericOnly>
                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.3rem;"
                  [(ngModel)]="digit6" type="tel" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, null)"
                  appNumericOnly>
              </div>
            </div>
          </form>
        </mat-card>
        <mat-dialog-actions align="end" class="login_btn" *ngIf="otpSent">
          <div class="d-flex p-2">
            <button class="btn login" (click)="Driver_login()" [disabled]="!activateLoginBtn">Login</button>
          </div>
          <div class="d-flex">
            <button class="btn login" (click)="ResendOTP()">Resend OTP</button>
            <button class="btn login" (click)="Cancel()">Cancel</button>
          </div>
        </mat-dialog-actions>
      </div>
    </ng-container>

    <ng-container *ngIf="register_comp">
      <h2 mat-dialog-title>Register As Driver</h2>
      <mat-card>
        <form [formGroup]="driver_register_form" class="d-grid justify-content-center">
          <div class="d-flex flex-column flex-md-row align-items-baseline justify-content-start">
            <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">Driver Name :<span class="text-danger">*</span>
            </div>
            <mat-form-field class="col-sm-8 input-field">
              <input matInput formControlName="driverName" placeholder="please enter your name...">
            </mat-form-field>
          </div>
          <div class="d-flex flex-column flex-md-row align-items-baseline justify-content-start">
            <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">Phone No. :<span class="text-danger">*</span>
            </div>
            <mat-form-field class="col-sm-8 input-field">
              <input matInput formControlName="phoneNumber" type="tel" appNumericOnly [(ngModel)]="phoneNumber" maxlength="10"
                *ngIf="!otpSent">
              <input matInput [placeholder]="phoneNumber" *ngIf="otpSent" [disabled]="true">
            </mat-form-field>
          </div>
          <mat-dialog-actions align="end" class="login_btn" *ngIf="!otpSent">
            <button class="btn login" (click)="SendOTP('')" [disabled]="!isMobileNumberValid()">Send OTP</button>
          </mat-dialog-actions>
          <div class="d-flex flex-column flex-md-row align-items-baseline justify-content-start" *ngIf="otpSent && !isOTPVerified">
            <div class="col-md-3 col-sm-6 col-xs-12 fw-bold label">OTP :<span class="text-danger">*</span>
            </div>
            <div class="col-sm-8 input-field input-container d-flex flex-row justify-content-center">
              <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.3rem;"
                [(ngModel)]="digit1" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 2)"
                appNumericOnly type="tel">
              <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.3rem;"
                [(ngModel)]="digit2" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 3)"
                appNumericOnly type="tel">
              <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.3rem;"
                [(ngModel)]="digit3" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 4)"
                appNumericOnly type="tel">
              <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.3rem;"
                [(ngModel)]="digit4" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 5)"
                appNumericOnly type="tel">
              <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.3rem;"
                [(ngModel)]="digit5" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 6)"
                appNumericOnly type="tel">
              <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.3rem;"
                [(ngModel)]="digit6" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, null)"
                appNumericOnly type="tel">
            </div>
          </div>
        </form>
      </mat-card>
      <mat-dialog-actions align="end" class="register_btn">
        <button class="btn login" (click)="login_comp=false;register_comp=false">Cancle</button>
        <button class="btn register"  (click)="Driver_register()">Register</button>
      </mat-dialog-actions>
    </ng-container>
  </div>

  <div *ngIf="data['title'] == 'driver_profile'">
    <div class="card">
      <h2 mat-dialog-title>Edit Profile</h2>
      <mat-card>
        <form [formGroup]="driver_edit_form" class="d-grid justify-content-center">
          <div class="d-flex flex-column flex-md-row align-items-baseline justify-content-start">
            <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">Name :<span class="text-danger">*</span>
            </div>
            <mat-form-field class="col-sm-8 input-field">
              <input matInput formControlName="name">
            </mat-form-field>
          </div>
          <div class="d-flex flex-column flex-md-row align-items-baseline justify-content-start">
            <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">Email :<span class="text-danger">*</span>
            </div>
            <mat-form-field class="col-sm-8 input-field">
              <input matInput formControlName="emailid">
            </mat-form-field>
          </div>
          <div class="d-flex flex-column flex-md-row align-items-baseline justify-content-start mb-3">
            <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">Profile :<span class="text-danger">*</span>
            </div>
            <mat-form-field class="col-sm-8 input-field">
              <input matInput readonly>
              <input type="file" accept="image/*" (change)="driver_edit_img($event)" style="font-size: 0.8rem;">
            </mat-form-field>
          </div>
        </form>
      </mat-card>
      <mat-dialog-actions align="end" class="login_btn">
        <button class="btn register" mat-button mat-dialog-close>Cancle</button>
        <button class="btn login" (click)="driver_update()">Update</button>
      </mat-dialog-actions>
    </div>
  </div>

  <!-- start ride -->
  <div *ngIf="(data['title'] == 'start_ride' || data['title'] == 'end_ride') && !customer_payment" >
    <div class="card">
      <ng-container *ngIf="data['title'] == 'start_ride'; else endRiding">
        <h2 mat-dialog-title>Start Ride</h2>
      </ng-container>
      <ng-template #endRiding>
        <h2 mat-dialog-title>End Ride</h2>
      </ng-template>
      <mat-card>
        <form [formGroup]="start_ride_form" class="d-grid justify-content-center">
          <div class="odoMeter d-flex align-items-baseline">
            <div class="col-md-5 col-sm-6 col-xs-12 fw-bold label">Odo Meter Reading. :<span
                class="text-danger">*</span>
            </div>
            <div class="d-grid">
              <mat-form-field class="col-sm-8 input-field">
                <input appNumericOnly matInput formControlName="reading" type="tel"
                  placeholder="please enter odo meter current reading" min="0" (focus)="errorMsg=false" />
              </mat-form-field>
              <span class="errorMsg" *ngIf="errorMsg">Odo Meter end reading should not less than Odo Meter start
                reading</span>
              <!-- <span class="errorMsg" *ngIf="mismatchDistance">Mis-Match in between distance and Odo meter reading</span> -->
            </div>
          </div>
          <mat-dialog-actions align="end" class="login_btn" *ngIf="!otpSent">
            <button class="btn login" *ngIf="data['title'] == 'start_ride'" (click)="SendOTP('')">Send OTP</button>
            <button class="btn login" *ngIf="data['title'] == 'end_ride'" (click)="SendOTP('end_ride')">Send OTP</button>
          </mat-dialog-actions>
          <div class="odoMeter d-flex flex-column flex-md-row align-items-baseline" *ngIf="otpSent && !isOTPVerified">
            <div class="col-md-5 col-sm-6 col-xs-12 fw-bold label">OTP :<span class="text-danger">*</span>
            </div>
            <div class="col-sm-8 input-field input-container d-flex flex-row justify-content-center" >
              <input type="tel" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem;"
                [(ngModel)]="digit1" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 2)"
                appNumericOnly>
              <input type="tel" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem;"
                [(ngModel)]="digit2" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 3)"
                appNumericOnly>
              <input type="tel" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem;"
                [(ngModel)]="digit3" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 4)"
                appNumericOnly>
              <input type="tel" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem;"
                [(ngModel)]="digit4" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 5)"
                appNumericOnly>
              <input type="tel" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem;"
                [(ngModel)]="digit5" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, 6)"
                appNumericOnly>
              <input type="tel" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem;"
                [(ngModel)]="digit6" [ngModelOptions]="{standalone: true}" (input)="moveToNext($event, null)"
                appNumericOnly>
            </div> 
          </div>
        </form>
      </mat-card>
      <mat-dialog-actions align="end" class="login_btn">
        <div *ngIf="!isOTPVerified" class="d-flex">
          <button class="btn login" (click)="ResendOTP()">Resend OTP</button>
          <button class="btn login" (click)="CancelRide()">Cancel</button>
          <button class="btn login" (click)="start_reading(data['title'])" *ngIf="data['title'] == 'start_ride'">Let's
            Start</button>
          <button class="btn login" (click)="start_reading(data['title'])" *ngIf="data['title'] == 'end_ride'">Collect Payment</button>
        </div>
        <div *ngIf="isOTPVerified">
          <div class="wrapper"> <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
        </div>
      </mat-dialog-actions>
    </div>
  </div>
  <div *ngIf="customer_payment">
    <div class="card">
      <h2 mat-dialog-title>Payment Details</h2>
      <mat-card>
        <div class="row d-flex">
          <div class="col-md-5">
            <span class="d-flex justify-content-start">
              <p>Original KMS :</p>
              <p class="mx-3">{{final_payment.original_kms}}</p>
            </span>
          </div>
          <div class="col-md-5">
            <span class="d-flex justify-content-start">
              <p>Original Charge :</p>
              <p class="mx-3">{{final_payment.original__price}}</p>
            </span>
          </div>
        </div>
        <div class="row d-flex">
          <div class="col-md-5">
            <span class="d-flex justify-content-start">
              <p>Actual KMS :</p>
              <p class="mx-3">{{final_payment.actual_kms}}</p>
            </span>
          </div>
          <div class="col-md-5">
            <span class="d-flex justify-content-start">
              <p>Extra KMS :</p>
              <p class="mx-3">{{final_payment.extra_kms}}</p>
            </span>
          </div>
        </div>
        <div class="row d-flex">
          <div class="col-md-5">
            <span class="d-flex justify-content-start">
              <p>Extra KMS Charge :</p>
              <p class="mx-3">{{final_payment.extra__price}}</p>
            </span>
          </div>
          <div class="col-md-5">
            <span class="d-flex justify-content-start">
              <p>Total Billable Amount :</p>
              <p class="mx-3">{{final_payment.total_bill}}</p>
            </span>
          </div>
        </div>
        <div class="row d-flex">
          <div class="col-md-5">
            <span class="d-flex justify-content-start">
              <p>Advance Collected :</p>
              <p class="mx-3">{{final_payment.amount_collected}}</p>
            </span>
          </div>
          <div class="col-md-5">
            <span class="d-flex justify-content-start">
              <p>Remaining Amount :</p>
              <p class="mx-3">{{final_payment.remaining_Amount}}</p>
            </span>
          </div>
        </div>
      </mat-card>
      <mat-dialog-actions align="end" class="login_btn">
          <button class="btn login" (click)="collectAmount()">Collect Amount</button>
      </mat-dialog-actions>
    </div>
  </div>
  <!-- book ride -->
  <div *ngIf="data['title']==='book_ride'">
    <div class="card">
      <h2 mat-dialog-title>YOUR FINAL DETAILS</h2>
      <mat-card>
        <mat-card-content class="w-100" style="padding-top: 16px;">
          <div class="row">
            <div class="d-flex col-md-6">
              <div class="col-md-4 fw-bold label">Booking ID :</div>
              <span>{{final_booking_data['booking_id']}}</span>
            </div>
            <div class="d-flex col-md-6">
              <div class="col-md-4 fw-bold label">Total Amount :</div>
              <span>{{final_booking_data['totalAmount']}}</span>
            </div>
          </div>
          <div class="row">
            <div class="d-flex col-md-6">
              <div class="col-md-4 fw-bold label">Name :</div>
              <span>{{final_booking_data['customer_name']}}</span>
            </div>
            <div class="d-flex col-md-6">
              <div class="col-md-4 fw-bold label">Mobile No. :</div>
              <span>{{final_booking_data['customer_number']}}</span>
            </div>
          </div>
          <div class="row">
            <div class="d-flex col-md-6">
              <div class="col-md-4 fw-bold label">Pickup :</div>
              <span>{{final_booking_data['booking_from']['landmark']}},
                {{final_booking_data['booking_from']['city']}}</span>
            </div>
            <div class="d-flex col-md-6">
              <div class="col-md-4 fw-bold label">Drop :</div>
              <span>{{final_booking_data['booking_to']['landmark']}},
                {{final_booking_data['booking_to']['city']}}</span>
            </div>
          </div>
          <div class="row">
            <div class="d-flex col-md-6">
              <div class="col-md-4 fw-bold label">Pickup Date :</div>
              <span>{{final_booking_data['pickup_date'] | date}} at {{final_booking_data['pickup_time']}}</span>
            </div>
            <div class="d-flex col-md-6" *ngIf="final_booking_data['return_date']">
              <div class="col-md-4 fw-bold label">Return Date :</div>
              <span>{{final_booking_data['return_date'] | date}} at {{final_booking_data['return_time']}}</span>
            </div>
          </div>
          <div class="row">
            <div class="d-flex col-md-6">
              <div class="col-md-4 fw-bold label">Car Category :</div>
              <span>{{final_booking_data['cabCategoryName']}}</span>
            </div>
            <div class="d-flex col-md-6">
              <div class="col-md-4 fw-bold label">Total Kilometers :</div>
              <span>{{final_booking_data['kmsOfRide']}} KM</span>
            </div>
          </div>
          <div class="row">
            <div class="d-flex col-md-6">
              <div class="col-md-4 fw-bold label">Trip Type :</div>
              <span>{{final_booking_data['tripType']}}</span>
            </div>
          </div>
          <button mat-flat-button (click)="close()"
            style="background-color: #28a745 !important;border-color: #28a745 !important;">Successfully Ride
            Booked</button>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>