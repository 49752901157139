import { HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GooglePlacesService {

  backendURL: string = environment.apiUrl;
  google_api_url: string = 'https://maps.googleapis.com/maps/api/'
  google_api_key: string = environment.google_api_key;
  radius: number = environment.radius;
  proxy_url: string = 'https://cors-anywhere.herokuapp.com/';

  constructor(public http: HttpClient) { }
  getCoordinates(cityName: string): Observable<any> {
    return this.http.get(this.backendURL + `/maps/getCoordinates/${cityName}`);
  }

  getPrediction(input: string, lat: string, long: string): Observable<any> {
    // let url = this.proxy_url + this.google_api_url + `place/autocomplete/json?input=${input}&location=${lat}%2C${long}&radius=${this.radius}&key=${this.google_api_key}`
    // let response = this.http.get(url);
    // console.log(response);
    // return response;
    return this.http.post(this.backendURL + '/maps/getPredictions', {input, lat, long});

  }

}
