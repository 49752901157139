import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableComponent } from 'src/app/admin/table/table.component';
import { AdminService } from 'src/app/services/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-collect-payment',
  templateUrl: './collect-payment.component.html',
  styleUrls: ['./collect-payment.component.scss']
})
export class CollectPaymentComponent {
  bookingId = "";
  bookingDetails : any;
  bookingDetailsFromTable: any;
  transactionID: string = "";
  amount!: number;
  modeOfTransaction = '';
  advanceAmountForBooking!: number;
  @Output() amountCollected = new EventEmitter<void>();


  constructor(public dialogRef: MatDialogRef<TableComponent>,private snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) private data: any, private _service : AdminService) {
    this.bookingId = data.content.ride_id;
    this.bookingDetailsFromTable = data.content;
    this._service.getBookingDetails(this.bookingId).subscribe((res) => {
      console.log('Collect payment', res);
      this.bookingDetails = res;
      this.advanceAmountForBooking = Math.ceil(this.bookingDetails.totalAmount * 0.1);
    })
  }

  addTransaction(){
    console.log("addTransaction: ", this.transactionID, this.amount, this.modeOfTransaction);
    this._service.addTransaction({
      transactionId: this.transactionID,
      bookingId: this.bookingId, 
      customerId: this.bookingDetails.customer_id,
      paidTo: "Company",
      amount: this.amount,
      paymentMode: this.modeOfTransaction,
      paymentStatus: "Paid",
      paymentType: "Advance",
      transactionDate: (new Date).toString()
    }).subscribe((res) => {
      console.log("Transcation Added: ",res.transaction);
      this.snackBar.open("Transaction added successfully!", 'Close', { duration: 3000 })
      this.dialogRef.close();
      this.amountCollected.emit()
    },((error) => {
      console.log(error);
      this.snackBar.open("Some error occured!", 'Close', { duration: 3000 })
    }))
  }
}
