import { Component } from '@angular/core';
import { MyTripComponent } from '../../my-trip/my-trip.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-ride-popup',
  templateUrl: './cancel-ride-popup.component.html',
  styleUrls: ['./cancel-ride-popup.component.scss']
})
export class CancelRidePopupComponent {

  cancellationMessage: string = '';

  constructor(private dialogRef: MatDialogRef<MyTripComponent>) { }

  CancelRide() {
    this.dialogRef.close(this.cancellationMessage);
  }

  Cancel() {
    this.dialogRef.close();
  }
}
