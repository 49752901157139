import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AgentServiceService } from 'src/app/services/agent-service.service';

@Component({
  selector: 'app-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.scss']
})
export class MyBookingsComponent {
  column1Data = [
    { field: "From", label: "From" },
    { field: "To", label: "TO" },
    { field: "Distance", label: "Distance" },
    { field: "DateTime", label: "DATE/TIME" },
    { field: "ReceiptAmountExtraCharges", label: "Receipt" },
    { field: "Car", label: "Car" },
    { field: "Driver", label: "Driver" }
  ];
  showLoader = true;

  table1Data = [
    { DateTime: "2023-12-20 08:30 AM", From: "Home", To: "Work", Distance: "10 miles", ReceiptAmountExtraCharges: "$15.00", Car: "Toyota Prius", Driver: "John Doe" },
    { DateTime: "2023-12-21 12:45 PM", From: "Airport", To: "Hotel", Distance: "25 miles", ReceiptAmountExtraCharges: "$30.50", Car: "Honda Accord", Driver: "Jane Johnson" },
    { DateTime: "2023-12-22 05:15 PM", From: "Train Station", To: "Conference Center", Distance: "5 miles", ReceiptAmountExtraCharges: "$8.75", Car: "Ford Fusion", Driver: "David Davis" }
    // Add more dummy data as needed
  ];
  column2Data = [
    { field: "From", label: "From" },
    { field: "To", label: "TO" },
    { field: "Distance", label: "Distance" },
    { field: "Penalities", label: "Penalities" },
    { field: "DateTime", label: "DATE/TIME" },
    { field: "Cancellation_Charge", label: "Cancellation Charge" },
    { field: "Reason", label: "Reason" }
  ];


  table2Data = [
    { DateTime: "2023-12-25 10:15 AM", From: "Work", To: "Home", Distance: "12 miles", Penalities: "$5.00", Cancellation_Charge: "$10.00", Car: "Chevrolet Malibu", Driver: "Alice Adams" },
    { DateTime: "2023-12-26 02:30 PM", From: "Hotel", To: "Airport", Distance: "20 miles", Penalities: "$8.50", Cancellation_Charge: "$15.00", Car: "Ford Explorer", Driver: "Bob Brown" },
    { DateTime: "2023-12-27 06:45 PM", From: "Conference Center", To: "Home", Distance: "8 miles", Penalities: "$3.00", Cancellation_Charge: "$7.50", Car: "Toyota Camry", Driver: "Charlie Clark" }
    // Add more dummy data as needed
  ];

  completedRides = [];
  cancelledRides = [];

  constructor(private _service: AgentServiceService, private router: Router) {
    this.checkLoginStatus();
  }

  checkLoginStatus() {
    // localStorage.setItem("admin_auth", res.phoneNumber);
    // localStorage.setItem("admin_authExpiration", expirationDate.toString());  
    console.log("Checking login status")
    const auth = localStorage.getItem("agent_auth");
    const time = localStorage.getItem("agent_authExpiration");
    console.log("checked auth: ", auth, time);
    if (time && auth) {
      const date = new Date(time);
      console.log("time: ", time)
      if (date <= new Date()) {
        console.log("time: ", time)
        localStorage.removeItem('agent_auth');
        localStorage.removeItem('agent_authExpiration');
      }
      else {
        this.agentId = auth;
      }
    }
    else {
      this.router.navigate(['/']);
    }
  }
  agentId = "";
  ngOnInit() {
    this._service.getAllBookings(this.agentId).subscribe((res) => {
      console.log("My Bookings: ",res);
      if (res.confirmedRide) {
        this.showLoader = false;
        let ridesCompleted = res.confirmedRide.filter((ride: any) => ride.status == 'Completed');
        let ridesCancelled = res.confirmedRide.filter((ride: any) => ride.status == 'Cancelled' || ride.status == 'Refunded');
        this.completedRides = ridesCompleted.map((ride: any) => {
          return {
            DateTime: ride.date,
            From: ride.from, //.landmark + ", " + ride.booking_from.city,
            To: ride.to, //.landmark + ", " + ride.booking_to.city,
            Distance: ride.distance, // + (ride.excessKMS && (ride.excessKMS != 0) ? " + " + ride.excessKMS + "(extra)" : "") + " kms",
            ReceiptAmountExtraCharges: "₹ " + (ride.fare && ride.fare != 0) ? ride.fare : 0,
            Car: ride.carNumber,
            Driver: ride.driver_name
          }
        })
        this.cancelledRides = ridesCancelled.map((ride: any) => {
          return {
            DateTime: ride.updatedAt,
            From: ride.from, //.landmark + ", " + ride.booking_from.city,
            To: ride.to, //.landmark + ", " + ride.booking_to.city,
            Distance: ride.distance, // + (ride.excessKMS != 0 ? " + " + ride.excessKMS + "(extra)" : "") + " kms",
            Penalities: 0, //"$5.00",
            Cancellation_Charge: 0, // "$10.00",
            Reason: ride.Reason,
          }
        })
      }
      console.log("Completed: ", this.completedRides, " Cancelled: ", this.cancelledRides);
    })
  }


}
