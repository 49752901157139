import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddLocationDialogComponent } from './add-location-dialog/add-location-dialog.component';
import { AdminService } from 'src/app/services/admin.service';
import { AgentServiceService } from 'src/app/services/agent-service.service';
@Component({
  selector: 'app-service-location',
  templateUrl: './service-location.component.html',
  styleUrls: ['./service-location.component.scss']
})
export class ServiceLocationComponent {
  // cityToCityServiceData:any
  showLoader: boolean = true;

  constructor(private dialog: MatDialog, public agentService: AgentServiceService, public adminService : AdminService) { }

  //   ngOnInit(){
  //     this.adminService.getServiceLocation().subscribe((res)=>{
  //       this.cityToCityServiceData=res
  // this.showLoader = false
  //     })
  //   }

  //   deleteAction(service:any) {
  //     console.log("data delete " , service)
  //     this.adminService.getLocationDeleted(service).subscribe((res)=>{
  //       console.log(res)
  //       this.cityToCityServiceData=this.cityToCityServiceData.filter((data: any)=>data._id!=service._id)
  //     })

  //   }
  //   addService() {
  //     const dialogRef = this.dialog.open(AddLocationDialogComponent, {
  //       width: '800px',
  //     });

  //     dialogRef.afterClosed().subscribe(result => {
  //       if (result) {
  //        this.adminService.getServiceLocationAdded(result).subscribe((res)=>{
  //        })
  //        this.cityToCityServiceData.push({fromLocation:result.from,toLocation:result.to,kilometers:result.kilometers})
  //       }
  //     });
  //   }
  states: string[] = [];
  stateData: any = {};
  selectedState: string = "";
  selectedDistrict: string = "";

  fromLocations: string[] = ["Buxar", "Gaya"];
  toLocations: string[] = ["Patna"];

  ngOnInit() {
    this.agentService.getStatesData().subscribe((res) => {
      this.stateData = res.states;
      this.states = res.states.map((element: { state: any; }) => { return element.state })
    })
    this.adminService.getServiceLocation().subscribe((res: any) => {
      console.log(res);
      this.fromLocations = res.fromList?res.fromList:[];
      this.toLocations = res.toList?res.toList:[];
      this.showLoader = false;
    })
  }

  async addLocation(source: string){
    if(source == 'From'){
      if(this.fromLocations.includes(this.selectedDistrict + ", " + this.selectedState)){
        alert("Location is already present in the list."); return;
      }
      this.fromLocations.push(this.selectedDistrict + ", " + this.selectedState);
      this.adminService.updateServiceLocation(source, this.fromLocations).subscribe((res) => {
        console.log("Location Updated successfully");
      });
    }
    else if(source == 'To'){
      if(this.toLocations.includes(this.selectedDistrict + ", " + this.selectedState)){
        alert("Location is already present in the list."); return;
      }
      this.toLocations.push(this.selectedDistrict + ", " + this.selectedState);
      await this.adminService.updateServiceLocation(source,this.toLocations).subscribe((res) => {
        console.log("Location Updated successfully");
      });
    }
    this.selectedDistrict = "";
    this.selectedState = "";
  }

  async removeLoation(source: string, location: string){
    alert(`Are you sure you want to delete ${location} from '${source}' list`) 
    if(source == 'From'){
      if(!this.fromLocations.includes(location)){
        alert("Location is not present in the list."); return;
      }
      this.fromLocations = this.fromLocations.filter((element) => element != location);
      await this.adminService.updateServiceLocation(source,this.fromLocations).subscribe((res) => {
        console.log("Location Updated successfully");
      });;
    }
    else if(source == 'To'){
      if(!this.toLocations.includes(location)){
        alert("Location is not present in the list."); return;
      }
      this.toLocations = this.toLocations.filter((element) => element != location);
      await this.adminService.updateServiceLocation(source,this.toLocations).subscribe((res) => {
        console.log("Location Updated successfully");
      });;
    }
  }

  districts: string[] = [];
  getDistrictsList() {
    this.districts = this.stateData.find((data: State) => data.state === this.selectedState)?.districts;
  }
}

interface State {
  state: string;
  districts: string[];
}

interface StatesData {
  states: State[];
}