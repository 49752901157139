import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  backendURL: string = environment.apiUrl;

  constructor(public http: HttpClient) { }

  explore_cabs(): Observable<any> {
    return this.http.get(this.backendURL + `/customer/explore_cabs`)
  }

  login(login_data: any): Observable<any> {
    return this.http.post(this.backendURL + `/customer/login`, login_data)
  }

  register(register_data: any): Observable<any> {
    return this.http.post(this.backendURL + `/customer/register`, register_data)
  }

  updateProfile(data: any): Observable<any> {
    return this.http.post(this.backendURL + `/customer/profileUpdate`, data)
  }

  fetchProfile(phoneNumber: any): Observable<any> {
    return this.http.get(this.backendURL + `/customer/profile/${phoneNumber}`);
  }

  total_rides(customer_id: any): Observable<any> {
    return this.http.get(this.backendURL + `/customer/customer_rides/${customer_id}`);
  }

  book_ride(ride_detials: any): Observable<any> {
    return this.http.post(this.backendURL + `/customer/book_ride`, ride_detials);
  }

  start_ride(booking_id: any, reading: any, type: any) {
    return this.http.get(this.backendURL + `/customer/startRide/${booking_id}/${reading}/${type}`)
  }
  change_ride_status(status: any, booking_id: any) {
    return this.http.get(this.backendURL + `/customer/changeRideStatus/${status}/${booking_id}`)
  }
  all_locations_name(state: any, district: any) {
    return this.http.post(this.backendURL + `/state/getArea`, { state, district })
  }
  rideDistance(origin: any, destination: any): Observable<any> {
    return this.http.post(this.backendURL + `/bookings/calculateDistance`, { origin, destination });
  }
  getCityLocations(origin: any, destination: any): Observable<any> {
    return this.http.post(this.backendURL + `/bookings/getInBetweenCityLocations`, { origin, destination });
  }
  updateCustomerBooking(booking_id: any, data: any): Observable<any> {
    return this.http.post(this.backendURL + `/customer/updateCustomerBooking`, { booking_id, data });
  }
}
