import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-window',
  templateUrl: './confirmation-window.component.html',
  styleUrls: ['./confirmation-window.component.scss']
})
export class ConfirmationWindowComponent {

  type: string = "";
  @Output() cancellRideConfirm = new EventEmitter<void>();

  constructor(private dialogRef: MatDialogRef<ConfirmationWindowComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.type = data.type;
  }

  onApproveClick(): void {
    this.dialogRef.close('approve');
  }

  onRejectClick(): void {
    this.dialogRef.close('reject');
  }

  cancelRide() {
    this.dialogRef.close('ConfirmedCancellation');
    this.cancellRideConfirm.emit();
  }
  ApproveDoc(){
    console.log("Approve DOc :: ",this.type)
    this.dialogRef.close('Approved');
  }
  rejectDoc(){
    this.dialogRef.close('Rejected');
  }
}
