import { Component } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-rides',
  templateUrl: './rides.component.html',
  styleUrls: ['./rides.component.scss']
})
export class RidesComponent {

adminTable:any[]=[];
tableData:any[]=[];
fiterData:any[]=[];
selectedValue: any = '';
showLoader: boolean = true;
constructor(public adminservice:AdminService){}

options : Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
  timeZone: "Asia/Kolkata"
};

ngOnInit(){
  this.fetchAllRides();
  this.startInterval();
}

fetchAllRides(){
  this.adminservice.getAllridesForDashboard().subscribe((res)=>{
    if(Array.isArray(res) && res.length>0){
     console.log("Rides: ", res)
     this.adminTable=res;
     this.fiterData=res;
     this.tableData=this.adminTable.map(admin=>{    
       return {
         ride_id:admin.booking_id,
         ride_type:admin.tripType,
         pickup_date:admin.pickup_date,
         pickup_time:admin.pickup_time,
         pickup_city:admin.booking_from.city,
         pickup_address:admin.booking_from.landmark + ", " + admin.booking_from.city,
         drop_address: admin.booking_to.landmark + ", " + admin.booking_to.city,
         drop_city:admin.booking_to.city,
         requested_at: new Date(admin.createdAt).toLocaleDateString("en-US", this.options),
         status:admin.status,
         customer_name:admin.customer_details.name,
         customer_contact:admin.customer_details.phoneNumber,
         cabCategoryName:admin.cabCategoryName,
       }
     })
    }
    this.showLoader  = false; 
   
     })
}
  columnData = [
    { field: "ride_id", label: "Ride ID" },
    { field: "ride_type", label: "Ride Type" },
    { field: "customer_name", label: "Customer Name" },
    { field: "customer_contact", label: "Customer Contact" },
    {field:  "cabCategoryName",label:"Car Category"},
    { field: "pickup_date", label: "Pickup Date" },
    { field: "pickup_time", label: "Pickup Time" },
    { field: "pickup_address", label: "Pickup Address" },
    { field: "drop_address", label: "Drop Address" },
    { field: "requested_at", label: "Requeste At" },
    { field: "status", label: "Status" },
  ];

  ride_filter: any[] = ['All', 'Requested', 'Ongoing', 'Completed', 'Assigned', 'Cancelled', 'Scheduled', 'Forwarded', 'Refunded']
  changeFilterValue() {
    console.log("selectedVal : ",this.selectedValue)
    if(this.selectedValue=='All'){
      this.tableData = this.adminTable.map(admin=>{    
        return {
          ride_id:admin.booking_id,
      ride_type:admin.tripType,
      pickup_date:admin.pickup_date,
      pickup_time:admin.pickup_time,
      pickup_city:admin.booking_from.city,
      pickup_address:admin.booking_from.landmark + ", " + admin.booking_from.city,
      drop_address: admin.booking_to.landmark + ", " + admin.booking_to.city,
      drop_city:admin.booking_to.city,
      requested_at: new Date(admin.createdAt).toLocaleDateString("en-US", this.options),
      status:admin.status,
      customer_name:admin.customer_details.name,
      customer_contact:admin.customer_details.phoneNumber
        }
      });
    }
    else{
    this.tableData = this.fiterData.filter(element=>element.status == this.selectedValue).map(admin=>{      
      return {
        ride_id:admin.booking_id,
      ride_type:admin.tripType,
      pickup_date:admin.pickup_date,
      pickup_time:admin.pickup_time,
      pickup_city:admin.booking_from.city,
      pickup_address:admin.booking_from.landmark + ", " + admin.booking_from.city,
      drop_address: admin.booking_to.landmark + ", " + admin.booking_to.city,
      drop_city:admin.booking_to.city,
      requested_at: new Date(admin.createdAt).toLocaleDateString("en-US", this.options),
      status:admin.status,
      customer_name:admin.customer_details.name,
      customer_contact:admin.customer_details.phoneNumber
      }
    })
  }
    
  }

  private intervalId: any;
  startInterval(): void {
    // Call the method every 1000 milliseconds (1 second)
    this.intervalId = setInterval(() => {
      this.fetchAllRides();
    }, 30000);
  }

  ngOnDestroy(): void {
    // Clear the interval when the component is destroyed to prevent memory leaks
    this.clearInterval();
  }

  clearInterval(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
