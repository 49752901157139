<mat-card class="example-card">
    <mat-card-header class="justify-content-around ">
        <mat-card-title>Partner</mat-card-title>
    </mat-card-header>
  </mat-card>
  <ng-container *ngIf="showLoader; else content" class="loader-container">
    <div class="row">
      <div class="loader"></div>
    </div>
  </ng-container>
  <ng-template #content>
<div class="stepper">
    <mat-card class="m-2 mb-4">
        <mat-horizontal-stepper [selectedIndex]="currentStep" (selectionChange)="onStepChange($event)">
            <mat-step label="All Agents" completed="false">
                <div style="display: flex; justify-content: flex-end;">
                    <mat-form-field class="col-md-3">
                        <mat-label>Filter partner</mat-label>
                        <mat-select [(ngModel)]="selectedValue" (ngModelChange)="changeFilterValue()">
                            <mat-option *ngFor="let filter of ride_filter" [value]="filter">{{filter}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <ng-template matStepLabel>All Partner</ng-template>
                <app-table [popUpData]="popUpData" source="Admin" [data]="tableData" [columnData]="columnData" [flag]="'agent'"
                    (agentStatusUpdated)="fetchAllAgent()"></app-table>
            </mat-step>
        </mat-horizontal-stepper>
    </mat-card>
</div>
  </ng-template>