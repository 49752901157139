import { Component, ElementRef, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { LoginSignupComponent } from '../login-signup/login-signup.component';
import { Router } from '@angular/router';
import { CustomerService } from '../services/customer.service';
import { DriverService } from '../services/driver.service';
import { AgentServiceService } from '../services/agent-service.service';
import { AgentLoginComponent } from '../popupWindows/agent-login/agent-login.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  constructor(private customerService: CustomerService, private driverService: DriverService, private agentService: AgentServiceService, private renderer: Renderer2, private elementRef: ElementRef, private dialog: MatDialog, private router: Router) {
  }

  ngOnInit() {
    // localStorage.clear();
    const auth = localStorage.getItem('customer_auth');
    const expirationDate = localStorage.getItem('customer_authExpiration');
    if (auth && expirationDate) {
      const date = new Date(expirationDate);
      if (date <= new Date()) {
        this.signout();
      } else {
        this.profile = true;
      }
    }
    else {
      this.profile = false;
    }
  }


  profile: boolean = false;
  login_signUp() {
    const dialog = this.dialog.open(LoginSignupComponent, {
      data: {
        title: "login"
      }
    });
    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.profile = true;
        // localStorage.clear();
        const expirationDate = new Date(new Date().getTime() + 20 * 60 * 60 * 1000);
        localStorage.setItem("customer_auth", res.phoneNumber);
        localStorage.setItem("customer_authExpiration", expirationDate.toString());
        this.profile_component();
      }
    })
  }

  profile_component() {
    this.router.navigate(['/customerProfile']);
  }

  signout() {
    localStorage.removeItem('customer_auth');
    localStorage.removeItem('customer_authExpiration');
    this.profile = false;
    this.router.navigate(['/']);
  }


  driverLogin() {
    const auth = localStorage.getItem("driver_auth");
    const time = localStorage.getItem("driver_authExpiration");
    if (time) {
      const date = new Date(time);
      if (date <= new Date()) {
        localStorage.removeItem('driver_auth');
        localStorage.removeItem('driver_authExpiration');
        this.driver_login();
      } else {
        this.router.navigate(['/driver']);
      }
    } else {
      this.driver_login();
    }
  }


  driver_login() {
    const dialog = this.dialog.open(LoginSignupComponent, {
      data: {
        title: "driver_login"
      }
    });
    dialog.afterClosed().subscribe(res => {
      console.log(res)
      if (res) {
        const expirationDate = new Date(new Date().getTime() + 20 * 60 * 60 * 1000);
        localStorage.setItem("driver_auth", res.driver_id);
        localStorage.setItem("driver_authExpiration", expirationDate.toString());
        this.router.navigate(['/driver']);
      }
    })
  }

  agentLogin() {
    const auth = localStorage.getItem("agent_auth");
    const time = localStorage.getItem("agent_authExpiration");
    if (time && auth) {
      const date = new Date(time);
      if (date <= new Date()) {
        localStorage.removeItem('agent_auth');
        localStorage.removeItem('agent_authExpiration');
        this.agent_login();
      } else {
        this.router.navigate(['/agent']);
      }
    } else {
      this.agent_login();
    }
  }


  agent_login() {
    const dialog = this.dialog.open(AgentLoginComponent, {
      data: {
        title: "agent_login"
      }
    });
    dialog.afterClosed().subscribe(res => {
      if (res) {
        const expirationDate = new Date(new Date().getTime() + 20 * 60 * 60 * 1000);
        localStorage.setItem("agent_auth", res.agent_id);
        localStorage.setItem("agent_authExpiration", expirationDate.toString());
      }
    })
  }
  adminLogin() {
    const auth = localStorage.getItem("admin_auth");
    const time = localStorage.getItem("admin_authExpiration");
    if (time) {
      const date = new Date(time);
      if (date <= new Date()) {
        localStorage.removeItem('admin_auth');
        localStorage.removeItem('admin_authExpiration');
        this.admin_login();
      } else {
        // this.adminService.fetchData(auth).subscribe((res) => {
        //   this.router.navigate(['/admin/', res.result[0]]);
        // })
      }
    } else {
      this.admin_login();
    }
  }


  admin_login() {
    const dialog = this.dialog.open(AgentLoginComponent, {
      data: {
        title: "admin_login"
      }
    });
    dialog.afterClosed().subscribe(res => {
      if (res) {
        const expirationDate = new Date(new Date().getTime() + 20 * 60 * 60 * 1000);
        localStorage.setItem("admin_auth", res.admin_id);
        localStorage.setItem("admin_authExpiration", expirationDate.toString());
      }
    })
  }

}
