<mat-card class="example-card">
    <mat-card-header class="justify-content-around ">
        <mat-card-title>Price Details</mat-card-title>
    </mat-card-header>
  </mat-card>
<mat-card class="pt-4 bank-card">
    <div class="edit-allfield">
        <p class="heading">Pricing Details</p>
        <mat-icon class="icon" (click)="editValue(false)">edit</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <div class="info">
        <span>Slab</span>
       <h2 *ngIf= "toggleButton">{{agentPrice.slab.toString()   |currency:'INR':'symbol'}}</h2>
       <textarea *ngIf="!toggleButton" [(ngModel)]="agentPrice.slab" class="textarea-inline-control"
            [disabled]='toggleButton'></textarea>
    </div>
    <div class="info">
        <span>Company Charges</span>
        <h2 *ngIf= "toggleButton">{{agentPrice.companyCharges.toString()   |currency:'INR':'symbol' }}</h2>
        <textarea *ngIf="!toggleButton" [(ngModel)]="agentPrice.companyCharges" class="textarea-inline-control"
             [disabled]='toggleButton'></textarea>
     </div>
    <div class="info">
        <span>Night charges</span>
        <h2 *ngIf= "toggleButton">{{agentPrice.nightCharges.toString()   |currency:'INR':'symbol'}}</h2>
        <textarea *ngIf="!toggleButton" [(ngModel)]="agentPrice.nightCharges" class="textarea-inline-control"
            [disabled]='toggleButton'></textarea>
    </div>
    <div class="info">
        <span>Driver Allowance</span>
        <h2 *ngIf= "toggleButton">{{agentPrice.driverAllowance}}%</h2>
        <textarea *ngIf="!toggleButton" [(ngModel)]="agentPrice.driverAllowance" class="textarea-inline-control"
            [disabled]='toggleButton'></textarea>
    </div>
    <div class="info">
        <span>PeakMultiplier</span>
        <h2 *ngIf= "toggleButton">{{agentPrice.peakmultiplier.toString()   |currency:'INR':'symbol'}}</h2>
        <textarea *ngIf="!toggleButton" [(ngModel)]="agentPrice.peakmultiplier" class="textarea-inline-control"
            [disabled]='toggleButton'></textarea>
    </div>
    <div class="info">
        <span>TAX</span>
        <h2 *ngIf= "toggleButton">{{agentPrice.tax}}%</h2>
        <textarea *ngIf="!toggleButton" [(ngModel)]="agentPrice.tax" class="textarea-inline-control"
            [disabled]='toggleButton'></textarea>
    </div>
    <div class="info">
        <span>Two Way Factor</span>
        <h2 *ngIf= "toggleButton">{{agentPrice.twoWayFactor}}%</h2>
        <textarea *ngIf="!toggleButton" [(ngModel)]="agentPrice.twoWayFactor" class="textarea-inline-control"
            [disabled]='toggleButton'></textarea>
    </div>
        <section class="example-container mat-elevation-z8" tabindex="0">
            <table mat-table [dataSource]="categoryDetails">
          
                <ng-container matColumnDef="Cab Category">
                <th mat-header-cell *matHeaderCellDef> Cab Category </th>
                <td mat-cell *matCellDef="let element"> {{element.categoryName}} </td>
              </ng-container>

              <ng-container matColumnDef="Per Km charges">
                <th mat-header-cell *matHeaderCellDef> Per Km charges </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf= "toggleButton;else editInput">
                    {{ element.perKmCharge }}
                  </ng-container>
                  <ng-template #editInput>
                    <mat-form-field>
                    <input matInput
                    placeholder="Enter value" type="number" [(ngModel)]="element.perKmCharge">
                    </mat-form-field>
                  </ng-template>
                </td>
              </ng-container>
              <ng-container matColumnDef="Extra km charges">
                <th mat-header-cell *matHeaderCellDef> Extra km charges </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf= "toggleButton;else editValue">
                    {{ element.extraKMCharge }}
                  </ng-container>
                  <ng-template #editValue>
                    <mat-form-field>
                    <input matInput
                    placeholder="Enter value" type="number" [(ngModel)]="element.extraKMCharge">
                    </mat-form-field>
                  </ng-template>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </section>
          
    <div class="btn" *ngIf="showbtn">
        <button mat-stroked-button (click)="addPricing()" >Save</button>
    </div>

</mat-card>