<!-- <mat-card class="table-card">
  <div class="container">
    <input type="text" matInput name="text" class="input" placeholder="search customer" (keyup)="applyFilter($event)" #input>
    <button class="search__btn">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22">
        <path
          d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
          fill="#efeff1"></path>
      </svg>
    </button>
  </div>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="position" class="table-row">
      <th mat-header-cell *matHeaderCellDef> No. </th>
      <td mat-cell *matCellDef="let element"> {{element.position}} </td>
    </ng-container>

    <ng-container matColumnDef="Customer">
      <th mat-header-cell *matHeaderCellDef> Customer </th>
      <td mat-cell *matCellDef="let element"> {{element.Customer}} </td>
    </ng-container>

    <ng-container matColumnDef="EmailId">
      <th mat-header-cell *matHeaderCellDef> EmailId </th>
      <td mat-cell *matCellDef="let element"> {{element.EmailId}} </td>
    </ng-container>

    <ng-container matColumnDef="Contact">
      <th mat-header-cell *matHeaderCellDef> Contact </th>
      <td mat-cell *matCellDef="let element"> {{element.Contact}} </td>
    </ng-container>
    <ng-container matColumnDef="Rating">
      <th mat-header-cell *matHeaderCellDef> Rating </th>
      <td mat-cell *matCellDef="let element"> {{element.Rating}} </td>
    </ng-container>
    <ng-container matColumnDef="Ride_History">
      <th mat-header-cell *matHeaderCellDef> Ride History </th>
     <td mat-cell *matCellDef="let element">  <Button class="button" [routerLink]="['/rideHistory']">{{element.Ride_History}} </Button></td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element">
        
        <Button class="btn btn-success button1" *ngIf="element.status == 'Active'"> {{element.status}} </Button>
        <Button class="btn btn-danger button1" *ngIf="element.status == 'InActive'"> {{element.status}} </Button>
        
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-card> -->
<mat-card class="example-card">
  <mat-card-header class="justify-content-around">
    <mat-card-title>Customer</mat-card-title>
  </mat-card-header>
</mat-card>

<ng-container *ngIf="showLoader; else content" class="loader-container">
  <div class="row">
    <div class="loader"></div>
  </div>
</ng-container>
<ng-template #content>
  <div class="steeper">
    <mat-card class="m-2 mb-4">
      <app-table
        [data]="tableData"
        [columnData]="columnData"
        [flag]="'customer'"
      ></app-table>
    </mat-card>
  </div>
</ng-template>
