import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddCustomerComponent } from './admin/admin-customer/add-customer/add-customer.component';
import { AdminComponent } from './admin/admin.component';
import { RideHistoryComponent } from './admin/admin-customer/ride-history/ride-history.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { CustomerComponent } from './customer/customer.component';
import { CustomerProfileComponent } from './customer/customer-profile/customer-profile.component';
import { AuthenticateComponent } from './authenticate/authenticate.component';
import { TravelAgentComponent } from './travel-agent/travel-agent.component';
import { TravvolDrviverComponent } from './travvol-drviver/travvol-drviver.component';
import { RegistrationComponent } from './registration/registration.component';
import { LoginSignupComponent } from './login-signup/login-signup.component';
import {DriverProfileComponent} from './travvol-drviver/driver-profile/driver-profile.component';

const routes: Routes = [
  { path: '', component: CustomerComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'customerProfile', component: CustomerProfileComponent },
  { path: 'login', component: AuthenticateComponent },
  { path: 'agent', component: TravelAgentComponent },
  { path: 'adminDashboard', component: AdminComponent },
  { path: 'driver', component: TravvolDrviverComponent },
  { path: 'driver_profile', component: DriverProfileComponent },
  { path: 'register', component: RegistrationComponent },
  { path: 'login_signup', component: LoginSignupComponent },
  { path: "**", redirectTo: "/", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
