<h2 mat-dialog-title>Add Driver</h2>
<mat-dialog-content>
    <div class="row" [formGroup]="driverFormGroup">
        <mat-form-field class="col-md-6">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" formControlName="driverName">
        </mat-form-field>
        <mat-form-field class="col-md-6">
            <mat-label>Email ID</mat-label>
            <input matInput placeholder="Email ID" formControlName="emailId">
        </mat-form-field>
        <div class="form-doc-fields">
            <span>Profile Picture</span>
            <div class="info">
                <div style="display: flex;">
                    <input class="form-control" formControlName="profilePic" type="file" (change)="onFileSelected($event,'profilePic')"
                        [(ngModel)]="driverclass['profilePic']">
                    <!-- <div>
                        <button (click)="onUpload()" class="btn btn-success">
                            Upload
                        </button>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="form-doc-fields">
            <div><span>Aadhar Card</span></div>

            <mat-form-field class="col-md-12">
                <mat-label>Aadhar Number</mat-label>
                <input matInput placeholder="Aadhar Number" appNumericOnly maxlength="12" formControlName="aadharNumber">
            </mat-form-field>
            <div class="info">
                <div style="display: flex;">
                    <input class="form-control" formControlName="aadharUpload" type="file" (change)="onFileSelected($event,'aadharUpload')"
                        [(ngModel)]="driverclass['aadharUpload']">
                    <!-- <div>
                        <button (click)="onUpload()" class="btn btn-success">
                            Upload
                        </button>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="form-doc-fields">
            <div>
                <span>Pan Card</span>
            </div>

            <mat-form-field class="col-md-12">
                <mat-label>Pan Number</mat-label>
                <input matInput placeholder="Pan Number" formControlName="panNumber" maxlength="10">
            </mat-form-field>
            <div class="info">
                <div style="display: flex;">
                    <input class="form-control" formControlName="panUpload" type="file" (change)="onFileSelected($event,'panUpload')"
                        [(ngModel)]="driverclass['panUpload']">
                    <!-- <div>
                        <button (click)="onUpload()" class="btn btn-success">
                            Upload
                        </button>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="form-doc-fields">
            <div><span>Driver License</span></div>
            <mat-form-field class="col-md-12">
                <mat-label>Driving License Number</mat-label>
                <input matInput placeholder="Driving License Number" formControlName="dlNumber" maxlength="13">
            </mat-form-field>
            <div class="info">
                <div style="display: flex;">
                    <input class="form-control" formControlName="dlUpload" type="file" (change)="onFileSelected($event,'dlUpload')"
                        [(ngModel)]="driverclass['dlUpload']">
                </div>
            </div>
        </div>

        <div class="form-doc-fields">
            <span>Police Verification Certificate</span>
            <div class="info">
                <div style="display: flex;">
                    <input class="form-control" formControlName="policeVerification" type="file"
                    (change)="onFileSelected($event,'policeVerification')" [(ngModel)]="driverclass['policeVerification']">
                    <!-- <div>
                        <button (click)="onUpload()" class="btn btn-success">
                            Upload
                        </button>
                    </div> -->
                </div>
            </div>
        </div>

        <mat-form-field class="col-md-6">
            <mat-label>Mobile Number</mat-label>
            <input matInput placeholder="Phone Number" appNumericOnly formControlName="mobileNumber"
                maxlength="10" required>
        </mat-form-field>

    </div>
    <div class="col-sm-4 fw-bold label" *ngIf="!otpSent && !phoneVerified">
        <button class="btn login" (click)=" SendOTP()" [disabled]="!isMobileNumberValid()">Send OTP</button>
    </div>

    <div class="d-flex main-div mt-4" *ngIf="otpSent">

        <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
            <div class="col-sm-4 fw-bold label">Enter OTP :<span class="text-danger">*</span></div>
            <mat-form-field class="col-sm-8 input-field">
                <input matInput placeholder="Enter 6 digit OTP" [(ngModel)]="otp" maxlength="6" appNumericOnly>
            </mat-form-field>


            <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                <button class="btn login" (click)="verifyOtp()" [disabled]="!activateLoginBtn()">Verify</button>
                <button class="btn login" (click)="ResendOTP()">Resend OTP</button>
                <button class="btn login" (click)="Cancel()">Cancel</button>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button class="btn login" (click)="onNoClick()">No Thanks</button>
    <button mat-button class="btn login" (click)="addDriver()" [disabled]="driverFormGroup.invalid || !phoneVerified"
        cdkFocusInitial>Add
        Driver</button>
</mat-dialog-actions>