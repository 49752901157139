import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from '../services/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss']
})
export class TransactionDetailsComponent {

  transactionData : Transaction[] = [];
  agentId: string = "";
  agentDetails: any = "";
  showLoader:boolean=true;
  source: string = "";
  columnData   = [
    { field: "transactionId", label: "Transaction ID" },
    { field: "bookingId", label: "Booking ID" },
    // { field: "customerId", label: "Customer ID" },
    { field: "paidTo", label: "Paid To" },
    { field: "amount", label: "Amount" },
    { field: "paymentMode", label: "Payment Mode" },
    { field: "paymenttype", label: "Payment Type" },
    { field: "transactionDate", label: "Transaction Date" },
    { field: "paymentStatus", label: "Payment Status" },
  ]

  earningData: any;
  amountToBePaid = 0;
  amountToBeClaimed = 0;
  slab = 0;
  companyCharges = 0;
  settlePaymentEnabled = false;
  transactionID = "";
  amount = "";
  modeOfTransaction = "";

  constructor(private snackbar : MatSnackBar,public dialogRef: MatDialogRef<TransactionDetailsComponent>,private snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) private data: any, private _service : AdminService) {
    this.source = data.source;
    this.earningData = data.content;
    console.log(this.earningData)
    this.agentId = data.content.agentId;
    this._service.getAllTransactionDetails(this.agentId).subscribe((res) => {
      this.agentDetails = res.agentDetaisl;
      this.slab = res.pricingDetails.slab;
      this.companyCharges = res.pricingDetails.companyCharges;
      this.transactionData = res.data.map((element: any) => {
        return {
          transactionId: element.paymentType != "Final Payment" ? element.transactionId : element._id,
          bookingId : element.paymentType == "Settlement"? " - ": element.bookingId,
          customerId : element.paymentType == "Settlement"? " - ": element.customerId,
          paidTo : element.paidTo,
          amount : element.amount,
          paymentMode : element.paymentMode,
          paymenttype : element.paymentType,
          transactionDate : element.transactionDate,
          paymentStatus : element.paymentStatus,
      }})
      this.calculateOutstandingBalance();
      this.showLoader=false;
    })
  }

  calculateOutstandingBalance(){
    this.amountToBeClaimed = (this.earningData.unCommissiondAmount >= this.slab) ? (Math.floor(this.earningData.unCommissiondAmount / this.slab))*this.companyCharges : 0;
    this.amountToBePaid = this.earningData.amountWithCompany - this.amountToBeClaimed; 
  }
  settlePayment(){
    this.earningData.receivedFromCompany += this.amountToBePaid;
    this.earningData.totalCompanyClaimed += this.amountToBeClaimed;
    this.earningData.amountWithCompany = 0;
    this.earningData.unCommissiondAmount = (this.earningData.unCommissiondAmount % this.slab);

    this._service.settlePayment({
      agentId:this.agentId ,
      LastSettlementDate: new Date(),
      transactionId: this.transactionID,
      modeOfPayment: this.modeOfTransaction,
      transactionAmount: this.amountToBePaid,
    }).subscribe((res) => {
      console.log(res);

      this.snackBar.open("Payment settled successfully!", 'Close', { duration: 3000 })
      this.calculateOutstandingBalance();
      this.snackBar.open("Amount paid successfully", 'Close', { duration: 3000 })
      this.dialogRef.close();
    },((error) => {
      console.log(error);
      this.snackBar.open("Some error occured!", 'Close', { duration: 3000 })
    }))
  }

}


class Transaction {
  transactionId : string = ""; 
  bookingId : string = ""; 
  customerId : string = ""; 
  paidTo : string = ""; 
  amount : string = ""; 
  paymentMode : string = ""; 
  paymenttype : string = ""; 
  transactionDate : string = ""; 
  paymentStatus : string = ""; 
}
