import { Component, ViewChild,AfterViewInit } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


export interface PeriodicElement {
  ride_id: string;
  Driver_name: string;
  DriverContact: string;
  Ridestatus:string,
  pickupDate:string,
  dropDate:String,
  vehicleNO:string,
  VehicleType: string; 
  PickupDropAddress: string; 
  Fare: number; 
  RideStatus: string; 
  InvoiceReceipt: string;
  PaymentMode: string; 
  AdvancePaymentAmount: number; 
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    ride_id: 'R123',
    Driver_name: 'john.doe@example.com',
    DriverContact: '123-456-7890',
    Ridestatus: 'Active',
    pickupDate: '2-12-2023',
    dropDate: '3-12-2023',
    vehicleNO: 'ABC123',
    VehicleType: 'Sedan',
    PickupDropAddress: '123 Main St, City',
    Fare: 30.00,
    RideStatus: 'upcoming',
    InvoiceReceipt: 'INV-12345',
    PaymentMode: 'Online',
    AdvancePaymentAmount: 10.00
  },
  {
    ride_id: 'R124',
    Driver_name: 'jane.doe@example.com',
    DriverContact: '987-654-3210',
    Ridestatus: 'InActive',
    pickupDate: '2-12-2023',
    dropDate: '3-12-2023',
    vehicleNO: 'XYZ789',
    VehicleType: 'SUV',
    PickupDropAddress: '456 Oak St, Town',
    Fare: 40.00,
    RideStatus: 'ongoing',
    InvoiceReceipt: 'INV-67890',
    PaymentMode: 'Cash',
    AdvancePaymentAmount: 15.00
  },
  {
    ride_id: 'R125',
    Driver_name: 'alice.smith@example.com',
    DriverContact: '555-123-4567',
    Ridestatus: 'Active',
    pickupDate: '2-12-2023',
    dropDate: '3-12-2023',
    vehicleNO: 'LMN456',
    VehicleType: 'Hatchback',
    PickupDropAddress: '789 Pine St, Village',
    Fare: 25.00,
    RideStatus: 'completed',
    InvoiceReceipt: 'INV-34567',
    PaymentMode: 'Online',
    AdvancePaymentAmount: 20.00
  },
  {
    ride_id: 'R126',
    Driver_name: 'bob.johnson@example.com',
    DriverContact: '444-567-8901',
    Ridestatus: 'InActive',
    pickupDate: '2-12-2023',
    dropDate: '3-12-2023',
    vehicleNO: 'PQR789',
    VehicleType: 'Compact',
    PickupDropAddress: '101 Pine St, Village',
    Fare: 35.00,
    RideStatus: 'upcoming',
    InvoiceReceipt: 'INV-45678',
    PaymentMode: 'Online',
    AdvancePaymentAmount: 12.00
  },
  {
    ride_id: 'R127',
    Driver_name: 'charlie.brown@example.com',
    DriverContact: '333-678-9012',
    Ridestatus: 'Active',
    pickupDate: '2-12-2023',
    dropDate: '3-12-2023',
    vehicleNO: 'JKL567',
    VehicleType: 'Sedan',
    PickupDropAddress: '222 Oak St, Town',
    Fare: 30.00,
    RideStatus: 'ongoing',
    InvoiceReceipt: 'INV-78901',
    PaymentMode: 'Cash',
    AdvancePaymentAmount: 18.00
  },
  
];


@Component({
  selector: 'app-ride-history',
  templateUrl: './ride-history.component.html',
  styleUrls: ['./ride-history.component.scss']
})
export class RideHistoryComponent {
  displayedColumns: string[] = [
    'ride_id',
    'Driver_name',
    'DriverContact',
    'Ridestatus',
    'pickupDate',
    'dropDate',
    'vehicleNO',
    'VehicleType',
    'PickupDropAddress',
    'Fare',
    'RideStatus',
    'InvoiceReceipt',
    'PaymentMode',
    'AdvancePaymentAmount'
  ];
  
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

}
