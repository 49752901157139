import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgentRegistrationComponent, DriverDetaisl } from '../agent-registration.component';
import { Driver } from '../agent';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DriverService } from 'src/app/services/driver.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminService } from 'src/app/services/admin.service';
import { AwsUploadService } from 'src/app/services/aws-upload.service';


@Component({
  selector: 'app-add-driver-dialog',
  templateUrl: './add-driver-dialog.component.html',
  styleUrls: ['./add-driver-dialog.component.scss']
})
export class AddDriverDialogComponent {
  driverclass: Driver = new Driver;
  mobileNumber: string = ''
  otpSent: boolean = false;
  otp:string = '';
  orderId: any;
  phoneVerified: boolean = false;

  constructor(private formBuilder: FormBuilder, private driverService: DriverService,
    public dialogRef: MatDialogRef<AgentRegistrationComponent>, private snackBar: MatSnackBar, public adminservice: AdminService
    , public docUpload: AwsUploadService) { }

  driverFormGroup!: FormGroup;
  ngOnInit() : void {
    this.driverFormGroup = this.formBuilder.group({
      driverName: ['', [Validators.required]],
      mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
          profilePic: [''],
      emailId: ['', [Validators.email]],
      panNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      panUpload: ['', [Validators.required]],
      dlNumber: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(16)]],
      dlUpload: ['', [Validators.required]],
      aadharNumber: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(12)]],
      aadharUpload: ['', [Validators.required]],
      policeVerification: ['', [Validators.required]],

    })
  }

  onUpload() {

  }

  onChange(event: any) {
    // Your logic to handle file change
  }


  addDriver(){
    this.driverFormGroup.get('mobileNumber')?.enable();
    if(this.driverFormGroup.valid){
      this.driverService.register(this.driverFormGroup.value, 'agent').subscribe(
        (res) => {
          if(!res.driver_data){
            this.otpSent = false;
            this.phoneVerified = false;
            this.snackBar.open(res.message, 'Close', { duration: 3000 });
          }
          else{
            this.dialogRef.close({ driverId: res.driver_data.driver_id, driverDetails: this.driverFormGroup.value });
            this.snackBar.open(res.message, 'Close', { duration: 3000 });
          }
          
        },
        (error)=>{

          this.snackBar.open(error.error, 'Close', { duration: 3000 });
         this.driverFormGroup.get('mobileNumber')?.disable();

}
      )
    }
    else{
      alert("Enter all the required values!");
      this.driverFormGroup.get('phoneNumber')?.disable();

    }
  }

 
SendOTP() {
  this.otpSent = true;  
  this.adminservice.getOTPValue(parseInt(this.driverFormGroup.value.mobileNumber)).subscribe((res: any) => {
    this.orderId = res.orderId;
    this.snackBar.open("OTP sent successfully !", 'Close', { duration: 3000 });
    this.driverFormGroup.get('mobileNumber')?.disable()
  }, (err) => {
    this.snackBar.open(err.error.error, 'Close', { duration: 3000 });
  })
}
async verifyOtp(): Promise<boolean> {
  this.driverFormGroup.get('mobileNumber')?.enable();
    let phNumber = this.driverFormGroup.value.mobileNumber;
    this.driverFormGroup.get('mobileNumber')?.disable();
  return this.adminservice.getOTPVerified(this.orderId, parseInt(this.otp), parseInt(phNumber)).toPromise()
      .then((res: any) => {
          console.log("Verify OTP: ", res, res.isOTPVerified);
        this.otpSent = false;
        this.phoneVerified = true;
        this.snackBar.open("OTP verified successfully !", 'Close', { duration: 3000 });
          return res.isOTPVerified;
      })
      .catch(error => {
          console.error("Error verifying OTP:", error);
          return false;
      });
}
ResendOTP(){
  this.adminservice.getReSendOTP(this.orderId).subscribe((res:any)=>{
    this.orderId = res.orderId;
    this.snackBar.open("OTP resend successfull !", 'Close', { duration: 3000 });
  },(err)=>{
    this.snackBar.open(err.error.error, 'Close', { duration: 3000 })
  })
}
Cancel(){
  this.driverFormGroup.get('mobileNumber')?.enable();
  // this.driverFormGroup.value.mobileNumber = '';
  this.otpSent=false;
}
isMobileNumberValid(): boolean {
  const mobileNumberString: string = this.driverFormGroup.value.mobileNumber.toString(); // Convert to string
  return mobileNumberString.length === 10;
}
  onNoClick(): void {
    this.otpSent = false;
    this.dialogRef.close();
  }
  //fileupload
  onFileSelected(event: any, source: string) {
    const file = event.target.files[0].name
    if (file) {
      this.docUpload.onFileSelected(event).then((res: any) => {
        if (this.driverclass.hasOwnProperty(source)) {
          this.driverclass[source] = res.fileUrl;
          console.log("Driver Info: ", this.driverclass);
        } else {
          console.error("File URL is not available.");
        }
      }).catch((error) => {
        console.log("Error in ts: ", error);
      });
    }
  }
  activateLoginBtn():boolean{
    return this.otp.length ===6
  }
}
