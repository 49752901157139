import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AgentServiceService } from 'src/app/services/agent-service.service';

@Component({
  selector: 'app-manage-worklocation',
  templateUrl: './manage-worklocation.component.html',
  styleUrls: ['./manage-worklocation.component.scss']
})
export class ManageWorklocationComponent {
  stateData: any = {};
  agentId: string = "";
  showLoader = true;
  columnData = [
    { field: "CityName", label: "City" },
    { field: "Preferred", label: "Preferred" },
    { field: "Action", label: "Action" },
  ];
  
  tableData = [
  {CityName: "Buxar", Action: "",Preferred:""},
  {CityName: "Patna", Action: "",Preferred:"Preferred"},
  {CityName: "Ranchi", Action: "",Preferred:""},
  {CityName: "Varanasi", Action: "",Preferred:""},
  {CityName: "Lucknow", Action: "",Preferred:""},
    // Add more dummy data as needed
  ];

  locationData : any[]= [];

  states: string[] = ['Bihar'];
  selectedState : string = "";
  selectedDistrict: string = "";
  preferredCity : string = "";
  constructor(private _service: AgentServiceService, private snackBar: MatSnackBar, private router: Router) {
    this.checkLoginStatus();
  }

  checkLoginStatus() {
    // localStorage.setItem("admin_auth", res.phoneNumber);
    // localStorage.setItem("admin_authExpiration", expirationDate.toString());  
    console.log("Checking login status")
    const auth = localStorage.getItem("agent_auth");
    const time = localStorage.getItem("agent_authExpiration");
    console.log("checked auth: ", auth, time);
    if (time && auth) {
      const date = new Date(time);
      console.log("time: ", time)
      if (date <= new Date()) {
        console.log("time: ", time)
        localStorage.removeItem('agent_auth');
        localStorage.removeItem('agent_authExpiration');
      }
      else {
        this.agentId = auth;
      }
    }
    else {
      this.router.navigate(['/']);
    }
  }
  ngOnInit(){
    this._service.getStatesData().subscribe((res) => {
      this.stateData = res.states; 
      //this.states = res.states.map((element: { state: any; }) => {return element.state})     
    })
    this._service.getWorkLocation(this.agentId).subscribe((res) => {
      console.log("Result: ",res);
      this.preferredCity = res.preferredLocation;
      this.locationData = res.workLocation?.map((data: any) => {
        // console.log(data)
        return {CityName: data, Action: "",Preferred:data == this.preferredCity ? "Preferred" : ""}
      })
      this.showLoader = false;
    })
  }

  districts : string[] = [];
  getDistrictsList(){
    this.districts = this.stateData.find((data: State) => data.state === this.selectedState)?.districts;
  }

  AddWorkLocation(){
    console.log("city: ", this.selectedDistrict);
    let location = this.selectedDistrict + ", " + this.selectedState;
    this._service.addWorkLocation(this.agentId, location).subscribe((res) => {
      console.log(res);
      this.locationData = res.data.map((data: any) => {
        return {CityName: data, Action: "",Preferred:data == this.preferredCity ? "Preferred" : ""}
      })
      this.selectedDistrict = "";
      this.selectedState = "";
      // this.snackBar.open("Location Added successfully!",'Close', 3000)
      this.snackBar.open("Location Added successfully!", 'Close', { duration: 3000 });
    })
    console.log(this.locationData);
  }

  UpdatePreferredCity() {
    this._service.updatePreferredCity(this.agentId, this.preferredCity).subscribe((res) =>{
      this.locationData = res.data.map((data: any) => {
        return {CityName: data, Action: "",Preferred:data == this.preferredCity ? "Preferred" : ""}
      })
      this.snackBar.open("Preferred Location updated successfully!", 'Close', { duration: 3000 });
    })
  }

  getCityNames() {
    return this.locationData.map(data => data.CityName)
  }

  removeCity(locatioData:any){
    try {

      this._service.removeWorkLocation(this.agentId, locatioData.CityName).subscribe((res)=>{
        console.log(res);
        this.preferredCity = res.preferredLocation;
        this.locationData = res.workLocation?.map((data: any) => {
          return {CityName: data, Action: "",Preferred:data == this.preferredCity ? "Preferred" : ""}
        })
        console.log(this.locationData);
        this.snackBar.open("Location removed successfully!", 'Close', { duration: 3000 });

      })

    } catch (error) {
      console.log(error)
    }
  }

}


interface State {
  state: string;
  districts: string[];
}

interface StatesData {
  states: State[];
}