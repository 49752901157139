import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { pricingDetails } from './pricing';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent {

  agentPrice:pricingDetails=new pricingDetails;
  toggleButton:boolean=true;
  showbtn:boolean=false;
  cabCategory:string="";
  perKmCharge:number=0;
  extraKMCharge:number=0;
  categoryDetails:any[]=[]

  constructor(private route: ActivatedRoute, private adminservice:AdminService) { }

  @ViewChild(MatStepper) stepper!: MatStepper;
  editValue(editable: boolean){
    this.toggleButton=editable
    this.showbtn=!editable

  }
  ngOnInit(){
    this.adminservice.getPricingValue().subscribe(
      (res: any) => {
        this.agentPrice = res.pricingValue[0];
        this.categoryDetails=res.categoryValue
        console.log( "Pricing details   ::: ",this.categoryDetails , res)
      },
      (error) => {
        console.error(error);
      }
    );
  }
  addPricing(){
    console.log("checking value :: ",this.categoryDetails)
    this.adminservice.getPricingAdded(this.agentPrice,this.categoryDetails).subscribe((res)=>{
      console.log(res)
    })
    this.showbtn=false
    this.toggleButton=true;
  }
  displayedColumns = ['Cab Category', 'Per Km charges', 'Extra km charges'];
}
