import { Component, EventEmitter, Input, Output, SimpleChange, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ModalPopupComponent } from './modal-popup/modal-popup.component';
import { AgentServiceService } from 'src/app/services/agent-service.service';

@Component({
  selector: 'app-agent-table',
  templateUrl: './agent-table.component.html',
  styleUrls: ['./agent-table.component.scss']
})
export class AgentTableComponent {
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [];
  agentId: string = "";

  @Input() data: any[] = [];
  @Input() columnData: any[] = [];
  @Input() flag: any = '';
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();
  @Output() removeCity: EventEmitter<any> = new EventEmitter();
  @Output() updateTable:EventEmitter<void> = new EventEmitter<void>()
  constructor(private dialog : MatDialog, private _service : AgentServiceService){}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    console.log("Data")
    if (this.columnData.length) {
      this.displayedColumns = this.columnData.map(({ field }) => field)
    }
    if (changes['data']) {
      this.dataSource.data = this.data;
       this.dataSource.paginator = this.paginator;
    }
  }

  openModel(flag: string, data: any){
    console.log("Flag: ", flag);
    console.log("Data: ", data);
      console.log(data)
      const dialog = this.dialog.open(ModalPopupComponent, {
        data: {
          flag: flag,
          content: data,
  
      }
      })
    dialog.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
     this.updateTable.emit();
    });
  }

  delinkDriver(data: any){
    console.log(data);
    this.notifyParent.emit(data);
  }

  removeCityFunc(data:any){
    console.log(data);
    this.removeCity.emit(data);
  }

  date(convertDate: any): any {
    convertDate = new Date(convertDate);
    return new Date(convertDate.getTime() + (convertDate.getTimezoneOffset() * 60000))
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}
