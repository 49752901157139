<div class="container-fluid">
  <mat-card class="profile-card mt-4">
    <div class="row">
      <div class="col-md-3 profile-image">
        <img
          class="img img-fluid"
          [src]="'../../../assets/profile.jpg'"
          alt="profile"
        />
      </div>
      <div class="col-md-5 customer">
        <div class="pb-2">
          <span class="customer-name pt-1">{{ user_data?.name }}</span>
        </div>
        <div class="d-flex customer-info">
          <span class="d-flex me-4">
            <mat-icon class="details-heading">mail</mat-icon>
            <span class="customer-detail">{{ user_data?.emailid }}</span>
          </span>
          <span class="d-flex me-4"
            ><mat-icon class="details-heading">call</mat-icon>
            <span class="customer-detail">{{ user_data?.phoneNumber }}</span>
          </span>
        </div>
      </div>
      <div class="col-md-3">
        <mat-chip-listbox>
          <mat-chip>
            <span class="mat-label">
              {{
                user_data?.referalCode ? user_data?.referalCode : "DummyCode"
              }}
              <mat-icon>content_copy</mat-icon>
            </span>
          </mat-chip>
          <mat-chip>
            <span class="mat-label">
              Rewards:<mat-icon>currency_rupee</mat-icon
              ><span>{{
                user_data?.CreditPoints ? user_data?.CreditPoints : 0
              }}</span>
            </span>
          </mat-chip>
          <mat-chip>
            <span class="mat-label" (click)="edit_profile()">
              Edit Profile
            </span>
          </mat-chip>
        </mat-chip-listbox>
      </div>
      <!-- <div class="col-md-1">

    <span class="nav-link" (click)="signout()" style="display: flex; flex-direction: column; align-items: center;">
        <mat-icon>logout</mat-icon>
        Logout
    </span> -->
      <div class="col-md-1">
        <button mat-icon-button class="logout" (click)="signout()">
          <mat-icon>logout</mat-icon>
        </button>
      </div>
      <!-- </div> -->
    </div>
  </mat-card>

  <ng-container *ngIf="showLoader; else content" class="loader-container">
    <div class="row">
      <div class="loader"></div>
    </div>
  </ng-container>
  <ng-template #content>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="start" dynamicHeight>
      <mat-tab label="Upcoming" *ngIf="upcoming_rides; else noRecords">
        <app-my-trip
          [data]="upcoming_rides"
          [columnData]="customer_upcoming_column"
          (refreshTable)="fetchRides()"
          [flag]="'upcoming'"
        ></app-my-trip>
      </mat-tab>
      <mat-tab label="Completed">
        <app-my-trip
          [data]="completed_rides"
          [columnData]="customer_completed_column"
          [flag]="'Completed'"
        ></app-my-trip>
      </mat-tab>
      <mat-tab label="Cancelled">
        <app-my-trip
          [data]="cancelled_rides"
          [columnData]="customer_cancled_column"
          [flag]="'cancelled'"
        ></app-my-trip>
      </mat-tab>
      <ng-template #noRecords>
        <div style="display: flex; justify-content: center">
          <span>No Records found</span>
        </div>
      </ng-template>
    </mat-tab-group>
  </ng-template>
</div>
