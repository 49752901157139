<mat-card class="example-card">
    <mat-card-header class="justify-content-around ">
        <mat-card-title>Rating & Review</mat-card-title>
    </mat-card-header>
  </mat-card>
  <ng-container *ngIf="showLoader; else content" class="loader-container">
    <div class="row">
      <div class="loader"></div>
    </div>
  </ng-container>
  <ng-template #content>
<div class="steeper ">
    <mat-card class="m-2 mb-4">
        <mat-horizontal-stepper [selectedIndex]="currentStep" (selectionChange)="onStepChange($event)">
            <mat-step label="Driver Rating" completed="false">
                <!-- <ng-template matStepLabel>Rating</ng-template> -->
                <app-table [data]="table1Data" [columnData]="column1Data" [flag]="'DriverRating'"></app-table>
            </mat-step>
            <mat-step label="Customer Rating" completed="false">
                <app-table [data]="table2Data" [columnData]="column2Data" [flag]="'CustomerRating'"></app-table>
            </mat-step>
        </mat-horizontal-stepper>
    </mat-card>
</div>
  </ng-template>