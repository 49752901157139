<mat-card class="example-card">
  <mat-card-header class="justify-content-around ">
    <mat-card-title>My Rides</mat-card-title>
  </mat-card-header>
</mat-card>

<ng-container *ngIf="showLoader; else content" class="loader-container">
  <div class="row">
    <div class="loader"></div>
  </div>
</ng-container>
<ng-template #content>
  <div class="container-fluid pt-2">
    <!-- <div class="row">
    <div class="col-md-3">
      <mat-form-field>
        <mat-label>Filter by date</mat-label>
        <mat-select [(ngModel)]="selectedValue" (ngModelChange)="changeFilterValue(selectedValue)">
          <mat-option>2024-01-01</mat-option>
          <mat-option>2024-01-10</mat-option>
          <mat-option>2024-01-12</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div> -->

    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">electric_car</mat-icon>
          Completed Trips
        </ng-template>
        <app-driver-table [data]="driver_completed_rides" [columnData]="driver_scheduled_column"></app-driver-table>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">electric_car</mat-icon>
          Cancelled Rides
        </ng-template>
        <app-driver-table [data]="driver_Cancelled_rides" [columnData]="driver_scheduled_column"></app-driver-table>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-template>