import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { AdminService } from 'src/app/services/admin.service';
import { AgentServiceService } from 'src/app/services/agent-service.service';
import { vehicle } from 'src/app/vehicle/vehicle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AwsUploadService } from 'src/app/services/aws-upload.service';
import * as moment from 'moment';
import { CabServiceService } from 'src/app/services/cab-service.service';
import { HelperService } from 'src/app/services/helper.service';
import { MatDatepicker } from '@angular/material/datepicker';


@Component({
  selector: 'app-manage-car',
  templateUrl: './manage-car.component.html',
  styleUrls: ['./manage-car.component.scss']
})
export class ManageCarComponent {
  vehicleForm: vehicle = new vehicle();
  vehicleDetails: any[] = [];
  tableData: any[] = [];
  vehicleFilterData: any[] = [];
  agentId: string = "";
  showLoader = true;
  cabFuelTypeList = ["Petrol", "Diesel", "CNG", "Electric"];
  minDate: Date = new Date();
  // phoneNumber: string = "";
  otpSent: boolean = false;

  isOTPVerified: boolean = false;
  otp: string = '';
  cabNumber: string = '';
  cabId: string = '';
  cabOwnerPhoneNumber: string = '';

  @ViewChild(MatStepper) stepper!: MatStepper;
  orderId: any;
  constructor(public _service: AgentServiceService, private helperService: HelperService, private cabService: CabServiceService, private as: AdminService, private adminservice: AdminService, private snackBar: MatSnackBar, private router: Router, public docUpload: AwsUploadService) {
    this.checkLoginStatus();
  }

  toggleOtpSent() {
    let cleanCabNumber = this.cabNumber; //this.helperService.cleanString(this.cabNumber);
    this.cabService.fetchCabWithCabNumber(cleanCabNumber).subscribe(
      (res: any) => {
        this.cabOwnerPhoneNumber = res.cabDetails.CarOwnerPhoneNumber;
        this.cabId = res.cabDetails._id;
        this.SendOTP();
      },
      (error) => {
        console.log(error);
        this.snackBar.open(error.error.message, 'Close', { duration: 3000 })
      }
    )
  }

  checkLoginStatus() {
    // localStorage.setItem("admin_auth", res.phoneNumber);
    // localStorage.setItem("admin_authExpiration", expirationDate.toString());  
    const auth = localStorage.getItem("agent_auth");
    const time = localStorage.getItem("agent_authExpiration");
    if (time && auth) {
      const date = new Date(time);
      if (date <= new Date()) {
        localStorage.removeItem('agent_auth');
        localStorage.removeItem('agent_authExpiration');
      }
      else {
        this.agentId = auth;
      }
    }
    else {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.fetchVehicle()
  }

  dateFilter(date: Date | null): boolean {
    const currentDate = moment().startOf('day');
    return date !== null && moment(date).isSameOrAfter(currentDate);
  }

  fetchVehicle() {
    this._service.getAllCarsLinked(this.agentId).subscribe((res) => {
      res = res.data?.filter((element: any) => element != null);
      if (res && Array.isArray(res) && res.length > 0) {
        this.vehicleDetails = res;
        this.vehicleFilterData = res;
        this.tableData = this.vehicleDetails.map(vehicle => {
          return {
            Owner_Name: vehicle.CarOwnerName,
            Email: '',
            Mobile: vehicle.CarOwnerPhoneNumber,
            limit: vehicle.userLimit,
            vehicle_Type: vehicle.CarBrand,
            Vehicle_Number: vehicle.CarNumber,
            Vehicle_Mode: vehicle.CarName,
            status: vehicle.Status,
          }

        });
      } else {
        console.log("Invalid response from the backend");
        this.tableData = [];
      }
      this.showLoader = false;
    })
  }
  columnData = [
    { field: "Owner_Name", label: "Owner Name" },
    // { field: "Email", label: "Email" },
    { field: "Mobile", label: "Mobile" },
    { field: "vehicle_Type", label: "vehicle Type" },
    { field: "Vehicle_Number", label: "Vehicle Number" },
    { field: "Vehicle_Mode", label: "Vehicle Model" },
    // { field: "Driver_Linked", label: "Driver Linked" },
    // { field: "Driver_Contact", label: "Driver Contact" },
    { field: "Document_List", label: "Document List" },
    { field: "status", label: "Status" },
    { field: "removeCab", label: "Action" },

  ];

  selectedValue: any = '';
  ride_filter: any[] = ['All', 'Approved', 'Unapproved']

  changeFilterValue() {
    if (this.selectedValue == 'All') {
      this.tableData = this.vehicleDetails.map(vehicle => {
        return {
          Owner_Name: vehicle.CarOwnerName,
          Email: '',
          Mobile: vehicle.CarOwnerPhoneNumber,
          limit: vehicle.userLimit,
          vehicle_Type: vehicle.CarBrand,
          Vehicle_Number: vehicle.CarNumber,
          Vehicle_Mode: vehicle.CarName,
          status: vehicle.Status,
        }
      })
    }
    else {
      this.tableData = this.vehicleDetails.filter(element => element.Status == this.selectedValue).map(vehicle => {
        return {
          Owner_Name: vehicle.CarOwnerName,
          Email: '',
          Mobile: vehicle.CarOwnerPhoneNumber,
          limit: vehicle.userLimit,
          vehicle_Type: vehicle.CarBrand,
          Vehicle_Number: vehicle.CarNumber,
          Vehicle_Mode: vehicle.CarName,
          status: vehicle.Status,
        }
      })
    }
  }

  currentStep: number = 0;
  onStepChange(event: StepperSelectionEvent): void {
    this.currentStep = event.selectedIndex;
  }


  //add vehicle
  addVehicle() {
    this.as.getVehicleRegisterdForadmin(this.vehicleForm).subscribe((res) => {
      this.vehicleForm = new vehicle();
      // this.isOTPVerified = true;
      this.otp = "";
      this.cabId = res.cabid;
      this.otpSent = false;
      this.linkCar('addedByMe')
    })
  }

  linkCar(type: string) {
    console.log("cabNumber", this.cabNumber);
    if (type == 'addedByOther') {
      this.verifyOtp().then((res: any) => {
        if (res) {
          this._service.linkCar(this.agentId, this.cabId).subscribe((res) => {
            this.snackBar.open("Cab linked successfully", 'Close', { duration: 3000 });
            this.cabNumber = '';
            this.otpSent = false;
            this.otp = '';
            this.fetchVehicle();
          }, (error) => {
            this.otpSent = false;
            this.otp = '';
          })
        }
      })
    } else if (type == 'addedByMe') {
      this._service.linkCar(this.agentId, this.cabId).subscribe((res) => {
        this.snackBar.open("Cab linked successfully", 'Close', { duration: 3000 });
        this.cabNumber = '';
        this.otpSent = false;
        this.otp = '';
        this.fetchVehicle();
      })
    }
  }

  cancleForm() {
    this.vehicleForm = new vehicle();
    this.stepper.selectedIndex = 0;
  }

  activateLoginBtn(): boolean {
    return this.otp.length === 6;
  }

  SendOTP() {
    this.otpSent = true;
    let phoneNumber = this.cabOwnerPhoneNumber ? this.cabOwnerPhoneNumber : this.vehicleForm.CarOwnerPhoneNumber;
    this.adminservice.getOTPValue(parseInt(phoneNumber)).subscribe((res: any) => {
      this.orderId = res.orderId;
      this.snackBar.open("OTP sent successfully !", 'Close', { duration: 3000 });

    }, (err) => {
      this.snackBar.open(err.error.error, 'Close', { duration: 3000 });
    })
  }
  async verifyOtp(): Promise<boolean> {
    let phoneNumber = this.cabOwnerPhoneNumber ? parseInt(this.cabOwnerPhoneNumber) : parseInt(this.vehicleForm.CarOwnerPhoneNumber)
    return this.adminservice.getOTPVerified(this.orderId, parseInt(this.otp), phoneNumber).toPromise()
      .then((res: any) => {
        this.isOTPVerified = res.isOTPVerified
        this.snackBar.open("OTP verified successfully !", 'Close', { duration: 3000 });

        return res.isOTPVerified;
      })
      .catch(error => {
        this.snackBar.open(error, 'Close', { duration: 3000 });
        return false;
      });
  }
  ResendOTP() {
    this.adminservice.getReSendOTP(this.orderId).subscribe((res: any) => {
      this.orderId = res.orderId;
      this.snackBar.open("OTP resend successfull !", 'Close', { duration: 3000 });
    }, (err) => {
      this.snackBar.open(err.error.error, 'Close', { duration: 3000 })
    })
  }
  Cancel() {
    this.vehicleForm.CarOwnerPhoneNumber = "";
    this.otpSent = false;
  }
  isMobileNumberValid(): boolean {
    const phoneNumberString: string = this.vehicleForm.CarOwnerPhoneNumber.toString(); // Convert to string
    return phoneNumberString.length === 10;
  }
  onFileSelected(event: any, source: string) {
    const file = event.target.files[0].name
    if (file) {
      this.docUpload.onFileSelected(event).then((res: any) => {
        if (this.vehicleForm.hasOwnProperty(source)) {
          this.vehicleForm[source] = res.fileUrl;
        } else {
          console.error("File URL is not available.");
        }
      }).catch((error) => {
        console.log("Error in ts: ", error);
      });
    }
  }

  isFormValid(): boolean {
    let result = (
      // Example validation condition
      this.vehicleForm.CarBrand == "" ||
      this.vehicleForm.CarName == "" ||
      this.vehicleForm.CarNumber == "" ||
      this.vehicleForm.CarOwnerAadharUpload == "" ||
      this.vehicleForm.CarOwnerName == "" ||
      this.vehicleForm.CarOwnerPanCardUpload == "" ||
      this.vehicleForm.CarPhotoWithOwnerUploaod == "" ||
      this.vehicleForm.FuelType == "" ||
      this.vehicleForm.InsuranceUpload == "" ||
      this.vehicleForm.NumberOfSeats == "" ||
      this.vehicleForm.PermitUpload == "" ||
      this.vehicleForm.registrationCertificateUpload == "" ||
      this.vehicleForm.InsuranceValidity == "" ||
      !this.isOTPVerified
    );

    return result;
  }

  // after click on Enter Insurance Validity date picker 
  openDatePicker(picker: MatDatepicker<Date>): void {
    picker.open();
  }
}
