<div class="container-fluid pt-3">
<mat-card class="table-card"> 
<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
      <!-- Ride ID Column -->
      <ng-container matColumnDef="ride_id">
        <th mat-header-cell *matHeaderCellDef> Ride ID </th>
        <td mat-cell *matCellDef="let element"> {{element.ride_id}} </td>
      </ng-container>
  
      <!-- Driver Name Column -->
      <ng-container matColumnDef="Driver_name">
        <th mat-header-cell *matHeaderCellDef> Driver Name </th>
        <td mat-cell *matCellDef="let element"> {{element.Driver_name}} </td>
      </ng-container>
  
      <!-- Driver Contact Column -->
      <ng-container matColumnDef="DriverContact">
        <th mat-header-cell *matHeaderCellDef> Driver Contact </th>
        <td mat-cell *matCellDef="let element"> {{element.DriverContact}} </td>
      </ng-container>
  
      <!-- Ride Status Column -->
      <ng-container matColumnDef="Ridestatus">
        <th mat-header-cell *matHeaderCellDef> Ride Status </th>
        <td mat-cell *matCellDef="let element"> {{element.Ridestatus}} </td>
      </ng-container>
  
      <!-- Pickup Date Column -->
      <ng-container matColumnDef="pickupDate">
        <th mat-header-cell *matHeaderCellDef> Pickup Date </th>
        <td mat-cell *matCellDef="let element"> {{element.pickupDate}} </td>
      </ng-container>
  
      <!-- Drop Date Column -->
      <ng-container matColumnDef="dropDate">
        <th mat-header-cell *matHeaderCellDef> Drop Date </th>
        <td mat-cell *matCellDef="let element"> {{element.dropDate}} </td>
      </ng-container>
  
      <!-- Vehicle Number Column -->
      <ng-container matColumnDef="vehicleNO">
        <th mat-header-cell *matHeaderCellDef> Vehicle Number </th>
        <td mat-cell *matCellDef="let element"> {{element.vehicleNO}} </td>
      </ng-container>
  
      <!-- Vehicle Type Column -->
      <ng-container matColumnDef="VehicleType">
        <th mat-header-cell *matHeaderCellDef> Vehicle Type </th>
        <td mat-cell *matCellDef="let element"> {{element.VehicleType}} </td>
      </ng-container>
  
      <!-- Pickup/Drop Address Column -->
      <ng-container matColumnDef="PickupDropAddress">
        <th mat-header-cell *matHeaderCellDef> Pickup/Drop Address </th>
        <td mat-cell *matCellDef="let element"> {{element.PickupDropAddress}} </td>
      </ng-container>
  
      <!-- Fare Column -->
      <ng-container matColumnDef="Fare">
        <th mat-header-cell *matHeaderCellDef> Fare </th>
        <td mat-cell *matCellDef="let element"> {{element.Fare}} </td>
      </ng-container>
  
      <!-- Ride Status (upcoming, ongoing, completed) Column -->
      <ng-container matColumnDef="RideStatus">
        <th mat-header-cell *matHeaderCellDef> Ride Status </th>
        <td mat-cell *matCellDef="let element"> {{element.RideStatus}} </td>
      </ng-container>
  
      <!-- Invoice Receipt Column -->
      <ng-container matColumnDef="InvoiceReceipt">
        <th mat-header-cell *matHeaderCellDef> Invoice Receipt </th>
        <td mat-cell *matCellDef="let element"> {{element.InvoiceReceipt}} </td>
      </ng-container>
  
      <!-- Payment Mode Column -->
      <ng-container matColumnDef="PaymentMode">
        <th mat-header-cell *matHeaderCellDef> Payment Mode </th>
        <td mat-cell *matCellDef="let element"> {{element.PaymentMode}} </td>
      </ng-container>
  
      <!-- Advance Payment Amount Column -->
      <ng-container matColumnDef="AdvancePaymentAmount">
        <th mat-header-cell *matHeaderCellDef> Advance Payment Amount </th>
        <td mat-cell *matCellDef="let element"> {{element.AdvancePaymentAmount}} </td>
      </ng-container>
  
      <!-- Add more columns as needed -->
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator> -->
  </div>
</mat-card>
</div>