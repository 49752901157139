<mat-card class="example-card">
    <mat-card-header class="justify-content-around ">
        <mat-card-title>Book For Customer</mat-card-title>
    </mat-card-header>
</mat-card>

<div class="form-container">
    <mat-card class="mat_card">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
            <mat-tab *ngFor="let trip of trip_type" [label]=trip>
                <div *ngIf="trip == 'One Way'" class="d-flex flex-wrap p-3 trip-form">
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field>
                            <mat-label>From</mat-label>
                            <input matInput placeholder="from location" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field>
                            <mat-label>To</mat-label>
                            <input matInput placeholder="to location" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Choose pick up date</mat-label>
                            <input matInput [matDatepicker]="picker" readonly>
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="picker">
                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field>
                            <mat-label>Choose pick up time</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let time of pickUpTime" [value]="time">{{time}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="trip == 'Round Trip'" class="d-flex flex-wrap p-3 trip-form">
                    <div class="col-md-6 col-lg-2">
                        <mat-form-field>
                            <mat-label>From</mat-label>
                            <input matInput placeholder="from location" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-2">
                        <mat-form-field>
                            <mat-label>To</mat-label>
                            <input matInput placeholder="to location" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-2">
                        <mat-form-field class="example-full-width">
                            <mat-label>Choose pick up date</mat-label>
                            <input matInput [matDatepicker]="picker1" readonly>
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="picker1">
                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-2">
                        <mat-form-field>
                            <mat-label>Choose pick up time</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let time of pickUpTime" [value]="time">{{time}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-2">
                        <mat-form-field class="example-full-width">
                            <mat-label>Choose return date</mat-label>
                            <input matInput [matDatepicker]="picker" readonly>
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="picker">
                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <!-- <div class="col-md-6 col-lg-3">
                        <mat-form-field>
                            <mat-label>Choose return time</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let time of pickUpTime" [value]="time">{{time}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> -->
                </div>

                <div *ngIf="trip == 'Local Trip'" class="d-flex justify-content-center flex-wrap p-3 ">
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field>
                            <mat-label>City</mat-label>
                            <input matInput placeholder="from location" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Choose pick up date</mat-label>
                            <input matInput [matDatepicker]="picker" readonly>
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="picker">
                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field>
                            <mat-label>Choose pick up time</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let time of pickUpTime" [value]="time">{{time}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="trip == 'Airport'" class="d-flex flex-wrap p-3">
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field>
                            <mat-label>Choose Trip</mat-label>
                            <mat-select (selectionChange)="onAirportTripTypeChange($event)" [value]="airport_trip[0]">
                                <mat-option *ngFor="let trip of airport_trip" [value]="trip">{{trip}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field>
                            <mat-label *ngIf="airportType === 'PickUp From Airport'">PickUp Airport Name</mat-label>
                            <mat-label *ngIf="airportType === 'Drop To Airport'">Drop Airport Name</mat-label>
                            <input matInput placeholder="airport name" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field *ngIf="airportType === 'PickUp From Airport'">
                            <mat-label>Drop Point</mat-label>
                            <input matInput placeholder="drop address name" required>
                        </mat-form-field>
                        <mat-form-field *ngIf="airportType === 'Drop To Airport'">
                            <mat-label>PickUp Address</mat-label>
                            <input matInput placeholder="pick from" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field *ngIf="airportType === 'PickUp From Airport'">
                            <mat-label>Terminal Name</mat-label>
                            <input matInput placeholder="pickUp terminal name" required>
                        </mat-form-field>
                        <mat-form-field *ngIf="airportType === 'Drop To Airport'">
                            <mat-label>Terminal Name</mat-label>
                            <input matInput placeholder="drop terminal name" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Choose pick up date</mat-label>
                            <input matInput [matDatepicker]="picker" readonly>
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="picker">
                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field>
                            <mat-label>Choose pick up time</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let time of pickUpTime" [value]="time">{{time}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="trip == 'Bulk Booking'" class="d-flex flex-wrap p-3">
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field>
                            <mat-label>Name</mat-label>
                            <input matInput placeholder="enter name" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field>
                            <mat-label>Email ID</mat-label>
                            <input matInput placeholder="email address" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field>
                            <mat-label>Mobile No.</mat-label>
                            <input matInput placeholder="enter mobile no." required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="d-grid justify-content-center">
                            <span class="grid-item">OTP has been sent to ****6151</span>
                            <div class="input-container d-flex flex-row justify-content-center">
                                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem;">
                                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem;">
                                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem;">
                                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem;">
                                <input type="text" class="m-1 form-control rounded opt_input" maxlength="1" style="width: 2.5rem;">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field>
                            <mat-label>From</mat-label>
                            <input matInput placeholder="from location" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field>
                            <mat-label>To</mat-label>
                            <input matInput placeholder="to location" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Choose pick up date</mat-label>
                            <input matInput [matDatepicker]="picker" readonly>
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="picker">
                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <mat-form-field>
                            <mat-label>Choose pick up time</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let time of pickUpTime" [value]="time">{{time}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="d-flex button mb-3">
                    <button type="button" class="btn btn-primary">Book Available Cab's</button>
                </div>

            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>