import { Component, Input } from '@angular/core';
import { AgentServiceService } from 'src/app/services/agent-service.service';
import { agentProfileDetails } from './profileDetails';
import { UpdateProfileComponent } from '../update-profile/update-profile.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AwsUploadService } from 'src/app/services/aws-upload.service';

@Component({
  selector: 'app-agent-profile',
  templateUrl: './agent-profile.component.html',
  styleUrls: ['./agent-profile.component.scss']
})
export class AgentProfileComponent {

  constructor(public agentService: AgentServiceService, private dialog: MatDialog, private router: Router,public docUpload:AwsUploadService) {
    this.checkLoginStatus();
  }
  agentProfile: agentProfileDetails = new agentProfileDetails;
  gstcertificate: string = "photo.jpg";
  toggleButton: boolean = false;
  shortLink: string = "";
  loading: boolean = false;
  file: any;
  showbtn: boolean = false;
  agentProfileData: any;
  showLoader = true;

  agentId: string = "";

  ngOnInit() {
    this.getProfile();
  }

  checkLoginStatus() {
    // localStorage.setItem("admin_auth", res.phoneNumber);
    // localStorage.setItem("admin_authExpiration", expirationDate.toString());  
    console.log("Checking login status")
    const auth = localStorage.getItem("agent_auth");
    const time = localStorage.getItem("agent_authExpiration");
    console.log("checked auth: ", auth, time);
    if (time && auth) {
      const date = new Date(time);
      console.log("time: ", time)
      if (date <= new Date()) {
        console.log("time: ", time)
        localStorage.removeItem('agent_auth');
        localStorage.removeItem('agent_authExpiration');
      }
      else {
        this.agentId = auth;
      }
    }
    else {
      this.router.navigate(['/']);
    }
  }

  getProfile() {
    console.log("Profile: ", this.agentId)
    this.agentService.agent_profile(this.agentId).subscribe((res: any) => {
      // this.agentProfileData = res;
      this.agentProfile = res;
      console.log(this.agentProfile);
      this.showLoader = false;
    })
  }

  editProfile() {
    const dialog = this.dialog.open(UpdateProfileComponent, {
      height: '400px',
      width: '600px',
      data: {
        agentProfile: this.agentProfile
      }
    });
    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.agentService.agent_profileUpdate({ agentProfile: res, agentId: this.agentId }).subscribe((res) => {
          this.agentProfile = res;
        })
      }
    })
  }

  editDocument() {
    this.toggleButton = true
    this.showbtn = true

  }
  onChange(event: any) {
    this.file = event.target.files[0];
  }

  saveBankUpdates() {
    this.agentService.agent_profileUpdate({ agentProfile: this.agentProfile, agentId: this.agentId }).subscribe((res) => {
      this.agentProfile = res;
      this.toggleButton=false
    })
    this.showbtn = false
  }

  // OnClick of button Upload 
  
  onFileSelected(event: any, source: string) {
    this.loading = !this.loading;
    const file = event.target.files[0].name
    if (file) {
      this.docUpload.onFileSelected(event).then((res: any) => {
        
        if (this.agentProfile.hasOwnProperty(source)) {
          this.agentProfile[source] = res.fileUrl;
          console.log("Driver Info: ", this.agentProfile);
        } else {
          console.error("File URL is not available.");
        }
      }).catch((error) => {
        console.log("Error in ts: ", error);
      });
    }
    
  }
}
