<mat-drawer-container *ngIf="profile" autosize>
    <mat-drawer #drawer mode="side" opened class="example-sidenav">
        <mat-icon role="button" class="ms-2 mt-3" *ngIf="drawer.opened" (click)="drawer.toggle()">menu</mat-icon>
        <div  (click)="pageview=menu.label; expandPane=false"
            [class.menu-group-highlight]="menu.label==pageview" class="d-flex align-items-center p-2 w-100"
            role="button" *ngFor="let menu of menuList">
            <mat-icon>{{menu.icon}}</mat-icon>
            <p class="mb-0 ms-2 menuLabel me-4" *ngIf="expandPane || !smallScreen">{{menu.label}}</p>
        </div>
        <div class="d-flex align-items-center p-2 w-100" (click)="logout()" role="button">
            <mat-icon>logout</mat-icon>
            <p class="mb-0 ms-2 menuLabel me-4" *ngIf="expandPane || !smallScreen">LogOut</p>
        </div>
    </mat-drawer>

    <mat-drawer-content class="p-2">
        <div class="d-flex align-items-center">
            <button type="button" *ngIf="!drawer.opened" mat-button (click)="drawer.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
        <ng-container *ngIf="pageview=='Dashboard'">
            <app-dashboard></app-dashboard>
        </ng-container>
        <ng-container *ngIf="pageview=='Customers'">
            <app-admin-customer></app-admin-customer>
        </ng-container>
        <ng-container *ngIf="pageview=='Drivers'">
            <app-driver></app-driver>
        </ng-container>
        <ng-container *ngIf="pageview=='Partner'">
            <app-agent></app-agent>
        </ng-container>
        <ng-container *ngIf="pageview=='Rides'">
            <app-rides></app-rides>
        </ng-container>
        <ng-container *ngIf="pageview=='Promo'">
            <app-promo></app-promo>
        </ng-container>
        <ng-container *ngIf="pageview=='Vehicle'">
            <app-vehicle></app-vehicle>
        </ng-container>
        <ng-container *ngIf="pageview=='Service Location'">
            <app-service-location></app-service-location>
        </ng-container>
        <ng-container *ngIf="pageview=='Earnings'">
            <app-earnings></app-earnings>
        </ng-container>
        <!-- <ng-container *ngIf="pageview=='Driver Earnings'">
            <app-driver-earning></app-driver-earning>
        </ng-container> -->
        <!-- <ng-container *ngIf="pageview=='Driver Status'">
            <app-driver-details></app-driver-details>
        </ng-container> -->
        <ng-container *ngIf="pageview=='Pricing'">
            <app-pricing></app-pricing>
        </ng-container>
        <ng-container *ngIf="pageview=='Rating'">
            <app-rating-review></app-rating-review>
        </ng-container>
        <ng-container *ngIf="pageview=='Settings'">
            <app-website-settings></app-website-settings>
        </ng-container>
        <ng-container *ngIf="pageview=='Notification'">
            <app-push-notification></app-push-notification>
        </ng-container>
    </mat-drawer-content>
</mat-drawer-container>



<!-- <div class="container-fluid">
    <mat-sidenav-container class="example-container">
        <mat-sidenav #sidenav mode="side" opened class="example-sidenav">
            <div [matTooltip]="menu.mat_tool_tip" (click)="pageview=menu.label;expandPane=false;" [class.menu-group-highlight]="menu.label==pageview" class="d-flex p-2 align-item-center w-100 mt-2" role="button" *ngFor="let menu of menuList">
                <mat-icon>{{menu.icon}}</mat-icon>
                <p *ngIf="expandPane || !smallScreen" class="mb-0 ms-2 menuLabel me-4">{{menu.label}}</p>
            </div>
        </mat-sidenav>

        <mat-sidenav-content style="padding: 1rem;">
            <h3 class="mb-2">{{pageview}}</h3>
            <ng-container class="main-container" *ngIf="pageview=='Dashboard'">
                <app-dashboard></app-dashboard>
            </ng-container>
            <ng-container class="main-container" *ngIf="pageview=='Customers'">
                <app-customer></app-customer>
            </ng-container>
            <ng-container class="main-container" *ngIf="pageview=='Drivers'">
                <app-driver></app-driver>
            </ng-container>
            <ng-container class="main-container" *ngIf="pageview=='Agent'">
                <app-agent></app-agent>
            </ng-container>
</mat-sidenav-content>

</mat-sidenav-container>
</div> -->
<!-- <div class="container-fluid ">

    <div class="navbar">
        <app-nav-bar></app-nav-bar>
    </div>

    <mat-drawer-container class="">
        <mat-drawer clsas="example-sidenav" opened="true" mode="side">
            <div *ngFor="let menu of menuList" class="menu">
                <div [routerLink]="[menu.route]" role="button" class="menu-item">
                    <mat-icon>{{menu.icon}}</mat-icon>
                    <p *ngIf="expandPane || !smallScreen" class="mb-0 ms-2 menuLabel me-4">{{menu.label}}</p> 
                    <p class="mb-0 ms-2 menuLabel me-4">{{menu.label}}</p>
                </div>
            </div>
        </mat-drawer>

        <div class="main-content">
            <router-outlet></router-outlet>
        </div>
    </mat-drawer-container>
</div> -->