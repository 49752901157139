<div class="container" style="display: flex; flex-direction: column; align-items: center;">
    <mat-card>
        <h1>{{ data.content.BookingID }}</h1>
        <mat-divider></mat-divider>
        <div class="row">

            <div class="col-md-6 bookingDetails">
                <H2>From : &nbsp;&nbsp;&nbsp;&nbsp;</H2>
                <p>{{bookingDetails.From}}</p>
            </div>
            <div class="col-md-6 bookingDetails">
                <H2>From Landmark : &nbsp;&nbsp;&nbsp;&nbsp;</H2>
                <p>{{bookingDetails.FromLandMark}}</p>
            </div>
            <div class="col-md-6 bookingDetails">
                <H2>To : &nbsp;&nbsp;&nbsp;&nbsp;</H2>
                <p>{{bookingDetails.To}}</p>
            </div>
            <div class="col-md-6 bookingDetails">
                <H2>To Landmark : &nbsp;&nbsp;&nbsp;&nbsp;</H2>
                <p>{{bookingDetails.ToLandMark}}</p>
            </div>
            <div class="col-md-6 bookingDetails">
                <H2>Distance : &nbsp;&nbsp;&nbsp;&nbsp;</H2>
                <p>{{bookingDetails.Distance}}</p>
            </div>
            <div class="col-md-6 bookingDetails">
                <H2>Fare : &nbsp;&nbsp;&nbsp;&nbsp;</H2>
                <p>{{bookingDetails.Fare}}</p>
            </div>
            <div class="col-md-6 bookingDetails">
                <H2>Pickup Date : &nbsp;&nbsp;&nbsp;&nbsp;</H2>
                <p>{{bookingDetails.PickupDateTime | date}}</p>
            </div>
            <div class="col-md-6 bookingDetails">
                <H2>Pickup Time : &nbsp;&nbsp;&nbsp;&nbsp;</H2>
                <p>{{bookingDetails.PickupTime}}</p>
            </div>

            <div class="col-md-6 bookingDetails">
                <H2 *ngIf="bookingDetails.TripType == 'ROUND TRIP'">Drop Date : &nbsp;&nbsp;&nbsp;&nbsp;</H2>
                <p *ngIf="bookingDetails.TripType == 'ROUND TRIP'">{{bookingDetails.DropDateTime | date}}</p>
            </div>
            <div class="col-md-6 bookingDetails">
                <H2>Trip Type: &nbsp;&nbsp;&nbsp;&nbsp;</H2>
                <p>{{bookingDetails.TripType}}</p>
            </div>
            <div class="col-md-6 bookingDetails">
                <H2>Cab Type: &nbsp;&nbsp;&nbsp;&nbsp;</H2>
                <p>{{bookingDetails.carType}}</p>
            </div>
            <mat-form-field class="col-md-6" *ngIf="availableDrivers.length > 0">
                <mat-label>Select a driver</mat-label>
                <mat-select [(ngModel)]="selectedDriverName" (ngModelChange)="selectDriver(selectedDriverName)">
                    <mat-option *ngFor="let driver of availableDrivers" [disabled]="driver.status != 'Approved'" [value]="driver.driver_id">
                        {{driver.driverName}} | {{driver.mobileNumber}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="col-md-6" *ngIf="availableDrivers.length == 0">
                <span class="text text-danger">No drivers found</span>
            </div>

            <mat-form-field class="col-md-6" *ngIf="availableCabs.length > 0">
                <mat-label>Select a Cab</mat-label>
                <mat-select [(ngModel)]="selectedCabNumber" (ngModelChange)="selectCab(selectedCabNumber)">
                    <mat-option *ngFor="let cab of availableCabs" [disabled]="cab.Status != 'Approved'"
                        [value]="cab._id">{{cab.CarNumber}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="col-md-6" *ngIf="availableCabs.length == 0">
                <span class="text text-danger">No Cabs found</span>
            </div>

            <div class="submit-btn-div">
                <button class="btn btn-danger cancel-btn" (click)="cancel()">Cancel</button>
                <button class="btn btn-primary submit-btn"
                    [disabled]='(selectedCabNumber == "") || (selectedDriverName == "")' (click)="acceptRide()">Accept
                    Ride</button>
            </div>
        </div>
    </mat-card>
</div>