import { Component ,OnInit} from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-website-settings',
  templateUrl: './website-settings.component.html',
  styleUrls: ['./website-settings.component.scss']
})
export class WebsiteSettingsComponent {

  constructor(private adminservice:AdminService){}
  enableEditing: boolean = false;

  siteSettings = {
    companyLogo: '',
    faviconLogo: '',
    playStoreLink: '',
    appStoreLink: '',
    contactNumber: '',
    contactEmail: '',
    facebookLink: '',
    instagramLink: '',
    twitterLink: '',
    whatsappLink: '',
    photoLink: '',
    videoLink: ''
  };

  ngOnInit(){
    this.adminservice.getWebSettingData().subscribe((res:any)=>{
      console.log("Data : ",res)
      this.siteSettings=res[0]
    })
  }

  saveSettings() {
    this.adminservice.getSiteSettingAdded(this.siteSettings).subscribe((res)=>{
      console.log(res)
    })
    console.log('Settings saved!', this.siteSettings);
    this.enableEditing = false;
  }

  editForm() {
    this.enableEditing = true;
  }
  cancel() {
    this.enableEditing = false;
  }
}
