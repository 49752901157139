<ng-container *ngIf="showLoader; else content" class="loader-container">
  <div class="row">
    <div class="loader"></div>
  </div>
</ng-container>
<ng-template #content>
  <mat-card class="example-card">
    <mat-card-header class="justify-content-around">
      <mat-card-title>My Earnings</mat-card-title>
    </mat-card-header>
  </mat-card>

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-8 mb-2">
        <mat-card class="card">
          <p style="font-size: 1rem; font-weight: 500" class="pt-2 ms-2">
            Total Earnings of partner
          </p>
          <mat-divider></mat-divider>
          <div class="row ms-2 card-content">
            <div class="col-md-8">
              <p style="font-size: 1rem; font-weight: 500">
                Total Ride Collection
              </p>
            </div>
            <div class="col-md-3">
              <p style="padding: 0.6rem">{{ data.TotalRideCollection }}</p>
            </div>
          </div>
          <div class="row ms-2 card-content">
            <div class="col-md-8">
              <p style="font-size: 1rem; font-weight: 500">
                Total Cash Received
              </p>
            </div>
            <div class="col-md-3">
              <p style="padding: 0.6rem">{{ data.TotalCashReceived }}</p>
            </div>
          </div>
          <div class="row ms-2 card-content">
            <div class="col-md-8">
              <p style="font-size: 1rem; font-weight: 500">
                Received From Company:
              </p>
            </div>
            <div class="col-md-3">
              <p style="padding: 0.6rem">{{ data.ReceivedFromCompany }}</p>
            </div>
          </div>
          <div class="row ms-2 card-content">
            <div class="col-md-8">
              <p style="font-size: 1rem; font-weight: 500">
                Company Platform Charge:
              </p>
            </div>
            <div class="col-md-3">
              <p style="padding: 0.6rem">{{ data.TotalCompanyClaimed }}</p>
            </div>
          </div>
          <div class="row ms-2 card-content">
            <div class="col-md-8">
              <p style="font-size: 1rem; font-weight: 500">
                Un-Commissiond Amount
              </p>
            </div>
            <div class="col-md-3">
              <p style="padding: 0.6rem">{{ data.UnCommissiondAmount }}</p>
            </div>
          </div>
          <div class="row ms-2 card-content">
                        <div class="col-md-8">
                            <p style="font-size: 1rem; font-weight: 500;">Advance amount with company</p>
                            </div>
                            <div class="col-md-3">
                                <p style="padding: 0.6rem;">{{data.AmountWithCompany}}</p>
                            </div>
                    </div>
                    <div class="row ms-2 card-content">
                      <div class="col-md-8">
                        <p style="font-size: 1rem; font-weight: 500;">Pending Amount With Company: </p>
                      </div>
                      <div class="col-md-3">
                        <p style="padding: 0.6rem;">{{data.PendingWithCompany}}</p>
                      </div>
                    </div>
        </mat-card>
      </div>
      <div class="col-md-4 mb-2">
        <mat-card class="card">
          <p style="font-size: 1rem; font-weight: 500" class="pt-2 ms-2">
            Ride stats of partner
          </p>
          <mat-divider />
          <div class="card-content">
            <div class="card-title text-center mb-0">
              <p style="font-size: 1rem; font-weight: 500">Total Rides</p>
              <p style="padding: 0.6rem">{{ data.TotalRides }}</p>
            </div>
          </div>
          <div class="card-content">
            <div class="card-title text-center mb-0">
              <p style="font-size: 1rem; font-weight: 500">
                Total Completed Rides
              </p>
              <p style="padding: 0.6rem">{{ data.TotalCompletedRides }}</p>
              </div>
              </div>
              <div class="card-content">
                <div class="card-title text-center mb-0">
                  <p style="font-size: 1rem; font-weight: 500">
                    Total Scheduled Rides
                  </p>
                  <p style="padding: 0.6rem">{{ data.TotalScheduledRides }}</p>
                </div>
              </div>
              <div class="card-content">
                <div class="card-title text-center mb-0">
                  <p style="font-size: 1rem; font-weight: 500">
                    Total Ongoing Rides
                  </p>
                  <p style="padding: 0.6rem">{{ data.TotalOngoingRides }}</p>
                </div>
              </div>
              <div class="card-content">
                <div class="card-title text-center mb-0">
                  <p style="font-size: 1rem; font-weight: 500">
                      Total Requested Rides
                      </p>
                    <p style="padding: 0.6rem">{{ data.TotalRequestedRides }}</p>
                    </div>
                    </div>
                    <div class="card-content">
                      <div class="card-title text-center mb-0">
                        <p style="font-size: 1rem; font-weight: 500">
                      Total Cancelled Rides
              </p>
              <p style="padding: 0.6rem">{{ data.TotalCancelledRides }}</p>
            </div>
          </div>
        </mat-card>
        <div class="ViewTransactionDiv">
          <button (click)="ShowTransactionDetails()" class="btn btn-primary">
            View Transaction Details ->
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid pt-4">
    <div class="row">
      <mat-card class="card">
        <div class="referral">
          <div class="col referral-value">
            <span>Total Credit</span>
            <span>1362</span>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div class="col referral-value">
            <span>Reward Credit</span>
            <span>1362</span>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div class="col referral-value">
            <span>Referral Credit</span>
            <span>1362</span>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div class="col referral-value">
            <span>Penalties</span>
            <span>1362</span>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</ng-template>
