import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { AdminService } from 'src/app/services/admin.service';
@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.scss']
})
export class PromoComponent {
  promoList:any[]=[];
  tableData:any[]=[]
  promoDetails: PromoCode = new PromoCode();
  editForm:boolean=false;
  showLoader: boolean = true;

  constructor(private router: Router, private route: ActivatedRoute,public adminService:AdminService) {
  }

  @ViewChild(MatStepper) stepper!: MatStepper;

  ngOnInit(){
    this.fetchPromoDetails();
  }

  columnData = [
    { field: "promo_code", label: "Promo Code" },
    { field: "type", label: "Type" },
    { field: "value", label: "Value" },
    { field: "limit", label: "Usage Limit" },
    { field: "total_user", label: "Total User Used" },
    { field: "expired_date", label: "Expired Date" },
    { field: "status", label: "Status" },
    { field: "action", label: "Action" },
  ];

  fetchPromoDetails () {
    this.adminService.getAllPromoForDashboard().subscribe((res)=>{
      if (Array.isArray(res) && res.length > 0) {
        this.promoList = res;
        this.tableData =this.promoList.map(promo=>{
        return  {
          promo_code: promo.codeName,
          type: promo.discountType,
          value: promo.discount_Amount,
          limit: promo.userLimit,
          total_use: '',
          expired_date: promo.expiry_date,
            document_list:" ",
            status:promo.status,
            action:''
          }
        });
        } else {
        console.log("Invalid response from the backend");
      }
      this.showLoader  = false; 

    })
  }

  savePromoDetails(){
    this.adminService.getpromoCreated(this.promoDetails).subscribe((res)=>{
      this.promoDetails = new PromoCode();
      this.fetchPromoDetails();
    },
    (err)=>{
      console.log("Some error occured")
    })
  }
  currentStep: number = 0;
  onStepChange(event: StepperSelectionEvent): void {
    this.currentStep = event.selectedIndex;
  }

  cancleForm() {
    this.stepper.selectedIndex = 0;
  }
  areDetailsFilled(){
    return this.promoDetails.codeName && this.promoDetails.discountType && this.promoDetails.userLimit && this.promoDetails.expiry_date && this.promoDetails.discount_Amount;

  }
  onEditClicked(data: any) {
    console.log("edit data came : ",data)
    this.promoDetails = { 
      ...data,
      codeName: data.promo_code,
      discount_Amount: data.value,
      discountType: data.type,
      userLimit: data.limit,  // Assuming limit corresponds to userLimit
      expiry_date:new Date(data.expired_date)
    };
    console.log("edited : ", this.promoDetails)
    this.stepper.next();
    this.editForm=true;
  }
  editPromoDetails(){
    console.log(this.promoDetails);
  this.adminService.getpromoEdited(this.promoDetails).subscribe((res)=>{
    console.log("Edited successfully")
  }
  ,
    error => {
      console.error("Error deleting promo:", error);
    })
  }
}


class PromoCode {
  codeName: string = "";
  discountType: string = "";
  userLimit: number = 0;
  expiry_date: string = "";
  discount_Amount: number = 0;
}
