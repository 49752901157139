import { ChangeDetectorRef, Component, HostListener, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DriverService } from '../services/driver.service';

@Component({
  selector: 'app-travvol-drviver',
  templateUrl: './travvol-drviver.component.html',
  styleUrls: ['./travvol-drviver.component.scss']
})
export class TravvolDrviverComponent {
  driverData: any;
  expandPane = false;
  smallScreen = false;
  constructor(private cdr: ChangeDetectorRef, private _activeRoute: ActivatedRoute, private router: Router, private driverService: DriverService) {
    const auth = localStorage.getItem("driver_auth");
    if (auth) {
      this.driverService.fetchData(auth).subscribe((res) => {
        if (res.result) {
          this.driverData = res.result;
        }
        this.fetchDriverData();
      })
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.smallScreen = window.innerWidth < 769 ? true : false;
  }
  ngOnInit() {
    this.onResize(event!);
  }

  pageview: string = "Home";
  menuList: any[] = [
    { icon: 'home', label: 'Home', mat_tool_tip: 'Home', route: 'driverHome' },
    { icon: 'no_crash', label: 'Driver Ride', mat_tool_tip: 'Driver Ride', route: 'driverRide' },
    { icon: 'account_circle', label: 'Profile', mat_tool_tip: 'Driver Profile', route: 'driverProfile' },
    // { icon: 'monetization_on', label: 'Driver Earnings', mat_tool_tip: 'Driver Earnings', route: 'driverEarnings' },
  ]

  driver(event: any) {
    this.driverData = event;
  }

  driver_scheduled_rides: any[] = [];
  driver_scheduled_column = [
    { field: "from", label: "From" },
    { field: "to", label: "To" },
    { field: "distance", label: "Distance" },
    { field: "fare", label: "Fare" },
    { field: "car_model", label: "Car Model" },
    { field: "car_no", label: "Car Number" },
    { field: "date", label: "Date" },
    { field: "time", label: "Time" },
    { field: 'start_ride', label: "Start Ride" }
  ]

  driver_ongoing_rides: any[] = [];
  driver_completed_rides: any[] = [];
  driver_Cancelled_rides: any[] = [];

  fetchDriverData() {
    this.driverService.driverScheduleRides(this.driverData['driver_id']).subscribe((res) => {
      if (res.result) {
        res.result.forEach((item: any) => {
          if (item['status'] == 'Ongoing') {
            this.driver_ongoing_rides.push(item);
          }
          if (item['status'] == 'Scheduled') {
            this.driver_scheduled_rides.push(item);
          }
          if (item['status'] == 'Cancelled' || item['status'] == 'Refunded') {
            this.driver_Cancelled_rides.push(item);
          }
          if (item['status'] == 'Completed') {
            this.driver_completed_rides.push(item);
          }
        })
      }
      this.cdr.detectChanges();
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      console.log('Pageview changed to:', changes['pageview'].currentValue);
    }
  }
}