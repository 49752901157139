<mat-card class="collectPaymentCard">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
    <mat-card class="bookingDetails">
        <div *ngIf="bookingDetails">
            <div class="row booking-header">
                Booking ID: {{bookingDetails.booking_id}} - {{bookingDetails.status}}
            </div>
            <div class="row">
                <div class="col-md-4 customer-details">

                    <div class="data-element">
                        <span>Customer Name:</span><span> {{bookingDetailsFromTable.customer_name}}</span>
                    </div>
                    <div class="data-element"> <span>Customer Contact:
                        </span><span>{{bookingDetailsFromTable.customer_contact}}</span>
                    </div>
                    <div class="data-element"> <span>Ride Type: </span><span>{{bookingDetails.tripType}}</span></div>
                    <div class="data-element"> <span>Allocated Cab:
                        </span><span>{{bookingDetails.car_id}}</span></div>
                    <div class="data-element"> <span>Allocated Driver:
                        </span><span>{{bookingDetails.driver_id}}</span></div>

                </div>
                <div class="col-md-4 customer-details">
                    <div class="data-element"> <span>Pickup City:
                        </span><span>{{bookingDetails.booking_from.city}}</span>
                    </div>
                    <div class="data-element"> <span>Pickup Address
                            :</span><span>{{bookingDetails.booking_from.landmark}}</span></div>
                    <div class="data-element"> <span>Pickup Date Time:
                        </span><span>{{bookingDetails.pickup_date | date}} {{bookingDetails.pickup_time}}</span>
                    </div>
                    <div class="data-element"> <span>Drop City: </span><span>{{bookingDetails.booking_to.city}}</span>
                    </div>
                    <div class="data-element"> <span>Drop Address:
                        </span><span>{{bookingDetails.booking_to.landmark}}</span>
                    </div>
                </div>
                <div class="col-md-4 customer-details">
                    <div class="data-element"> <span>Total Distance: </span><span>{{bookingDetails.kmsOfRide}} KM</span>
                    </div>
                    <div class="data-element" *ngIf="bookingDetails.status != 'Cancelled'">
                        <span>Total Price: </span>
                        <span>{{bookingDetails.totalAmount | currency:'INR'}}</span>
                    </div>
                    <!-- <div class="data-element"> <span>10% of Total Price: </span><span>{{advanceAmountForBooking |
                            currency:'INR'}}</span>
                    </div> -->
                    <div class="data-element"> <span>Agent Accepted Ride:
                        </span><span>{{bookingDetails.assignedBy}}</span>
                        </div>
                        <div class="data-element" *ngIf="bookingDetails.status == 'Cancelled'">
                            <span>Cancelled By (Ph No.):
                            </span><span>{{bookingDetails.cancelledBy}}</span>
                        </div>
                        <div class="data-element" *ngIf="bookingDetails.status == 'Cancelled'">
                            <span>Cancellation Message:
                            </span><span>{{bookingDetails.cancellationMessage}}</span>
                        </div>
                        <div class="data-element" *ngIf="bookingDetails.status == 'Cancelled'">
                            <span>Cancellation Date:
                            </span><span>{{bookingDetails.cancelledAt | date:'medium'}}</span>
                        </div>

                </div>
            </div>
        </div>
        <div class="p-2"> 
            <table class="table">
                <thead>
                  <tr>
                    <th scope="col">TransactionId</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Mode</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="bookingDetails?.advancePayment" style="background-color: rgb(174, 243, 174);">
                    <td>{{bookingDetails?.advancePayment?.transactionID}}</td>
                    <td>{{bookingDetails?.advancePayment?.amount}}</td>
                    <td>{{bookingDetails?.advancePayment?.modeOfPayment}}</td>
                  </tr>
                  <tr *ngIf="bookingDetails?.refundPayment" style="background-color: rgb(239, 153, 153);">
                    <td>{{bookingDetails?.refundPayment?.transactionID}}</td>
                    <td>{{bookingDetails?.refundPayment?.amount}}</td>
                    <td>{{bookingDetails?.refundPayment?.modeOfPayment}}</td>
                  </tr>
                  <tr *ngIf="bookingDetails?.remainingAmount" style="background-color: rgb(174, 243, 174);">
                    <td>{{bookingDetails?.remainingAmount?.transactionID}}</td>
                    <td>{{bookingDetails?.remainingAmount?.amount}}</td>
                    <td>{{bookingDetails?.remainingAmount?.modeOfPayment}}</td>
                  </tr>
                </tbody>
            </table>
           
        </div>
    </mat-card>


    <mat-card *ngIf="bookingDetails?.status == 'Scheduled'">

        <button class="btn btn-primary" *ngIf="!cancelRequested" (click)="cancelRequested = true">Cancel Ride</button>
        <div class="cancellationRequest" *ngIf="cancelRequested">
            <!-- <input matInput [(ngModel)]="cancelRequestMessage" > -->
            <!-- <mat-form-field appearance="outline">
                                        
                <input matInput placeholder="Cancellation Message">
                </mat-form-field> -->
            <mat-form-field class="col-sm-9 input-field">
                <mat-label>Cancellation Message</mat-label>
                <input matInput placeholder="Cancellation Message" [(ngModel)]="cancelRequestMessage">
                <mat-hint>Minimum 10 characters</mat-hint>
            </mat-form-field>
            <div class="col-sm-2">
                <button class="btn btn-primary" [disabled]="cancelRequestMessage.length < 10" (click)="cancelRide()">Confirm
                    Cancellation</button>
            </div>
        </div>
    </mat-card>


    <mat-card *ngIf="bookingDetails?.status == 'Cancelled'">

        <button class="btn btn-primary" *ngIf="!refundRequested" (click)="refundRequested = true">Initiate Refund</button>
        <div class="refundRequested" *ngIf="refundRequested && bookingDetails?.advancePayment">
            <div class="payment-section row">
                <mat-form-field class="col-md-4">
                    <mat-label>Transaction ID</mat-label>
                    <input type="text" matInput placeholder="TransactionID" required [(ngModel)]="transactionID">
                </mat-form-field>
                <mat-form-field class=" col-md-4">
                    <mat-label>Amount</mat-label>
                    <input [min]="0" matInput placeholder="Amount" required [(ngModel)]="amount">
                </mat-form-field>
                <mat-form-field class="col-md-4">
                    <mat-label>Mode</mat-label>
                    <mat-select [(ngModel)]="modeOfTransaction">
                        <mat-option value="Cash">Cash</mat-option>
                        <mat-option value="UPI">UPI</mat-option>
                        <mat-option value="BankTransfer">Bank Transfer</mat-option>
                        <mat-option value="Whatsapp">Whatsapp</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
    
            <div class="row">
                <button class="btn btn-primary" (click)="addTransaction()"
                    [disabled]="amount == 0 || transactionID == ''">Complete Refund</button>
            </div>
        </div>
        <div class="refundRequested" *ngIf="refundRequested && !bookingDetails?.advancePayment">
            <div style="display: flex; justify-content: center; align-items: center; padding: 2rem;">
            <span class="text text-danger">No advance collected</span>
        </div>
        </div>
    </mat-card>

</mat-card>