import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-driver-rides',
  templateUrl: './driver-rides.component.html',
  styleUrls: ['./driver-rides.component.scss']
})
export class DriverRidesComponent {

  showLoader = true;
  ngOnInit() {
    setTimeout(() => {
      this.showLoader = false;
    }, 2000)
  }

  @Input() driver_completed_rides: any;
  @Input() driver_Cancelled_rides: any;
  @Input() driver_scheduled_column: any;

  selectedValue: string = ""
  column1Data = [
    { field: "DateTime", label: "DATE/TIME" },
    { field: "From", label: "From" },
    { field: "To", label: "TO" },
    { field: "Distance", label: "Distance" },
    { field: "ReceiptAmountExtraCharges", label: "Receipt" },
    { field: "Car", label: "Car" },
    { field: "Driver", label: "Driver" }
  ];


  table1Data = [
    { DateTime: "2023-12-20 08:30 AM", From: "Home", To: "Work", Distance: "10 miles", ReceiptAmountExtraCharges: "$15.00", Car: "Toyota Prius", Driver: "John Doe" },
    { DateTime: "2023-12-21 12:45 PM", From: "Airport", To: "Hotel", Distance: "25 miles", ReceiptAmountExtraCharges: "$30.50", Car: "Honda Accord", Driver: "Jane Johnson" },
    { DateTime: "2023-12-22 05:15 PM", From: "Train Station", To: "Conference Center", Distance: "5 miles", ReceiptAmountExtraCharges: "$8.75", Car: "Ford Fusion", Driver: "David Davis" }
    // Add more dummy data as needed
  ];
  column2Data = [
    { field: "DateTime", label: "DATE/TIME" },
    { field: "From", label: "From" },
    { field: "To", label: "TO" },
    { field: "Distance", label: "Distance" },
    { field: "Penalities", label: "Penalities" },
    { field: "Cancellation_Charge", label: "Cancellation Charge" },
    { field: "Car", label: "Car" },
    { field: "Driver", label: "Driver" }
  ];


  table2Data = [
    { DateTime: "2023-12-25 10:15 AM", From: "Work", To: "Home", Distance: "12 miles", Penalities: "$5.00", Cancellation_Charge: "$10.00", Car: "Chevrolet Malibu", Driver: "Alice Adams" },
    { DateTime: "2023-12-26 02:30 PM", From: "Hotel", To: "Airport", Distance: "20 miles", Penalities: "$8.50", Cancellation_Charge: "$15.00", Car: "Ford Explorer", Driver: "Bob Brown" },
    { DateTime: "2023-12-27 06:45 PM", From: "Conference Center", To: "Home", Distance: "8 miles", Penalities: "$3.00", Cancellation_Charge: "$7.50", Car: "Toyota Camry", Driver: "Charlie Clark" }
    // Add more dummy data as needed
  ];
  changeFilterValue(filter: any) {
    // console.log(filter);
  }
}
