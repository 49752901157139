import { Component, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DriverListComponent } from './driver-list/driver-list.component';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss']
})
export class AgentComponent {
agentTableData:any[]=[];
tableData:any[]=[];
agentFilterData:any[]=[];
showLoader: boolean = true;

ngOnInit(){
  this.fetchAllAgent()
}

 @Output() popUpData:any[]=[
    {CertiName:"Registartion Certificate", url:"certificate url"},
    {CertiName:"Insurance", url:"certificate url"},
    {CertiName:"Permit", url:"certificate url"},
    {CertiName:"Car with photo owner", url:"certificate url"},
    {CertiName:"Owner Pancard", url:"certificate url"},
    {CertiName:"Owner Aadharcard", url:"certificate url"}
  ];
  constructor(private route: ActivatedRoute,private dialog: MatDialog,public adminservice:AdminService) { }
  @ViewChild(MatStepper) stepper!: MatStepper;
 columnData = [
    { field: "name", label: "Agent Name" },
    { field: "email_id", label: "Email ID" },
    { field: "contact", label: "Contact Number" },
    { field: "driver_list", label: "Driver List" },
    { field: "cab_list", label: "Cab List" },
    { field: "work_location_count", label: "Work Location" },
    { field: "status", label: "Status" },
    { field: "ride_history", label: "Ride History" },
    // { field: "statement", label: "Statement" },
    { field: "document_list", label: "Document List" }
  ];

  currentStep: number = 0;
  onStepChange(event: StepperSelectionEvent): void {
    this.currentStep = event.selectedIndex;
  }
  cancleForm() {
    this.stepper.selectedIndex = 0;
  }
  onFileSelected(event: any) {
    const file = event.target.files[0].name
    if (file) {
      console.log(file);
    }
  }

  fetchAllAgent(){
    this.adminservice.getAllAgentForDashboard().subscribe((res=>{
      if (Array.isArray(res) && res.length > 0) {
        this.agentTableData = res;
        this.agentFilterData = res;
        console.log("AgentDetails: ",res)
        this.tableData = this.agentTableData.map(agent => {
          return {
            agentId: agent.agentId,
            name: agent.name || agent.agencyName,
            email_id: agent.emailid,
            contact: agent.phoneNumber,
            work_location_count: agent.workLocation.length,
            work_location: agent.workLocation,
            preferredLocation: agent.preferredLocation,
            status: agent.status,
            ride_history: 'Ride History',
            document_list: '',
            statement: ''
          };
        });
  
        console.log("Customer response for Dashboard: ", this.agentTableData);
      } else {
        console.log("Invalid response from the backend");
      } 
      this.showLoader  = false; 
  
     }))
  }

  selectedValue: any = '';
  ride_filter: any[] = ['All','Approved', 'Un-Approved', 'Rejected']
  changeFilterValue() {
    if(this.selectedValue=='All'){
      this.tableData = this.agentTableData.map(agent => {
        console.log("WorkLocation count: ",agent.workLocation.length )
        return {
          name: agent.name || agent.agencyName,
          email_id: agent.emailid,
          contact: agent.phoneNumber,
          work_location_count: agent.workLocation.length,
          work_location: agent.workLocation,
          preferredLocation: agent.preferredLocation,
          status: agent.status,
          ride_history: 'Ride History',
          document_list: '',
          statement: ''
        };
      });
    }
    else{
  this.tableData = this.agentFilterData.filter(element=>element.status==this.selectedValue).map(agent => {
    return {
      name: agent.name || agent.agencyName,
      email_id: agent.emailid,
      contact: agent.phoneNumber,
      work_location_count: agent.workLocation.length,
      work_location: agent.workLocation,
      preferredLocation: agent.preferredLocation,
      status: agent.status,
      ride_history: 'Ride History',
      document_list: '',
      statement: ''
    };
  })
    console.log(this.tableData);
  }
}

}
