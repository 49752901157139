
export class agentProfileDetails{
    phoneNumber:string="";
    emailid:string="";
    name:string="";
    ProfilePic: string = "";
    ReferralCode: string = "";
    GSTNumber:string="";
    PanNumber:string="";
    AadharNumber:string="";
    AccountNo: Number = 0;
    phonepeNumber: Number = 0;
    gpayNumber: Number = 0;
    upiId: string = "";
    paytmNumber: Number = 0;
    bankName: string = '';
    IFSCCode: string = '';
    GSTUpload: string = "";
    agentId: string = "";
    type: string = "";
    statusagencyName: string = "";
    agencyAddress: string = "";
    agencyName: string = "";
    status: string = "";
    AreYouADriver: string = "";
    AadharUpload: string = "";
    PanUpload: string = "";
    PanStatus: string = "";
    GSTStatus: string = "";
    AadharStatus: string = "";
    [key: string]: string | Number;
    // Account: accountDetail = new accountDetail();
    
}