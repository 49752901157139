
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HelperService {

    constructor() { }

    cleanString(input: string): string {
        return input.replace(/[^a-zA-Z0-9]/g, '');
    }

}