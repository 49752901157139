<mat-card class="container-fluid" style="margin-top: 1rem;box-shadow:  0px 0px 4px 0px #6e6e07 ;margin-bottom: 2rem;">
    <div class="mat-elevation-z8 table-responsive">
        <table mat-table [dataSource]="dataSource">
            <ng-container *ngFor="let column of columnData;let i=index" [matColumnDef]="column.field">
                <th mat-header-cell *matHeaderCellDef>{{column.label}}</th>
                <td mat-cell *matCellDef="let element">

                    <div *ngIf="(flag==='Ongoing' || flag==='scheduled' || flag==='Cancelled'|| flag==='completed'|| flag==='custUpcoming'|| flag==='AvailableRides' ||
                                flag==='ManageWorkLocation'|| flag==='custcompleted'|| flag==='custCancelled' || flag==='ManageDriver') &&  column.field !=='DateTime' && column.field !=='PickupDateTime'"> 
                        {{element[column.field]}}
                    </div>

                    <div *ngIf="(flag === 'AvailableRides' || flag ==='Ongoing' || flag ==='scheduled' || flag==='completed' || flag==='Cancelled') && (column.field=='PickupDateTime' || column.field=='DateTime')">
                        <span>
                            {{element[column.field] | date}}
                        </span>
                    </div>
                    <div *ngIf="flag==='custUpcoming' && column.field==='action'">
                        <button mat-icon-button style="color: red; ">
                            <mat-icon>cancel</mat-icon>
                        </button>
                        <button mat-icon-button style="color: #6e6e07; ">
                            <mat-icon>share</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="flag==='ManageDriver' && column.field==='Action'">
                        <button class="remove-button" (click)="delinkDriver(element)">
                            Remove
                        </button>
                    </div>
                    <div *ngIf="flag==='AvailableRides' && column.field==='Action'">
                        <button class="remove-button" (click)="openModel('AvailableRides', element)">
                            Take Action
                        </button>
                    </div>
                    <div *ngIf="flag==='ManageWorkLocation' && column.field==='Action'">
                        <button class="remove-button" (click)="removeCityFunc(element)">
                            Remove
                        </button>
                    </div>
                </td>

            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns "></tr>
            <tr mat-row *matRowDef="let row;columns:displayedColumns; "></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5,10,20] " showFirstLastButtons
            aria-label="Select page of periodic elements "></mat-paginator>
    </div>
</mat-card>