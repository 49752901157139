
  <mat-drawer-container class="example-container h-100" autosize>
    <mat-drawer #drawer mode="side" opened class="example-sidenav">
        <mat-icon role="button" class="ms-2 mt-3" *ngIf="drawer.opened" (click)="drawer.toggle()">menu</mat-icon>
        <div (click)="pageview=menu.label; expandPane=false"
            [class.menu-group-highlight]="menu.label==pageview" class="d-flex align-items-center p-2 w-100"
            role="button" *ngFor="let menu of menuList">
            <mat-icon>{{menu.icon}}</mat-icon>
            <p class="mb-0 ms-2 menuLabel me-4" *ngIf="expandPane || !smallScreen">{{menu.label}}</p>
        </div>
        <div class="d-flex align-items-center p-2 w-100" (click)="logout()" role="button">
            <mat-icon>logout</mat-icon>
            <p class="mb-0 ms-2 menuLabel me-4" *ngIf="expandPane || !smallScreen">LogOut</p>
        </div>
    </mat-drawer>

    <div class="h-100 overflow-auto">
        <div class="d-flex align-items-center">
            <button type="button" *ngIf="!drawer.opened" mat-button (click)="drawer.toggle()">
            <mat-icon>menu</mat-icon>
            </button>
        </div>
        <ng-container *ngIf="pageview=='Home'">
            <app-agent-home></app-agent-home>
        </ng-container>
        <ng-container *ngIf="pageview=='Book for Customer'">
            <app-book-for-customer></app-book-for-customer>
        </ng-container>
        <ng-container *ngIf="pageview=='Profile'">
            <app-agent-profile [agentId]='agentId'></app-agent-profile>
        </ng-container>
        <ng-container *ngIf="pageview=='My Bookings'">
            <app-my-bookings></app-my-bookings>
        </ng-container>
        <ng-container *ngIf="pageview=='Manage Driver'">
            <app-manage-driver></app-manage-driver>
        </ng-container>
        <ng-container *ngIf="pageview=='Manage Work Loction'">
            <app-manage-worklocation></app-manage-worklocation>
        </ng-container>
        <ng-container *ngIf="pageview=='Manage Car'">
            <app-manage-car></app-manage-car>
        </ng-container>
        <ng-container *ngIf="pageview=='My Earnings'">
            <app-my-earning></app-my-earning>
        </ng-container>
    </div>
</mat-drawer-container>
