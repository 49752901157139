import { Component, EventEmitter, Input, Output, SimpleChange, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CancelRidePopupComponent } from '../customer-profile/cancel-ride-popup/cancel-ride-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-my-trip',
  templateUrl: './my-trip.component.html',
  styleUrls: ['./my-trip.component.scss']
})
export class MyTripComponent {
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [];
  @Input() data: any[] = [];
  @Input() columnData: any[] = [];
  @Input() flag: any = '';
  @Output() refreshTable: EventEmitter<void> = new EventEmitter<void>()

  ngOnInit(){
    // console.log(this.data)
    // console.log(this.columnData)
  }

  constructor(public dialog: MatDialog, private adminService: AdminService, private snackbar: MatSnackBar) { }
  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (this.columnData.length) {
      this.displayedColumns = this.columnData.map(({ field }) => field)
    }
    if (changes['data']) {
      this.dataSource.data = this.data;
      this.dataSource.paginator = this.paginator;
    }
  }
  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }


  customerCancelRide(item: any) {
    const dialog = this.dialog.open(CancelRidePopupComponent, {
      width: "50%",
      height: "50%"
    });
    dialog.afterClosed().subscribe(result => {
      if (result) {
        // alert("CancelRide: " + result)
        this.adminService.cancelRide(item.booking_id, result, 'Customer').subscribe((res: any) => {
          this.snackbar.open("Ride cancelled successfully", "Close", { duration: 3000 });
          this.refreshTable.emit();
        })

      }
    })
  }
}
