<div class="container">
    <h2 mat-dialog-title style="text-align: center;" class="dialog-title">Document List</h2>

    <div class="listOfDocument">
        <div *ngFor="let document of documentList">
            <mat-card class="m-2 p-4 documentStrip">

                <div class="initials">
                    <mat-icon *ngIf="document.status == 'Approved'" class="icon icon-approved" style="color: green;">
                        task_alt</mat-icon>
                    <mat-icon *ngIf="document.status == 'Unapproved'" class="icon icon-unapproved">
                        hourglass_bottom</mat-icon>
                    <mat-icon *ngIf="document.status == 'Rejected'" class="icon icon-rejected" style="color: red;">
                        not_interested</mat-icon>

                    <h2>{{document.documentName}}</h2>
                </div>
                <div class="action" *ngIf="document.documentUrl">
                    <!-- <a class="m-2 text text-primary" [href]="document.documentUrl">View</a> -->
                    <button mat-raised-button
                        *ngIf="document.status == 'Unapproved' && (source =='Driver' || source == 'Agent' || (source=='Cab' && userType=='Admin') )"
                        class="m-2 text text-success" (click)="approve(document)">Approve</button>
                    <button mat-raised-button
                        *ngIf="document.status == 'Unapproved' && (source =='Driver' || source == 'Agent' || (source=='Cab' && userType=='Admin'))"
                        class="m-2 text text-danger" (click)="reject(document)">Reject</button>
                    <div *ngIf="document.status == 'Rejected' && (source=='Cab' && userType=='Agent')">
                        <div class="info">
                            <div style="display: flex;">
                                <input class="form-control" type="file" (change)="onFileSelected($event,document.documentName)">
                            </div>
                        </div>                    </div>
                    <a *ngIf="document.status != 'Rejected' || (source=='Cab' && userType=='Admin')" class="m-2 text text-primary" [href]="document.documentUrl">Download</a>
                </div>
                <div class="action" *ngIf="!document.documentUrl">
                    <!-- <a class="m-2 text text-primary" [href]="document.documentUrl">View</a> -->
                    <span>No documents uploaded</span>
                </div>
            </mat-card>
        </div>
    </div>
</div>