import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AgentServiceService } from 'src/app/services/agent-service.service';
import { TransactionDetailsComponent } from 'src/app/transaction-details/transaction-details.component';

@Component({
  selector: 'app-my-earning',
  templateUrl: './my-earning.component.html',
  styleUrls: ['./my-earning.component.scss']
})
export class MyEarningComponent {

  showLoader = true;

  data: any ;
  agentId: string = "";
  constructor(public dialog: MatDialog, private _service: AgentServiceService, private router: Router) {
    this.checkLoginStatus();
  }
  ngOnInit(){
    this._service.getDashboardData(this.agentId).subscribe((res) => {
      console.log("Response: ",res);
      this.data = res;
      this.showLoader = false;
    })
  }

  checkLoginStatus() {
    // localStorage.setItem("admin_auth", res.phoneNumber);
    // localStorage.setItem("admin_authExpiration", expirationDate.toString());  
    console.log("Checking login status")
    const auth = localStorage.getItem("agent_auth");
    const time = localStorage.getItem("agent_authExpiration");
    console.log("checked auth: ", auth, time);
    if (time && auth) {
      const date = new Date(time);
      console.log("time: ", time)
      if (date <= new Date()) {
        console.log("time: ", time)
        localStorage.removeItem('agent_auth');
        localStorage.removeItem('agent_authExpiration');
      }
      else {
        this.agentId = auth;
      }
    }
    else {
      this.router.navigate(['/']);
    }
  }

  ShowTransactionDetails(){
    let data = {
      agentId: this.agentId,
      totalRideCollection: this.data.TotalRideCollection,
      totalCashReceived: this.data.TotalCashReceived,
      receivedFromCompany: this.data.ReceivedFromCompany,
      unCommissiondAmount: this.data.UnCommissiondAmount,
      amountWithCompany: this.data.AmountWithCompany,
      totalCompanyClaimed: this.data.TotalCompanyClaimed,
      pendingWithCompany: this.data.PendingForSettlement,
      lastSettlementDate: this.data.LastSettlementDate,
      settledPayment: this.data.TotalRideCollection - this.data.UnCommissiondAmount,
    }
    console.log("Transaction Details: ", data);
    const dialog = this.dialog.open(TransactionDetailsComponent, {
      width: '80%',
      height: '80%',
      data: {
        source: "AgentEarnings",
        entity: "Agent",
        content: data,
      }
    })
  }
}

// class DashboardData{
//   TotalRideCollection: Number = 0;
//   TotalAgentReceived: Number = 0;
//   TotalCompanyClaimed: Number = 0;
//   AmountWithAgent: Number = 0;
//   AmountWithCompany: Number = 0;
//   SettledPayment: Number = 0;
//   PendingForSettlement: Number = 0;

//   TotalRides: Number = 0;
//   TotalCancelledRides: Number = 0;
//   TotalCompletedRides: Number = 0;
//   TotalScheduledRides: Number = 0;
// }