import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AgentTableComponent } from '../agent-table.component';
import { AgentServiceService } from 'src/app/services/agent-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent {
  // @Input() data: any = {};

  bookingDetails: any = {};
  availableDrivers: any[] = [];
  availableCabs: any[] = [];

  selectedCabId: string = "";
  selectedCabNumber: string = ""

  selectedDriverName: string = "";
  selectedDriverId: string = "";


  constructor(public dialogRef: MatDialogRef<AgentTableComponent>,
    private _service: AgentServiceService, private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any, private router: Router) {

    const agentId = localStorage.getItem("agent_auth");
    const time = localStorage.getItem("agent_authExpiration");
    console.log("checked auth: ", agentId, time);
    if (time && agentId) {
      const date = new Date(time);
      console.log("time: ", time)
      if (date <= new Date()) {
        console.log("time: ", time)
        localStorage.removeItem('agent_auth');
        localStorage.removeItem('agent_authExpiration');
        this.router.navigate(["/"])
        this.dialogRef.close();
      }
    }

    console.log("Hey data : ", data);
    if (data.flag == 'AvailableRides') {
      this.bookingDetails = data.content;
      if (!agentId) {
        this.router.navigate(["/"])
        this.dialogRef.close();
      }
      else
        this._service.getAvailableCarAndDriver(agentId).subscribe((res) => {
          console.log("Results: ", res);
          this.availableDrivers = res.drivers;
          this.availableCabs = res.cars;
          if (this.availableCabs.length == 1) {
            this.selectedCabNumber = this.availableCabs[0]._id;
            this.selectCab(this.selectedCabNumber);
          }
          if (this.availableDrivers.length == 1) {
            this.selectedDriverName = this.availableDrivers[0].driver_id;
            this.selectDriver(this.selectedDriverName);
          } 
        })
    }
  }

  selectCab(cabId: string) {
    this.bookingDetails.cabID = cabId;
    console.log('Cab selected: ', this.bookingDetails.cabID, cabId)
  }

  selectDriver(driverID: string) {
    this.bookingDetails.driverID = driverID;
    console.log('driverSelected: ', this.bookingDetails.driverID, driverID)
  }

  acceptRide() {
    console.log("Accept Booking called");
    if (this.bookingDetails.cabID && this.bookingDetails.driverID && this.bookingDetails.BookingID) {
      console.log("Accepted Booking: ", this.bookingDetails);
      this._service.acceptBooking(this.bookingDetails).subscribe((res) => {
        console.log(res);
        this.snackBar.open("Ride accepted successfully!", 'Close', { duration: 3000 })
        this.dialogRef.close({ bookingId: this.bookingDetails.BookingID, status: "accepted" });

      }, (error) => {
        console.log(error);
        this.snackBar.open("Some error occured!", 'Close', { duration: 3000 })
      })
    }

    // 
  }

  cancel() {
    this.dialogRef.close({ status: "cancel" });
  }
}
