import { Component } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';


@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss']
})
export class PushNotificationComponent {
  allUser: any[] = ['Customer', 'Agent', 'Driver'];
  channelList:any[]=['Email','WhatsApp','Text'];
  notificationSentTo:string="";
  notificationChannel:string="";
  messageSent:string="";
  messageData:any[]=[];
  tableData: any[] = [];

  constructor(private adminservice:AdminService){}
  
  ngOnInit(){
    this.fetchMessageData()
   }
  sendNotification(){
    let data = {notificationSentTo:this.notificationSentTo,notificationChannel:this.notificationChannel,messageSent:this.messageSent}
    this.adminservice.postNotifications(data).subscribe((res)=>{
     this.notificationSentTo="";
     this.notificationChannel="";
     this.messageSent="";
     this.fetchMessageData()
    },(err)=>{
      console.log(err)
    })
  }

  fetchMessageData(){
    this.adminservice.getAllMessage().subscribe((res)=>{
      console.log("Res for message : ",res)
      if (Array.isArray(res) && res.length > 0) {
      this.messageData=res
      this.tableData=this.messageData.map(mess=>{
        return{
          Sent_To:mess.notificationSentTo,
          Message:mess.messageSent,
          Sent_On:mess.updatedAt
        }
      })
    }},
    (error)=>{
      console.log(error)
    })
  }
  columnData = [
    // { field: "No", label: "No" },
    { field: "Sent_To", label: "Sent To" },
    { field: "Message", label: "Message" },
    { field: "Sent_On", label: "Sent On" }
  ];
  
  changeFilterValue(filter: any) {
    console.log(filter);
   // console.log(this.tableData);
  }
}
