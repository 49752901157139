import { Component, HostListener } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-travel-agent',
  templateUrl: './travel-agent.component.html',
  styleUrls: ['./travel-agent.component.scss']
})
export class TravelAgentComponent {
  expandPane = false;
  smallScreen = false;

  pageview: string = "Home";
  agentId: string = "";
  menuList: any[] = [
    { icon: 'home', label: 'Home', mat_tool_tip: 'Home', route: 'agentHome' },
    // { icon: 'no_crash', label: 'Book for Customer', mat_tool_tip: 'Book for Customer', route: 'customerBooking' },
    { icon: 'account_circle', label: 'Profile', mat_tool_tip: 'Agent Profile', route: 'agentProfile' },
    { icon: 'directions_car', label: 'My Bookings', mat_tool_tip: 'My Bookings', route: 'Mybookings' },
    // { icon: 'directions_car', label: 'Customer Bookings', mat_tool_tip: 'Customer Bookings', route: 'Customerbookings' },
    { icon: 'location_on', label: 'Manage Work Loction', mat_tool_tip: 'Manage Work Location', route: 'ManageWorkLocation' },
    { icon: 'directions_car', label: 'Manage Driver', mat_tool_tip: 'Manage Driver', route: 'manageDriver' },
    { icon: 'directions_car', label: 'Manage Car', mat_tool_tip: 'Manage Car', route: 'ManageCar' },
    { icon: 'monetization_on', label: 'My Earnings', mat_tool_tip: 'My Earnings', route: 'myEarnings' },]

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.smallScreen = window.innerWidth < 769 ? true : false;
  }
  ngOnInit() {
    this.onResize(event!);
  }

  logout(){
    localStorage.removeItem('agent_auth');
    localStorage.removeItem('agent_authExpiration');
    this.router.navigate(['/']);
  }

  constructor(private router: Router){}

}
