import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AgentServiceService } from 'src/app/services/agent-service.service';

@Component({
  selector: 'app-agent-home',
  templateUrl: './agent-home.component.html',
  styleUrls: ['./agent-home.component.scss']
})
export class AgentHomeComponent {

  constructor(private _service: AgentServiceService, private router: Router) {
    this.checkLoginStatus();
  }
  showLoader: boolean = true;
  scheduledRidesColumnData = [
    { field: "From", label: "From" },
    { field: "To", label: "To" },
    { field: "Distance", label: "Distance" },
    { field: "Fare", label: "Fare" },
    { field: "CarModel", label: "Car Model" },
    { field: "CarNumber", label: "Car Number" },
    { field: "carType", label: "Car Category" },
    { field: "PickupDateTime", label: "Pickup Date" },
    { field: "PickupTime", label: "Pickup Time" },
    { field: "DriverName", label: "Driver Name" },
    { field: "DriverContact", label: "Driver Contact" },
    { field: "CustomerContact", label: "Customer Contact" },

  ];

  requestedRidesColumnData = [
    { field: "BookingID", label: "BookingID" },
    { field: "From", label: "From" },
    { field: "To", label: "To" },
    { field: "Distance", label: "Distance" },
    { field: "carType", label: "Car Category" },
    { field: "Fare", label: "Fare" },
    { field: "PickupDateTime", label: "Pickup Date" },
    { field: "PickupTime", label: "Pickup Time" },
    { field: "Action", lable: "Action" }];

  agentId: string = "";
  requestedRidesTableData = [];
  scheduledRidesTableData = [];
  ongoingRidesTableData = [];
  ngOnInit() {
    this.fetchBookingDetails();
    this.startInterval();
  }

  checkLoginStatus() {
    const auth = localStorage.getItem("agent_auth");
    const time = localStorage.getItem("agent_authExpiration");
    if (time && auth) {
      const date = new Date(time);
      if (date <= new Date()) {
        localStorage.removeItem('agent_auth');
        localStorage.removeItem('agent_authExpiration');
      }
      else {
        this.agentId = auth;
      }
    }
    else {
      this.router.navigate(['/']);
    }
  }

  //Fetch bookings details 
  fetchBookingDetails() {
    const auth = localStorage.getItem("agent_auth");
    this.showLoader = true;
    if (auth)
      this._service.getAllBookings(auth).subscribe((res) => {
        let requestedRides = res.requestedRide.filter((ride: any) => ride.status == 'Forwarded');
        this.requestedRidesTableData = requestedRides.map((ride: any) => {
          return {
            AssignedBy: this.agentId,
            BookingID: ride.booking_id,
            From: ride.from,
            To: ride.to,
            Distance: ride.distance,
            Fare: ride.fare,
            TripType: ride.tripType,
            PickupDateTime: ride.date,
            PickupTime: ride.time,
            Action: "",
            carType: ride.carType
          }
        })

        let scheduledRides = res.confirmedRide.filter((ride: any) => ride.status == 'Scheduled');
        console.log("scheduledRides", scheduledRides);
        this.scheduledRidesTableData = scheduledRides.map((ride: any) => {
          return {
            From: ride.from,
            To: ride.to,
            Distance: ride.distance,
            Fare: ride.fare,
            PickupDateTime: ride.date,
            PickupTime: ride.time,
            CarModel: ride.car_brand + " - " + ride.CarName,
            CarNumber: ride.carNumber,
            DriverName: ride.driver_name,
            CustomerContact: ride.customerContact,
            DriverContact: ride.driverContact,
            carType: ride.cabCategoryName


          }
        })

        let ongoingRides = res.confirmedRide.filter((ride: any) => ride.status == 'Ongoing');
        this.ongoingRidesTableData = ongoingRides.map((ride: any) => {
          return {
            From: ride.from,
            To: ride.to,
            Distance: ride.distance,
            Fare: ride.fare,
            CarModel: ride.car_brand + " - " + ride.CarName,
            CarNumber: ride.carNumber,
            carType: ride.cabCategoryName,
            PickupDateTime: ride.date,
            PickupTime: ride.time,
            DriverName: ride.driver_name,
            DriverContact: ride.driverContact,
            ContactNumber: "",
            CustomerContact: ride.customerContact,
          }
        })
        this.showLoader = false;
      })
  }

  private intervalId: any;
  startInterval(): void {
    // Call the method every 1000 milliseconds (1 second)
    this.intervalId = setInterval(() => {
      this.fetchBookingDetails();
    }, 30000);
  }

  ngOnDestroy(): void {
    // Clear the interval when the component is destroyed to prevent memory leaks
    this.clearInterval();
  }

  clearInterval(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
