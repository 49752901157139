<!-- <form class="add_driver_form"> -->
    <div class="form-group col-sm-12">
        <mat-card class="example-card">
            <mat-card-header class="justify-content-around ">
                <mat-card-title>Push Notification</mat-card-title>
            </mat-card-header>
            <div class="d-flex main-div mt-4">
                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                    <div class="col-sm-4 fw-bold label">Sent To :<span class="text-danger">*</span></div>
                    <mat-form-field class="col-sm-8 input-field">
                        <mat-select [(ngModel)]="notificationSentTo">
                            <mat-option *ngFor="let filter of allUser" [value]="filter">{{filter}}</mat-option>
                        </mat-select>                 
                       </mat-form-field>
                </div>
            </div>
            <div class="d-flex main-div mt-4">
                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                    <div class="col-sm-4 fw-bold label">Channel:<span class="text-danger">*</span></div>
                    <mat-form-field class="col-sm-8 input-field">
                        <mat-select [(ngModel)]="notificationChannel">
                            <mat-option *ngFor="let filter of channelList" [value]="filter">{{filter}}</mat-option>
                        </mat-select>                 
                       </mat-form-field>
                </div>
            </div>
            
            <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                <div class="col-sm-4 fw-bold label">Push Message :<span class="text-danger">*</span></div>
                <mat-form-field class="col-sm-8 input-field">
                    <textarea matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5" [(ngModel)]="messageSent"></textarea>
                    </mat-form-field>
            </div>
            <div class="btn1">
                <button type="submit" class="btn btn-success col-sm m-4" (click)="sendNotification()" [disabled]="notificationSentTo =='' || messageSent == '' || notificationChannel ==''">Send</button>
            </div>
        </mat-card>
    </div>
<!-- </form> -->

<mat-card class="m-2 p-4">
    <app-table [data]="tableData" [columnData]="columnData" [flag]="'Notification'"></app-table>
</mat-card>