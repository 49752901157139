// admin-routing.module.ts
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminCustomerComponent} from './admin-customer/admin-customer.component';
import { DriverComponent } from './driver/driver.component';
import { AgentComponent } from './agent/agent.component';
import { DriverListComponent } from './agent/driver-list/driver-list.component';
import { RidesComponent } from './rides/rides.component';
import { PromoComponent } from './promo/promo.component';

const adminRoutes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      // Define your nested routes here
      // Example:
      { path: 'dashboard', component: DashboardComponent },
      { path: 'customer', component: AdminCustomerComponent },
      { path: 'driver', component: DriverComponent },
      { path: 'agent', component: AgentComponent },
      { path: 'agent/driverList', component: DriverListComponent },
      { path: 'rides', component:  RidesComponent},
      { path: 'promo', component:  PromoComponent},
      // ... other nested routes
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(adminRoutes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
