import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component } from '@angular/core';

@Component({
  selector: 'app-driver-details',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.scss']
})
export class DriverDetailsComponent {
  showLoader: boolean = true;

   column1Data = [
    { field: "rideId", label: "Ride ID" },
    { field: "rideType", label: "Ride Type" },
    { field: "driverName", label: "Driver Name" },
    { field: "driverContact", label: "Driver Contact" },
    { field: "customerName", label: "Customer Name" },
    { field: "customerContact", label: "Customer Contact" },
    { field: "pickDateTime", label: "Pick Date/Time" },
    { field: "dropDate", label: "Drop Date" },
    { field: "pickDropAddress", label: "Pick/Drop Address" },
    { field: "tripStatus", label: "Trip Status" }
  ];
   column2Data = [
    { field: "driverName", label: "Driver Name" },
    { field: "driverMobile", label: "Driver Mobile" },
    { field: "workLocation", label: "Work Location" },
    { field: "vehicleType", label: "Vehicle Type" },
    { field: "vehicleModel", label: "Vehicle Model" }
  ];
  
  
  table1Data=[
    {
      rideId: "R123",
      rideType: "Premium",
      driverName: "John Doe",
      driverContact: "123-456-7890",
      customerName: "Alice Smith",
      customerContact: "987-654-3210",
      pickDateTime: "2023-01-15 08:00 AM",
      dropDate: "2023-01-15",
      pickDropAddress: "123 Main Street, Cityville",
      tripStatus: "Completed"
    },
    {
      rideId: "R456",
      rideType: "Standard",
      driverName: "Jane Smith",
      driverContact: "987-654-3210",
      customerName: "Bob Johnson",
      customerContact: "555-123-4567",
      pickDateTime: "2023-02-20 10:30 AM",
      dropDate: "2023-02-20",
      pickDropAddress: "456 Oak Avenue, Townsville",
      tripStatus: "In Progress"
    },
    {
      rideId: "R789",
      rideType: "Premium",
      driverName: "Robert Johnson",
      driverContact: "555-123-4567",
      customerName: "Emily Davis",
      customerContact: "111-222-3333",
      pickDateTime: "2023-03-25 12:15 PM",
      dropDate: "2023-03-25",
      pickDropAddress: "789 Pine Street, Villagetown",
      tripStatus: "Pending"
    },
    {
      rideId: "R101",
      rideType: "Standard",
      driverName: "Emily Davis",
      driverContact: "111-222-3333",
      customerName: "Michael Brown",
      customerContact: "333-444-5555",
      pickDateTime: "2023-04-10 05:45 PM",
      dropDate: "2023-04-10",
      pickDropAddress: "101 Maple Lane, Hamletville",
      tripStatus: "Canceled"
    },
    {
      rideId: "R202",
      rideType: "Premium",
      driverName: "Michael Brown",
      driverContact: "333-444-5555",
      customerName: "Robert Johnson",
      customerContact: "555-123-4567",
      pickDateTime: "2023-05-15 03:00 PM",
      dropDate: "2023-05-15",
      pickDropAddress: "202 Cedar Road, Suburbia",
      tripStatus: "Scheduled"
    }          
  ]

   table2Data = [
    {
      driverName: "John Doe",
      driverMobile: "123-456-7890",
      workLocation: "City Center",
      vehicleType: "Sedan",
      vehicleModel: "2020 Honda Accord"
    },
    {
      driverName: "Jane Smith",
      driverMobile: "987-654-3210",
      workLocation: "Downtown",
      vehicleType: "SUV",
      vehicleModel: "2019 Toyota RAV4"
    },
    {
      driverName: "Robert Johnson",
      driverMobile: "555-123-4567",
      workLocation: "Industrial Zone",
      vehicleType: "Truck",
      vehicleModel: "2021 Ford F-150"
    },
    {
      driverName: "Emily Davis",
      driverMobile: "111-222-3333",
      workLocation: "Suburbia",
      vehicleType: "Electric",
      vehicleModel: "2023 Tesla Model S"
    },
    {
      driverName: "Michael Brown",
      driverMobile: "333-444-5555",
      workLocation: "Tech Park",
      vehicleType: "Hybrid",
      vehicleModel: "2022 Toyota Prius"
    }
  ];
  
  currentStep: number = 0;
  onStepChange(event: StepperSelectionEvent): void {
    this.currentStep = event.selectedIndex;
  }
  selectedValue: any = '';
  ride_filter: any[] = ['Completed', 'Cancelled','OnGoing','Scheduled']
  changeFilterValue(filter: any) {
    console.log(filter);
   
  }

}
