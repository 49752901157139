import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../environment';


@Injectable({
  providedIn: 'root'
})
export class AdminService {
  backendURL: string = environment.apiUrl;

  constructor(public http: HttpClient) { }
  getAllCustomerForDashboard(){
    return this.http.get(this.backendURL + `/customer/fetchAll`)
  }
  getAllDriverForDashboard(){
    return this.http.get(this.backendURL + `/admin/fetchAllDrivers`)
  }
  getAllAgentForDashboard(){
    return this.http.get(this.backendURL + `/admin/fetchallAgent`)
  }
  getAllridesForDashboard(){
    return this.http.get(this.backendURL+`/admin/fetchallrides`)
  }
  getAllPromoForDashboard(){
    return this.http.get(this.backendURL + `/admin/fetchallPromo`)
  }
  getpromoCreated(promoDetails: any){
    return this.http.post(this.backendURL + `/promocodeApi/createPromo`,promoDetails)
  }
  getpromoDeleted(promoCode: any){
    return this.http.delete(this.backendURL + `/promocodeApi/deletePromo/${promoCode}`)
  }
  getpromoEdited(promoDetails:any){
    return this.http.put(this.backendURL + `/promocodeApi/editPromo/${promoDetails.codeName}`,promoDetails)
  }
  getAllVehicleForDashboard(){
    return this.http.get(this.backendURL + `/admin/fetchAllVehicle`)
  }
  getVehicleRegisterdForadmin(vehicleDetails:any):Observable<any>{
    return this.http.post(this.backendURL + `/cab/cabRegistration`,vehicleDetails)
  }
  updateServiceLocation(source:string, updatedLocation: string[]){
    return this.http.put(this.backendURL + `/servicelocation/updateLocation`,{source, updatedLocation})
  }
  getServiceLocation(){
    return this.http.get(this.backendURL + `/servicelocation/fetchLocation`)
  }
  getLocationDeleted(serviceValue:any){
    return this.http.delete(this.backendURL+`/serviceLocation/deleteLocation`,{body: serviceValue})
  }
   
  getmetrics(){
    return this.http.get(this.backendURL + `/admin/metrics`)
  }
  getWeeklyMetric(){
    return this.http.get(this.backendURL + `/admin/metric/week`)
  }
  getMonthlyAgent(startDate:any,endDate:any){
    return this.http.put(this.backendURL + `/admin/agentMonthlyMetric`,{startDate , endDate})
  }
  getMonthlyDriver(startDate:any,endDate:any){
    return this.http.put(this.backendURL + `/admin/driverMonthlyMetric`,{startDate , endDate})
   
  }
  getMonthlyOverAllGraph(){
    return this.http.get(this.backendURL + `/admin/monthly/graph`)
  }
  getPricingAdded(pricingData:any,categoryDetails:any){
      return this.http.put(this.backendURL + `/pricing/addPricing`,{pricingData,categoryDetails})
  }
  getPricingValue(){
    return this.http.get(this.backendURL + `/pricing/fetchPricing`)
  }
  getSiteSettingAdded(siteSetting:any){
    return this.http.put(this.backendURL + `/websetting/addsiteSetting`,siteSetting)
  }
  getWebSettingData(){
    return this.http.get(this.backendURL + `/websetting/fetchSiteSettings`)
  }
  driverRegister(data: any,admin:string): Observable<any> {
    return this.http.post(this.backendURL + `/driver/driver_register/${admin}`, data);
  }
  getDriverDocumentList(phoneNumber: string): Observable<any>{
    return this.http.get(this.backendURL + '/driver/getDocumentsList/'+ phoneNumber)
  }
  getCabDocumentList(vehicleNumber: string): Observable<any>{
    return this.http.get(this.backendURL + '/cab/documentsList/'+ vehicleNumber)
  }
  getAgentDocumentList(agentId: string): Observable<any>{
    return this.http.get(this.backendURL + '/agent/documentsList/' + agentId)
  }
  getEarningsData(): Observable<any>{
    return this.http.get(this.backendURL + '/admin/getEarnings');
  }

  getAllTransactionDetails(agentId: string): Observable<any> {
    return this.http.get(this.backendURL + '/agent/getAllTransactions/' + agentId);
  }

  addTransaction(transactionObj: any): Observable<any>{
    return this.http.post(this.backendURL + '/transaction/add', transactionObj);
  }

  //Notification API
  postNotifications(data:any){
    return this.http.post(this.backendURL + '/notification/pushNotification',data)
  }
  getAllMessage(){
    return this.http.get(this.backendURL+'/notification/fetchAllNotification');
  }
  //Rating API
  getAllRating(){
    return this.http.get(this.backendURL + '/admin/fetchRatings')
  }

  approveDoc(source: string, documentName: string,UID: string){
    return this.http.put(this.backendURL + '/admin/approveDoc',  {source, documentName,UID})
  }
  rejectDoc(source: string, documentName: string,UID: string){
    return this.http.put(this.backendURL + '/admin/rejectDoc',  {source, documentName,UID})
  }
  getAllCustomerRides(customer_id: string): Observable<any>{
    return this.http.get(this.backendURL + '/customer/customer_rides/' + customer_id);
  }
  getAllAgentRides(agentId: string): Observable<any>{
    return this.http.get(this.backendURL + '/agent/rideHistory/' + agentId);
  }

  getchAgentWithLocation(locations: any, preference: string): Observable<any> {
    return this.http.put(this.backendURL + '/admin/fetchAgentWithLocation', {locations, preference});
  }

  forwardRide(bookingId: string, agentIds: any): Observable<any>{
    return this.http.put(this.backendURL + '/admin/forwardRide', {bookingId, agentIds})
  }

  settlePayment(transactionObj: any): Observable<any>{
    return this.http.put(this.backendURL + '/admin/settlePayment', transactionObj);
  }

  getBookingDetails(bookingId: string): Observable<any>{
    return this.http.get(this.backendURL + '/bookings/' + bookingId);
  }

  //otp
  getOTPValue(phoneNumber:number){
    console.log("OTP phNO : ",phoneNumber)
    return this.http.post(this.backendURL + '/authentication/send-otp' ,{phoneNumber})
  }
  getOTPVerified(orderId:number, otp:number, phoneNumber:number){
    return this.http.post(this.backendURL + '/authentication/verify-otp' ,{phoneNumber,orderId, otp})
  }
  getReSendOTP(orderId:number){
    return this.http.post(this.backendURL + `/authentication/resend-otp`,{orderId})
  }

  updateDriver(driverId: string, status: string): Observable<any>{
    return this.http.put(this.backendURL + "/admin/updateDriverStatus", { driverId, status })
  }
  updateCab(Vehicle_Number: string, status: string): Observable<any>{
    return this.http.put(this.backendURL + "/admin/updateCabStatus", { Vehicle_Number, status })
  }
  updateAgent(agentId: string, status: string): Observable<any>{
    return this.http.put(this.backendURL + "/admin/updateAgentStatus", { agentId, status })
  }

  fetchProfile(phoneNumber: any): Observable<any>{
    return this.http.get(this.backendURL + `/admin/profile/${phoneNumber}`);
  }
  login(phoneNumber: string, password: string): Observable<any>{
    return this.http.post(this.backendURL + '/admin/login', {phoneNumber, password});
  }
  cancelRide(bookingId: string, cancellationMessage: string, cancelledBy: string): Observable<any> {
    return this.http.put(this.backendURL + '/admin/cancelRide', { bookingId, cancellationMessage, cancelledBy });
  }

}