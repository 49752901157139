import { Component } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-earnings',
  templateUrl: './earnings.component.html',
  styleUrls: ['./earnings.component.scss']
})
export class EarningsComponent {
  selectedValue: any = '';
  showLoader: boolean = true;
 columnData = [
    { field: "agentId", label: "Agent ID" },
    { field: "agentName", label: "Agent Name" },
    { field: "settlementDate", label: "Last Settlement Date" },
    { field: "totalRideCollection", label: "A=Total Ride Collection" },
    { field: "settledPayment", label: "B=Settled Payment" },
    { field: "unCommissiondAmount", label: "C=Pending For Settlement (A - B)" },
    { field: "Action", label: "Transaction Details" },
  ];
  
  agentEarningData : any[] = [];
  
  constructor(private _service: AdminService){}

  changeFilterValue(filter: any) {
    console.log("")
  }

  ngOnInit(){
    this.fetchEarningsData();
  }

  fetchEarningsData(){
    this._service.getEarningsData().subscribe((res) => {
      console.log(res);
      this.agentEarningData = res.data.map((element: any) => {
        return {
            agentId: element.agentId,
            agentName: element.name,
            settlementDate: element.Earnings.LastSettlementDate?element.Earnings.LastSettlementDate: null,
            totalRideCollection: element.Earnings.TotalRideCollection,
            totalCashReceived: element.Earnings.TotalCashReceived,
            receivedFromCompany: element.Earnings.ReceivedFromCompany,
            unCommissiondAmount: element.Earnings.UnCommissiondAmount,
            amountWithCompany: element.Earnings.AmountWithCompany,
            totalCompanyClaimed: element.Earnings.TotalCompanyClaimed,
            pendingWithCompany: element.Earnings.PendingWithCompany,
            settledPayment: element.Earnings.TotalRideCollection - element.Earnings.UnCommissiondAmount,
          }
      })
      this.showLoader  = false; 

    })
  }
}

class agentEarning {
  agentId:string = "";
  agentName:string = "";
  settlementDate:Date | null = null;
  totalEarning:number = 0;
  settledAmount:number = 0;
  RemainingAmount:number = 0;
  amountWithCompany:number = 0;
  siteEarning:number = 0;
}
