<mat-card class="example-card">
  <mat-card-header class="justify-content-around ">
      <mat-card-title>Home</mat-card-title>
  </mat-card-header>
</mat-card>
<ng-container *ngIf="showLoader; else content" class="loader-container">
  <div class="row">
    <div class="loader"></div>
  </div>
</ng-container>
<ng-template #content>
  <mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">electric_car</mat-icon>
    Available Rides
    </ng-template>
    <app-agent-table [data]="requestedRidesTableData" [columnData]="requestedRidesColumnData"
      [flag]="'AvailableRides'" (updateTable)="fetchBookingDetails()"></app-agent-table>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">electric_car</mat-icon>
       Ongoing Rides
      </ng-template>
      <app-agent-table [data]="ongoingRidesTableData" [columnData]="scheduledRidesColumnData"
        [flag]="'Ongoing'"></app-agent-table>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">electric_car</mat-icon>
        Scheduled Rides
      </ng-template>
      <app-agent-table [data]="scheduledRidesTableData" [columnData]="scheduledRidesColumnData"
        [flag]="'scheduled'"></app-agent-table>
    </mat-tab>
</mat-tab-group>
</ng-template>
