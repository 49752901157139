<mat-card class="example-card">
    <mat-card-header class="justify-content-around ">
        <mat-card-title>Vehicles</mat-card-title>
    </mat-card-header>
</mat-card>
<ng-container *ngIf="showLoader; else content" class="loader-container">
    <div class="row">
        <div class="loader"></div>
    </div>
</ng-container>
<ng-template #content>
    <div class="steeper ">
        <mat-card class="m-2 mb-4">
            <mat-horizontal-stepper [selectedIndex]="currentStep" (selectionChange)="onStepChange($event)">
                <mat-step label="All Vehicle" completed="false">
                    <div style="display: flex; justify-content: flex-end;">
                        <mat-form-field class="col-md-3">
                            <mat-label>Filter vehicle</mat-label>
                            <mat-select [(ngModel)]="selectedValue" (ngModelChange)="changeFilterValue()">
                                <mat-option *ngFor="let filter of ride_filter" [value]="filter">{{filter}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <ng-template matStepLabel>All Vehicles</ng-template>
                    <app-table [data]="tableData" [columnData]="columnData" source="Admin" [flag]="'vehicle'"
                        (vehicleStatusUpdated)="fetchVehicle()"></app-table>
                </mat-step>
                <mat-step label="Add Vehicle" completed="false">
                    <!-- <form class="add_driver_form"> -->
                    <div class="form-group col-sm-12">
                        <mat-card class="example-card">

                            <mat-card-header class="mt-4 justify-content-around">
                                <mat-card-title>Vehicle Info</mat-card-title>
                            </mat-card-header>
                            <div class="d-flex main-div mt-4">
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter Vehicle Brand :<span
                                            class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput placeholder="Vehicle Type" [(ngModel)]="vehicleForm.CarBrand">
                                    </mat-form-field>
                                </div>
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter Vehicle Model :<span class="text-danger">*</span>
                                    </div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput placeholder="Vehicle Model" [(ngModel)]="vehicleForm.CarName">
                                    </mat-form-field>
                                </div>

                            </div>
                            <div class="d-flex main-div mt-4">
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter Fuel Type :<span
                                            class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <mat-select placeholder="Fuel type" [(ngModel)]="vehicleForm.FuelType">
                                            <mat-option *ngFor="let FuelType of cabFuelTypeList" [value]="FuelType">{{FuelType}}</mat-option>
                                        </mat-select>
                                        <!-- <input matInput placeholder="Fuel Type" [(ngModel)]="vehicleForm.FuelType"> -->
                                    </mat-form-field>
                                </div>
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Number of Seats :<span
                                            class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput [(ngModel)]="vehicleForm.NumberOfSeats" appNumericOnly type="tel">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="d-flex main-div mt-4">
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter Insurance Validity :<span
                                            class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <mat-label>Choose a date</mat-label>
                                        <input matInput [matDatepicker]="picker" [min]="minDate" [(ngModel)]="vehicleForm.InsuranceValidity">
                                        <mat-hint>MM/DD/YYYY</mat-hint>
                                        <mat-datepicker-toggle matIconSuffix [for]="picker">
                                            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter Car Owner Name :<span
                                            class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput [(ngModel)]="vehicleForm.CarOwnerName">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="d-flex main-div mt-4">

                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Select Cab Category :<span class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <mat-select placeholder="Cab Category" [(ngModel)]="vehicleForm.cabCategoryId">
                                            <mat-option *ngFor="let category of cabCategoryList"
                                                [value]="category.cabCategoryId">{{category.categoryName}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Insurance :<span class="text-danger">*</span>
                                    </div>
                                    <div class="col-sm-8 input-field">
                                        <!-- <input matInput class="fileInput"> -->
                                        <input type="file" (change)="onFileSelected($event,'InsuranceUpload')"
                                            [(ngModel)]="vehicleForm.InsuranceUpload">
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex main-div mt-4">
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter Vehicle Number :<span
                                            class="text-danger">*</span>
                                    </div>
                                    <mat-form-field class="input-field">
                                        <input matInput placeholder="Vehicle Number" [(ngModel)]="vehicleForm.CarNumber" maxlength="10">
                                    </mat-form-field>
                                </div>
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Permit :<span class="text-danger">*</span></div>
                                    <div class="col-sm-8 input-field">
                                        <!-- <input matInput class="fileInput"> -->
                                        <input type="file" (change)="onFileSelected($event,'PermitUpload')"
                                            [(ngModel)]="vehicleForm.PermitUpload">
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex main-div mt-4">
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Car Owner PAN Card :<span
                                            class="text-danger">*</span>
                                    </div>
                                    <div class="col-sm-8 input-field">
                                        <!-- <input matInput class="fileInput"> -->
                                        <input type="file" (change)="onFileSelected($event,'CarOwnerPanCardUpload')"
                                            [(ngModel)]="vehicleForm.CarOwnerPanCardUpload">
                                    </div>
                                </div>
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Car Owner Aadhar Card :<span
                                            class="text-danger">*</span></div>
                                    <div class="col-sm-8 input-field">
                                        <!-- <input matInput class="fileInput"> -->
                                        <input type="file" (change)="onFileSelected($event,'CarOwnerAadharUpload')"
                                            [(ngModel)]="vehicleForm.CarOwnerAadharUpload">
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex main-div mt-4">
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Car Photo With Owner :<span class="text-danger">*</span>
                                    </div>
                                    <div class="col-sm-8 input-field">
                                        <!-- <input matInput class="fileInput"> -->
                                        <input type="file" (change)="onFileSelected($event,'CarPhotoWithOwnerUploaod')"
                                            [(ngModel)]="vehicleForm.CarPhotoWithOwnerUploaod">
                                    </div>
                                    </div>
                                    <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                        <div class="col-sm-4 fw-bold label">Registration Certificate :<span class="text-danger">*</span></div>
                                        <div class="col-sm-8 input-field">
                                            <!-- <input matInput class="fileInput"> -->
                                            <input type="file" (change)="onFileSelected($event,'registrationCertificateUpload')"
                                                [(ngModel)]="vehicleForm.registrationCertificateUpload">
                                        </div>
                                    </div>
                                    </div>
                                    <div class="d-flex main-div mt-4">
                                        <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">CarOwner PhoneNumber :<span
                                            class="text-danger">*</span>
                                    </div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <!-- <input matInput placeholder="CarOwner PhoneNumber"
                                            [(ngModel)]="vehicleForm.CarOwnerPhoneNumber"> -->
                                        <input matInput placeholder="e.g 987XXXXXXX"
                                            [(ngModel)]="vehicleForm.CarOwnerPhoneNumber" *ngIf="!otpSent"
                                            appNumericOnly maxlength="10">
                                        <input matInput [placeholder]="vehicleForm.CarOwnerPhoneNumber" *ngIf="otpSent"
                                            [disabled]="true">
                                    </mat-form-field>

                                    <div *ngIf="isOTPVerified">
                                        <div class="wrapper"> <svg class="checkmark" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 52 52">
                                                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                <path class="checkmark__check" fill="none"
                                                    d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-sm-4 fw-bold label" *ngIf="!otpSent">
                                <button class="btn login" (click)=" SendOTP()" [disabled]="!isMobileNumberValid()">Send
                                    OTP</button>
                            </div>

                            <div class="d-flex main-div mt-4" *ngIf="otpSent && !isOTPVerified">
                                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter OTP :<span class="text-danger">*</span> </div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput type="tel" placeholder="Enter 6 digit OTP" [(ngModel)]="otp" appNumericOnly>
                                    </mat-form-field>

                                </div>
                                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <button class="btn login" (click)="verifyOtp()" [disabled]="!activateLoginBtn()">Verify</button>
                                    <button class="btn login" (click)="ResendOTP()">Resend OTP</button>
                                    <button class="btn login" (click)="Cancel()">Cancel</button>
                                </div>
                            </div>
                            <div class="btn">
                                <button type="submit" class="btn btn-success col-sm m-3" [disabled]="!isFormValid()"
                                    (click)="addVehicle()">Submit</button>
                                <button type="submit" class="btn btn-danger col-sm m-3"
                                    (click)="cancleForm()">Cancle</button>
                            </div>
                        </mat-card>
                    </div>
                    <!-- </form> -->
                </mat-step>
            </mat-horizontal-stepper>
        </mat-card>
    </div>
</ng-template>