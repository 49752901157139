<mat-card style="height: 100%;padding: 1.5rem">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon">close</mat-icon>
    </button> <mat-card class="bookingDetails">
        <div *ngIf="bookingDetails">
            <div class="row booking-header">
                Booking ID: {{bookingDetails.booking_id}}
            </div>
            <div class="row">
                <div class="col-md-6 customer-details">

                    <div class="data-element">
                        <span>Customer Name:</span><span> {{bookingDetailsFromTable.customer_name}}</span>
                    </div>
                    <div class="data-element"> <span>Customer Contact:
                        </span><span>{{bookingDetailsFromTable.customer_contact}}</span>
                    </div>
                    <div class="data-element"> <span>Ride Type: </span><span>{{bookingDetails.tripType}}</span></div>
                    <div class="data-element"> <span>Requested Cab:
                        </span><span>{{bookingDetails.cabCategoryName}}</span></div>

                </div>
                <div class="col-md-6 customer-details">
                    <div class="data-element"> <span>Pickup City:
                        </span><span>{{bookingDetails.booking_from.city}}</span>
                    </div>
                    <div class="data-element"> <span>Pickup Address
                            :</span><span>{{bookingDetails.booking_from.landmark}}</span></div>
                    <div class="data-element"> <span>Pickup Date Time:
                        </span><span>{{bookingDetails.pickup_date | date}} {{bookingDetails.pickup_time}}</span>
                    </div>
                    <div class="data-element"> <span>Drop City:
                        </span><span>{{bookingDetails.booking_to.landmark}}</span>
                    </div>
                    <div class="data-element"> <span>Drop Address:
                        </span><span>{{bookingDetails.booking_to.landmark}}</span>
                    </div>

                </div>
            </div>
        </div>

    </mat-card>
    <mat-card class="container-card">
        <div class="row">
            <div class="col-md-12 getLocationDiv">
                <!-- <mat-form-field class="col-md-6">
                    <mat-label>Location</mat-label>
                    <mat-select [(ngModel)]="selectedLocations" multiple>
                        <mat-option [value]="'All'" (click)="toggleSelectAllLocation()">All</mat-option>
                        <mat-option *ngFor="let location of locations" [value]="location">{{location}}</mat-option>
                        </mat-select>
                        </mat-form-field> -->
                        <mat-form-field class="col-md-6">
                            <mat-label>Location</mat-label>
                            <mat-select [(ngModel)]="selectedLocations" (selectionChange)="onLocationSelectionChange($event)" multiple>
                                <mat-option [value]="'All'" (click)="toggleSelectAllLocation()">All</mat-option>
                        <mat-option *ngFor="let location of locations" [value]="location">{{location}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-radio-group [(ngModel)]="preference" class="col-md-4" aria-label="Filter based on">
                    <mat-radio-button value="Preferred">Prefered City</mat-radio-button>
                    <mat-radio-button value="WorkLocation">Work Locations</mat-radio-button>
                </mat-radio-group>
                <div class="col-md-2">
                    <button (click)="fetchAgentList()" class="btn btn-primary">Fetch Agent</button>
                </div>
            </div>
            <div class="col-md-12 getLocationDiv" *ngIf="agentListFetched">
                <div *ngIf="!agentList || (agentList && agentList.length == 0)">
                    <span class="text text-danger">No Agents available for the selected filter.</span>
                </div>
                <div *ngIf="agentList && agentList.length != 0" class="col-md-12 "
                    style="display: flex; justify-content: space-between;">
                    <mat-form-field class="col-md-9">
                        <mat-label>Agent</mat-label>
                        <mat-select [(ngModel)]="selectedAgent" (selectionChange)="onAgentSelectionChange($event)" multiple>
                            <mat-option [value]="'All'" (click)="toggleSelectAllAgent()">All</mat-option>
                            <mat-option *ngFor="let agent of agentList" [value]="agent">{{agent}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="col-md-2">
                        <button (click)="forwardRide()" class="btn btn-primary">Forward Ride</button>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
</mat-card>