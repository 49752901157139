<ng-container *ngIf="showLoader; else content" class="loader-container">
    <div class="row">
        <div class="loader"></div>
    </div>
</ng-container>
<ng-template #content>

    <div class="container-fluid w-90 pt-2">
        <mat-card class="card pt-4">
            <div class="row">
                <div class="col-md-2 profile-container">
                    <div class="profile-image">
                        <img src="/assets/profile.jpg">
                    </div>
                </div>
                <div class="col-md-2 profile-detail">
                    <p class="name">{{agentProfile.name}}</p>
                </div>
                <div class="col-md-4 contact-details">
                    <span class="" *ngIf="agentProfile.emailid">
                        <mat-icon class="details-heading">mail</mat-icon>
                        <span class="customer-detail ml-1">{{agentProfile.emailid}}</span>
                    </span>
                    <span class="">
                        <mat-icon class="details-heading">call</mat-icon>
                        <span class="customer-detail ml-1">{{agentProfile.phoneNumber}}</span>
                    </span>
                </div>
                <div class="col-md-2 edit-details">
                    <button mat-stroked-button (click)="editProfile()">Edit</button>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="container-fluid pt-4 w-90">
        <mat-card class="card">
            <div class="edit-allfield">
                <p class="heading">Partner Details</p>
                <mat-icon class="icon" *ngIf="!toggleButton" (click)="editDocument()">edit</mat-icon>
                <div class="btn" *ngIf="showbtn">
                    <button mat-stroked-button (click)="saveBankUpdates()">Save</button>
                </div>
            </div>
            <mat-divider></mat-divider>
            <br>
            <mat-card class="pt-4 doc-card">
                <div class="document">
                    <p style="font-size: 1.2rem; font-weight: 500;">General Details</p>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="info">
                        <span>Agent ID</span>
                        <div class="d-flex">
                            <span>{{agentProfile.agentId}}</span>
                        </div>
                    </div>
                    <div class="info">
                        <span>Profile Type</span>
                        <div class="d-flex">
                            <span *ngIf="agentProfile.type == 'TravelAgent'">Agent</span>
                            <span *ngIf="agentProfile.type == 'Owner' && agentProfile.AreYouADriver">SingleOwner</span>
                            <span *ngIf="agentProfile.type == 'Owner' && !agentProfile.AreYouADriver">Owner</span>
                        </div>
                    </div>
                    <div class="info">
                        <span>Profile Status</span>
                        <div class="d-flex">
                            <span *ngIf="agentProfile.status == 'Approved'" class="ApprovedStatus">Approved</span>
                            <span *ngIf="agentProfile.status == 'Rejected'" class="RejectedStatus">Rejected</span>
                            <span *ngIf="agentProfile.status == 'Un-Approved'" class="UnApprovedStatus">Under Review</span>
                        </div>
                    </div>
                    <div class="info">
                        <span>Acency Name</span>
                        <div class="d-flex">
                            <span>{{agentProfile.agencyName}}</span>
                        </div>
                    </div>
                    <div class="info">
                        <span>Acency Address</span>
                        <div class="d-flex">
                            <span>{{agentProfile.agencyAddress}}</span>
                        </div>
                    </div>
                    
                    
                    </mat-card>
                    <mat-card class="pt-4 doc-card">
                        <div class="document">
                    <p style="font-size: 1.2rem; font-weight: 500;">Document Details</p>
                </div>
                <mat-divider></mat-divider>
                <div class="info">
                    <span>Aadhar Number</span>
                    <!-- <textarea [(ngModel)]="agentProfile.AadharNumber" maxlength="12" appNumericOnly class="textarea-inline-control"
                                                                *ngIf="toggleButton" type="tel">{{agentProfile.AadharNumber}}</textarea> -->
                    <div class="d-flex">
                        <span>{{agentProfile.AadharNumber}}</span>
                    </div>
                </div>
                <div class="info">
                    <span>Uploaded Aadhar Card</span>
                    <div style="display: flex;">
                        <input class="form-control" type="file" (change)="onFileSelected($event,'AadharUpload')"
                            [(ngModel)]="agentProfile.AadharUpload"
                            *ngIf="toggleButton && agentProfile.AadharStatus != 'Approved'">
                        <span *ngIf="!toggleButton && agentProfile.AadharStatus == 'Rejected'" class="RejectedStatus">Rejected</span>
                        <span *ngIf="!toggleButton && agentProfile.AadharStatus == 'Unapproved' && agentProfile.AadharUpload"
                            class="UnApprovedStatus">Under
                            Review</span>
                        <span *ngIf="!toggleButton && agentProfile.AadharStatus == 'Unapproved' && !agentProfile.AadharUpload"
                            class="UnApprovedStatus">Not Uploaded</span>
                        <div class="container text-center jumbotron" *ngIf="agentProfile.AadharStatus == 'Approved'">
                            <a [href]="agentProfile.AadharUpload" target="_blank">View</a>
                        </div>
                    </div>
                    </div>
                    <div class="info">
                    <span>PAN Card</span>
                    <!-- <textarea [(ngModel)]="agentProfile.PanNumber" maxlength="10" class="textarea-inline-control"
                                                                *ngIf="toggleButton">{{agentProfile.PanNumber}}</textarea> -->
                    <div class="d-flex">
                        <span>{{agentProfile.PanNumber}}</span>
                    </div>
                </div>
                <div class="info">
                    <span>Uploaded Pan Card</span>
                    <div style="display: flex;">
                        <input class="form-control" type="file" (change)="onFileSelected($event,'PanUpload')"
                            [(ngModel)]="agentProfile.PanUpload"
                            *ngIf="toggleButton && agentProfile.PanStatus != 'Approved'">
                        <span *ngIf="!toggleButton && agentProfile.PanStatus == 'Rejected'" class="RejectedStatus">Rejected</span>
                        <span *ngIf="!toggleButton && agentProfile.PanStatus == 'Unapproved' && agentProfile.PanUpload"
                            class="UnApprovedStatus">Under Review</span>
                        <span *ngIf="!toggleButton && agentProfile.PanStatus == 'Unapproved' && !agentProfile.PanUpload"
                            class="RejectedStatus">Not Uploaded</span>
                        <div class="container text-center jumbotron" *ngIf="agentProfile.PanStatus == 'Approved'">
                            <a [href]="agentProfile.PanUpload" target="_blank">View</a>
                        </div>
                    </div>
                    </div>
                    <div class="info">
                    <span>GST Number</span>
                    <!-- <textarea [(ngModel)]="agentProfile.GSTNumber" class="textarea-inline-control"
                                                                *ngIf="toggleButton">{{agentProfile.GSTNumber}}</textarea> -->
                    <div class="d-flex">
                        <span>{{agentProfile.GSTNumber}}</span>
                    </div>
                </div>
                    <div class="info">
                        <span>Uploaded GST Certificate</span>
                        <div style="display: flex;">
                            <input class="form-control" type="file" (change)="onFileSelected($event,'GSTUpload')"
                                [(ngModel)]="agentProfile.GSTUpload"
                                *ngIf="toggleButton && agentProfile.GSTStatus != 'Approved'">
                            <span *ngIf="!toggleButton && agentProfile.GSTStatus == 'Rejected'"
                                class="RejectedStatus">Rejected</span>
                            <span *ngIf="!toggleButton && agentProfile.GSTStatus == 'Unapproved' && agentProfile.GSTUpload"
                                class="UnApprovedStatus">Under Review</span>
                                <span *ngIf="!toggleButton && agentProfile.GSTStatus == 'Unapproved' && !agentProfile.GSTUpload"
                                class="RejectedStatus">Not Uploaded</span>
                            <div class="container text-center jumbotron"
                                *ngIf="agentProfile.GSTStatus == 'Approved'">
                                <a [href]="agentProfile.GSTUpload" target="_blank">View</a>
                            </div>
                        </div>
                        </div>

            </mat-card>
            <mat-card class="pt-4 bank-card">
                <div class="bank">
                    <p style="font-size: 1.2rem; font-weight: 500;">Bank Details</p>
                </div>
                <mat-divider></mat-divider>
                <div class="info">
                    <span>Account Number</span>
                    <!-- <textarea [(ngModel)]="agentProfile.AccountNo" class="textarea-inline-control" type="tel"
                                                                *ngIf="toggleButton">XXXX XXXX 2345</textarea> -->
                    <div class="d-flex">
                        <span>{{agentProfile.AccountNo}}</span>
                    </div>
                </div>
                <div class="info">
                    <span>Bank Name</span>
                    <div class="d-flex">
                        <span>{{agentProfile.bankName}}</span>
                    </div>
                    </div>
                    <div class="info">
                        <span>IFSC Code</span>
                        <div class="d-flex">
                            <span>{{agentProfile.IFSCCode}}</span>
                        </div>
                    </div>
                    <div class="info">
                    <span>UPI</span>
                    <!-- <textarea [(ngModel)]="agentProfile.upiId" class="textarea-inline-control"
                                            *ngIf="toggleButton">XXXX XXXX 2345</textarea> -->
                    <div class="d-flex">
                        <span>{{agentProfile.upiId}}</span>
                    </div>
                </div>
                <div class="info">
                    <span>PhonePe Number</span>
                    <!-- <textarea [(ngModel)]="agentProfile.phonepeNumber" class="textarea-inline-control"
                                                                *ngIf="toggleButton" type="tel">XXXX XXXX 2345</textarea> -->
                    <div class="d-flex">
                        <span>{{agentProfile.phonepeNumber}}</span>
                    </div>
                </div>


            </mat-card>
        </mat-card>
    </div>
</ng-template>