<div class="mat-elevation-z8 table-responsive">
    <!-- <table mat-table [dataSource]="dataSource">
         <ng-container *ngFor="let column of columnData;let i=index" [matColumnDef]="column.field">
            <th mat-header-cell *matHeaderCellDef>{{column.label}}</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="flag==='upcoming'">
                    {{element[column.field]}}
                </div>
                <div *ngIf="flag==='Completed'">
                    {{element[column.field]}}
                </div>
                <div *ngIf="flag==='cancelled'">
                    {{element[column.field]}}
                </div>
                <div *ngIf="flag==='upcoming' && column.field==='action'">
                    <button mat-icon-button style="color: red; ">
                        <mat-icon>cancel</mat-icon>
                    </button>
                    <button mat-icon-button style="color: #6e6e07; ">
                        <mat-icon>share</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>
        <ng-container *ngFor="let column of columnData" [matColumnDef]="column.field">
            <th mat-header-cell *matHeaderCellDef>{{column.label}}</th>
            <td mat-cell *matCellDef="let element">
                <span>{{element[column.field]}}</span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row;columns:displayedColumns;"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns "></tr>
        <tr mat-row *matRowDef="let row;columns:displayedColumns; "></tr>
    </table> -->
    <table mat-table [dataSource]="dataSource">
        <ng-container *ngFor="let column of columnData;let i=index" [matColumnDef]="column.field">
            <th mat-header-cell *matHeaderCellDef>{{column.label | uppercase}}</th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="column.field!== 'date'">
                    {{element[column.field]}}
                </ng-container>
                <ng-container *ngIf="column.field=== 'date'">
                    {{element[column.field] | date}}
                </ng-container>
                <ng-container *ngIf="flag==='upcoming' && column.field==='action'">
                    <button mat-icon-button style="color: red; " (click)="customerCancelRide(element)">
                        <mat-icon>cancel</mat-icon>
                    </button>
                    <button mat-icon-button style="color: #6e6e07; ">
                        <mat-icon>share</mat-icon>
                    </button>
                </ng-container>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row;columns:displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5,10,20] " showFirstLastButtons
        aria-label="Select page of periodic elements "></mat-paginator>
</div>