import { Component } from '@angular/core';

@Component({
  selector: 'app-available-rides',
  templateUrl: './available-rides.component.html',
  styleUrls: ['./available-rides.component.scss']
})
export class AvailableRidesComponent {

}
