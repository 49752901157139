import { Component, Input, Output, SimpleChange, ViewChild, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CustomerService } from '../../services/customer.service';


@Component({
  selector: 'app-driver-table',
  templateUrl: './driver-table.component.html',
  styleUrls: ['./driver-table.component.scss']
})
export class DriverTableComponent {
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input() data: any[] = [];
  @Input() flag: any;
  @Input() columnData: any[] = [];
  @Output() changeStatus: EventEmitter<any> = new EventEmitter<any>();

  constructor(private customerService: CustomerService) { }
  displayedColumns: string[] = [];

  ngOnInit() {
    this.dataSource.data = this.data;
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChange) {
    if (this.columnData.length) {
      this.displayedColumns = this.columnData.map(({ field }) => field)
    }
    if (changes) {
      this.dataSource.data = this.data;
      this.dataSource.paginator = this.paginator;
    }
  }

  isScheduled(element: any): boolean {
    const ride_date = element['date'];
    const date = new Date(ride_date);
    const extractedDate = date.toISOString().slice(0, 10);

    let [time, period] = element['time'].split(' ');
    // console.log(time, period)
    let [hour, minute] = element['time'].split(/[.:]/).map((num: any) => parseInt(num));
    if (period === 'PM' && hour !== 12) {
      hour += 12;
    } else if (period === 'AM' && hour === 12) {
      hour = 0;
    }
    const combinedDateTime = new Date(`${extractedDate}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`);
    const now = new Date();
    const timeDifference = combinedDateTime.getTime() - now.getTime();
    const twentyMinutesInMillis = 20 * 60 * 1000;
    // console.log(timeDifference, twentyMinutesInMillis, Math.abs(timeDifference) <= twentyMinutesInMillis);

    return Math.abs(timeDifference) <= twentyMinutesInMillis || timeDifference < 0;
  }

  convertTime(time12h: any) {
    const [timePart, meridiem] = time12h.split(' ');
    let [hours, minutes] = timePart.split('.').map((part: any) => parseFloat(part));
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  startRide(ride: any) {
    this.customerService.change_ride_status('Ongoing', ride['booking_id']).subscribe((res) => {
      this.changeStatus.emit('goBack');
    })
  }

  date(convertDate: any): any {
    convertDate = new Date(convertDate);
    return new Date(convertDate.getTime() + (convertDate.getTimezoneOffset() * 60000))
  }

}
