<mat-card class="example-card">
    <mat-card-header class="justify-content-around ">
        <mat-card-title>Driver</mat-card-title>
    </mat-card-header>
  </mat-card>
  <ng-container *ngIf="showLoader; else content" class="loader-container">
    <div class="row">
      <div class="loader"></div>
    </div>
  </ng-container>

  <ng-template #content>
<div class="steeper ">
    <mat-card class="m-2 mb-4">
        <mat-horizontal-stepper [selectedIndex]="currentStep" (selectionChange)="onStepChange($event)">
            <mat-step label="Booked" completed="false">
                <ng-template matStepLabel>Booked</ng-template>
                <div style="display: flex; justify-content: flex-end;">
                    <mat-form-field class="col-md-3">
                        <mat-label>Filter Rides</mat-label>
                        <mat-select [(ngModel)]="selectedValue" (ngModelChange)="changeFilterValue(selectedValue)">
                            <mat-option *ngFor="let filter of ride_filter" [value]="filter">{{filter}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <app-table [data]="table1Data" [columnData]="column1Data" [flag]="'bookedDriver'"></app-table>
            </mat-step>
            <mat-step label="Available" completed="false">
                <app-table [data]="table2Data" [columnData]="column2Data" [flag]="'Availabledriver'"></app-table>
            </mat-step>
        </mat-horizontal-stepper>
    </mat-card>
</div>
  </ng-template>