<ng-container *ngIf="showLoader; else content" class="loader-container">
    <div class="row">
        <div class="loader"></div>
    </div>
</ng-container>
<ng-template #content>

    <div class="form-group col-sm-12">
        <mat-card class="example-card pt-4">
            <mat-card-header class="justify-content-around ">
                <mat-card-title>Manage Work Location</mat-card-title>
            </mat-card-header>
            <mat-card class="m-2 p-4">
                <h1>Add Work Location</h1>
                <div class="row align-items-end">
                    <div class="d-flex col-md-5 col-sm-6 col-xs-12 flex-column flex-md-row align-items-baseline justify-content-start">
                        <div class="col-sm-3 fw-bold label">State Name :<span class="text-danger">*</span></div>
                        <mat-form-field class="col-sm-8 input-field">
                            <!-- <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea> -->
                            <mat-select [(ngModel)]="selectedState" (selectionChange)="getDistrictsList()">
                                <mat-option *ngFor="let state of states" [value]="state">{{state}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="d-flex col-md-4 col-sm-6 col-xs-12 flex-column flex-md-row align-items-baseline justify-content-start">
                        <div class=" col-sm-3 fw-bold label">City Name :<span class="text-danger">*</span></div>
                        <mat-form-field class="col-sm-8 input-field">
                            <!-- <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea> -->
                            <mat-select [(ngModel)]="selectedDistrict">
                                <mat-option *ngFor="let district of districts" [value]="district">{{district}} </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="btn1 col-md-2 col-sm-4 col-xs-6">
                        <button type="submit" class="btn btn-success col-sm m-4" (click)="AddWorkLocation()">Add</button>
                    </div>
                </div>
                <h1>Update Preferred Location</h1>
                <div class="row align-items-end">
                    <div class="d-flex col-md-4 col-sm-6 col-xs-12 flex-column flex-md-row align-items-baseline justify-content-start">
                        <div class=" col-sm-4 fw-bold label">Preferred City : </div>
                        <mat-form-field class="col-sm-7 input-field">
                            <mat-select [(ngModel)]="preferredCity">
                                <mat-option *ngFor="let city of getCityNames()" [value]="city">{{city}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="btn1 col-md-2 col-sm-4 col-xs-6">
                        <button type="submit" class="btn btn-success col-sm m-4" (click)="UpdatePreferredCity()">Update</button>
                    </div>
                </div>
            </mat-card>
            
        </mat-card>
    </div>

<mat-card class="m-2 p-4">
    <app-agent-table [data]="locationData" (removeCity)="removeCity($event)" [columnData]="columnData"
        [flag]="'ManageWorkLocation'"></app-agent-table>
</mat-card>
</ng-template>