<div class="container-fluid p-0">
    <div class="main">
        <div *ngIf="!getCabs && !selectedCar && !paymentOption" class="form-container">
            <mat-card class="mat_card">
                <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" [selectedIndex]="seletedTabIndex"
                    (selectedTabChange)="OnSelect($event)" #tabGroup class="mat-tabs">
                    <mat-tab class="booking_form" *ngFor="let trip of trip_type">
                        <ng-template mat-tab-label>
                            <ng-container *ngIf="!smallScreen">
                                {{trip.label}}
                            </ng-container>
                            <ng-container *ngIf="smallScreen">
                                <mat-icon class="mat_tab_icon">{{trip.mat_icon}}</mat-icon>
                            </ng-container>
                        </ng-template>
                        <form *ngIf="trip.label == 'ONE WAY'" [formGroup]="one_way_form">
                            <div class="d-flex flex-wrap p-3 trip-form">
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>FROM</mat-label>
                                        <input matInput placeholder="City" [formControl]="fromCityControlOneWay"
                                            formControlName="from" [matAutocomplete]="onewayfrom">
                                        <mat-autocomplete #onewayfrom="matAutocomplete" (optionSelected)="filterOneWayToCity()">

                                            <mat-option *ngFor="let city of filteredFromCitiesOneWay | async"
                                                [value]="city">
                                                {{ city }}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-hint>* Select district name</mat-hint>
                                        <mat-error
                                            *ngIf="one_way_form.get('from')?.hasError('required') && one_way_form.get('from')?.touched">Please
                                            fill above field...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>TO</mat-label>
                                        <input matInput placeholder="to location" [formControl]="toCityControlOneWay"
                                            formControlName="to" [matAutocomplete]="onewayto">
                                        <mat-autocomplete #onewayto="matAutocomplete" (optionSelected)="filterOneWayFromCity()">
                                            <mat-option *ngFor="let city of filteredToCitiesOneWay | async"
                                                [value]="city">
                                                {{ city }}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-hint>* Select district name</mat-hint>
                                        <mat-error
                                            *ngIf="one_way_form.get('to')?.hasError('required') && one_way_form.get('to')?.touched">Please
                                            fill above field...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>PICK UP DATE</mat-label>
                                        <input matInput [matDatepicker]="picker" formControlName="pickUpDate" readonly [matDatepickerFilter]="dateFilter"
                                            (focus)="openDatePicker(picker)">

                                        <mat-datepicker-toggle matIconSuffix [for]="picker">
                                            <mat-icon class="calender_menu" class="calender_menu"
                                                matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                        <mat-error
                                            *ngIf="one_way_form.get('pickUpDate')?.hasError('required') && one_way_form.get('pickUpDate')?.touched">Please
                                            select date...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>PICK UP TIME</mat-label>
                                        <mat-select formControlName="pickUpTime">
                                            <ng-container *ngFor="let time of pickUpTime">
                                                <mat-option  [value]="time"
                                                *ngIf="!isDisable(time)">{{time}}</mat-option>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="one_way_form.get('pickUpTime')?.hasError('required') && one_way_form.get('pickUpTime')?.touched">Please
                                            select suitable time...</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="d-flex mb-3" style="justify-content: center;">
                                <!-- <span class="ms-2 text text-danger">* Select district name</span> -->
                                <button type="button" class="btn btn-primary" (click)="exploreOneWayCabs()">Book Cab</button>
                            </div>
                        </form>

                        <form *ngIf="trip.label == 'LOCAL TRIP'" [formGroup]="local_form">
                            <div class="d-flex justify-content-center flex-wrap p-3 ">
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>FROM</mat-label>
                                        <input matInput placeholder="City" [formControl]="fromCityControlLocalWay"
                                            [matAutocomplete]="localwayfrom" formControlName="from" required>
                                        <mat-autocomplete #localwayfrom="matAutocomplete">
                                            <mat-option *ngFor="let city of filteredFromCitiesLocalWay | async"
                                                [value]="city">
                                                {{ city}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error
                                            *ngIf="local_form.get('from')?.hasError('required') && local_form.get('from')?.touched">Please
                                            fill above field...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>TO</mat-label>
                                        <input matInput placeholder="to location" [formControl]="toCityControlLocalWay"
                                            [matAutocomplete]="localwayto" formControlName="to" required>
                                        <mat-autocomplete #localwayto="matAutocomplete">
                                            <mat-option *ngFor="let city of filteredToCitiesLocalWay| async"
                                                [value]="city">
                                                {{ city}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error
                                            *ngIf="local_form.get('to')?.hasError('required') && local_form.get('to')?.touched">Please
                                            fill above field...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>PICK UP DATE</mat-label>
                                        <input matInput [matDatepicker]="picker" [matDatepickerFilter]="dateFilter"
                                            formControlName="pickUpDate" (focus)="openDatePicker(picker)" readonly>

                                        <mat-datepicker-toggle matIconSuffix [for]="picker">
                                            <mat-icon class="calender_menu"
                                                matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                        <mat-error
                                            *ngIf="local_form.get('pickUpDate')?.hasError('required') && local_form.get('pickUpDate')?.touched">Please
                                            select date...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>PICK UP TIME</mat-label>
                                        <mat-select formControlName="pickUpTime">
                                            <ng-container *ngFor="let time of pickUpTime">
                                                <mat-option  [value]="time"
                                                *ngIf="!isDisable(time)">{{time}}</mat-option>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="local_form.get('pickUpTime')?.hasError('required') && local_form.get('pickUpTime')?.touched">Please
                                            select suitable time...</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="d-flex button mb-3">
                                <button type="button" class="btn btn-primary" (click)="exploreLocalCabs()">Book
                                    Available
                                    Cab's</button>
                            </div>
                        </form>

                        <form *ngIf="trip.label == 'ROUND TRIP'" [formGroup]="round_form">
                            <div class="d-flex flex-wrap p-3 trip-form">
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>FROM</mat-label>
                                        <input matInput placeholder="City" [formControl]="fromCityControlRoundWay"
                                            [matAutocomplete]="roundwayfrom" formControlName="from" required>
                                        <mat-autocomplete #roundwayfrom="matAutocomplete" (optionSelected)="filterRoundWayToCity()">
                                            <mat-option *ngFor="let city of filteredFromCitiesRoundWay | async"
                                                [value]="city">
                                                {{ city}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error
                                            *ngIf="round_form.get('from')?.hasError('required') && round_form.get('from')?.touched">Please
                                            fill above field...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>TO</mat-label>
                                        <input matInput placeholder="to location" [formControl]="toCityControlRoundWay"
                                            [matAutocomplete]="roundwayto" formControlName="to" required>
                                        <mat-autocomplete #roundwayto="matAutocomplete">
                                            <mat-option *ngFor="let city of filteredToCitiesRoundWay | async"
                                                [value]="city">
                                                {{ city}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error
                                            *ngIf="round_form.get('to')?.hasError('required') && round_form.get('to')?.touched">Please
                                            fill above field...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>PICK UP DATE</mat-label>
                                        <input matInput #pickupDateRef [matDatepicker]="picker1" [matDatepickerFilter]="dateFilter"
                                            (dateChange)="PickupDateChange(pickupDateRef.value)"
                                            formControlName="pickUpDate" (focus)="openDatePicker(picker1)" readonly>

                                        <mat-datepicker-toggle matIconSuffix [for]="picker1">
                                            <mat-icon class="calender_menu"
                                                matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                        <mat-error
                                            *ngIf="round_form.get('pickUpDate')?.hasError('required') && round_form.get('pickUpDate')?.touched">Please
                                            select date...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>PICK UP TIME</mat-label>
                                        <mat-select formControlName="pickUpTime">
                                            <ng-container *ngFor="let time of pickUpTime">
                                                <mat-option  [value]="time"
                                                *ngIf="!isDisable(time)">{{time}}</mat-option>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="round_form.get('pickUpTime')?.hasError('required') && round_form.get('pickUpTime')?.touched">Please
                                            select time...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>RETURN DATE</mat-label>
                                        <input matInput [matDatepicker]="picker" formControlName="returnDate" [min]="minReturnDate"
                                            (focus)="openDatePicker(picker)" readonly>

                                        <mat-datepicker-toggle matIconSuffix [for]="picker">
                                            <mat-icon class="calender_menu"
                                                matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                        <mat-error
                                            *ngIf="round_form.get('returnDate')?.hasError('required') && round_form.get('returnDate')?.touched">Please
                                            select date...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>RETURN TIME</mat-label>
                                        <mat-select formControlName="returnTime">
                                            <ng-container *ngFor="let time of pickUpTime">
                                                <mat-option  [value]="time"
                                                *ngIf="!isDisable(time)">{{time}}</mat-option>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="round_form.get('returnTime')?.hasError('required') && round_form.get('returnTime')?.touched">Please
                                            select time...</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="d-flex button mb-3">
                                <button type="button" class="btn btn-primary" (click)="exploreRoundCabs()">Book
                                    Available
                                    Cab's</button>
                            </div>
                        </form>

                        <form *ngIf="trip.label == 'AIRPORT'" [formGroup]="airport_form">
                            <div class="d-flex flex-wrap p-3">
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>CHOOSE TRIP</mat-label>
                                        <mat-select (selectionChange)="onAirportTripTypeChange($event)"
                                            formControlName="trip">
                                            <mat-option *ngFor="let trip of airport_trip" [value]="trip">{{ trip
                                                }}</mat-option>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="airport_form.get('trip')?.hasError('required') && airport_form.get('trip')?.touched">
                                            Please select trip type...
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label *ngIf="airportType === 'PICKUP FROM AIRPORT'">PICKUP AIRPORT
                                            NAME</mat-label>
                                        <mat-label *ngIf="airportType === 'DROP TO AIRPORT'">PICKUP ADDRESS</mat-label>
                                        <input matInput placeholder="pickup from" formControlName="from" required>
                                        <mat-error
                                            *ngIf="airport_form.get('from')?.hasError('required') && airport_form.get('from')?.touched">Please
                                            fill above field...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label *ngIf="airportType === 'PICKUP FROM AIRPORT'">DROP POINT</mat-label>
                                        <mat-label *ngIf="airportType === 'DROP TO AIRPORT'">DROP AIRPORT
                                            NAME</mat-label>
                                        <input matInput placeholder="drop address" formControlName="to" required>
                                        <mat-error
                                            *ngIf="airport_form.get('to')?.hasError('required') && airport_form.get('to')?.touched">Please
                                            fill above field...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>TERMINAL NAME</mat-label>
                                        <input matInput placeholder="pickUp terminal name"
                                            formControlName="terminal_name" required>
                                        <mat-error
                                            *ngIf="airport_form.get('terminal_name')?.hasError('required') && airport_form.get('terminal_name')?.touched">Please
                                            fill above field...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>PICK UP DATE</mat-label>
                                        <input matInput [matDatepicker]="picker1" [matDatepickerFilter]="dateFilter"
                                            formControlName="pickUpDate" (focus)="openDatePicker(picker1)" readonly>
                                        <mat-datepicker-toggle matIconSuffix [for]="picker1">
                                            <mat-icon class="calender_menu"
                                                matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                        <mat-error
                                            *ngIf="airport_form.get('pickUpDate')?.hasError('required') && airport_form.get('pickUpDate')?.touched">Please
                                            select date...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>PICK UP TIME</mat-label>
                                        <mat-select formControlName="pickUpTime">
                                            <ng-container *ngFor="let time of pickUpTime">
                                                <mat-option  [value]="time"
                                                *ngIf="!isDisable(time)">{{time}}</mat-option>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="airport_form.get('pickUpTime')?.hasError('required') && airport_form.get('pickUpTime')?.touched">Please
                                            select time...</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="d-flex button mb-3">
                                <button type="button" class="btn btn-primary" (click)="exploreAirportCabs()">Book
                                    Available Cab's</button>
                            </div>
                        </form>

                        <!-- <form *ngIf="trip.label == 'BULK BOOKING'" [formGroup]="bulk_form">
                            <div class="d-flex flex-wrap p-3">
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>NAME</mat-label>
                                        <input matInput placeholder="enter name" formControlName="user_name" required>
                                        <mat-error
                                            *ngIf="bulk_form.get('user_name')?.hasError('required') && bulk_form.get('user_name')?.touched">Please
                                            fill above field...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>EMAIL ID</mat-label>
                                        <input matInput placeholder="email address" formControlName="user_email_id"
                                            required>
                                        <mat-error
                                            *ngIf="bulk_form.get('user_email_id')?.hasError('required') && bulk_form.get('user_email_id')?.touched">Please
                                            fill above field...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>MOBILE NO.</mat-label>
                                        <input type="tel" matInput placeholder="enter mobile no."
                                            formControlName="user_mobile" required pattern="[0-9]{10}">
                                        <mat-error
                                            *ngIf="bulk_form.get('user_mobile')?.hasError('required') && bulk_form.get('user_mobile')?.touched">Please
                                            fill above field...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <div class="d-grid justify-content-center">
                                        <div class="input-container d-flex flex-row justify-content-center">
                                            <input type="tel" class="m-1 form-control rounded opt_input" maxlength="1"
                                                style="width: 2.5rem;" appNumericOnly>
                                            <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                                                style="width: 2.5rem;" appNumericOnly>
                                            <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                                                style="width: 2.5rem;" appNumericOnly>
                                            <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                                                style="width: 2.5rem;" appNumericOnly>
                                            <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                                                style="width: 2.5rem;" appNumericOnly>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>FROM</mat-label>
                                        <input matInput placeholder="City" [formControl]="fromCityControlBulkWay"
                                            [matAutocomplete]="bulkwayfrom" formControlName="from" required>
                                        <mat-autocomplete #bulkwayfrom="matAutocomplete">
                                            <mat-option *ngFor="let city of filteredFromCitiesBulkWay | async"
                                                [value]="city">
                                                {{ city }}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error
                                            *ngIf="bulk_form.get('from')?.hasError('required') && bulk_form.get('from')?.touched">Please
                                            fill above field...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>TO</mat-label>
                                        <input matInput placeholder="to location" [formControl]="toCityControlBulkWay"
                                            formControlName="to" [matAutocomplete]="bulkwayto">
                                        <mat-autocomplete #bulkwayto="matAutocomplete">
                                            <mat-option *ngFor="let city of filteredToCitiesBulkWay | async"
                                                [value]="city">
                                                {{ city }}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error
                                            *ngIf="bulk_form.get('to')?.hasError('required') && bulk_form.get('to')?.touched">Please
                                            fill above field...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>PICK UP DATE</mat-label>
                                        <input matInput [matDatepicker]="picker1" [matDatepickerFilter]="dateFilter"
                                            formControlName="pickUpDate" (focus)="openDatePicker(picker1)" readonly>

                                        <mat-datepicker-toggle matIconSuffix [for]="picker1">
                                            <mat-icon class="calender_menu"
                                                matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                        <mat-error
                                            *ngIf="bulk_form.get('pickUpDate')?.hasError('required') && bulk_form.get('pickUpDate')?.touched">Please
                                            select date...</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-form-field>
                                        <mat-label>PICK UP TIME</mat-label>
                                        <mat-select formControlName="pickUpTime">
                                            <ng-container *ngFor="let time of pickUpTime">
                                                <mat-option  [value]="time"
                                                *ngIf="!isDisable(time)">{{time}}</mat-option>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="bulk_form.get('pickUpTime')?.hasError('required') && bulk_form.get('pickUpTime')?.touched">Please
                                            select time...</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="d-flex button mb-3">
                                <button type="button" class="btn btn-primary" (click)="exploreBulkCabs()">Book Available
                                    Cab's</button>
                            </div>
                        </form> -->
                    </mat-tab>
                </mat-tab-group>
            </mat-card>
        </div>

        <div *ngIf="getCabs && !selectedCar && !paymentOption">
            <app-explore-cabs [formData]="all_form_data" [availableCabs]="availableCabs" (goback)="modifyTrip($event)"
                (modifySelectedTrip)="modifyTripType($event)" [selected_trip]='selected_trip'
                (getDetails)="getDetails($event)" [locations_data]="locations_data"></app-explore-cabs>
        </div>

        <div *ngIf="selectedCar && !getCabs">
            <div class="form-container ride-details">
                <div class="row" *ngIf="!paymentOption">
                    <div class="col-lg-6 card_1">
                        <mat-card>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-dark d-flex" (click)="goBack()"><mat-icon
                                        style="color: #fff;">keyboard_arrow_left</mat-icon>Back</button>
                            </div>
                            <mat-card-header>
                                <mat-card-title class="text-center">TRIP DETAILS</mat-card-title>
                                <form [formGroup]="final_details">
                                    <mat-card-content class="w-100">
                                        <div
                                            class="main_card d-flex col-sm-5 flex-column flex-md-row align-items-baseline justify-content-start w-100">
                                            <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">NAME :<span
                                                    class="text-danger">*</span></div>
                                            <mat-form-field class="col-sm-8 input-field">
                                                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                                                    formControlName="userName"></textarea>
                                                <mat-error
                                                    *ngIf="final_details.get('userName')?.hasError('required') && final_details.get('userName')?.touched">Please
                                                    fill above field...</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div
                                            class="d-flex col-sm-5 flex-column flex-md-row align-items-baseline justify-content-start w-100">
                                            <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">MOBILE :<span
                                                    class="text-danger">*</span></div>
                                            <mat-form-field class="col-sm-8 input-field">
                                                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                                                    formControlName="mobileNo" [readonly]="otpSent || isOTPVerified"
                                                    [(ngModel)]="phoneNumber" maxlength="10" type="tel"
                                                    appNumericOnly></textarea>
                                                <!-- <textarea matInput cdkTextareaAutosize [disabled]="otpSent || isOTPVerified" #autosize="cdkTextareaAutosize"
                                                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                                                    formControlName="mobileNo" [(ngModel)]="phoneNumber" *ngIf="otpSent || isOTPVerified" ></textarea> -->
                                                <mat-error
                                                    *ngIf="final_details.get('mobileNo')?.hasError('required') && final_details.get('mobileNo')?.touched">Please
                                                    fill above field...</mat-error>
                                                <mat-error
                                                    *ngIf="final_details.get('mobileNo')?.hasError('pattern') && final_details.get('mobileNo')?.touched">Invalid
                                                    mobile number format.</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="d-flex col-sm-5 flex-column flex-md-row align-items-baseline justify-content-start w-100 otp_section"
                                            *ngIf="otpSent && !isOTPVerified">
                                            <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">OTP :<span
                                                    class="text-danger">*</span></div>
                                            <div
                                                class="col-sm-8 input-field input-container d-flex flex-row justify-content-center">
                                                <input type="tel" class="m-1 form-control rounded opt_input"
                                                    maxlength="1" style="width: 2.5rem;" [(ngModel)]="digit1"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (input)="moveToNext($event, 2)" appNumericOnly>
                                                <input type="tel" class="m-1 form-control rounded opt_input"
                                                    maxlength="1" style="width: 2.5rem;" [(ngModel)]="digit2"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (input)="moveToNext($event, 3)" appNumericOnly>
                                                <input type="tel" class="m-1 form-control rounded opt_input"
                                                    maxlength="1" style="width: 2.5rem;" [(ngModel)]="digit3"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (input)="moveToNext($event, 4)" appNumericOnly>
                                                <input type="tel" class="m-1 form-control rounded opt_input"
                                                    maxlength="1" style="width: 2.5rem;" [(ngModel)]="digit4"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (input)="moveToNext($event, 5)" appNumericOnly>
                                                <input type="tel" class="m-1 form-control rounded opt_input"
                                                    maxlength="1" style="width: 2.5rem;" [(ngModel)]="digit5"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (input)="moveToNext($event, 6)" appNumericOnly>
                                                <input type="tel" class="m-1 form-control rounded opt_input"
                                                    maxlength="1" style="width: 2.5rem;" [(ngModel)]="digit6"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (input)="moveToNext($event, null)" appNumericOnly>
                                            </div>
                                        </div>
                                        <div *ngIf="!otpSent && !isOTPVerified">
                                            <button class="btn btn-primary" (click)="SendOTP()"
                                                [disabled]="!isMobileNumberValid()">Send OTP</button>
                                        </div>
                                        <div *ngIf="otpSent && !isOTPVerified">
                                            <button class="btn btn-primary" (click)="verifyOtp()" [disabled]="!activateLoginBtn">Verify OTP</button>
                                            <button class="btn btn-primary ms-2" (click)="ResendOTP()">Resend OTP</button>
                                        </div>
                                        <div *ngIf="isOTPVerified">
                                            <div class="wrapper"> <svg class="checkmark"
                                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                    <circle class="checkmark__circle" cx="26" cy="26" r="25"
                                                        fill="none" />
                                                    <path class="checkmark__check" fill="none"
                                                        d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div
                                            class="d-flex col-sm-5 flex-column flex-md-row align-items-baseline justify-content-start w-100">
                                            <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">PICKUP ADDRESS
                                                :<span class="text-danger">*</span></div>
                                            <mat-form-field class="col-sm-8 input-field">
                                                <!-- <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                                                    formControlName="pickUpAddress" pickUpAddress></textarea> -->
                                                <input type="text" placeholder="Pickup Address" aria-label="Pickup Address"
                                                    (input)="getPredictions('Pickup')" matInput formControlName="pickUpAddress"
                                                    [matAutocomplete]="auto">
                                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                    <mat-option [value]="option"
                                                        *ngFor="let option of pickupAddressOption">{{option}}</mat-option>
                                                </mat-autocomplete>
                                                <mat-error
                                                    *ngIf="final_details.get('pickUpAddress')?.hasError('required') && final_details.get('pickUpAddress')?.touched">Please
                                                    fill above field...</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div
                                            class="d-flex col-sm-5 flex-column flex-md-row align-items-baseline justify-content-start w-100">
                                            <div class="col-md-4 col-sm-6 col-xs-12 fw-bold label">DROP ADDRESS
                                                :<span class="text-danger">*</span></div>
                                            <mat-form-field class="col-sm-8 input-field">
                                                <!-- <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                                                    formControlName="dropAddress"></textarea> -->
                                                    <input type="text" placeholder="Drop Address" aria-label="Drop Address"
                                                    (input)="getPredictions('Drop')" matInput formControlName="dropAddress"
                                                    [matAutocomplete]="dropAuto">
                                                <mat-autocomplete autoActiveFirstOption #dropAuto="matAutocomplete">
                                                    <mat-option [value]="option"
                                                        *ngFor="let option of drpoAddressOption">{{option}}</mat-option>
                                                </mat-autocomplete>
                                                <mat-error
                                                    *ngIf="final_details.get('dropAddress')?.hasError('required') && final_details.get('dropAddress')?.touched">Please
                                                    fill above field...</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <button mat-flat-button class="btn btn-primary" [disabled]="!isOTPVerified"
                                            (click)="final_details_submit()">PROCEED</button>
                                    </mat-card-content>
                                </form>
                            </mat-card-header>
                        </mat-card>
                    </div>
                    <div class="col-lg-6 card_2">
                        <mat-card>
                            <mat-card-header class="p-0">
                                <div class="details-title">
                                    <mat-card-title>YOUR BOOKING DETAILS</mat-card-title>
                                </div>
                                <mat-card-content class="w-100" style="padding-top: 16px;">
                                    <div
                                        class="d-flex col-sm-5 flex-column flex-md-row align-items-baseline justify-content-start w-100">
                                        <div class="col-md-3 col-sm-6 col-xs-12 fw-bold label">Pickup City :</div>
                                        <div class="col-sm-8">
                                            <span>{{all_form_data.from}}</span>
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex col-sm-5 flex-column flex-md-row align-items-baseline justify-content-start w-100">
                                        <div class="col-md-3 col-sm-6 col-xs-12 fw-bold label">Drop City :</div>
                                        <div class="col-sm-8">
                                            <span>{{all_form_data.to}}</span>
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex col-sm-5 flex-column flex-md-row align-items-baseline justify-content-start w-100">
                                        <div class="col-md-3 col-sm-6 col-xs-12 fw-bold label">Trip Type :</div>
                                        <div class="col-sm-8 ">
                                            <span>{{all_form_data.trip_type}}</span>
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex col-sm-5 flex-column flex-md-row align-items-baseline justify-content-start w-100">
                                        <div class="col-md-3 col-sm-6 col-xs-12 fw-bold label">Pickup Date :</div>
                                        <div class="col-sm-8 ">
                                            <span>{{all_form_data.pickUpDate
                                                |
                                                date}} at {{all_form_data.pickUpTime}}</span>
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex col-sm-5 flex-column flex-md-row align-items-baseline justify-content-start w-100">
                                        <div class="col-md-3 col-sm-6 col-xs-12 fw-bold label">Car Type :</div>
                                        <div class="col-sm-8 ">
                                            <span>{{selected_cab_details.categoryName}}</span>
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex col-sm-5 flex-column flex-md-row align-items-baseline justify-content-start w-100">
                                        <div class="col-md-3 col-sm-6 col-xs-12 fw-bold label">Total Fare :</div>
                                        <div class="col-sm-8 ">
                                            <span>{{total_fare}}</span>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card-header>
                        </mat-card>
                        <mat-card class="mt-2">
                            <div class="details-content">
                                <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start"
                                    class="custom-tab-group mt-1">
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            <ng-container *ngIf="smallScreen; else INCLUSIONS">
                                                <mat-icon class="detils_icon">done_all</mat-icon>
                                            </ng-container>
                                            <ng-template #INCLUSIONS>
                                                INCLUSIONS
                                            </ng-template>
                                        </ng-template>
                                        <div class="inclusions">
                                            <h5 *ngIf="smallScreen" class="text-center">INCLUSIONS</h5>
                                            <div class="details">
                                                <img src="../../../assets/3952770-removebg-preview.png"
                                                    class="img-fluid" alt="">
                                                <p class="text">Night Charges</p>
                                            </div>
                                            <div class="details">
                                                <img src="../../../assets/transport-driver-conductor-peak-cap-600nw-143693560-removebg-preview.png"
                                                    class="img-fluid" alt="">
                                                <p class="text">Driver Allowance</p>
                                            </div>
                                            <div class="details">
                                                <img src="../../../assets/gst-vector-icon-white-gst-vector-icon-white-eps-file-easy-to-edit-139556691-removebg-preview.png"
                                                    class="img-fluid" alt="">
                                                <p class="text">GST</p>
                                            </div>
                                        </div>
                                    </mat-tab>
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            <ng-container *ngIf="smallScreen; else EXCLUSIONS">
                                                <mat-icon class="detils_icon">block</mat-icon>
                                            </ng-container>
                                            <ng-template #EXCLUSIONS>
                                                EXCLUSIONS
                                            </ng-template>
                                        </ng-template>
                                        <div class="exclusions">
                                            <h5 *ngIf="smallScreen" class="text-center">EXCLUSIONS</h5>
                                            <div class="details">
                                                <img src="../../../assets/free-toll-1817203-1538074-removebg-preview.png"
                                                    class="img-fluid" alt="">
                                                <p class="text">Toll and State Tax</p>
                                            </div>
                                            <div class="details">
                                                <img src="../../../assets/5388823-removebg-preview.png"
                                                    class="img-fluid" alt="">
                                                <p class="text">Parking</p>
                                            </div>
                                        </div>
                                    </mat-tab>
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            <ng-container *ngIf="smallScreen; else FACILITIES">
                                                <mat-icon class="detils_icon">check_circle</mat-icon>
                                            </ng-container>
                                            <ng-template #FACILITIES>
                                                FACILITIES
                                            </ng-template>
                                        </ng-template>
                                        <div class="facilities">
                                            <h5 *ngIf="smallScreen" class="text-center">FACILITIES</h5>
                                            <div class="details">
                                                <img src="../../../assets/pngtree-car-seat-png-image_1511320-removebg-preview.png"
                                                    class="img-fluid" alt="">
                                                <p class="text">{{selected_cab_details.NumberOfSeats}} Seaters</p>
                                            </div>
                                            <div class="details">
                                                <img src="../../../assets/travel-suitcase-icon-cartoon-bag-removebg-preview.png"
                                                    class="img-fluid" alt="">
                                                <p class="text">2 Bags</p>
                                            </div>
                                            <div class="details">
                                                <img src="../../../assets/kisspng-illustration-air-conditioning-air-conditioners-vec--5b7c67a1334159.25137320153487964921-removebg-preview.png"
                                                    class="img-fluid" alt="">
                                                <p class="text">AC</p>
                                            </div>
                                        </div>
                                    </mat-tab>
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            <ng-container *ngIf="smallScreen; else TC">
                                                <mat-icon class="detils_icon">description</mat-icon>
                                            </ng-container>
                                            <ng-template #TC>
                                                T&C
                                            </ng-template>
                                        </ng-template>
                                        <ul class="p-0">
                                            <h5 *ngIf="smallScreen" class="text-center">T&C</h5>
                                            <li>Your Trip has a KM limit as well as an Hours limit. If your usage
                                                exceeds
                                                these limits,
                                                you will be charged for the excess KM and/or hours used.</li>
                                            <li>The KM and Hour(s) usage will be calculated starting from your pick-up
                                                point
                                                and back to
                                                the pick-up point.</li>
                                            <li>All road toll fees, parking charges, state taxes etc. if applicable will
                                                be
                                                charged
                                                extra and need to be paid to the concerned authorities as per actuals.
                                            </li>
                                            <li>The Airport entry charge, if applicable, is not included in the fare and
                                                will be charged
                                                extra.</li>
                                        </ul>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </mat-card>
                    </div>
                </div>
                <div class="row" *ngIf="paymentOption">
                    <div class="card_2">
                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn btn-dark d-flex" (click)="goBack()"><mat-icon
                                    style="color: #fff;">keyboard_arrow_left</mat-icon>Back</button>
                        </div>
                        <mat-card>
                            <mat-card-header class="p-0">
                                <div class="details-title">
                                    <mat-card-title>YOUR FINAL DETAILS</mat-card-title>
                                </div>
                                <mat-card-content class="w-100" style="padding-top: 16px;">
                                    <div class="row">
                                        <div class="d-flex col-md-6">
                                            <div class="col-md-4 fw-bold label">Name :</div>
                                            <span>{{final_booking_data[0]['customer_name']}}</span>
                                        </div>
                                        <div class="d-flex col-md-6">
                                            <div class="col-md-4 fw-bold label">Mobile No. :</div>
                                            <span>{{final_booking_data[0]['customer_number']}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="d-flex col-md-6">
                                            <div class="col-md-4 fw-bold label">Pickup Address :</div>
                                            <span>{{final_booking_data[0]['booking_from']['landmark']}},
                                                {{final_booking_data[0]['booking_from']['city']}}</span>
                                        </div>
                                        <div class="d-flex col-md-6">
                                            <div class="col-md-4 fw-bold label">Pickup Date :</div>
                                            <span>{{final_booking_data[0]['pickup_date'] | date}} at
                                                {{final_booking_data[0]['pickup_time']}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="d-flex col-md-6">
                                            <div class="col-md-4 fw-bold label">Drop Address :</div>
                                            <span>{{final_booking_data[0]['booking_to']['landmark']}},
                                                {{final_booking_data[0]['booking_to']['city']}}</span>
                                        </div>
                                        <div class="d-flex col-md-6">
                                            <div class="col-md-4 fw-bold label">Total Kilometers :</div>
                                            <span>{{final_booking_data[0]['kmsOfRide']}} KM</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="d-flex col-md-6">
                                            <div class="col-md-4 fw-bold label">Car Category :</div>
                                            <span>{{final_booking_data[0]['cabCategoryName']}}</span>
                                        </div>
                                        <div class="d-flex col-md-6">
                                            <div class="col-md-4 fw-bold label">Total Amount :</div>
                                            <span>{{final_booking_data[0]['totalAmount']}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="d-flex col-md-6">
                                            <div class="col-md-4 fw-bold label">Trip Type :</div>
                                            <span>{{final_booking_data[0]['tripType']}}</span>
                                        </div>
                                        <div class="d-flex col-md-6" *ngIf="final_booking_data[0]['return_date']">
                                            <div class="col-md-4 fw-bold label">Return Date :</div>
                                            <span>{{final_booking_data[0]['return_date'] | date}} at
                                                {{final_booking_data[0]['return_time']}}</span>
                                        </div>
                                    </div>
                                </mat-card-content>
                                <button mat-flat-button (click)="book_ride()"
                                    style="width: 40%;margin-bottom: 1rem;">Book Ride</button>
                            </mat-card-header>
                        </mat-card>
                        <mat-card class="mt-2">
                            <div class="details-content">
                                <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start"
                                    class="custom-tab-group mt-1">
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            <ng-container *ngIf="smallScreen; else INCLUSIONS">
                                                <mat-icon class="detils_icon">done_all</mat-icon>
                                            </ng-container>
                                            <ng-template #INCLUSIONS>
                                                INCLUSIONS
                                            </ng-template>
                                        </ng-template>
                                        <div class="inclusions">
                                            <h5 *ngIf="smallScreen" class="text-center">INCLUSIONS</h5>
                                            <div class="details">
                                                <img src="../../../assets/3952770-removebg-preview.png"
                                                    class="img-fluid" alt="">
                                                <p class="text">Night Charges</p>
                                            </div>
                                            <div class="details">
                                                <img src="../../../assets/transport-driver-conductor-peak-cap-600nw-143693560-removebg-preview.png"
                                                    class="img-fluid" alt="">
                                                <p class="text">Driver Allowance</p>
                                            </div>
                                            <div class="details">
                                                <img src="../../../assets/gst-vector-icon-white-gst-vector-icon-white-eps-file-easy-to-edit-139556691-removebg-preview.png"
                                                    class="img-fluid" alt="">
                                                <p class="text">GST</p>
                                            </div>
                                        </div>
                                    </mat-tab>
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            <ng-container *ngIf="smallScreen; else EXCLUSIONS">
                                                <mat-icon class="detils_icon">block</mat-icon>
                                            </ng-container>
                                            <ng-template #EXCLUSIONS>
                                                EXCLUSIONS
                                            </ng-template>
                                        </ng-template>
                                        <div class="exclusions">
                                            <h5 *ngIf="smallScreen" class="text-center">EXCLUSIONS</h5>
                                            <div class="details">
                                                <img src="../../../assets/free-toll-1817203-1538074-removebg-preview.png"
                                                    class="img-fluid" alt="">
                                                <p class="text">Toll and State Tax</p>
                                            </div>
                                            <div class="details">
                                                <img src="../../../assets/5388823-removebg-preview.png"
                                                    class="img-fluid" alt="">
                                                <p class="text">Parking</p>
                                            </div>
                                        </div>
                                    </mat-tab>
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            <ng-container *ngIf="smallScreen; else FACILITIES">
                                                <mat-icon class="detils_icon">check_circle</mat-icon>
                                            </ng-container>
                                            <ng-template #FACILITIES>
                                                FACILITIES
                                            </ng-template>
                                        </ng-template>
                                        <div class="facilities">
                                            <h5 *ngIf="smallScreen" class="text-center">FACILITIES</h5>
                                            <div class="details">
                                                <img src="../../../assets/pngtree-car-seat-png-image_1511320-removebg-preview.png"
                                                    class="img-fluid" alt="">
                                                <p class="text">{{selected_cab_details.NumberOfSeats}} Seaters</p>
                                            </div>
                                            <div class="details">
                                                <img src="../../../assets/travel-suitcase-icon-cartoon-bag-removebg-preview.png"
                                                    class="img-fluid" alt="">
                                                <p class="text">2 Bags</p>
                                            </div>
                                            <div class="details">
                                                <img src="../../../assets/kisspng-illustration-air-conditioning-air-conditioners-vec--5b7c67a1334159.25137320153487964921-removebg-preview.png"
                                                    class="img-fluid" alt="">
                                                <p class="text">AC</p>
                                            </div>
                                        </div>
                                    </mat-tab>
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            <ng-container *ngIf="smallScreen; else TC">
                                                <mat-icon class="detils_icon">description</mat-icon>
                                            </ng-container>
                                            <ng-template #TC>
                                                T&C
                                            </ng-template>
                                        </ng-template>
                                        <ul class="p-0">
                                            <h5 *ngIf="smallScreen" class="text-center">T&C</h5>
                                            <li>Your Trip has a KM limit as well as an Hours limit. If your usage
                                                exceeds
                                                these limits,
                                                you will be charged for the excess KM and/or hours used.</li>
                                            <li>The KM and Hour(s) usage will be calculated starting from your pick-up
                                                point
                                                and back to
                                                the pick-up point.</li>
                                            <li>All road toll fees, parking charges, state taxes etc. if applicable will
                                                be
                                                charged
                                                extra and need to be paid to the concerned authorities as per actuals.
                                            </li>
                                            <li>The Airport entry charge, if applicable, is not included in the fare and
                                                will be charged
                                                extra.</li>
                                        </ul>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main_content mt-4" *ngIf="!getCabs && !selectedCar && !paymentOption">
        <div class="section d-flex justify-content-center">
            <h1 class="service_title">Customer Services</h1>
        </div>
        <div class="service_section">
            <div class="row justify-content-center">
                <div class="service col-12 col-sm-6 col-md-5 col-lg-4 mb-4">
                    <mat-card>
                        <mat-card-header class="d-flex flex-column">
                            <div class="d-flex">
                                <mat-icon mat-card-avatar>smartphone</mat-icon>
                                <mat-card-title class="ml-2">Mobile Phone Reservation</mat-card-title>
                            </div>
                            <div>
                                <mat-card-subtitle>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores enim laboriosam
                                    sapiente voluptatem sed eveniet sint perspiciatis porro ut est aperiam quas amet
                                    numquam, id consequuntur facere esse nemo ipsam!
                                </mat-card-subtitle>
                            </div>
                        </mat-card-header>
                    </mat-card>
                </div>
                <div class="service col-12 col-sm-6 col-md-5 col-lg-4 mb-4">
                    <mat-card>
                        <mat-card-header class="d-flex flex-column">
                            <div class="d-flex">
                                <mat-icon mat-card-avatar>drive_eta</mat-icon>
                                <mat-card-title class="ml-2">Special rates on car booking</mat-card-title>
                            </div>
                            <div>
                                <mat-card-subtitle>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores enim laboriosam
                                    sapiente voluptatem sed eveniet sint perspiciatis porro ut est aperiam quas amet
                                    numquam, id consequuntur facere esse nemo ipsam!
                                </mat-card-subtitle>
                            </div>
                        </mat-card-header>
                    </mat-card>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="service col-12 col-sm-6 col-md-5 col-lg-4 mb-4">
                    <mat-card>
                        <mat-card-header class="d-flex flex-column">
                            <div class="d-flex">
                                <mat-icon mat-card-avatar>arrow_forward</mat-icon>
                                <mat-card-title class="ml-2">One Way Car Rentals</mat-card-title>
                            </div>
                            <div>
                                <mat-card-subtitle>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores enim laboriosam
                                    sapiente voluptatem sed eveniet sint perspiciatis porro ut est aperiam quas amet
                                    numquam, id consequuntur facere esse nemo ipsam!
                                </mat-card-subtitle>
                            </div>
                        </mat-card-header>
                    </mat-card>
                </div>
                <div class="service col-12 col-sm-6 col-md-5 col-lg-4 mb-4">
                    <mat-card>
                        <mat-card-header class="d-flex flex-column">
                            <div class="d-flex">
                                <mat-icon mat-card-avatar>all_inclusive</mat-icon>
                                <mat-card-title class="ml-2">Unlimited Miles Car Rental</mat-card-title>
                            </div>
                            <div>
                                <mat-card-subtitle>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores enim laboriosam
                                    sapiente voluptatem sed eveniet sint perspiciatis porro ut est aperiam quas amet
                                    numquam, id consequuntur facere esse nemo ipsam!
                                </mat-card-subtitle>
                            </div>
                        </mat-card-header>
                    </mat-card>
                </div>
            </div>
        </div>

        <div class="choose_us section ">
            <div class="d-flex justify-content-center">
                <div class="d-grid justify-items-center">
                    <h1 style="font-size: 2.7rem;margin-top: 1rem;">Why Choose Us</h1>
                    <h4>Explore our first class car rental services</h4>
                </div>
            </div>

            <div class="row d-flex justify-content-around">
                <div class="col-lg-3 col-md-6 col-sm-12 mb-4">
                    <div class="text-center">
                        <mat-icon class="nav_icon">local_car_wash</mat-icon>
                        <h3>Variety of Car Brands</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean commodo ligula eget dolor.
                        </p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 mb-4">
                    <div class="text-center">
                        <mat-icon class="nav_icon">favorite_border</mat-icon>
                        <h3>Awesome Customer Support</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean commodo ligula eget dolor.
                        </p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 mb-4">
                    <div class="text-center">
                        <mat-icon class="nav_icon">sentiment_satisfied</mat-icon>
                        <h3>Best Rate Guarantee</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean commodo ligula eget dolor.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main_content mt-4" *ngIf="!getCabs && !selectedCar && !paymentOption">
        <div class="section d-flex justify-content-center">
            <h1 class="article_title">Articles & Tips</h1>
        </div>
        <div class="service_section">
            <div class="row justify-content-center">
                <div class="articles col-12 col-sm-6 col-md-5 col-lg-4 mb-4">
                    <div class="card h-100" style="width: 18rem;">
                        <img class="card-img-top" src="../../assets/IMG_3496bfree-960x636.jpg" alt="Card image cap">
                        <div class="card-body">
                            <h6 class="card-subtitle mb-2 text-muted">January 18, 2024 </h6>
                            <h4 class="card-title fw-bold">What TO Do if Your Rental Car Has Met With An Accident</h4>
                            <p class="card-text text-truncate">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                Tenetur laborum repellat dolorum qui nesciunt.</p>
                            <a href="#" class="btn p-0">Read More ></a>
                        </div>
                    </div>
                </div>
                <div class="articles col-12 col-sm-6 col-md-5 col-lg-4 mb-4">
                    <div class="card h-100" style="width: 18rem;">
                        <img class="card-img-top" src="../../assets/IMG_3496bfree-960x636.jpg" alt="Card image cap">
                        <div class="card-body">
                            <h6 class="card-subtitle mb-2 text-muted">January 18, 2024 </h6>
                            <h4 class="card-title fw-bold">On The Trail of 6 Best Foods in North America</h4>
                            <p class="card-text text-truncate">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                Tenetur laborum repellat dolorum qui nesciunt.</p>
                            <a href="#" class="btn p-0">Read More ></a>
                        </div>
                    </div>
                </div>
                <div class="articles col-12 col-sm-6 col-md-5 col-lg-4 mb-4">
                    <div class="card h-100" style="width: 18rem;">
                        <img class="card-img-top" src="../../assets/IMG_3496bfree-960x636.jpg" alt="Card image cap">
                        <div class="card-body">
                            <h6 class="card-subtitle mb-2 text-muted">January 18, 2024 </h6>
                            <h4 class="card-title fw-bold">Car Rental Mistakes That Can Cost You Big</h4>
                            <p class="card-text text-truncate">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                Tenetur laborum repellat dolorum qui nesciunt.</p>
                            <a href="#" class="btn p-0">Read More ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>