import { Component } from '@angular/core';

@Component({
  selector: 'app-driver-earning',
  templateUrl: './driver-earning.component.html',
  styleUrls: ['./driver-earning.component.scss']
})
export class DriverEarningComponent {
   columnData = [
    { field: "driverName", label: "Driver Name" },
    { field: "driverContact", label: "Driver Contact" },
    { field: "driverAccountNumber", label: "Driver Account Number" },
    { field: "totalRideAmount", label: "A=Total Ride Amount (Customer Fare)" },
    { field: "siteCommission", label: "B=Site Commission" },
    { field: "driverOrAgentFare", label: "C=Driver/Travel Agent Fare (A-B)" },
    { field: "paymentInCashReceived", label: "D=Payment in Cash Received" },
    { field: "settlementAmount", label: "E=Settlement Amount (C-D)" },
    { field: "details", label: "Details" }
  ];
  
tableData=[
  {
    driverName: "John Doe",
    driverContact: "123-456-7890",
    driverAccountNumber: "ABC123",
    totalRideAmount: 50,
    siteCommission: 5,
    driverOrAgentFare: 45,
    paymentInCashReceived: 30,
    settlementAmount: 15,
    details:""},
    {
    driverName: "Jane Smith",
    driverContact: "987-654-3210",
    driverAccountNumber: "XYZ789",
    totalRideAmount: 40,
    siteCommission: 4,
    driverOrAgentFare: 36,
    paymentInCashReceived: 25,
    settlementAmount: 11,
    details:""},
  {
    driverName: "Robert Johnson",
    driverContact: "555-123-4567",
    driverAccountNumber: "MNO456",
    totalRideAmount: 60,
    siteCommission: 6,
    driverOrAgentFare: 54,
    paymentInCashReceived: 40,
    settlementAmount: 14,
    details:""},
  {
    driverName: "Emily Davis",
    driverContact: "111-222-3333",
    driverAccountNumber: "PQR789",
    totalRideAmount: 70,
    siteCommission: 7,
    driverOrAgentFare: 63,
    paymentInCashReceived: 50,
    settlementAmount: 13,
    details:""},
   
  {
    driverName: "Michael Brown",
    driverContact: "333-444-5555",
    driverAccountNumber: "STU001",
    totalRideAmount: 55,
    siteCommission: 5.5,
    driverOrAgentFare: 49.5,
    paymentInCashReceived: 35,
    settlementAmount: 14.5,
    details:""}
]
}
