import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomerComponent } from 'src/app/customer/customer.component';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgentServiceService } from 'src/app/services/agent-service.service';

@Component({
  selector: 'app-agent-login',
  templateUrl: './agent-login.component.html',
  styleUrls: ['./agent-login.component.scss']
})
export class AgentLoginComponent {
  loginActivated: boolean = false;
  source: string = '';
  headerText: string = "";
  registrationUrl: string = "";
  successUrl: string = "";
  chooseLoginSignup: boolean = true;
  activateLoginBtn : boolean = false;
  otpVerified: boolean = false;
  password: string = '';
  phoneNumber: string = "";
  otpSent: boolean = false;
  orderId: any;
  digit1: string = '';
  digit2: string = '';
  digit3: string = '';
  digit4: string = '';
  digit5: string = '';
  digit6: string = '';
  constructor(private adminservice: AdminService, private agentService: AgentServiceService, private snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) private data: any,private dialogRef: MatDialogRef<CustomerComponent>, private router: Router){
    console.log(data);
    this.checkLoginStatus();
    if(data.title == 'admin_login'){
      this.headerText = "Admin Login";
      this.registrationUrl = "";
      this.successUrl ='/adminDashboard'
      this.source = "Admin"
    }
    else if(data.title == 'agent_login'){
      this.headerText = "Partner Login";
      this.registrationUrl = '/register';
      this.successUrl = "/agent"
      this.source = "Agent"
    }
  }


  redirectAndClose(){
    this.router.navigate([this.registrationUrl]);
    this.dialogRef.close();
  }

  isMobileNumberValid(): boolean {
    // const phoneNumberString: string = this.phoneNumber;
    return this.phoneNumber.length === 10;
  }

  checkLoginStatus(){
    // localStorage.setItem("admin_auth", res.phoneNumber);
    // localStorage.setItem("admin_authExpiration", expirationDate.toString());  
    console.log("Checking login status")
    const auth = localStorage.getItem("agent_auth");
    const time = localStorage.getItem("agent_authExpiration");
    console.log("checked auth: ", auth, time);
    if (time && auth) {
      const date = new Date(time);
      console.log("time: ", time)
      if (date <= new Date()) {
        console.log("time: ", time)
        localStorage.removeItem('agent_auth');
        localStorage.removeItem('agent_authExpiration');
      } else {
        console.log("time: ", time)
        this.router.navigate(['/agent']);
      }
    }
  }

  async login(){
    console.log("Login Requested")
      if(this.source == 'Admin') {
        this.adminservice.login(this.phoneNumber, this.password).subscribe((res: any) =>{
          this.dialogRef.close(this.phoneNumber)
        },(error) => {
          console.log(error);
          this.snackBar.open(error.error.message, 'Close', { duration: 3000 })
        })
      }
      else if(this.source == 'Agent'){
        let isOTPVerified = await this.verifyOtp();
        console.log("Login: ",this.phoneNumber, isOTPVerified)

        if(isOTPVerified){
          this.otpVerified = true;
          this.agentService.agent_login(this.phoneNumber).subscribe((res: any) => {
            this.snackBar.open("Agent Login successfull!", 'Close', { duration: 3000 })

            const expirationDate = new Date(new Date().getTime() + 20 * 60 * 60 * 1000);
            localStorage.setItem("agent_auth", res.agentId);
            localStorage.setItem("agent_authExpiration", expirationDate.toString());  

            this.router.navigate(['/agent']);
            this.dialogRef.close();
          },(error)=> {
            console.log(error);
            this.snackBar.open(error.error.msg, 'Close', { duration: 3000 })
          })
        }
        else{
          this.snackBar.open("Otp Verification failed, Please try again!", 'Close', { duration: 3000 })
        }
      }
  }

  

  //OTP Segment
  SendOTP() {
    this.otpSent = true;
    this.adminservice.getOTPValue(parseInt(this.phoneNumber)).subscribe((res: any) => {
      this.orderId = res.orderId;
      this.snackBar.open("OTP Sent successfully!", 'Close', { duration: 3000 })
    }, (err) => {
      this.snackBar.open(err.error.error, 'Close', { duration: 3000 });
    })
  }
  async verifyOtp(): Promise<boolean> {
    const otp: number = parseInt(this.digit1 + this.digit2 + this.digit3 + this.digit4 + this.digit5 + this.digit6, 10);
    return this.adminservice.getOTPVerified(this.orderId, otp, parseInt(this.phoneNumber)).toPromise()
      .then((res: any) => {
        this.snackBar.open("OTP verified successfully !", 'Close', { duration: 3000 });
        return res.isOTPVerified;
      })
      .catch(error => {
        this.snackBar.open(error, 'Close', { duration: 3000 });
        return false;
      });
  }

  ResendOTP() {
    this.adminservice.getReSendOTP(this.orderId).subscribe((res: any) => {
      this.orderId = res.orderId;
      this.snackBar.open("OTP resend successfull !", 'Close', { duration: 3000 });
    }, (err) => {
      this.snackBar.open(err.error.error, 'Close', { duration: 3000 })
    })
  }

  Cancel(){
    this.phoneNumber = " ";
    this.otpSent = false;
    this.digit1 = '';
    this.digit2 = '';
    this.digit3 = '';
    this.digit4 = '';
    this.digit5 = '';
    this.digit6 = '';
    this.dialogRef.close();
  }

  moveToNext(event: any, nextInputIndex: number | null): void {
    const input = event.target as HTMLInputElement;
    const maxLength = parseInt(input.getAttribute('maxlength') || '1', 10);
    const currentLength = input.value.length;

    if (currentLength >= maxLength && nextInputIndex !== null) {
      const nextInput = document.querySelectorAll('.opt_input')[nextInputIndex - 1] as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    }
    else{
      this.activateLoginBtn = true;
    }
  }
}
