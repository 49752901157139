import { Component } from '@angular/core';
import { AgentServiceService } from 'src/app/services/agent-service.service';
import { AdminService } from 'src/app/services/admin.service';
import {
  MatSnackBar,  MatSnackBarHorizontalPosition} from '@angular/material/snack-bar';
import { duration } from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-driver',
  templateUrl: './manage-driver.component.html',
  styleUrls: ['./manage-driver.component.scss']
})
export class ManageDriverComponent {

  otpSent: boolean = false;
  showLoader = true;
  otp: string = "";
  agentId: string = "";
  phoneNumber: string = "";
  orderId:any
  errorMessage: string = '';
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  columnData = [
    { field: "DriverName", label: "Driver Name" },
    { field: "PhoneNumber", label: "Phone Number" },
    { field: "Status", label: "Status"},
    { field: "Action", label: "Action" },
   
  ];
  constructor(private _service: AgentServiceService, private adminservice: AdminService, private _snackBar: MatSnackBar, private router: Router) {
    this.checkLoginStatus();
  }

  checkLoginStatus() {
    // localStorage.setItem("admin_auth", res.phoneNumber);
    // localStorage.setItem("admin_authExpiration", expirationDate.toString());  
    console.log("Checking login status")
    const auth = localStorage.getItem("agent_auth");
    const time = localStorage.getItem("agent_authExpiration");
    console.log("checked auth: ", auth, time);
    if (time && auth) {
      const date = new Date(time);
      console.log("time: ", time)
      if (date <= new Date()) {
        console.log("time: ", time)
        localStorage.removeItem('agent_auth');
        localStorage.removeItem('agent_authExpiration');
      }
      else {
        this.agentId = auth;
      }
    }
    else {
      this.router.navigate(['/']);
    }
  }

 tableData : DriverData[] = [];
 openSncakBar(message:string){
  this._snackBar.open(message,'ok',{
    horizontalPosition: this.horizontalPosition,
    duration:3000
  })
 }

  toggleOtpSent(value: boolean){
    // const phone: number = this.phoneNumber;
    if(this.phoneNumber.length == 10){
      this.otpSent = value;
      // console.log("OTP sent Value  :: ",phone)
      this.adminservice.getOTPValue(parseInt(this.phoneNumber)).subscribe((res:any)=>{
        this.orderId=res.orderId;
        this.openSncakBar("OTP sent successfully !");

      },(err)=>{
        this.openSncakBar(err.error.Message)
      })
    }
    else{
      this.openSncakBar("Please enter the corerct phone number")
    }
  }

  
  async AddDriver(){
    let otpreturnValue = await this.verifyOtp(parseInt(this.otp));
    if( otpreturnValue){
      this._service.linkDriver(this.agentId, this.phoneNumber).subscribe(async (res) => {
        console.log("Fetched Driver List :: ",res)
        this.fetchTableData();
        this.phoneNumber ="";
        this.otp="";
        this.otpSent = false;
      },
      (error) => {
        this.errorMessage = error.error.Message;
        this.openSncakBar(this.errorMessage)
      })
    }
  }


 
  async verifyOtp(otp: number): Promise<boolean> {
   
    return this.adminservice.getOTPVerified(this.orderId, otp, parseInt(this.phoneNumber)).toPromise()
        .then((res: any) => {
          this.openSncakBar("OTP verified successfully !");
            return res.isOTPVerified;
        })
        .catch(error => {
          this.openSncakBar(error);
            return false;
        });
  }

  selectedValue: any = '';
  allUser: any[] = ['Customer', 'Agent', 'Driver']
  changeFilterValue(filter: any) {
    console.log(filter);
   // console.log(this.tableData);
  }


  ngOnInit(){
    this.fetchTableData();
  }
  fetchTableData(){
    this._service.getAllDriverLinked(this.agentId).subscribe((res) => {
      console.log(res);
      this.tableData = res.data;
      this.showLoader = false;
    })
  }

  removeDriver(driverDetails: any){
    console.log(driverDetails);
    this._service.delinkDriver(this.agentId, driverDetails.PhoneNumber,"").subscribe((res) => {
      console.log(res);
      this.fetchTableData();
    })
  }
  isMobileNumberValid(): boolean {
    const phoneNumberString: string = this.phoneNumber.toString(); // Convert to string
    return phoneNumberString.length === 10;
}
Cancel(){
  this.phoneNumber ="";
  this.otpSent=false;
}
ResendOTP(){
  this.adminservice.getReSendOTP(this.orderId).subscribe((res:any)=>{
    this.orderId = res.orderId;
    this.openSncakBar("OTP resend successfull !");

  },(err)=>{
    this.openSncakBar(err.error.error)
  })
}

}

class DriverData {
  DriverName: string = "";
  PhoneNumber: string = "";
  Status: string="";
  Action: string = "";
}
