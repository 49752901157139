
export class pricingDetails {
    slab: Number = 0;
    companyCharges: Number = 0;
    peakmultiplier: Number = 0;
    perKMRate: Number = 0;
    extraKMcharges: Number = 0;
    nightCharges: Number = 0;
    driverAllowance: Number = 0;
    tax: Number = 0;
    twoWayFactor: Number = 0;
}