<mat-card style="margin-top: 1rem;box-shadow:  0px 0px 4px 0px #000000;margin-bottom: 2rem;">
    <mat-form-field>
        <mat-label>Search....</mat-label>
        <input matInput (keyup)="applyFilter($event)" #input>
        <mat-icon matSuffix class="search-icon">search</mat-icon>
    </mat-form-field>

    <div class="mat-elevation-z8 table-responsive">
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">
            <ng-container *ngFor="let column of columnData;let i=index" [matColumnDef]="column.field">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by column.label">
                    {{column.label}}</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="column.label != 'Status'">
                        <span
                            *ngIf="column.label != 'Ride History' && column.label !== 'Work Location' && column.label !='Pickup Date' && column.label !='Drop Date' && (column.field != 'DateTime') && (column.label != 'Transaction Date') && (column.label != 'Last Settlement Date') && (column.label !='Sent On')">
                            {{element[column.field]}}
                        </span>
                        <span
                            *ngIf="column.label=='Pickup Date' || column.label=='Drop Date'  || column.label=='Transaction Date' || column.label=='Last Settlement Date'">
                            {{element[column.field] | date}}
                        </span>

                    </span>
                    <div *ngIf="(flag === 'DriverRating' || flag ==='CustomerRating') && column.field=='DateTime'">
                        <span>
                            {{element[column.field] | date}}
                        </span>
                    </div>
                    <div *ngIf=" flag==='promo' || flag==='customer' ">
                        <span *ngIf="column.label=='Status' " (click)="changeStatus(element.status) "
                            [ngClass]="{ 'btn button1': true, 'btn-success': element.status==='active' , 'btn-danger': element.status==='inactive' } ">
                            {{element[column.field]}}
                        </span>
                    </div>
                    <div *ngIf="flag==='ride'">
                        <button *ngIf="element.status == 'Requested' && column.label=='Status'" (click)="ForwardRide(element)"
                            class="btn btn-primary">
                            <span>Forward</span>
                        </button>
                        <button *ngIf="element.status == 'Forwarded' && column.label=='Status'" (click)="ForwardRide(element)" class="text"
                            style="border: 1px solid blue; border-radius: 0.5rem; padding: 0.5rem; display: flex; justify-content: center; align-items: center;">
                            <span>Forwarded</span>
                        </button>

                        <button *ngIf="element.status == 'Assigned' && column.label=='Status'" (click)="CollectAdvance(element)" class="btn
                                                    btn-primary">
                            <span>Collect Advance</span>
                        </button>
                        <div *ngIf="element.status == 'Completed' && column.label=='Status'" (click)="ViewDetailsForBooking(element)"
                            class="text text-success">
                            <span>{{element.status}}</span>
                        </div>
                        <div *ngIf="element.status == 'Cancelled' && column.label=='Status'" (click)="ViewDetailsForBooking(element)"
                            class="text text-danger">
                            <span>{{element.status}}</span>
                        </div>
                        <div *ngIf="element.status == 'Refunded' && column.label=='Status'" (click)="ViewDetailsForBooking(element)"
                            class="text text-danger">
                            <span>{{element.status}}</span>
                        </div>
                        <div *ngIf="element.status == 'Scheduled' && column.label=='Status'" (click)="ViewDetailsForBooking(element)"
                            class="text text-primary">
                            <span>{{element.status}}</span>
                        </div>
                        <div *ngIf="element.status == 'Ongoing' && column.label=='Status'" (click)="ViewDetailsForBooking(element)" class="text"
                            style="border: 1px solid green; border-radius: 0.5rem; padding: 0.5rem; display: flex; justify-content: center; align-items: center;">
                            <span>{{element.status}}</span>
                        </div>
                        
                        <!-- <mat-icon class="icon icon-approved" style="color: green;"> task_alt</mat-icon> -->
                        <!-- <mat-icon *ngIf="element.status == 'Requested' && column.label=='Status'" class="icon icon-unapproved" style="color: #b38405;"> hourglass_bottom</mat-icon>
                        <mat-icon *ngIf="element.status == 'Cancelled' && column.label=='Status'" class="icon icon-rejected" style="color: red;"> not_interested</mat-icon>
                        <mat-icon *ngIf="element.status == 'Assigned' && column.label=='Status'" class="icon icon-rejected" style="color: red;">
                            not_interested</mat-icon> -->
                    </div>
                    <div *ngIf="flag==='RideHistory' ">
                        <span *ngIf="element.status == 'Completed' && column.label=='Status'"
                            style="color: green;">{{element.status}}</span>
                        <span *ngIf="element.status == 'Requested' && column.label=='Status'"
                            style="color: rgb(205 205 0);">{{element.status}}</span>
                        <span *ngIf="element.status == 'Cancelled' && column.label=='Status'" style="color: red;">{{element.status}}</span>
                        <span *ngIf="element.status == 'Assigned' && column.label=='Status'" style="color: black;">{{element.status}}</span>
                        <span *ngIf="element.Status == 'Approved' && column.label=='Status'" style="color: green;">{{element.status}}</span>
                        <span *ngIf="element.Status == 'Registered' && column.label=='Status'"
                            style="color: rgb(205 205 0);">{{element.status}}</span>
                        <span *ngIf="element.Status == 'Rejected' && column.label=='Status'" style="color: red;">{{element.status}}</span>
                        <span *ngIf="element.Status == 'Unapproved' && column.label=='Status'" style="color: black;">{{element.status}}</span>
                    </div>
                    <div *ngIf="flag==='Notification' && column.label=='Sent On'">
                        <span> {{element[column.field] | date }}</span>
                    </div>

                    <div *ngIf="(flag==='promo' || flag==='pricing') && column.field==='action' ">
                        <button mat-icon-button (click)="editAction(element) " style="color: blue; ">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button (click)="deleteAction(element) ">
                            <mat-icon>delete_forever</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="flag==='pricing' && column.field==='action' " style="color: green; ">
                            <mat-icon>local_atm</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="flag=='vehicle' ">
                        <!-- <span *ngIf="column.label=='Status' " (click)="changeStatus(element.status) " [ngClass]="{ 'btn button1': true, 'btn-success': element.status==='Approved' , 'btn-danger': element.status==='Unapproved' } ">
                            {{element[column.field]}}
                        </span> -->
                        <mat-icon *ngIf="element.status == 'Approved' && column.label=='Status'" class="icon icon-approved"
                            style="color: green;"> task_alt</mat-icon>
                        <mat-icon *ngIf="element.status == 'Unapproved' && column.label=='Status'" (click)="approveEntity('Vehicle', element)"
                            class="icon icon-unapproved">
                            hourglass_bottom</mat-icon>
                        <mat-icon *ngIf="element.status == 'Rejected' && column.label=='Status'" class="icon icon-rejected" style="color: red;">
                            not_interested</mat-icon>
                        <!-- <mat-icon *ngIf="column.label=='Document List' ">
                            <span class="material-symbols-outlined "></span>
                        </mat-icon> -->
                        <mat-icon (click)="viewDocumentList('Cab',element)" *ngIf="column.label == 'Document List'">
                            <span class="material-symbols-outlined">preview</span>
                        </mat-icon>
                        <mat-icon (click)="delinkCab(element)" *ngIf="column.label == 'Action'">
                            <span class="material-symbols-outlined">delete</span>
                        </mat-icon>

                    </div>
                    <div *ngIf="flag=='earning' ">
                        <mat-icon *ngIf="column.label == 'Transaction Details'">
                            <span class="material-symbols-outlined" (click)="ShowTransactionDetails('AdminEarnings', element)">receipt_long</span>
                        </mat-icon>
                    </div>
                    <div *ngIf="flag=='agent'">
                        <mat-icon *ngIf="element.status == 'Approved' && column.label=='Status'" class="icon icon-approved"
                            style="color: green;"> task_alt</mat-icon>
                        <mat-icon *ngIf="element.status == 'Un-Approved' && column.label=='Status'" (click)="approveEntity('Agent', element)"
                            class="icon icon-unapproved">
                            hourglass_bottom</mat-icon>
                        <mat-icon *ngIf="element.status == 'Rejected' && column.label=='Status'" class="icon icon-rejected" style="color: red;">
                            not_interested</mat-icon>
                        <mat-icon *ngIf=" column.label=='Ride History'" (click)="ShowRideHistory('RideHistory','Agent',element)"
                            class="icon icon-history">
                            history</mat-icon>
                        <mat-icon *ngIf=" column.label=='Statement'" class="icon "> receipt_long</mat-icon>
                        <mat-icon *ngIf=" column.label=='Document List'" (click)="viewDocumentList('Agent',element)"
                            class="icon ">folder_shared</mat-icon>
                        <mat-icon *ngIf=" column.label=='Driver List'" (click)="ShowDriverList('DriverList','Agent',element)"
                            class="icon">list</mat-icon>
                        <mat-icon *ngIf=" column.label=='Cab List'" (click)="ShowCabList('CabList','Agent',element)"
                            class="icon">list</mat-icon>
                        <!-- <mat-icon *ngIf=" column.label=='Work Location'" (click)="ShowWorkLocation('WorkLocation','Agent',element)"
                                                                                                                                                                                                                                                                                                                                                                                                                                                    class="icon">list</mat-icon> -->
                        <button *ngIf="column.label=='Work Location'" (click)="ShowWorkLocation('WorkLocation','Agent',element)"
                            class="workLocationBadge">{{element[column.field]}}</button>
                    </div>
                    <div *ngIf="flag ==='drivers'">
                        <mat-icon *ngIf="element.status == 'Approved' && column.label=='Status'" class="icon icon-approved"
                            style="color: green;"> task_alt</mat-icon>
                        <mat-icon *ngIf="element.status == 'Unapproved' && column.label=='Status'" (click)="approveEntity('Driver', element)"
                            class="icon icon-unapproved">
                            hourglass_bottom</mat-icon>
                        <mat-icon *ngIf="element.status == 'Rejected' && column.label=='Status'" class="icon icon-rejected" style="color: red;">
                            not_interested</mat-icon>
                        <mat-icon *ngIf="column.label=='Ride History' && element.status == 'Approved'" class="icon icon-history"
                            (click)="ShowRideHistory('RideHistory','Driver',element)">
                            history</mat-icon>
                        <mat-icon *ngIf=" column.label=='Statement'" class="icon "> receipt_long</mat-icon>
                        <mat-icon *ngIf=" column.label=='Document List'" class="icon "
                            (click)="viewDocumentList('Driver',element)">folder_shared</mat-icon>
                        <mat-icon *ngIf=" column.label=='Driver List'" class="icon">list</mat-icon>

                    </div>
                    <div *ngIf="flag === 'customer'">
                        <ng-container *ngIf="column.label === 'Customer Rating'  && flag === 'customer'">
                            <ng-container *ngIf="element.rating >= 0 && element.rating <= 5">
                                <ng-container *ngFor="let fullStar of [].constructor(Math.floor(element.rating)); let i = index">
                                    <mat-icon>star</mat-icon>
                                </ng-container>
                                <ng-container *ngIf="element.rating % 1 !== 0">
                                    <mat-icon>star_half</mat-icon>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="element.rating === 0">No rating</ng-container>
                        </ng-container>
                        <ng-container *ngIf="column.label === 'Ride History'  && flag === 'customer'">
                            <mat-icon *ngIf="column.label=='Ride History' " class="icon icon-history "
                                (click)="ShowRideHistory('RideHistory','Customer',element)"> history</mat-icon>
                        </ng-container>
                    </div>
                    <div *ngIf="flag=='DriverRating' || flag=='CustomerRating'">
                        <ng-container *ngIf="column.label === 'Rating'  && flag === 'DriverRating'">
                            <ng-container *ngIf="element.Rating >= 0 && element.Rating <= 5">
                                <ng-container *ngFor="let fullStar of [].constructor(Math.floor(element.Rating)); let i = index">
                                    <mat-icon>star</mat-icon>
                                </ng-container>
                                <ng-container *ngIf="element.Rating % 1 !== 0">
                                    <mat-icon>star_half</mat-icon>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="element.Rating === 0">No rating</ng-container>
                        </ng-container>
                        <ng-container *ngIf="column.label === 'Rating'  && flag === 'CustomerRating'">
                            <ng-container *ngIf="element.Rating >= 0 && element.Rating <= 5">
                                <ng-container *ngFor="let fullStar of [].constructor(Math.floor(element.Rating)); let i = index">
                                    <mat-icon>star</mat-icon>
                                </ng-container>
                                <ng-container *ngIf="element.Rating % 1 !== 0">
                                    <mat-icon>star_half</mat-icon>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="element.Rating === 0">No rating</ng-container>
                        </ng-container>
                        <mat-icon *ngIf="column.label=='Action'" style="color: red;" class="icon">delete</mat-icon>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns "></tr>
            <tr mat-row *matRowDef="let row;columns:displayedColumns; "></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5,10,20] " showFirstLastButtons
            aria-label="Select page of periodic elements "></mat-paginator>
    </div>
</mat-card>