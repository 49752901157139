import { Component, ElementRef, OnInit, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AgentServiceService } from '../services/agent-service.service';
@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss']
})
export class AuthenticateComponent {
 loginphone:boolean=false
 phonenumber:number = 0;
  constructor(public agentService:AgentServiceService,private router:Router) {
   
  }
  sendOTP() {
    this.loginphone = true
  }
  validateOTP() {
    this.router.navigate(['/'])
  }

  ngOnInit(): void {
    this.agentService.agent_login(this.phonenumber).subscribe((res)=>{
      console.log("successfully logged in !!")
    })
  }
}
