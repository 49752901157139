import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminComponent } from './admin/admin.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { DriverComponent } from './admin/driver/driver.component';
import { MatIconModule } from '@angular/material/icon';
import { NgChartsModule } from 'ng2-charts';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { AddCustomerComponent } from './admin/admin-customer/add-customer/add-customer.component';
import { MatInputModule } from '@angular/material/input';
import { RideHistoryComponent } from './admin/admin-customer/ride-history/ride-history.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AgentComponent } from './admin/agent/agent.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { NavBarComponent } from './admin/nav-bar/nav-bar.component';
import { AdminRoutingModule } from './admin/admin-routing.module';
import { AddAgentComponent } from './admin/agent/add-agent/add-agent.component';
import { DriverListComponent } from './admin/agent/driver-list/driver-list.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TableComponent } from './admin/table/table.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DriverDetailsComponent } from './admin/driver/driver-details/driver-details.component';
import { RidesComponent } from './admin/rides/rides.component';
import { PromoComponent } from './admin/promo/promo.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { VehicleComponent } from './vehicle/vehicle.component';
import { DataDialogComponent } from './admin/table/data-dialog/data-dialog.component';
import { ServiceLocationComponent } from './admin/service-location/service-location.component';
import { EarningsComponent } from './admin/earnings/earnings.component';
import { DriverEarningComponent } from './admin/driver-earning/driver-earning.component';
import { PricingComponent } from './admin/pricing/pricing.component';
import { RatingReviewComponent } from './admin/rating-review/rating-review.component';
import { PushNotificationComponent } from './admin/push-notification/push-notification.component';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { WebsiteSettingsComponent } from './admin/website-settings/website-settings.component';
import { CustomerProfileComponent } from './customer/customer-profile/customer-profile.component';
import { MyTripComponent } from './customer/my-trip/my-trip.component';
import { MatDividerModule } from '@angular/material/divider';
import { AuthenticateComponent } from './authenticate/authenticate.component';
import { TravelAgentComponent } from './travel-agent/travel-agent.component';
import { AgentHomeComponent } from './travel-agent/agent-home/agent-home.component';
import { AgentTableComponent } from './travel-agent/agent-table/agent-table.component';
import { BookForCustomerComponent } from './travel-agent/book-for-customer/book-for-customer.component';
import { AgentProfileComponent } from './travel-agent/agent-profile/agent-profile.component';
import { MyBookingsComponent } from './travel-agent/my-bookings/my-bookings.component';
import { CustomerBookingsComponent } from './travel-agent/customer-bookings/customer-bookings.component';
import { ManageDriverComponent } from './travel-agent/manage-driver/manage-driver.component';
import { ManageWorklocationComponent } from './travel-agent/manage-worklocation/manage-worklocation.component';
import { ManageCarComponent } from './travel-agent/manage-car/manage-car.component';
import { MyEarningComponent } from './travel-agent/my-earning/my-earning.component';
import { NavbarComponent } from './navbar/navbar.component';
import { AdminCustomerComponent } from './admin/admin-customer/admin-customer.component';
import { CustomerComponent } from './customer/customer.component';
import { ExploreCabsComponent } from './customer/explore-cabs/explore-cabs.component'
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { FooterComponent } from './footer/footer.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TravvolDrviverComponent } from './travvol-drviver/travvol-drviver.component';
import { DriverHomeComponent } from './travvol-drviver/driver-home/driver-home.component';
import { DriverProfileComponent } from './travvol-drviver/driver-profile/driver-profile.component';
import { DriverTableComponent } from './travvol-drviver/driver-table/driver-table.component';
import { DriverEarningsComponent } from './travvol-drviver/driver-earnings/driver-earnings.component';
import { DriverRidesComponent } from './travvol-drviver/driver-rides/driver-rides.component';
import { RegistrationComponent } from './registration/registration.component';
import { AgentRegistrationComponent } from './registration/agent-registration/agent-registration.component';
import { MatRippleModule } from '@angular/material/core';
import { HttpClientModule } from '@angular/common/http';
import { LoginSignupComponent } from './login-signup/login-signup.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UpdateProfileComponent } from './travel-agent/update-profile/update-profile.component';
import { AddDriverDialogComponent } from './registration/agent-registration/add-driver-dialog/add-driver-dialog.component';
import { AddCarDialogComponent } from './registration/agent-registration/add-car-dialog/add-car-dialog.component';
import { AddLocationDialogComponent } from './admin/service-location/add-location-dialog/add-location-dialog.component';
import { AvailableRidesComponent } from './travel-agent/available-rides/available-rides.component';
import { ModalPopupComponent } from './travel-agent/agent-table/modal-popup/modal-popup.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TransactionDetailsComponent } from './transaction-details/transaction-details.component';
import { DocumentListComponent } from './popupWindows/document-list/document-list.component';
import { ForwardRideComponent } from './popupWindows/forward-ride/forward-ride.component';
import { CollectPaymentComponent } from './popupWindows/collect-payment/collect-payment.component';
import { ConfirmationWindowComponent } from './popupWindows/confirmation-window/confirmation-window.component';
import { BookingDetailsComponent } from './popupWindows/booking-details/booking-details.component';
import { AgentLoginComponent } from './popupWindows/agent-login/agent-login.component';
import { NumericOnlyDirective } from './numeric-only.directive';
import { DatePipe } from '@angular/common';
import { CancelRidePopupComponent } from './customer/customer-profile/cancel-ride-popup/cancel-ride-popup.component';
import { ProfileDetailsComponent } from './popupWindows/profile-details/profile-details.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    DashboardComponent,
    DriverComponent,
    AddCustomerComponent,
    RideHistoryComponent,
    AddCustomerComponent,
    AgentComponent,
    NavBarComponent,
    AddAgentComponent,
    DriverListComponent,
    TableComponent,
    DriverDetailsComponent,
    RidesComponent,
    PromoComponent,
    VehicleComponent,
    DataDialogComponent,
    ServiceLocationComponent,
    EarningsComponent,
    DriverEarningComponent,
    PricingComponent,
    RatingReviewComponent,
    WebsiteSettingsComponent,
    PushNotificationComponent,
    CustomerProfileComponent,
    MyTripComponent,
    AuthenticateComponent,
    TravelAgentComponent,
    AgentHomeComponent,
    AgentTableComponent,
    BookForCustomerComponent,
    MyBookingsComponent,
    CustomerBookingsComponent,
    AgentProfileComponent,
    ManageDriverComponent,
    ManageWorklocationComponent,
    ManageCarComponent,
    MyEarningComponent,
    NavbarComponent,
    AdminCustomerComponent,
    CustomerComponent,
    ExploreCabsComponent,
    FooterComponent,
    ExploreCabsComponent,
    TravvolDrviverComponent,
    DriverHomeComponent,
    DriverProfileComponent,
    DriverTableComponent,
    DriverEarningsComponent,
    DriverRidesComponent,
    RegistrationComponent,
    AgentRegistrationComponent,
    LoginSignupComponent,
    UpdateProfileComponent,
    AddDriverDialogComponent,
    AddCarDialogComponent,
    AddLocationDialogComponent,
    AvailableRidesComponent,
    ModalPopupComponent,
    TransactionDetailsComponent,
    DocumentListComponent,
    ForwardRideComponent,
    CollectPaymentComponent,
    ConfirmationWindowComponent,
    BookingDetailsComponent,
    AgentLoginComponent,
    NumericOnlyDirective,
    CancelRidePopupComponent,
    ProfileDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AdminRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatIconModule,
    MatTooltipModule,
    MatIconModule,
    NgChartsModule,
    MatTableModule,
    MatInputModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatStepperModule,
    FormsModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    CommonModule,
    MatChipsModule,
    MatTabsModule,
    MatDividerModule,
    MatMenuModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatRippleModule,
    HttpClientModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    DatePipe 
  ],
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: {
        color: 'primary',
      },
    },
    MatDialogModule,
    DatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
