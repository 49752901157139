<mat-card class="example-card">
    <mat-card-header class="justify-content-around ">
        <mat-card-title>Dashoard</mat-card-title>
    </mat-card-header>
</mat-card>
<!-- <ng-container class="loader-container" style="visibility: hidden;">
    <div class="row1">
        <div class="loader"></div>
    </div>
</ng-container> -->
<!-- <ng-template> -->
    <div class="steeper ">
        <div class="dashboard-container">
            <div class="row">
                <div class="mygroup">
                    <div class="scrollfeature cardData" *ngFor="let i of infoType">
                        <div class="title-card">
                            <mat-card class="card card1">
                                <div class="card-title text-center mb-0">
                                    <p>{{i.info}}</p>
                                </div>
                                <div class="card-body d-flex justify-content-around">
                                    <p style="padding: 0.6rem;">{{i.totalValue}}</p>
                                    <div class="mat_icon">
                                        <mat-icon>{{i.icon}}</mat-icon>
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <p class="statics-heading">Total statics</p> -->
            <div class="row">
                <div class="mygroup">
                    <div class="scrollfeature cardData" *ngFor="let i of weeklyStatic">
                        <div class="title-card">
                            <mat-card class="card card1">
                                <div class="card-title text-center mb-0">
                                    <p>{{i.info}}</p>
                                </div>
                                <div class="card-body d-flex justify-content-around">
                                    <p style="padding: 0.6rem;">{{i.totalValue}}</p>
                                    <div class="mat_icon">
                                        <mat-icon>{{i.icon}}</mat-icon>
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container pie-block">
                <div class="row">
                    <div style="display: block; height:450px;">
                        <mat-card class="chart-Card">
                            <p class="chart_title">Monthly statics</p>
                            <div>
                                <canvas baseChart width="700" height="400" [type]="'line'" [data]="lineChartData"
                                    [options]="lineChartOptions" [legend]="lineChartLegend">
                                </canvas>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </div>
            <div class="filter">
                <mat-form-field class="example-full-width">
                    <mat-label>Choose start date</mat-label>
                    <input matInput [matDatepicker]="startDatePicker" [(ngModel)]="startDate">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="startDatePicker">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>Choose end date</mat-label>
                    <input matInput [matDatepicker]="endDatePicker" [(ngModel)]="endDate">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="endDatePicker">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                </mat-form-field>
                <button mat-raised-button class="ms-2 Apply-btn" (click)="fetchMonthlyData()">Apply</button>

            </div>
            <div class="container pie-block">
                <div class="row pieBlock">
                    <div class="col-md-5 mb-4">
                        <mat-card class="chart-Card">
                            <p class="chart_title">Agents Monthly statics</p>
                            <div class="canvas_style">
                                <canvas id="agentPerformance" width="400" height="200">
                                </canvas>
                                <div *ngIf="errorMessageforAgent" class="error-message">{{ errorMessageforAgent }}</div>
                            </div>
                        </mat-card>
                    </div>
                    <div class="col-md-5 mb-4">
                        <mat-card class="chart-Card">
                            <p class="chart_title">Drivers Monthly statics</p>
                            <div class="canvas_style">
                                <canvas width="600" height="300" id="driverPerformance">
                                </canvas>
                                <div *ngIf="errorMessageforDriver" class="error-message">{{ errorMessageforDriver }}
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>

            </div>
        </div>
    </div>
<!-- </ng-template> -->