<mat-card class="example-card">
    <mat-card-header class="justify-content-around ">
        <mat-card-title>My Earnings</mat-card-title>
    </mat-card-header>
</mat-card>

<div class="container-fluid pt-4">
    <div class="row">
        <div class="col-md-8">
            <mat-card class="card">
                <p style="font-size: 1rem;font-weight: 500;" class="pt-2 ms-2">Total Earnings of Driver</p>
                <mat-divider></mat-divider>
                <div class="row ms-2 card-content" *ngFor="let i of infoType">
                    <div class="col-md-1">
                        <div class="mat_icon">
                            <mat-icon>{{i.icon}}</mat-icon>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <p style="font-size: 1rem; font-weight: 500;">{{i.info}}</p>
                    </div>
                    <div class="col-md-3">
                        <p style="padding: 0.6rem;">{{i.totalValue}}</p>
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="col-md-4">
            <mat-card class="card">
                <p style="font-size: 1rem;font-weight: 500;" class="pt-2 ms-2">Total Earnings of Driver</p>
                <mat-divider/>
                <div class="card-content" *ngFor="let i of infoType">
                    <div class="card-title text-center mb-0">
                        <p style="font-size: 1rem; font-weight: 500;">{{i.info}}</p>
                        <p style="padding: 0.6rem;">{{i.totalValue}}</p>
                    </div>
                </div>

            </mat-card>

        </div>

    </div>

</div>
<div class="container-fluid pt-4">
    <div class="row">
        <div class="col">
            <mat-card class="card">
                <div class="referral">
                    <div class="referral-value">
                     <span>Total Credit</span>
                    <span>1362</span>
                    </div>
                    <mat-divider [vertical]="true"></mat-divider>
                    <div class="referral-value">
                        <span>Reward Credit</span>
                       <span>1362</span>
                       </div>
                    <mat-divider [vertical]="true"></mat-divider>
                    <div class="referral-value">
                        <span>Referral Credit</span>
                       <span>1362</span>
                       </div>
                       <mat-divider [vertical]="true"></mat-divider>
                       <div class="referral-value">
                        <span>Penalties</span>
                       <span>1362</span>
                       </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>