import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, NgModule, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomerService } from '../services/customer.service';
import { DriverService } from '../services/driver.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdminService } from '../services/admin.service';
import { NumericOnlyDirective } from '../numeric-only.directive';
import { AwsUploadService } from 'src/app/services/aws-upload.service';


@Component({
  selector: 'app-login-signup',
  templateUrl: './login-signup.component.html',
  styleUrls: ['./login-signup.component.scss']
})
export class LoginSignupComponent {
  login_comp = false;
  register_comp = false;
  customer_login_form: FormGroup;
  customer_register_form: FormGroup;
  customer_edit_form: FormGroup;
  otpForm!: FormGroup;
  driver_login_form: FormGroup;
  driver_register_form: FormGroup;
  driver_edit_form: FormGroup;
  start_ride_form: FormGroup;
  end_ride_form: FormGroup;
  user_data: any;
  final_booking_data: any;
  activateLoginBtn: boolean = false;
  phoneNumber: string = "";
  orderId: any;
  digit1: string = '';
  digit2: string = '';
  digit3: string = '';
  digit4: string = '';
  digit5: string = '';
  digit6: string = '';
  otpSent: boolean = false;
  isOTPVerified: boolean = false;


  constructor(private fb: FormBuilder, private router: Router, private customerService: CustomerService, private driverService: DriverService, private snackBar: MatSnackBar, private dialogRef: MatDialogRef<LoginSignupComponent>, @Inject(MAT_DIALOG_DATA) public data: any
    , private adminservice: AdminService, public docUpload: AwsUploadService) {
    this.user_data = data['user'];
    this.final_booking_data = data['booking_details'];
    this.customer_login_form = this.fb.group({});
    this.customer_register_form = this.fb.group({});
    this.driver_register_form = this.fb.group({});
    this.customer_edit_form = this.fb.group({});
    this.driver_edit_form = this.fb.group({});
    this.start_ride_form = this.fb.group({});
    this.end_ride_form = this.fb.group({});

    // customer
    this.customer_login_form = this.fb.group({
      phoneNumber: ['', [Validators.required, Validators.maxLength(10)]]
    })
    this.customer_register_form = this.fb.group({
      phoneNumber: ['', [Validators.required, Validators.maxLength(10)]]
    })
    if (data['title'] == 'profile') {
      this.customer_edit_form = this.fb.group({
        userid: [this.user_data.customer_id],
        profilePic: [this.user_data.profilePic],
        name: [this.user_data.name, Validators.required],
        emailid: [this.user_data.emailid, Validators.required],
        phoneNumber: [this.user_data.phoneNumber, Validators.required]
      })
    }

    // driver
    this.driver_login_form = this.fb.group({
      phoneNumber: ['', Validators.required]
    })
    this.driver_register_form = this.fb.group({
      driverName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
    })
    if (data['title'] == 'driver_profile') {
      this.driver_edit_form = this.fb.group({
        driver_id: [this.user_data.driver_id],
        profilePic: [this.user_data.profilePic],
        name: [this.user_data.driverName, Validators.required],
        emailid: [this.user_data.emailId],
        phoneNumber: [this.user_data.mobileNumber, Validators.required]
      })
    }
    // start_ride_odometer
    if (data.title == "start_ride" || data.title == 'end_ride') {
      this.orderId = data.orderId;
      this.phoneNumber = data['rideData']['customerPhoneNumber'];
    }
    this.start_ride_form = this.fb.group({
      reading: ['', Validators.required]
    })
  }
  final_payment = { original_kms: '', original__price: "", actual_kms: "", extra_kms: "", extra__price: "", total_bill: "", amount_collected: "", remaining_Amount: "", odoMeterReadingAtEnd: "" };
  ngOnInit() {
    if (this.data['title'] === 'book_ride') {
      this.snackBar.open("Successfully Book Ride. Will get back to you for further updates", 'Close', {
        duration: 3000
      })
    }
  }

  // customer
  async customer_login() {
    let otpreturnValue = await this.verifyOtp();
    console.log(otpreturnValue)
    if (otpreturnValue) {
      let data = this.customer_login_form.value;
      if (this.customer_login_form.valid) {
        this.customerService.login(data).subscribe((res) => {
          if (res.message === "Error") {
            this.snackBar.open('This phone number is not registered!', 'Close', { duration: 3000 });
          } else if (res.result) {
            this.dialogRef.close(res.result);
          }
        }, (err) => {
          this.snackBar.open('An error occurred. Please try again later.', 'Close', { duration: 3000 });
        })
      }
    }
    else {
      this.snackBar.open('OTP verification failed. Please try again later.', 'Close', { duration: 3000 });
    }
  }

  async customer_register() {
    let otpreturnValue = await this.verifyOtp();
    // console.log("otpreturnValue: ",otpreturnValue)
    if (otpreturnValue) {
      console.log("otpreturnValue: ", otpreturnValue)
      let data = this.customer_login_form.value;
      if (this.customer_login_form.valid) {
        this.customerService.register(data).subscribe((res) => {
          if (res.message === "Number Found") {
            this.snackBar.open('This number already exist!!', 'Close', { duration: 3000 });
          } else if (res.message === "Error") {
            this.snackBar.open('Registration failed', 'Close', { duration: 3000 });
          } else if (res.result) {
            this.login_comp = false;
            this.register_comp = false;
            this.snackBar.open(res.message)
            this.dialogRef.close();
          }
        }, (err) => {
          this.snackBar.open('An error occurred. Please try again later.', 'Close', { duration: 3000 });
        })
      }
    }
    else {
      this.snackBar.open('OTP verification failed. Please try again later.', 'Close', { duration: 3000 });
    }
  }
  customer_register_img(event: any) {
    const file = event.target.files[0].name;
    if (file) {
      this.docUpload.onFileSelected(event).then((res: any) => {
        this.customer_register_form.patchValue({ profilePic: res.fileUrl });
      })
    }
  }
  customer_edit_img(event: any) {
    const file = event.target.files[0].name;
    if (file) {
      this.docUpload.onFileSelected(event).then((res: any) => {
        this.customer_edit_form.patchValue({ profilePic: res.fileUrl });
      })
    }
  }
  customer_update() {
    if (this.customer_edit_form.valid) {
      this.customerService.updateProfile(this.customer_edit_form.value).subscribe((res) => {
        this.dialogRef.close(res.result);
      })
    }
  }

  // driver

  showDriverLoginForm() {
    this.register_comp = false;
    this.login_comp = true;
    this.otpSent = false;
    this.isOTPVerified = false;
    this.Cancel()
  }

  showDriverRegisterForm() {
    this.register_comp = true;
    this.login_comp = false;
    this.otpSent = false;
    this.isOTPVerified = false;
    this.Cancel()
  }

  async Driver_login() {
    let otpreturnValue = await this.verifyOtp();
    if (otpreturnValue) {
      let data = this.driver_login_form.value;
      if (this.driver_login_form.valid) {
        this.driverService.login(data).subscribe((res) => {
          if (res.message === "Error") {
            this.snackBar.open('This phone number is not registered!', 'Close', { duration: 3000 });
            this.register_comp = true;
            this.login_comp = false;
            this.isOTPVerified = false;
            this.phoneNumber = "";
            this.otpSent = false;
            this.digit1 = '';
            this.digit2 = '';
            this.digit3 = '';
            this.digit4 = '';
            this.digit5 = '';
            this.digit6 = '';
          } else if (res.result) {
            // this.router.navigate(['/driver/', res.result]);
            this.dialogRef.close(res.result);
          }
        }, (err) => {
          this.snackBar.open(err.Message, 'Close', { duration: 3000 });
        })
      }
    }
    else {
      this.snackBar.open('OTP verification failed. Please try again later.', 'Close', { duration: 3000 });
    }
  }
  async Driver_register() {
    if (!this.isOTPVerified) {
      await this.verifyOtp();
    }
    if (this.isOTPVerified) {
      let data = {
        driverName: this.driver_register_form?.value?.driverName,
        mobileNumber: this.driver_register_form?.value?.phoneNumber
      }
      console.log(data)
      if (this.driver_register_form.valid) {
        this.driverService.register(data, 'driver').subscribe((res) => {
          if (res.message === "Phone number already registered") {
            this.login_comp = false;
            this.register_comp = false;
            this.Cancel();
            this.snackBar.open('This number already exist!!', 'Close', { duration: 3000 });
          } else if (res.message === "Error") {
            this.snackBar.open('Registration failed', 'Close', { duration: 3000 });
          } else if (res.message == 'Driver Registration successful') {
            this.dialogRef.close(res.driver_data);
            this.driverService.login(res.driver_data['phoneNumber']).subscribe((res) => {
              const expirationDate = new Date(new Date().getTime() + 20 * 60 * 60 * 1000);
              localStorage.setItem("driver_auth", res.driver_id);
              localStorage.setItem("driver_authExpiration", expirationDate.toString());
              this.router.navigate(['/driver/', res.result]);
            })
            // this.router.navigate(['/driver/', res.result]);
            this.snackBar.open(res.message, 'Close', { duration: 3000 });
          }
        }, (err) => {
          this.snackBar.open('An error occurred. Please try again later.', 'Close', { duration: 3000 });
        })
      }
    }
    else {
      this.snackBar.open('OTP verification failed. Please try again later.', 'Close', { duration: 3000 });
    }
  }
  driver_update() {
    if (this.driver_edit_form.valid) {
      this.driverService.update(this.driver_edit_form.value).subscribe((res) => {
        this.dialogRef.close(res.result);
      })
    }
  }

  driver_edit_img(event: any) {
    const file = event.target.files[0].name;
    if (file) {
      this.docUpload.onFileSelected(event).then((res: any) => {
        this.driver_edit_form.patchValue({ profilePic: res.fileUrl });
      })
    }
  }
  errorMsg = false;
  // mismatchDistance = false;
  customer_payment = false;
  actual_km = 0;
  extra_km = 0;
  // odometer
  ride_type = ''
  start_reading(type: any) {
    this.errorMsg = false
    this.verifyOtp().then((res: any) => {
      this.isOTPVerified = res;
      if (this.isOTPVerified) {
        if (type == 'start_ride') {
          this.dialogRef.close(this.start_ride_form.value);
        }
        if (type === 'end_ride') {
          console.log(this.data)
          const odoMeterReadingStart = parseFloat(this.data['rideData']['odoMeterReadingAtStart']);
          const odoMeterReadingEnd = parseFloat(this.start_ride_form.value['reading']);
          const totalDistance = parseFloat(this.data['rideData']['distance']);
          this.actual_km = odoMeterReadingEnd - odoMeterReadingStart;
          this.extra_km = this.actual_km - totalDistance > 0 ? this.actual_km - totalDistance : 0;
          console.log("Data: ", this.data);
          console.log("Extra KM Charge", this.data['rideData']['extraKMCharge']);;
          console.log("Extra KM Charge calculated", this.extra_km * this.data['rideData']['extraKMCharge'])
          console.log("Total Bill: ", this.final_payment.extra__price, (parseInt(this.final_payment.extra__price), this.final_payment.original__price, parseInt(this.final_payment.original__price)));
          if (this.start_ride_form.valid) {
            this.final_payment.original_kms = this.data['rideData']['distance'];
            this.final_payment.original__price = this.data['rideData']['fare'];
            this.final_payment.actual_kms = this.actual_km.toString();
            this.final_payment.extra_kms = this.extra_km.toString();
            this.final_payment.extra__price = (this.extra_km * this.data['rideData']['extraKMCharge']).toString();
            this.final_payment.total_bill = (parseInt(this.final_payment.extra__price) + parseInt(this.final_payment.original__price)).toString();
            this.final_payment.amount_collected = this.data['rideData']['advanceAmount'];
            this.final_payment.remaining_Amount = ((parseInt(this.final_payment.total_bill)) - (parseInt(this.final_payment.amount_collected))).toString();
            this.final_payment.odoMeterReadingAtEnd = this.start_ride_form.value['reading'];
            this.customer_payment = true;
          }
        }
      }
      else {
        this.snackBar.open("OTP verification failed")
      }
    })
      .catch(error => {
        console.error("Error verifying OTP:", error);
        return false;
      });
  }

  collectAmount() {
    console.log("final ", this.final_payment)
    this.dialogRef.close(this.final_payment);
  }

  //OTP Segment
  SendOTP(type: any) {
    if (type == 'end_ride' && parseInt(this.data['rideData']['odoMeterReadingAtStart']) >= this.start_ride_form.value['reading']) {
      this.errorMsg = true;
      return;
    }
    if (!this.errorMsg) {
      this.otpSent = true;
      this.adminservice.getOTPValue(parseInt(this.phoneNumber)).subscribe((res: any) => {
        this.orderId = res.orderId;
        this.snackBar.open("OTP sent successfully !", 'Close', { duration: 3000 });
      }, (err) => {
        this.snackBar.open(err.error.error, 'Close', { duration: 3000 });
      })
    }
  }
  async verifyOtp(): Promise<boolean> {
    const otp: number = parseInt(this.digit1 + this.digit2 + this.digit3 + this.digit4 + this.digit5 + this.digit6, 10);
    this.snackBar.open("OTP verified successfully !", 'Close', { duration: 3000 });
    return this.adminservice.getOTPVerified(this.orderId, otp, parseInt(this.phoneNumber)).toPromise()
      .then((res: any) => {
        this.isOTPVerified = res.isOTPVerified;
        return res.isOTPVerified;
      })
      .catch(error => {
        this.snackBar.open(error, 'Close', { duration: 3000 });
        return false;
      });
  }

  ResendOTP() {
    this.adminservice.getReSendOTP(this.orderId).subscribe((res: any) => {
      this.orderId = res.orderId;
      this.snackBar.open("OTP resend successfull !", 'Close', { duration: 3000 });
    }, (err) => {
      this.snackBar.open(err.error.error, 'Close', { duration: 3000 })
    })
  }
  Cancel() {
    this.phoneNumber = "";
    this.otpSent = false;
    this.digit1 = '';
    this.digit2 = '';
    this.digit3 = '';
    this.digit4 = '';
    this.digit5 = '';
    this.digit6 = '';
  }
  isMobileNumberValid(): boolean {
    // console.log(this.phoneNumber, this.phoneNumber.length)
    // const phoneNumberString: string = this.phoneNumber;
    return this.phoneNumber.length === 10;
  }
  close() {
    this.dialogRef.close();
  }

  moveToNext(event: any, nextInputIndex: number | null): void {
    const input = event.target as HTMLInputElement;
    const maxLength = parseInt(input.getAttribute('maxlength') || '1', 10);
    const currentLength = input.value.length;

    if (currentLength >= maxLength && nextInputIndex !== null) {
      const nextInput = document.querySelectorAll('.opt_input')[nextInputIndex - 1] as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }

    }
    else {
      this.activateLoginBtn = true;
    }
  }
  CancelRide() {
    this.otpSent = false;
    this.digit1 = '';
    this.digit2 = '';
    this.digit3 = '';
    this.digit4 = '';
    this.digit5 = '';
    this.digit6 = '';
    this.dialogRef.close()
  }

}