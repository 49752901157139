import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import {AdminService} from 'src/app/services/admin.service'
@Component({
  selector: 'app-admin-customer',
  templateUrl: './admin-customer.component.html',
  styleUrls: ['./admin-customer.component.scss']
})
export class AdminCustomerComponent {
  customerTable:any[]=[];
  tableData: any[] = [];
  showLoader: boolean = true;

  constructor(private route: ActivatedRoute,public adminService:AdminService) { }

  @ViewChild(MatStepper) stepper!: MatStepper;
  ngOnInit(){
    this.adminService.getAllCustomerForDashboard().subscribe((res) => {
      if (Array.isArray(res) && res.length > 0) {
        this.customerTable = res;
  
        this.tableData = this.customerTable.map(cust=>{
        return  {
            customer_id: cust.customer_id,
            customer_name: cust.name,
            email_id: cust.emailid,
            contact: cust.phoneNumber,
            rating: 4,
            status: 'Active',
            ride_history: 'Ride History'
          }
        });
  
        console.log("Customer response for Dashboard: ", this.customerTable[0], res);
      } else {
        console.log("Invalid response from the backend");
      }
      this.showLoader  = false; 
    });
  }

  columnData = [
    { field: "customer_name", label: "Customer Name" },
    { field: "email_id", label: "Email ID" },
    { field: "contact", label: "Contact Number" },
    // { field: " ", label: "Customer Rating" },
    { field: "ride_history", label: "Ride History" },
    // { field: "status", label: "Status" }
  ];
 

  selectedValue: any = '';
  ride_filter: any[] = ['Active', 'Inactive']
  changeFilterValue(filter: any) {
    console.log(filter);
    // console.log(this.tableData);
  }
}
