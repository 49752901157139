    <h2 mat-dialog-title>Update profile</h2>
        <mat-dialog-content>
            <div class="container">
            <div class="d-flex justify-content-between fw-bold label">
                <span>Name :</span>
            <mat-form-field class="col-sm-8 input-field">
                <input matInput [(ngModel)]="agentProfile.name" >
            </mat-form-field>
        </div>
        <div class="d-flex justify-content-between fw-bold label">Emaild : 
            
            <mat-form-field class="col-sm-8 input-field">
                <input matInput [(ngModel)]="agentProfile.emailid">
            </mat-form-field>
        </div>
        <!-- <div class="d-flex justify-content-between fw-bold label">PhoneNumber : 
            <mat-form-field class="col-sm-8 input-field">
                <input matInput [(ngModel)]="agentProfile.phoneNumber">
            </mat-form-field>
        </div> -->
        <div class="saveprofile">
            <button mat-stroked-button style="background-color: #b38405;" (click)="saveProfile()" >Save</button>
        </div>
    </div>
        </mat-dialog-content>
 