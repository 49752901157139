import { Component } from '@angular/core';
import { Chart, ChartOptions, ChartDataset } from 'chart.js';
import { ChartConfiguration, ChartType } from "chart.js";
import { AdminService } from 'src/app/services/admin.service';
import * as moment from 'moment';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  metricData:any = {};
  weeklyMetric:any = {};
  infoType: any[] = [];
  weeklyStatic:any[]=[];
  agentMonthlyData:any = {};
  driverMonthlyData:any={}
  endDate : Date = new Date();
  startDate : Date  = moment(this.endDate).subtract(1, 'month').toDate();
  errorMessageforDriver:string="";
  errorMessageforAgent:string="";
  showLoader: boolean = true;
  constructor(public adService:AdminService){
    // this.defaultMonth = moment(); 
    // this.defaultStartDate = this.defaultMonth.startOf('month').toDate();
    // this.defaultEndDate = this.defaultMonth.endOf('month').toDate();
  }

  ngOnInit(){
   this.fetchCardData()
    // this.fetchMonthlyData();
    this.agentOverAllMetric();
    this.driverOverAllMetric();
    this.getMonthlyStats()
    // this.showLoader=false;
  }
  fetchCardData(){
    this.adService.getmetrics().subscribe((res)=>{
      this.metricData = res;
      console.log("Metric data : ",this.metricData);
    this.infoType = [
        { info: "Total Customers", icon: "electric_car", totalValue:this.metricData.totalUser},
        { info: "Total Drivers", icon: "toys", totalValue:this.metricData.totalDrivers},
        { info: "Total Travel Agents", icon: "block", totalValue: this.metricData.totalAgent },
        { info: "Total Vehicles", icon: "monetization_on", totalValue: this.metricData.totalVehicle },
        { info: "Total Rides", icon: "monetization_on", totalValue:this.metricData.totalRides}
      ]
      // this.showLoader=true
    })
    this.adService.getWeeklyMetric().subscribe((res)=>{
      this.weeklyMetric = res;
      console.log("Weekly : ",this.weeklyMetric)
      this. weeklyStatic = [
        // { info: "Active Bookings", icon: "wallet", totalValue: "120" },
        { info: "Total Completed Bookings", icon: "done_outline", totalValue: this.weeklyMetric.totalCompletedBooking },
        { info: "Total Scheduled Bookings", icon: "run_circle", totalValue: this.weeklyMetric.totalAssignedBooking },
        { info: "Total Cancelled Bookings", icon: "disabled_by_default", totalValue: this.weeklyMetric.totalCancelledBooking },
        { info: "Total Revenue", icon: "monetization_on", totalValue: "₹ " + this.weeklyMetric.totalReveneuCollected }
    
      ]
      // this.showLoader=true

    })
  }
  //monthly data fetch
  fetchMonthlyData(){
    this.driverOverAllMetric();
    this.agentOverAllMetric();
  }
//agentPieChart
 public agentPieChart:any;
public agentPieChartLabels = ['Approved', 'Rejected', 'Unapproved'];
public agentPieChartDatasets = [{
  data: [0, 0, 0], // Initialize with default values
  backgroundColor: ['rgba(241, 163, 8)', 'rgba(0, 83, 119)', 'rgba(50, 147, 111)'],
}];

// Driver chart properties
public driverPieChart:any;
public driverPieChartLabels = ['Approved', 'Unapproved', 'Rejected'];
public driverPieChartDatasets = [{
  data: [0, 0, 0], // Initialize with default values
  backgroundColor: ['rgba(241, 163, 8)', 'rgba(0, 83, 119)', 'rgba(50, 147, 111)'],
}];
agentOverAllMetric() {
  this.adService.getMonthlyAgent(this.startDate.toISOString(),this.endDate.toISOString()).subscribe((res) => { 
    this.agentMonthlyData = res;
    if (!res || ( this.agentMonthlyData.totalApproved === 0 &&  this.agentMonthlyData.totalUnApproved === 0 &&  this.agentMonthlyData.totalRejected === 0)) {
     this.errorMessageforAgent="No records to display"
    }

    var performanceCtx = <HTMLCanvasElement>document.getElementById('agentPerformance');
    const approved = this.agentMonthlyData.totalApproved;
    const unapproved = this.agentMonthlyData.totalUnApproved;
    const rejected = this.agentMonthlyData.totalRejected;
    var datasets = [{
      data: [approved, unapproved, rejected],
      backgroundColor: ['rgba(241, 163, 8)', 'rgba(0, 83, 119)', 'rgba(50, 147, 111)'],
    }];

    if (this.agentPieChart) {
      this.agentPieChart.data.datasets = datasets;
      this.agentPieChart.update();
    } else {
      this.agentPieChart = new Chart(performanceCtx, {
        type: 'pie',
        data: {
          labels: this.agentPieChartLabels,
          datasets: datasets,
        },
        options:{
          plugins:{
            legend:{
              display:true
            }
          },
          scales:{
        
          }
        }
      });
    }
    // this.showLoader=false
  })  
}
//Driver Monthly

driverOverAllMetric() {
  this.adService.getMonthlyDriver(this.startDate.toISOString(),this.endDate.toISOString()).subscribe((res) => {
    this.driverMonthlyData = res;
    if (!res || ( this.driverMonthlyData.totalApproved === 0 &&  this.driverMonthlyData.totalUnApproved === 0 &&  this.driverMonthlyData.totalRejected === 0)) {
      this.errorMessageforDriver="No records to display"
     }
 
    var performanceCtx = <HTMLCanvasElement>document.getElementById('driverPerformance');
    const approved = this.driverMonthlyData.totalApproved || 0;
    const unapproved = this.driverMonthlyData.totalUnApproved || 0;
    const rejected = this.driverMonthlyData.totalRejected || 0;
    var datasets = [{
      data: [approved, unapproved, rejected],
      backgroundColor: ['rgba(241, 163, 8)', 'rgba(0, 83, 119)', 'rgba(50, 147, 111)'],
    }];

    if (this.driverPieChart) {
      this.driverPieChart.data.datasets = datasets;
      this.driverPieChart.update();
    } else {
      this.driverPieChart = new Chart(performanceCtx, {
        type: 'pie',
        data: {
          labels: this.driverPieChartLabels,
          datasets: datasets,
        },
        options:{
          plugins:{
            legend:{
              display:true
            }
          },
          scales:{
        
          }
        }
      });
    }
    // this.showLoader  = true; 
  });

}

monthlyPerformance:any
approvedDriverData:number[]=[];
approvedAgentData:number[]=[];
rideData:number[]=[];
customerData:number[]=[];
totalRevenue:number[]=[]
getMonthlyStats(){

  this.adService.getMonthlyOverAllGraph().subscribe((res)=>{
    this.monthlyPerformance = res;
    console.log("Monthly Data : " , this.monthlyPerformance)
    this.approvedDriverData = this.monthlyPerformance.map((data: any) => {
      return data.totalApprovedDriver;
     })
     this.approvedAgentData = this.monthlyPerformance.map((data: any) => {
      return data.totalApprovedAgent
     })
     this.rideData = this.monthlyPerformance.map((data: any) => {
      return data.totalRides
     })
     this.customerData = this.monthlyPerformance.map((data: any) => {
      return data.totalUser
     })
    this.totalRevenue = this.monthlyPerformance.map((data: any) => {
      return data.totalRevenue
    })

    console.log("approvedriver data: ", this.approvedDriverData, this.rideData, this.approvedAgentData, this.totalRevenue);
     this.updateChartData();
     this.showLoader  = false; 
  })
}

updateChartData() {
  this.lineChartData.datasets[0].data = this.customerData;
  this.lineChartData.datasets[1].data = this.rideData;
  this.lineChartData.datasets[3].data = this.approvedDriverData;
  this.lineChartData.datasets[4].data = this.approvedAgentData;
  this.lineChartData.datasets[2].data = this.totalRevenue;

  this.lineChartData = { ...this.lineChartData };
}

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [
      "Jan" , "Feb" , "Mar" , "Apr","May" , "Jun" , "Jul" , "Aug" , "Sep" , "Oct" ,"Nov" ,"Dec" 
    ],
    datasets: [
      {
        label: 'Customer',
        data:this.customerData,
        backgroundColor: 'blue',
        borderColor: 'blue',
        fill: false,
      },
      {
        label: 'Ride',
        data: this.rideData,
        backgroundColor: 'red',
        borderColor: 'red',
        fill: false,
      },
      {
        label: 'Revenue (value in K)',
        data: this.totalRevenue,
        backgroundColor: 'rgba(241, 163, 8)',
        borderColor: 'rgba(241, 163, 8)',
        fill: false,
      },
      {
        label: 'Approved Driver',
        data: this.approvedDriverData,
        backgroundColor: 'rgba(0, 83, 119)',
        borderColor: 'rgba(0, 83, 119)',
        fill: false,
      },
      {
        label: 'Approved Agent',
        data:this.approvedAgentData
        ,
        backgroundColor: 'rgba(50, 147, 111)',
        borderColor: 'rgba(50, 147, 111)',
        fill: false,
      },
    ]
  };
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false
  };
  public lineChartLegend = true;

}
