<footer class="footer bg-dark text-white pt-5">
    <div class="row">
        <div class="d-flex justify-content-center">
            <div class="col-4 text-center">
                <h5>COMPANY</h5>
                <ul class="list-unstyled">
                    <li class="mb-2"><a href="#">About Us</a></li>
                    <li class="mb-2"><a href="#">Careers</a></li>
                    <li class="mb-2"><a href="#">Privacy Policy</a></li>
                    <li class="mb-2"><a href="#">Terms & Conditions</a></li>
                </ul>
            </div>
            <div class="col-4 text-center">
                <h5>SERVICES</h5>
                <ul class="list-unstyled">
                    <li class="mb-2"><a href="#">Local Car Rentals</a></li>
                    <li class="mb-2"><a href="#">Outstation Taxi</a></li>
                    <li class="mb-2"><a href="#">One way cabs</a></li>
                    <li class="mb-2"><a href="#">Corporate Car Rental</a></li>
                    <li class="mb-2"><a href="#">Airport Taxi</a></li>
                </ul>
            </div>
            <div class="col-4 text-center">
                <h5>GET IN TOUCH</h5>
                <ul class="list-unstyled">
                    <li class="mb-2"><a href="#">Contact Us</a></li>
                    <li class="mb-2"><a href="#">Sitemap</a></li>
                    <li class="mb-2"><a href="#">XML Sitemap</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="text-center py-3">
        Copyright &copy; 2024 Travvol
    </div>
</footer>