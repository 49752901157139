<mat-card class="example-card">
    <mat-card-header class="justify-content-around ">
        <mat-card-title>Service Locations</mat-card-title>
    </mat-card-header>
</mat-card>
<ng-container *ngIf="showLoader; else content" class="loader-container">
    <div class="row1">
        <div class="loader"></div>
    </div>
</ng-container>
<ng-template #content>
    <div class="container fluid">
        <mat-card class="m-2 p-2">
            <mat-card-header class="justify-content-around ">
                <mat-card-title>Manage Service Location</mat-card-title>
            </mat-card-header>
            <div class="row align-items-end pt-4">
                <div
                    class="d-flex col-md-4 col-sm-6 col-xs-12 flex-column flex-md-row align-items-baseline justify-content-start">
                    <div class="col-sm-3 fw-bold label">State Name :<span class="text-danger">*</span></div>
                    <mat-form-field class="col-sm-8 input-field">
                        <!-- <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea> -->
                        <mat-select [(ngModel)]="selectedState" (selectionChange)="getDistrictsList()">
                            <mat-option *ngFor="let state of states" [value]="state">{{state}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div
                    class="d-flex col-md-4 col-sm-6 col-xs-12 flex-column flex-md-row align-items-baseline justify-content-start">
                    <div class=" col-sm-3 fw-bold label">City Name :<span class="text-danger">*</span></div>
                    <mat-form-field class="col-sm-8 input-field">
                        <!-- <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea> -->
                        <mat-select [(ngModel)]="selectedDistrict" [disabled]="selectedState == ''">
                            <mat-option *ngFor="let district of districts" [value]="district">{{district}} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="btn1 col-md-2 col-sm-4 col-xs-6">
                    <button type="submit" class="btn btn-success col-sm m-4" [disabled]="selectedDistrict == ''"
                        (click)="addLocation('From')">Add
                        From</button>
                </div>
                <div class="btn1 col-md-2 col-sm-4 col-xs-6">
                    <button type="submit" class="btn btn-success col-sm m-4" [disabled]="selectedDistrict == ''"
                        (click)="addLocation('To')">Add
                        To</button>
                </div>
            </div>
        </mat-card>
        <mat-card class="row col-md-12" style="display: flex; flex-direction: row; min-height: 55vh;">
            <div class="col-md-6">
                <mat-card class="m-2 p-4">
                    <span class="mb-4">From List</span>
                    <div class="location-chipset">
                        <div *ngFor="let location of fromLocations" class="location-chip">
                            {{location}}
                            <mat-icon (click)="removeLoation('From', location)">cancel</mat-icon>
                        </div>
                    </div>
                    <!-- </mat-chip-set> -->
                </mat-card>
            </div>
            <div class="col-md-6">
                <mat-card class="m-2 p-4">
                    <span class="mb-4">To List</span>
                    <div class="location-chipset">
                        <div *ngFor="let location of toLocations" class="location-chip">
                            {{location}}
                            <mat-icon (click)="removeLoation('To', location)">cancel</mat-icon>
                        </div>
                    </div>
                </mat-card>
            </div>
        </mat-card>
    </div>
</ng-template>