<mat-card class="example-card">
    <mat-card-header class="justify-content-around ">
        <mat-card-title>Earnings</mat-card-title>
    </mat-card-header>
  </mat-card>
  <ng-container *ngIf="showLoader; else content" class="loader-container">
    <div class="row">
      <div class="loader"></div>
    </div>
  </ng-container>
  <ng-template #content>
<mat-card class="m-2 p-4">
    <app-table (updateTransactionDetails)="fetchEarningsData()"  [data]="agentEarningData" [columnData]="columnData" [flag]="'earning'"></app-table>
</mat-card>
  </ng-template>