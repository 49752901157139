export class Agent {
    type: string = "";
    phoneNumber: string = "";
    name: string = "";
    emailID: string = "";
    profilePic: string = "";
    agencyName: string = "";
    agencyAddress: string = "";
    GST: string = "";
    GSTUpload: string = "";
    Worklocation: string = "";
    panCard: string = "";
    panCardUpload: string = "";
    aadharCard: string = "";
    aadharCardUpload: string = "";
    accountNumber: string = "";
    upiID: string = "";
    phonePeNumber: string = "";
    AreYouADriver: string = "";
    driverList: string[] = [];
    carList: string[] = [];
    [key: string]: string | string[];
} 

export class Driver {
    driverName: string = '';
    mobileNumber: string = '';
    profilePic: string = '';
    emailId: string = '';
    panNumber: string = '';
    panUpload: string = '';
    dlNumber: string = '';
    dlUpload: string = '';
    aadharNumber: string = '';
    aadharUpload: string = '';
    policeVerification: string = '';
    accountNo: string = '';
    phonepeNumber: string = '';
    upiId: string = '';
    [key: string]: string
}