import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginSignupComponent } from 'src/app/login-signup/login-signup.component';
import { DriverService } from 'src/app/services/driver.service';
import { FormGroup, Validator, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AwsUploadService } from 'src/app/services/aws-upload.service';


@Component({
  selector: 'app-driver-profile',
  templateUrl: './driver-profile.component.html',
  styleUrls: ['./driver-profile.component.scss']
})
export class DriverProfileComponent {
  // :any
  driverProfile: Driver = new Driver;
  constructor(private dialog: MatDialog, private driverService: DriverService, private router: Router, private fb: FormBuilder, public docUpload: AwsUploadService) {
    this.upload_document_details_from = this.fb.group({});
    this.account_details_from = this.fb.group({});
  }
  upload_document_details_from: FormGroup;
  account_details_from: FormGroup;

  @Input() driver_data: any;
  @Output() driver: EventEmitter<any> = new EventEmitter<any>();

  showbankbtn = false;
  toggleButton = false;

  ngOnInit() {
    console.log("Driver data", this.driver_data)
    this.upload_document_details_from = this.fb.group({
      driver_id: [this.driver_data.driver_id],
      mobileNumber: [this.driver_data.mobileNumber],
      aadharUpload: ['', Validators.required],
      panUpload: ['', Validators.required],
      dlUpload: ['', Validators.required],
      policeVerification: ['', Validators.required],   
     aadharNumber: ['', Validators.required],
     dlNumber: ['', Validators.required],
     panNumber: ['', Validators.required],

    })

    this.account_details_from = this.fb.group({
      driver_id: [this.driver_data.driver_id],
      mobileNumber: [this.driver_data.mobileNumber],
      bankAccountNumber: ['', Validators.required],
      bankName: ['', Validators.required],
      ifsc_Code: ['', Validators.required],
      upi: ['', Validators.required],
      phonePay: ['', Validators.required],
      gPay: ['', Validators.required],
      paytm: ['', Validators.required],
    })
    this.driverService.fetchData(this.driver_data.driver_id).subscribe((res)=>{
      console.log(res);
      this.driverProfile.aadharNumber = res.result.aadharNumber;
      this.driverProfile.aadharUpload = res.result.aadharUpload;
      this.driverProfile.aadharStatus = res.result.aadharStatus;
      this.driverProfile.dlNumber = res.result.dlNumber;
      this.driverProfile.dlUpload = res.result.dlUpload;
      this.driverProfile.dlStatus = res.result.dlStatus;
      this.driverProfile.panUpload = res.result.panUpload;
      this.driverProfile.panNumber = res.result.panNumber;
      this.driverProfile.panStatus = res.result.panStatus;
      this.driverProfile.policeVerification = res.result.policeVerification;
      this.driverProfile.policeVerificationStatus = res.result.policeVerificationStatus;
      this.driverProfile.emailId = res.result.emailId;
      this.driverProfile.mobileNumber = res.result.mobileNumber;
      this.driverProfile.driverName = res.result.driverName;
      this.driverProfile.driver_id = res.result.driver_id;
      this.driverProfile.status = res.result.status;
      this.driverProfile.connectedWith = res.result.connectedWith;
      console.log("Driver Profile :: ", this.driverProfile)
    })
  }

  editDocument() {
    this.toggleButton = true;
  }

  saveProfile() {
    this.driverService.updateDocuments(this.driverProfile).subscribe((res) => {
      this.driverProfile = res;
      this.toggleButton = false
      })
    
  }

  onFileSelected(event: any, source: string) {
    const file = event.target.files[0].name
    if (file) {
      this.docUpload.onFileSelected(event).then((res: any) => {
        if (this.driverProfile.hasOwnProperty(source)) {
          this.driverProfile[source] = res.fileUrl;
          console.log("Driver Info: ", this.driverProfile);
        } else {
          console.error("File URL is not available.");
        }
      }).catch((error) => {
        console.log("Error in ts: ", error);
      });
    }
  }

  ngOnChange(change: ChangeDetectorRef) {
    if (change) {
      this.driver_data;
    }
  }

  edit_profile() {
    const dialog = this.dialog.open(LoginSignupComponent, {
      data: {
        title: "driver_profile",
        user: this.driver_data
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res) {
        console.log(res);
        this.driverProfile = res;
        this.driver.emit(this.driver_data);
      }
    })
  }

  logout() {
    localStorage.removeItem("driver_auth");
    localStorage.removeItem("driver_authExpiration");
    this.router.navigate(['/']);
  }

}

export class Driver {
  emailId: string = '';
  mobileNumber: string = '';
  driverName: string = '';
  driver_id: string = '';
  aadharNumber: string = '';
  aadharUpload: string = '';
  aadharStatus: string = '';
  panNumber: string = '';
  panUpload: string = '';
  panStatus: string = '';
  dlNumber: string = '';
  dlUpload: string = '';
  dlStatus: string = '';
  policeVerification: string = '';
  policeVerificationStatus: string = '';
  status: string = '';
  connectedWith: string = '';

  [key: string]: string
}