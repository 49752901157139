<mat-card class="collectPaymentCard">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon">close</mat-icon>
    </button> <mat-card class="bookingDetails">
        <div *ngIf="bookingDetails">
            <div class="row booking-header">
                Booking ID: {{bookingDetails.booking_id}}
            </div>
            <div class="row">
                <div class="col-md-4 customer-details">
        
                    <div class="data-element">
                        <span>Customer Name:</span><span> {{bookingDetailsFromTable.customer_name}}</span>
                    </div>
                    <div class="data-element"> <span>Customer Contact:
                        </span><span>{{bookingDetailsFromTable.customer_contact}}</span>
                    </div>
                    <div class="data-element"> <span>Ride Type: </span><span>{{bookingDetails.tripType}}</span></div>
                    <div class="data-element"> <span>Allocated Cab:
                        </span><span>{{bookingDetails.car_id}}</span></div>
                    <div class="data-element"> <span>Allocated Driver:
                        </span><span>{{bookingDetails.driver_id}}</span></div>
        
                </div>
                <div class="col-md-4 customer-details">
                    <div class="data-element"> <span>Pickup City: </span><span>{{bookingDetails.booking_from.city}}</span>
                    </div>
                    <div class="data-element"> <span>Pickup Address
                            :</span><span>{{bookingDetails.booking_from.landmark}}</span></div>
                    <div class="data-element"> <span>Pickup Date Time:
                        </span><span>{{bookingDetails.pickup_date | date}} {{bookingDetails.pickup_time}}</span>
                    </div>
                    <div class="data-element"> <span>Drop City: </span><span>{{bookingDetails.booking_to.city}}</span></div>
                    <div class="data-element"> <span>Drop Address: </span><span>{{bookingDetails.booking_to.landmark}}</span>
                    </div>
                    </div>
                    <div class="col-md-4 customer-details">
                        <div class="data-element"> <span>Total Distance: </span><span>{{bookingDetails.kmsOfRide}} KM</span>
                        </div>
                        <div class="data-element"> <span>Total Price: </span><span>{{bookingDetails.totalAmount | currency:'INR'}}</span>
                        </div>
                        <div class="data-element"> <span>10% of Total Price: </span><span>{{advanceAmountForBooking |
                                currency:'INR'}}</span>
                        </div>
                        <div class="data-element"> <span>Agent Accepted Ride: </span><span>{{bookingDetails.assignedBy}}</span></div>
        
                </div>
            </div>
        </div>
    </mat-card>
    <mat-card class="transactionCard">
        Add Transaction Details
        <div class="payment-section row">
            <mat-form-field class="col-md-4">
                <mat-label>Transaction ID</mat-label>
                <input type="text" matInput placeholder="TransactionID" required [(ngModel)]="transactionID">
            </mat-form-field>
            <mat-form-field class=" col-md-4">
                <mat-label>Amount</mat-label>
                <input [min]="0" matInput placeholder="Amount" required [(ngModel)]="amount">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label>Mode</mat-label>
                <mat-select [(ngModel)]="modeOfTransaction">
                    <mat-option value="Cash">Cash</mat-option>
                    <mat-option value="UPI">UPI</mat-option>
                    <mat-option value="BankTransfer">Bank Transfer</mat-option>
                    <mat-option value="Whatsapp">Whatsapp</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="row">
            <button class="btn btn-primary" (click)="addTransaction()"
                [disabled]="amount == 0 || transactionID == ''">Submit</button>
        </div>
    </mat-card>
</mat-card>