<mat-card class="container-fluid" style="margin-top: 1rem; box-shadow: 0px 0px 4px 0px #6e6e07; margin-bottom: 2rem;">

    <div class="mat-elevation-z8 table-responsive">
      <table mat-table [dataSource]="dataSource">
        <ng-container *ngFor="let column of columnData" [matColumnDef]="column.field">
          <th mat-header-cell *matHeaderCellDef>{{ column.label | uppercase }}</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="column.field === 'date'">{{ date(element[column.field]) | date }}</span>
            <span *ngIf="column.field !== 'date'">{{ element[column.field] }}</span>
            <span *ngIf="column.field === 'start_ride' && element.status === 'Scheduled'">
              <ng-container *ngIf="isScheduled(element)">
                <button mat-icon-button style="color: rgb(221,162,4);" (click)="startRide(element)">
                  <mat-icon>directions_car</mat-icon>
                </button>
              </ng-container>
            </span>
          </td>
        </ng-container>        
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5,10,20]" showFirstLastButtons aria-label="Select page of periodic elements"></mat-paginator>
    </div>
  </mat-card>
  