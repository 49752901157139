import { Component, SimpleChange } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginSignupComponent } from 'src/app/login-signup/login-signup.component';
import { CancelRidePopupComponent } from './cancel-ride-popup/cancel-ride-popup.component';

@Component({
  selector: 'app-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.scss']
})
export class CustomerProfileComponent {
  user_data: any;
  upcoming_rides: any[] = [];
  completed_rides: any[] = [];
  cancelled_rides: any[] = [];
  showLoader = true;


  constructor(private customerService: CustomerService, private router: Router, private activeRouter: ActivatedRoute, private dialog: MatDialog) {
    let auth = localStorage.getItem('customer_auth');
    this.customerService.fetchProfile(auth).subscribe((res) => {
      this.user_data = res.result;
      this.fetchRides();
    })
  }

  fetchRides() {
    this.showLoader = true
    this.upcoming_rides = [];
    this.completed_rides = [];
    this.cancelled_rides = [];
    this.customerService.total_rides(this.user_data.customer_id).subscribe((res) => {
      res.result.forEach((items: any) => {
        if (items.status == 'Completed') {
          this.completed_rides.push(items);
        } else if (items.status == 'Cancelled') {
          this.cancelled_rides.push(items);
        } else if (items.status == 'Scheduled' || items.status == 'Forwarded' || items.status == 'Requested' || items.status == 'Assigned') {
          this.upcoming_rides.push(items);
        }
      });
      this.cancelled_rides = this.cancelled_rides.filter((ride: any) => ride.status === 'Cancelled');
      this.upcoming_rides = this.upcoming_rides.filter((items: any) => (items.status == 'Scheduled' || items.status == 'Forwarded' || items.status == 'Requested' || items.status == 'Assigned'))
      console.log("Upcoming Rides: ", this.upcoming_rides, this.cancelled_rides, this.completed_rides);
      this.showLoader = false;
    })
  }


  customer_upcoming_column = [
    { field: "from", label: "From" },
    { field: "to", label: "To" },
    { field: "date", label: "Date" },
    { field: "time", label: "Time" },
    { field: "driverName", label: "Driver Name" },
    { field: "mobileNumber", label: "Driver Contact" },
    { field: "car_no", label: "Vehicle Number" },
    { field: "action", label: "Action" }
  ]

  customer_completed_column = [
    { field: "from", label: "From" },
    { field: "to", label: "To" },
    { field: "date", label: "Date" },
    { field: "time", label: "Time" },
    { field: "distance", label: "Distance" },
    { field: "fare", label: "RECEIPT - AMOUNT/EXTRA CHARGES" },
  ]

  customer_cancled_column = [
    { field: "from", label: "From" },
    { field: "to", label: "To" },
    { field: "date", label: "Date" },
    { field: "time", label: "Time" },
    { field: "distance", label: "Distance" },
  ]

  ngOnInit() {
  }

  ngOnChanges(change: SimpleChange) {
    if (change) {
      this.edit_profile();
    }
  }

  signout() {
    localStorage.removeItem('customer_auth');
    localStorage.removeItem('customer_authExpiration');
    this.router.navigate(['/']).then(() => {
      // Force reload or refresh to ensure updated state
      window.location.reload();
    });
  }

  edit_profile() {
    const dialog = this.dialog.open(LoginSignupComponent, {
      data: {
        title: "profile",
        user: this.user_data
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.user_data = res;
      }
    })
  }





}
