<mat-card class="example-card">
    <mat-card-header class="justify-content-around ">
        <mat-card-title>Driver</mat-card-title>
    </mat-card-header>
</mat-card>
<ng-container *ngIf="showLoader; else content" class="loader-container">
    <div class="row">
        <div class="loader"></div>
    </div>
</ng-container>
<ng-template #content>
    <div class="steeper ">
        <mat-card class="m-2 mb-4">
            <mat-horizontal-stepper [selectedIndex]="currentStep" (selectionChange)="onStepChange($event)">
                <mat-step label="All Drivers" completed="false">
                    <div style="display: flex; justify-content: flex-end;">
                        <mat-form-field class="col-md-3">
                            <mat-label>Filter Driver</mat-label>
                            <mat-select [(ngModel)]="selectedFilter" (ngModelChange)="changeFilterValue()">
                                <mat-option *ngFor="let filter of ride_filter" [value]="filter">{{filter}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <ng-template matStepLabel>All Drivers</ng-template>
                    <app-table [data]="tableData" source="Admin" [columnData]="columnData" [flag]="'drivers'"
                        (driverStatusUpdated)="fetchAllDriver()"></app-table>
                </mat-step>
                <mat-step label="Add Driver" completed="false">
                    <!-- <form class="add_driver_form"> -->
                    <div class="form-group col-sm-12">
                        <mat-card class="example-card">
                            <mat-card-header class="justify-content-around ">
                                <mat-card-title>Driver Basic Info</mat-card-title>
                            </mat-card-header>
                            <div class="d-flex main-div mt-4">
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter Driver Name :<span
                                            class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput placeholder="e.g. Jane Smith"
                                            [(ngModel)]="driverInformation.driverName">
                                    </mat-form-field>
                                </div>
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Profile Image :<span
                                            class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput class="fileInput">
                                        <input type="file" (change)="onFileSelected($event, 'profilePic')"
                                            [(ngModel)]="driverInformation.profilePic">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="d-flex main-div mt-4">
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter Driving Licence Number:<span
                                            class="text-danger">*</span></div>
                                    <mat-form-field class="input-field">
                                        <input matInput placeholder="XXXX XXXX 1234"
                                            [(ngModel)]="driverInformation.dlNumber" maxlength="13">
                                    </mat-form-field>
                                </div>
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Driver Driving Licence :<span
                                            class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput class="fileInput">
                                        <input type="file" (change)="onFileSelected($event,'dlUpload')" [(ngModel)]="driverInformation.dlUpload">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="d-flex main-div mt-4">
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter Aadhar Number:<span
                                            class="text-danger">*</span></div>
                                    <mat-form-field class="input-field">
                                        <input matInput placeholder="XXXX XXXX 1234" appNumericOnly maxlength="12"
                                            [(ngModel)]="driverInformation.aadharNumber">
                                    </mat-form-field>
                                </div>
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Driver Aadhar-Card :<span
                                            class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput class="fileInput">
                                        <input type="file" (change)="onFileSelected($event,'aadharUpload')" [(ngModel)]="driverInformation.aadharUpload">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="d-flex main-div mt-4">
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter PAN Number:<span
                                            class="text-danger">*</span></div>
                                    <mat-form-field class="input-field">
                                        <input matInput placeholder="XXXX XXXX 12" maxlength="10" [(ngModel)]="driverInformation.panNumber">
                                    </mat-form-field>
                                </div>
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Driver PAN-Card :<span
                                            class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput class="fileInput">
                                        <input type="file" (change)="onFileSelected($event,'panUpload')" [(ngModel)]="driverInformation.panUpload">
                                    </mat-form-field>
                                </div>

                            </div>
                            <div class="d-flex main-div mt-4">
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter EmailId :<span
                                            class="text-danger">*</span></div>
                                    <mat-form-field class="input-field">
                                        <input matInput placeholder="e.g. jane.smith@example.com" [(ngModel)]="driverInformation.emailId">
                                    </mat-form-field>
                                </div>
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Police Verification :<span
                                            class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput class="fileInput">
                                        <input type="file" (change)="onFileSelected($event,'policeVerification')"
                                            [(ngModel)]="driverInformation.policeVerification">
                                    </mat-form-field>
                                </div>
                            </div>
                            
                            <div class="d-flex main-div mt-4">
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter Contact Number :<span
                                            class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <!-- <input matInput placeholder="e.g 987XXXXXXX" [(ngModel)]="driverInformation.mobileNumber"> -->
                                        <input matInput placeholder="e.g 987XXXXXXX"
                                            [(ngModel)]="driverInformation.mobileNumber" appNumericOnly maxlength="10" *ngIf="!otpSent">
                                        <input matInput [placeholder]="driverInformation.mobileNumber" *ngIf="otpSent"
                                            [disabled]="true">
                                    </mat-form-field>
                                    <div *ngIf="isOTPVerified">
                                        <div class="wrapper"> <svg class="checkmark"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                <circle class="checkmark__circle" cx="26" cy="26" r="25"
                                                    fill="none" />
                                                <path class="checkmark__check" fill="none"
                                                    d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                

                            </div>
                            <div class="fw-bold label" *ngIf="!otpSent">
                                <button class="btn login" (click)=" SendOTP()" [disabled]="!isMobileNumberValid()">Send OTP</button>
                            </div>
                            <div class="d-flex main-div mt-4" *ngIf="otpSent && !isOTPVerified">
                                <div
                                    class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter OTP :<span class="text-danger">*</span>
                                    </div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input type="tel" matInput placeholder="Enter 6 digit OTP" maxlength="6" appNumericOnly [(ngModel)]="otp"
                                            appNumericOnly>
                                    </mat-form-field>
                                    <div
                                        class="d-flex flex-column flex-md-row align-items-baseline justify-content-start">
                                        <button class="btn login" (click)="verifyOtp()" [disabled]="!activateLoginBtn()">Verify</button>
                                        <button class="btn login" (click)="ResendOTP()">Resend OTP</button>
                                        <button class="btn login" (click)="Cancel()">Cancel</button>
                                    </div>
                                </div>
                            </div>

                            <!-- <mat-card-header class="mt-4 justify-content-around">
                                <mat-card-title>Driver Vehicle Info</mat-card-title>
                            </mat-card-header>
                            <div class="d-flex main-div mt-4">
                                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter Vehicle Type :<span class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput placeholder="e.g. Sedan">
                                    </mat-form-field>
                                </div>
                                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Registration Certificate :<span class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput class="fileInput">
                                        <input type="file" (change)="docUpload.onFileSelected($event)">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="d-flex main-div mt-4">
                                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter Vehicle Model :<span class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput placeholder="e.g. Toyota Camry">
                                    </mat-form-field>
                                </div>
                                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Insurance :<span class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput class="fileInput">
                                        <input type="file" (change)="docUpload.onFileSelected($event)">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="d-flex main-div mt-4">
                                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter Vehicle Number :<span class="text-danger">*</span></div>
                                    <mat-form-field class="input-field">
                                        <input matInput placeholder="e.g. XYZ789">
                                    </mat-form-field>
                                </div>
                                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Permit :<span class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput class="fileInput">
                                        <input type="file" (change)="docUpload.onFileSelected($event)">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="d-flex main-div mt-4">
                                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter Work Location:<span class="text-danger">*</span></div>
                                    <mat-form-field class="input-field">
                                        <input matInput placeholder="e.g. City B">
                                    </mat-form-field>
                                </div>
                                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Car Photo With Owner :<span class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput class="fileInput">
                                        <input type="file" (change)="docUpload.onFileSelected($event)">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="d-flex main-div mt-4">
                                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Car Owner PAN Card :<span class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput class="fileInput">
                                        <input type="file" (change)="docUpload.onFileSelected($event)">
                                    </mat-form-field>
                                </div>
                                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Car Owner Aadhar Card :<span class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput class="fileInput">
                                        <input type="file" (change)="docUpload.onFileSelected($event)">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="btn">
                                <button type="submit" class="btn btn-success col-sm m-3">Submit</button>
                                <button type="submit" class="btn btn-danger col-sm m-3" (click)="cancleForm()">Cancle</button>
                            </div> -->
                            <div class="btn driver">
                                <button type="submit" class="btn btn-success col-sm m-3" (click)="addDriver()"
                                    [disabled]="!isFormValid()">Submit</button>
                                <button type="submit" class="btn btn-danger col-sm m-3"
                                    (click)="cancleForm()">Cancle</button>
                            </div>
                        </mat-card>
                    </div>
                    <!-- </form> -->
                </mat-step>
            </mat-horizontal-stepper>
        </mat-card>
    </div>
</ng-template>














<!-- <mat-card class="table-card">
                    <div class="container">
                        <button class="button add-driver" (click)="goToNextStep()"> 
                        Add Driver
                      </button>
                        <input type="text" matInput name="text" class="input" placeholder="search customer" (keyup)="applyFilter($event)" #input>
                        <button class="search__btn">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22">
                          <path
                            d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                            fill="#efeff1"></path>
                        </svg>
                      </button>
                    </div>
                    <div class="table-container">
                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                            <ng-container matColumnDef="Driver_Name">
                                <th mat-header-cell *matHeaderCellDef> Customer </th>
                                <td mat-cell *matCellDef="let element"> {{element.Driver_Name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="EmailId">
                                <th mat-header-cell *matHeaderCellDef> EmailId </th>
                                <td mat-cell *matCellDef="let element"> {{element.EmailId}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Contact">
                                <th mat-header-cell *matHeaderCellDef> Contact </th>
                                <td mat-cell *matCellDef="let element"> {{element.Contact}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Vehicle_Type">
                                <th mat-header-cell *matHeaderCellDef> Vehicle Type </th>
                                <td mat-cell *matCellDef="let element"> {{element.Vehicle_Type}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Vehicle_Model">
                                <th mat-header-cell *matHeaderCellDef> Vehicle Model </th>
                                <td mat-cell *matCellDef="let element"> {{element.Vehicle_Model}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Vehicle_number">
                                <th mat-header-cell *matHeaderCellDef> Vehicle Number </th>
                                <td mat-cell *matCellDef="let element"> {{element.Vehicle_number}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Work_loction">
                                <th mat-header-cell *matHeaderCellDef> Work Loction </th>
                                <td mat-cell *matCellDef="let element"> {{element.Work_loction}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Statements">
                                <th mat-header-cell *matHeaderCellDef> Statements </th>
                                <td mat-cell *matCellDef="let element">
                                    <Button class="button" [routerLink]="['/rideHistory']">View Statements </Button> </td>
                            </ng-container>
                            <ng-container matColumnDef="DocumentList">
                                <th mat-header-cell *matHeaderCellDef> Document List </th>
                                <td mat-cell *matCellDef="let element">
                                    <Button class="button" [routerLink]="['/rideHistory']">View Documents </Button>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Ride_History">
                                <th mat-header-cell *matHeaderCellDef> Ride History </th>
                                <td mat-cell *matCellDef="let element">
                                    <Button class="button" [routerLink]="['/rideHistory']">{{element.Ride_History}} </Button>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Status">
                                <th mat-header-cell *matHeaderCellDef> Status </th>
                                <td mat-cell *matCellDef="let element">
                                    <Button class="btn btn-success button1" *ngIf="element.Status == 'Active'"> {{element.Status}} </Button>
                                    <Button class="btn btn-danger button1" *ngIf="element.Status == 'Inactive'"> {{element.Status}} </Button>

                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </mat-card> -->