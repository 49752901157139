<nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
        <a class="navbar-brand" href="/">
            <img src="/assets/brand-logo.png" alt="Travvol Logo">
        </a>
        <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button> -->
        <!-- <div class="collapse navbar-collapse" id="navbarNav"> -->
        <div class="contact">
            <div class="code"><mat-icon style="font-size: 1rem;">phone</mat-icon><span>24x7</span></div>
            <div class="phoneNumber"><a href="tel: 9871321228"> 9871321228</a></div>
        </div>
        <div class="contact-phoneView">
            <a href="tel: 9871321228">
                <mat-icon style="font-size: 1rem;">phone</mat-icon>
                <span>24x7</span>
            </a>
        </div>
        <ul class="navbar-nav ul">
            <!-- <li class="nav-item d-flex align-items-center">
                                                                                                                                        <mat-icon>tablet_android</mat-icon>
                                                                                                                                        <a class="nav-link" href="#">Download App</a>
            </li> -->
            <li class="nav-item align-items-center agent-element" (click)="agentLogin()">
                <a class="nav-link">
                    <span class="nav-link " style="display: flex; flex-direction: column; align-items: center;">
                        <mat-icon>card_travel</mat-icon>
                        Partner
                    </span></a>
            </li>
            <!-- <li class="nav-item d-flex align-items-center">
                <a class="nav-link" href="#">Contact</a>
            </li> -->

            <!-- <li class="nav-item d-flex align-items-center">
                                                                                                                                        <span class="nav-link" (click)="adminLogin()">Dashboard</span>
                                                                                                                                    </li> -->
            <li class="nav-item align-items-center driver-element" (click)="driverLogin()">
                <span class="nav-link"><span class="nav-link" style="display: flex; flex-direction: column; align-items: center;">
                        <mat-icon>card_travel</mat-icon>
                        Driver
                    </span></span>
            </li>
            <li class="nav-item d-flex align-items-center" *ngIf="profile" (click)="profile_component()">
                <span class="nav-link" style="display: flex; flex-direction: column; align-items: center;">
                    <mat-icon>account_circle</mat-icon>
                    Profile
                </span>
            </li>
            <li class="nav-item d-flex align-items-center" *ngIf="!profile" (click)="login_signUp()">
                <span class="nav-link" style="display: flex; flex-direction: column; align-items: center;">
                    <mat-icon>account_circle</mat-icon>
                    Login
                </span>
            </li>
            </ul>
        <!-- </div> -->
    </div>
</nav>