import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../environment';

@Injectable({
  providedIn: 'root'
})
export class AgentServiceService {
  backendURL: string = environment.apiUrl;

  constructor(public http: HttpClient) { }

  agent_register(data: any): Observable<any> {
    console.log("Service file Agent : ",data)
    return this.http.post(this.backendURL + `/agent/register`, data);
  }
  agent_login(phoneNumber: any): Observable<any> {
    return this.http.post(this.backendURL + `/agent/login`, {phoneNumber});
  }
  agent_profile(id: any): Observable<any> {
    console.log(id)
    return this.http.post(this.backendURL + `/agent/profile`, {id});
  }
  agent_profileUpdate({ agentProfile, agentId }: { agentProfile: any, agentId: any }): Observable<any> {
    return this.http.put(this.backendURL + `/agent/profile`, { agentProfile, agentId });
  }
  agent_profileDelete(data: any): Observable<any> {
    return this.http.delete(this.backendURL + `/agent/${data}`);
  }
  agent_profileChangePassword(data: any): Observable<any> {
    return this.http.put(this.backendURL + `/agent/change_password`, data);
  }
  agent_fetchallProfiles(): Observable<any> {
    return this.http.get(this.backendURL + `/agent/fetchall`);
  }
  
  getAllBookings(agentId: string): Observable<any>{
    return this.http.get(this.backendURL + "/agent/getAllBookins/"+ agentId);
  }
  getAvailableCarAndDriver(agentId: string): Observable<any> {
    return this.http.get(this.backendURL + "/bookings/getAllAvailableCarAndDriver/" + agentId);
  }
  acceptBooking(bookingData: any): Observable<any>{
    return this.http.put(this.backendURL + "/bookings/acceptBooking", {bookingId : bookingData.BookingID, driverId : bookingData.driverID, carId: bookingData.cabID, assignedBy: bookingData.AssignedBy})
  }
  getAllDriverLinked(agentId: any): Observable<any>{
    return this.http.get(this.backendURL + "/agent/getAllDriversLinked/" + agentId);
  }  
  linkCar(agentId: any, cabId: any): Observable<any>{
    return this.http.put(this.backendURL + '/agent/linkCar', {agentId, cabId})
  }
  linkDriver(agentId: any, driverPhoneNumber: any): Observable<any>{
    return this.http.put(this.backendURL + '/agent/linkDriver', {agentId, driverPhoneNumber})
  }
  delinkDriver(agentId: any, driverPhoneNumber: any, driverId: any): Observable<any>{
    return this.http.put(this.backendURL + '/agent/delinkDriver', {agentId, driverPhoneNumber, driverId})
  }
  getAllCarsLinked(agentId: any): Observable<any>{
    return this.http.get(this.backendURL + "/agent/getAllCarsLinked/"+agentId);
  }

  getStatesData(): Observable<any> {
    return this.http.get<any>('assets/stateDistrict.json');
  }

  getWorkLocation(agentId:string): Observable<any>{
    return this.http.get<any>(this.backendURL + "/agent/getWorkLocation/" + agentId);
  }

  addWorkLocation(agentId: string,location: string): Observable<any>{
    return this.http.put(this.backendURL + '/agent/addWorkLocation', {agentId, location})
  }

  updatePreferredCity(agentId: string,location: string): Observable<any>{
    return this.http.put(this.backendURL + '/agent/updatePreferredCity', {agentId, location})
  }

  removeWorkLocation(agentId: string,location: string): Observable<any>{
    return this.http.put(this.backendURL + '/agent/removeWorkLocation', {agentId, location})
  }

  getDashboardData(agentId: string): Observable<any>{
    console.log(agentId)
    return this.http.get(this.backendURL + '/agent/getDashboardData/' + agentId);
  }

  checkIfAgentPresent(phoneNumber: string): Observable<any> {
    return this.http.get(this.backendURL + '/agent/checkStatus/' + phoneNumber)
  }

  delinkCab(agentId: string, cabNumber: string): Observable<any>{
    return this.http.put(this.backendURL + '/agent/delinkCar', {agentId, cabNumber});
  }

}
