export class vehicle{
    CarBrand:string=''
    CarName:string=''
    CarNumber:string=''
    cabCategoryId: string = ''
    FuelType:string=''
    NumberOfSeats: string = ''
    registrationCertificateUpload:string=''
    InsuranceValidity!: Date | string;
    InsuranceUpload:string=''
    PermitUpload:string=''
    CarOwnerName:string=''
    CarOwnerPhoneNumber:string = ''
    CarOwnerAadharUpload:string=''
    CarOwnerPanCardUpload:string=''
    CarPhotoWithOwnerUploaod: string = '';
    [key: string]: string | Date;
}