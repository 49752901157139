import { Component, ViewChild } from '@angular/core';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../services/admin.service';
import { vehicle } from './vehicle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AwsUploadService } from 'src/app/services/aws-upload.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent {

  vehicleForm: vehicle = new vehicle();
  vehicleDetails: any[] = [];
  cabCategoryList: any[] = [];
  tableData: any[] = [];
  vehicleFilterData: any[] = [];
  showLoader: boolean = true;
  otpSent: boolean = false;
  otp: string='';
  orderId: any;
  isOTPVerified:boolean = false;
  minDate: Date;
  cabFuelTypeList = ["Petrol", "Diesel", "CNG", "Electric"];

  @ViewChild(MatStepper) stepper!: MatStepper;
  constructor(private datePipe: DatePipe, public as: AdminService, private snackBar: MatSnackBar, private adminservice: AdminService, public docUpload: AwsUploadService) {
    this.minDate = new Date();
  }

  ngOnInit() {
    this.fetchVehicle()
  }
  fetchVehicle() {
    this.as.getAllVehicleForDashboard().subscribe((response: any) => {
      this.cabCategoryList = response.cabCategories;
      let res = response.allVehicles;
      if (Array.isArray(res) && res.length > 0) {
        this.vehicleDetails = res;
        this.vehicleFilterData = res;
        this.tableData = this.vehicleDetails.map(vehicle => {
          return {
            Owner_Name: vehicle.CarOwnerName,
            Mobile: vehicle.CarOwnerPhoneNumber,
            limit: vehicle.userLimit,
            vehicle_Brand: vehicle.CarBrand,
            Vehicle_Number: vehicle.CarNumber,
            Vehicle_Mode: vehicle.CarName,
            status: vehicle.Status,
          }
        });
      } else {
        console.log("Invalid response from the backend");
      }
      this.showLoader  = false; 

    })
  }
  columnData = [
    { field: "Owner_Name", label: "Owner Name" },
    { field: "Mobile", label: "Mobile" },
    { field: "vehicle_Brand", label: "vehicle Type" },
    { field: "Vehicle_Number", label: "Vehicle Number" },
    { field: "Vehicle_Mode", label: "Vehicle Model" },
    // { field: "Driver_Linked", label: "Driver Linked" },
    // { field: "Driver_Contact", label: "Driver Contact" },
    { field: "Document_List", label: "Document List" },
    { field: "status", label: "Status" },
  ];

  selectedValue: any = '';
  ride_filter: any[] = ['All', 'Approved', 'Unapproved']

  changeFilterValue() {
    if (this.selectedValue == 'All') {
      this.tableData = this.vehicleDetails.map(vehicle => {
        return {
          Owner_Name: vehicle.CarOwnerName,
          Mobile: vehicle.CarOwnerPhoneNumber,
          limit: vehicle.userLimit,
          vehicle_Brand: vehicle.CarBrand,
          Vehicle_Number: vehicle.CarNumber,
          Vehicle_Mode: vehicle.CarName,
          status: vehicle.Status,
        }
      })
    }
    else {
      console.log("!!!! : ", this.selectedValue)
      this.tableData = this.vehicleDetails.filter(element => element.Status == this.selectedValue).map(vehicle => {
        return {
          Owner_Name: vehicle.CarOwnerName,
          Mobile: vehicle.CarOwnerPhoneNumber,
          limit: vehicle.userLimit,
          vehicle_Brand: vehicle.CarBrand,
          Vehicle_Number: vehicle.CarNumber,
          Vehicle_Mode: vehicle.CarName,
          status: vehicle.Status,
        }
      })
    }
  }

  currentStep: number = 0;
  onStepChange(event: StepperSelectionEvent): void {
    this.currentStep = event.selectedIndex;
  }
  onFileSelected(event: any, source: string) {
    const file = event.target.files[0].name
    if (file) {
      this.docUpload.onFileSelected(event).then((res: any) => {
        if (this.vehicleForm.hasOwnProperty(source)) {
          this.vehicleForm[source] = res.fileUrl;
          console.log("Driver Info: ", this.vehicleForm);
        } else {
          console.error("File URL is not available.");
        }
      }).catch((error) => {
        console.log("Error in ts: ", error);
      });
    }
  }

  isFormValid(): boolean {

    return (
      // Example validation condition
      !!this.vehicleForm.CarBrand &&
      !!this.vehicleForm.CarName &&
      !!this.vehicleForm.CarNumber &&
      !!this.vehicleForm.CarOwnerAadharUpload &&
      !!this.vehicleForm.cabCategoryId &&
      !!this.vehicleForm.CarOwnerName &&
      !!this.vehicleForm.CarOwnerPanCardUpload &&
      !!this.vehicleForm.CarOwnerPhoneNumber &&
      !!this.vehicleForm.CarPhotoWithOwnerUploaod &&
      !!this.vehicleForm.FuelType &&
      !!this.vehicleForm.InsuranceUpload &&
      !!this.vehicleForm.NumberOfSeats &&
      !!this.vehicleForm.PermitUpload &&
      !!this.vehicleForm.registrationCertificateUpload &&
      !!this.isOTPVerified
    );
  }

  //add vehicle
  addVehicle() {
    console.log("Validity Before: ", this.vehicleForm.InsuranceValidity);
    this.vehicleForm.InsuranceValidity = this.datePipe.transform(this.vehicleForm.InsuranceValidity, 'yyyy-MM-dd') + 'T00:00:00.000Z';
    console.log("Validity Final: ", this.vehicleForm.InsuranceValidity);
    this.as.getVehicleRegisterdForadmin(this.vehicleForm).subscribe((res) => {
      console.log("Value ", this.vehicleForm)
      console.log(res)
      this.vehicleForm = new vehicle();
      this.otpSent = false;
      this.isOTPVerified = false;
      this.fetchVehicle();
      this.stepper.selectedIndex = 0;
    })
  }
  cancleForm() {
    this.stepper.selectedIndex = 0;
    this.vehicleForm = new vehicle();
  }
  
  SendOTP() {
    this.otpSent = true;
    this.adminservice.getOTPValue(parseInt(this.vehicleForm.CarOwnerPhoneNumber)).subscribe((res: any) => {
      this.orderId = res.orderId;
      this.snackBar.open("OTP sent successfully !", 'Close', { duration: 3000 });
    }, (err) => {
      console.log("Error Message : ", err);
      this.snackBar.open(err.error.error, 'Close', { duration: 3000 });
    })
  }

  activateLoginBtn():boolean{
    return this.otp.length ===6
  }

  async verifyOtp(): Promise<boolean> {

    return this.adminservice.getOTPVerified(this.orderId, parseInt(this.otp), parseInt(this.vehicleForm.CarOwnerPhoneNumber)).toPromise()
        .then((res: any) => {
            this.isOTPVerified = res.isOTPVerified;
            this.snackBar.open("OTP verified successfully !", 'Close', { duration: 3000 });
            return res.isOTPVerified;
        })
        .catch(error => {
          this.snackBar.open(error, 'Close', { duration: 3000 });
            return false;
        });
  }
  ResendOTP(){
    this.adminservice.getReSendOTP(this.orderId).subscribe((res:any)=>{
      this.orderId = res.orderId;
      this.snackBar.open("OTP resend successfull !", 'Close', { duration: 3000 });
    },(err)=>{
      console.log("Error for Resend OTP: ",err)
      this.snackBar.open(err.error.error, 'Close', { duration: 3000 })
    })
  }
  Cancel(){
    this.vehicleForm.CarOwnerPhoneNumber ='';
    this.otpSent=false;
  }
  isMobileNumberValid(): boolean {
    return this.vehicleForm.CarOwnerPhoneNumber.length === 10;
}


}
