<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<div class="row" style="justify-content: space-around">
  <div class="col-md-3 mt-2">
    <div class="card1">
      <div style="display: flex; align-items: baseline; justify-content: space-between;">
        <span> AgentID: </span>
        <h3 class="ms-2">{{ agentId }}</h3>
      </div>
      <div style="display: flex; align-items: baseline; justify-content: space-between;">
        <span> Name: </span>
        <h3 class="ms-2">{{ agentDetails.agentName }}</h3>
      </div>
      <div style="display: flex; align-items: baseline; justify-content: space-between;">
        <span> PhoneNumber: </span>
        <h3 class="ms-2">{{ agentDetails.phoneNumber }}</h3>
      </div>
    </div>
  </div>

  <div class="col-md-4 mt-2">
    <div class="card2">
      <div class="row">
        <div class="col-md-12 agent-data">
          <div class="agent-data" style="display: flex; align-items: baseline">
            Total Ride Collection:
          </div>
          <p>{{ earningData.totalRideCollection || 0 }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 agent-data">
          <div class="agent-data" *ngIf="source == 'AdminEarnings'" style="display: flex; align-items: baseline">
            Total Agent Received:
          </div>
          <div class="agent-data" *ngIf="source != 'AdminEarnings'" style="display: flex; align-items: baseline">
            Total Cash Recieved:
          </div>
          <p>{{ earningData.totalCashReceived || 0 }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 agent-data">
          <div class="agent-data" style="display: flex; align-items: baseline">
            Commissioned Amount:
          </div>
          <p>{{ (earningData.totalRideCollection - earningData.unCommissiondAmount) || 0 }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 agent-data">
          <div class="agent-data" style="display: flex; align-items: baseline">
            Un-Commissioned Amount:
          </div>
          <p>{{ earningData.unCommissiondAmount || 0 }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 agent-data">
          <div class="agent-data" style="display: flex; align-items: baseline">
            Total Company platform charge:
          </div>
          <p>{{ earningData.totalCompanyClaimed || 0 }}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- </div>

<div class="row"> -->
  <div class="col-md-4 mt-2">
    <div class="card2">
      <div class="row">
        <div class="col-md-12 agent-data">
          <div class="agent-data" style="display: flex; align-items: baseline">
            Received From Company:
          </div>
          <p>{{ earningData.receivedFromCompany || 0 }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 agent-data">
          <div class="agent-data" style="display: flex; align-items: baseline">
            Total Amount with Company:
            </div>
          <p>{{ amountToBeClaimed + amountToBePaid }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 agent-data">
          <div class="agent-data" *ngIf="source == 'AdminEarnings'" style="display: flex; align-items: baseline">
            Amount to be claimed:
          </div>
          <div class="agent-data" *ngIf="source != 'AdminEarnings'" style="display: flex; align-items: baseline">
            Amount to be deducted:
          </div>
          <p>{{ amountToBeClaimed || 0 }}</p>
        </div>
      </div>
      
      <div class="row">
        <div class="col-md-12 agent-data">
          <div class="agent-data" *ngIf="source == 'AdminEarnings'" style="display: flex; align-items: baseline">
            Amount to be Paid:
          </div>
          <div class="agent-data" *ngIf="source != 'AdminEarnings'" style="display: flex; align-items: baseline">
            Amount to be Received:
          </div>
          <p>{{ amountToBePaid || 0 }}</p>
        </div>
      </div>
      
      <!-- <div class="col-md-12" *ngIf="source == 'AdminEarnings'">
        <div class="agent-data" style="display: flex; align-items: baseline">
          <H3> Amount to be Paid: </H3> &nbsp;
          <p>{{ amountToBePaid }}</p>
        </div>
      </div> -->

      <!-- <div *ngIf="!settlePaymentEnabled && source == 'AdminEarnings'" class="col-md-12"
                style="display: flex; align-items: baseline; justify-content: space-between;">
                </div> -->
      <div class="col-md-12 settle-btn" *ngIf="
          source == 'AdminEarnings' &&
          !settlePaymentEnabled &&
          amountToBePaid > 0
        ">
        <div>
          <button mat-raised-button class="btn-payment" (click)="settlePaymentEnabled = true">
            Settle Payment
          </button>

        </div>
      </div>
    </div>
  </div>
  <div *ngIf="settlePaymentEnabled && source == 'AdminEarnings'" class="settlePaymentParentDiv mt-2">
    <div class="row settlePayment">
      <div class="col-md-6">
        <div class="col-md-12" style="display: flex; justify-content: center">
          Bank Details
        </div>
        <div class="col-md-12">
          <div class="agent-bank-data" style="display: flex; align-items: baseline">
            <H3> PhonePe Number: </H3> &nbsp;
            <p>{{ agentDetails.phonepeNumber }}</p>
          </div>
        </div>
        <div class="col-md-12">
          <div class="agent-bank-data" style="display: flex; align-items: baseline">
            <H3> UpiId: </H3> &nbsp;
            <p>{{ agentDetails.upiId }}</p>
          </div>
        </div>
        
        <div class="col-md-12">
          <div class="agent-bank-data" style="display: flex; align-items: baseline">
            <H3> Account Number: </H3> &nbsp;
            <p>{{ agentDetails.accountNo }}</p>
          </div>
        </div>
        <div class="col-md-12">
          <div class="agent-bank-data" style="display: flex; align-items: baseline">
            <H3> Bank Name: </H3> &nbsp;
            <p>{{ agentDetails.bankName }}</p>
          </div>
        </div>
        <div class="col-md-12">
          <div class="agent-bank-data" style="display: flex; align-items: baseline">
            <H3> IFSC Code: </H3> &nbsp;
            <p>{{ agentDetails.IFSCCode }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row" style="
            flex-direction: column;
            display: flex;
            align-items: center;
            padding-top: 1rem;
          ">
          <mat-form-field class="col-md-12">
            <mat-label>Transaction Amount</mat-label>
            <input disabled="true" type="text" matInput placeholder="TransactionAmount" [(ngModel)]="amountToBePaid" />
          </mat-form-field>
          <mat-form-field class="col-md-12">
            <mat-label>Transaction ID</mat-label>
            <input type="text" matInput placeholder="TransactionID" required [(ngModel)]="transactionID" />
          </mat-form-field>
          <mat-form-field class="col-md-12">
            <mat-label>Mode</mat-label>
            <mat-select [(ngModel)]="modeOfTransaction">
              <mat-option value="Cash">Cash</mat-option>
              <mat-option value="UPI">UPI</mat-option>
              <mat-option value="BankTransfer">Bank Transfer</mat-option>
              <mat-option value="Whatsapp">Whatsapp</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="div" style="display: flex; justify-content: space-evenly; align-items: center;">
            <button mat-raised-button class="btn-payment col-md-4" (click)="settlePayment(); settlePaymentEnabled = false">
              Save
            </button>
            <button mat-raised-button class="btn-payment col-md-4" (click)="settlePaymentEnabled = false">
              Cancel
            </button>
            </div>
        </div>
      </div>
    </div>
  </div>
  <mat-card class="m-2 p-4" style="width: 96%;">
    <div *ngIf="transactionData.length == 0" class="noRecordsFound">
      No Records found
    </div>
    <app-table *ngIf="transactionData.length > 0" [data]="transactionData" [columnData]="columnData"
      [flag]="'transactions'"></app-table>
  </mat-card>
</div>