import { Component, Inject } from '@angular/core';
import { AgentServiceService } from 'src/app/services/agent-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent {

  agentProfile: any;

  constructor(
    public dialogRef: MatDialogRef<UpdateProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.agentProfile = { ...data.agentProfile }; // Initialize agentProfile with data passed from parent
  }
  saveProfile(){
    console.log("After close : ",this.agentProfile)
    this.dialogRef.close(this.agentProfile);
    console.log("After closev2 : ",this.agentProfile)

  }
}
