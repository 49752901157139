import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-rating-review',
  templateUrl: './rating-review.component.html',
  styleUrls: ['./rating-review.component.scss']
})
export class RatingReviewComponent {

  ratingData:any[]=[];
  table1Data:any[]=[];
  table2Data:any[]=[];
  showLoader: boolean = true;
  constructor(private adminservice:AdminService){}

  ngOnInit(){
    this.displayRating();
  }
  displayRating(){
    this.adminservice.getAllRating().subscribe((res)=>{
      if(Array.isArray(res) && res.length>0){
        this.ratingData = res;
        console.log("Rating :::: ",this.ratingData)
        this.table1Data=this.ratingData.map(driverRating=>{
          if(driverRating.driverRating){
          return{
            rideId:driverRating.rideId,
            Driver:driverRating.driverName,
            comment:driverRating.driverRating.comment,
            DateTime:driverRating.driverRating.time,
            Rating: driverRating.driverRating.rating,
            customerId:driverRating. customer_id
          }
        }
        else{
          return null;
        }
        }).filter(data => !!data);
        this.table2Data=this.ratingData.map(custRating=>{
          if(custRating.customerRating){
          return{
            rideId:custRating.rideId,
            Driver:custRating.driverName,
            comment:custRating.customerRating.comment,
            DateTime:custRating.customerRating.time,
            Rating: custRating.customerRating.rating,
            customerId:custRating. customer_id
          }
        }
        else{
          return null;
        }
        }).filter(data => !!data);
      }
      this.showLoader  = false; 

    })
  }
  column1Data = [
    { field: "rideId", label: "Ride ID" },
    { field: "Driver", label: "Driver Name" },
    { field: "customerId", label: "Customer Id" },
    { field: " ", label: "Rating" },
    { field: "DateTime", label: "Date & Time" },
    { field: "comment", label: "Comments" },
    { field: "Action", label: "Action" }
  ];
  column2Data = [
    { field: "rideId", label: "Ride ID" },
    { field: "customerId", label: "Customer Id" },
    { field: "Driver", label: "Driver Name(To)" },
    { field: " ", label: "Rating" },
    { field: "DateTime", label: "Date & Time" },
    { field: "comment", label: "Comments" },
    { field: "Action", label: "Action" }
  ];
    
  currentStep: number = 0;
  onStepChange(event: StepperSelectionEvent): void {
    this.currentStep = event.selectedIndex;
  }
}
