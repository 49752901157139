<div class="container-fluid">
    <mat-card class="form-card mx-auto">
        <form>
            <div class="img-div" style="display: flex; justify-content: center;">
                <img src="assets/loginnew-logo1.png">
            </div>
            <ng-container *ngIf="!loginphone">
            <div class="row g-3 align-items-center justify-content-center">
                <div class="col-auto">
                    <label for="inputPassword6" class="col-form-label">phone Number</label>
                </div>
                <div class="col-auto">
                    <input name="phonenumber" class="form-control" [(ngModel)]="phonenumber" maxlength="10">
                </div>

            </div>
            <div class="btn-div">
                <button mat-raised-button type="submit" (click)="sendOTP()">Send OTP</button>
            </div>
        </ng-container>
            <ng-container *ngIf="loginphone">
            <div class="row g-3 align-items-center justify-content-center">
               
                    <div class="col-auto"> 
                        <span class="grid-item">OTP has been sent to ****6151</span>
                    </div>
                    <div class="col-auto"> 
                    <div class="input-container d-flex flex-row justify-content-center">
                        <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                            style="width: 2.5rem;">
                        <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                            style="width: 2.5rem;">
                        <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                            style="width: 2.5rem;">
                        <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                            style="width: 2.5rem;">
                        <input type="text" class="m-1 form-control rounded opt_input" maxlength="1"
                            style="width: 2.5rem;">
                    </div>
                    </div>
            </div>
            <div class="btn-div">
                <button mat-raised-button type="submit" (click)="validateOTP()">Validate</button>
            </div>
            <div class="btn-div">
                <p>Didn't get the code? <mat-icon (click)="sendOTP()">refresh</mat-icon></p>
            </div>
        </ng-container>
           
            <div class="content__or-text">
                <span></span>
                <span>OR</span>
                <span></span>
              </div>
              <div style="text-align: center;">
                <p><a class="link-offset-2 link-underline link-underline-opacity-50" href="#">Not registered yet?</a></p>
              </div>
        </form>
    </mat-card>
</div>