<div *ngIf="type == 'approval'">
    <h1 mat-dialog-title>Confirmation</h1>
    <div mat-dialog-content>
        Are you sure you want to perform this action?
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button (click)="onApproveClick()">Approve</button>
        <button mat-raised-button (click)="onRejectClick()">Reject</button>
        <button mat-raised-button mat-dialog-close>Cancel</button>
    </div>
</div>

<div *ngIf="type == 'confirmation'">
    <h1 mat-dialog-title>Confirmation</h1>
    <div mat-dialog-content>
        Are you sure you want to perform this action?
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button (click)="cancelRide()">Confirm Cancellation</button>
        <button mat-raised-button mat-dialog-close>Cancel</button>
    </div>
</div>
<div *ngIf="type == 'approveDoc'">
    <h1 mat-dialog-title>Confirmation</h1>
    <div mat-dialog-content>
        Are you sure you want to approve this document?
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button (click)="ApproveDoc()">Approve</button>
        <button mat-raised-button mat-dialog-close>Cancel</button>
    </div>
</div>
<div *ngIf="type == 'rejectDoc'">
    <h1 mat-dialog-title>Confirmation</h1>
    <div mat-dialog-content>
        Are you sure you want to reject this document?
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button (click)="rejectDoc()">Reject</button>
        <button mat-raised-button mat-dialog-close>Cancel</button>
    </div>
</div>