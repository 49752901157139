import { Component, HostListener, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AgentLoginComponent } from '../popupWindows/agent-login/agent-login.component';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {

  windowWidth: number = 0;

  expandPane: boolean = false;
  smallScreen: boolean = false;
  @HostListener('window:resize', ['$event'])

  onResize(event: Event) {
    this.smallScreen = window.innerWidth < 769 ? true : false;
  }

  profile: boolean = false;
  expand: boolean = false;
  pageview: string = "Dashboard";
  admin_data: any;

  constructor(private _service : AdminService, private dialog: MatDialog, private router: Router) {
    const auth = localStorage.getItem("admin_auth");
    const time = localStorage.getItem("admin_authExpiration");
    if (time) {
      const date = new Date(time);
      if (date <= new Date()) {
        localStorage.removeItem('admin_auth');
        localStorage.removeItem('admin_authExpiration');
        this.admin_login();
      } else {
        this.profile = true;
      }
    } else {
      this.admin_login();
    }
  }
  
  admin_login() {
    const dialog = this.dialog.open(AgentLoginComponent, {
      data: {
        title: "admin_login"
      }
    });
    dialog.afterClosed().subscribe(res => {
      if (res) {
        const expirationDate = new Date(new Date().getTime() + 20 * 60 * 60 * 1000);
        localStorage.setItem("admin_auth", res);
        localStorage.setItem("admin_authExpiration", expirationDate.toString());  
        this.profile= true;
      }
    })
  }

  logout(){
    localStorage.removeItem('admin_auth');
    localStorage.removeItem('admin_authExpiration');
    this.profile = false;
    this.router.navigate(['/']);
  }
  

  menuList: any[] = [
    { icon: 'home', label: 'Dashboard', mat_tool_tip: 'Dashboard', route: 'dashboard' },
    { icon: 'people', label: 'Customers', mat_tool_tip: 'All Customers', route: 'customer' },
    { icon: 'directions_car', label: 'Drivers', mat_tool_tip: 'All Drivers', route: 'driver' },
    { icon: 'people', label: 'Partner', mat_tool_tip: 'All Partners', route: 'agent' },
    { icon: 'local_taxi', label: 'Rides', mat_tool_tip: 'All Rides', route: 'rides' },
    { icon: 'camera_roll', label: 'Promo', mat_tool_tip: 'All Promo/Coupons', route: 'promo' },
    { icon: 'local_taxi', label: 'Vehicle', mat_tool_tip: 'All Vehicle', route: 'vehicle' },
    { icon: 'location_on', label: 'Service Location', mat_tool_tip: 'Service location', route: 'service' },
    { icon: 'currency_rupee', label: 'Earnings', mat_tool_tip: 'Admin Reports', route: 'earning' },
    // { icon: 'currency_rupee', label: 'Driver Earnings', mat_tool_tip: 'Driver Reports', route: 'Driverearning' },
    // { icon: 'event_available', label: 'Driver Status', mat_tool_tip: 'Driver Status', route: 'Driverdetails' },
    { icon: 'monetization_on', label: 'Pricing', mat_tool_tip: 'Pricing', route: 'Driverdetails' },
    { icon: 'star', label: 'Rating', mat_tool_tip: 'Rating & Review',route:'ratingReview' },
    { icon: 'settings', label: 'Settings', mat_tool_tip: 'Settings',route:'settings' },
    { icon: 'notifications_active', label: 'Notification', mat_tool_tip: 'notification',route:'notification' },
  ]

  classList: any = [];
  ngOnInit() {
    this.onResize(event!);
  }


}