<mat-card class="example-card">
    <mat-card-header class="justify-content-around ">
        <mat-card-title>Promo Code List</mat-card-title>
    </mat-card-header>
  </mat-card>
  <ng-container *ngIf="showLoader; else content" class="loader-container">
    <div class="row">
      <div class="loader"></div>
    </div>
  </ng-container>
  <ng-template #content>
<div class="steeper">
    <mat-card class="m-2 mb-4">
        <mat-horizontal-stepper [selectedIndex]="currentStep" (selectionChange)="onStepChange($event)">
            <mat-step label="All Promo/Coupon" completed="false">
                <!-- <ng-template matStepLabel>All Promo/Coupon</ng-template> -->
                <app-table [data]="tableData" [columnData]="columnData" [flag]="'promo'" (editClicked)="onEditClicked($event)"></app-table>
            </mat-step>
            <mat-step label="Add Promo/Coupon" completed="false">
                <!-- <form class="add_driver_form"> -->
                    <div class="form-group col-sm-12">
                        <mat-card class="example-card">
                            <div class="d-flex main-div mt-4">
                                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter Code Name :<span class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput [(ngModel)]="promoDetails.codeName" placeholder="e.g.NewYear2024">
                                    </mat-form-field>
                                </div>
                                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Expiry Date :<span class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input matInput [(ngModel)]="promoDetails.expiry_date" [matDatepicker]="picker" placeholder="e.g.21/12/2023">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="d-flex main-div mt-4">
                                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Choose Discount Type :<span class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <mat-select placeholder="select type" [(ngModel)]="promoDetails.discountType">
                                            <mat-option value="flat">Discount in Amount</mat-option>
                                            <mat-option value="percentage">Discount in Percentage</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Enter Discount Amount :<span class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input type="number" [(ngModel)]="promoDetails.discount_Amount" matInput placeholder="e.g.50">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="d-flex main-div mt-4">
                                <div class="d-flex col-sm-6 flex-column flex-md-row align-items-baseline justify-content-start">
                                    <div class="col-sm-4 fw-bold label">Usage Limit For One User :<span class="text-danger">*</span></div>
                                    <mat-form-field class="col-sm-8 input-field">
                                        <input type="number" [(ngModel)]="promoDetails.userLimit" matInput placeholder="e.g.1">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="btn">
                                <button type="submit" class="btn btn-success col-sm m-3" *ngIf="!editForm" [disabled]="!areDetailsFilled()" (click)="savePromoDetails()">Submit</button>
                                <button type="submit" class="btn btn-success col-sm m-3" *ngIf="editForm" [disabled]="!areDetailsFilled()" (click)="editPromoDetails()">Save changes</button>
                                <button type="submit" class="btn btn-danger col-sm m-3" (click)="cancleForm()">Cancle</button>
                            </div>
                        </mat-card>
                    </div>
                <!-- </form> -->
            </mat-step>
        </mat-horizontal-stepper>
    </mat-card>
</div>
  </ng-template>