<mat-drawer-container class="example-container h-100" autosize>
    <mat-drawer #drawer mode="side" opened class="example-sidenav">
        <mat-icon role="button" class="ms-2 mt-3" *ngIf="drawer.opened" (click)="drawer.toggle()">menu</mat-icon>
        <div (click)="pageview=menu.label; expandPane=false"
            [class.menu-group-highlight]="menu.label==pageview" class="d-flex align-items-center p-2 w-100"
            role="button" *ngFor="let menu of menuList">
            <mat-icon>{{menu.icon}}</mat-icon>
            <p class="mb-0 ms-2 menuLabel me-4" *ngIf="expandPane || !smallScreen">{{menu.label}}</p>
        </div>
    </mat-drawer>

    <div class="h-100 overflow-auto">
        <div class="d-flex align-items-center">
            <button type="button" *ngIf="!drawer.opened" mat-button (click)="drawer.toggle()">
            <mat-icon>menu</mat-icon>
            </button>
        </div>
        <ng-container *ngIf="pageview=='Home'">
            <app-driver-home [driver_scheduled_rides]="driver_scheduled_rides"
                [driver_scheduled_column]="driver_scheduled_column"
                [driver_ongoing_rides]="driver_ongoing_rides"></app-driver-home>
        </ng-container>
        <ng-container *ngIf="pageview=='Profile'">
            <app-driver-profile [driver_data]="driverData" (driver)="driver($event)"></app-driver-profile>
        </ng-container>
        <ng-container *ngIf="pageview=='Driver Ride'">
            <app-driver-rides [driver_completed_rides]="driver_completed_rides"
                [driver_Cancelled_rides]="driver_Cancelled_rides"
                [driver_scheduled_column]="driver_scheduled_column"></app-driver-rides>
        </ng-container>
        <!-- <ng-container *ngIf="pageview=='Driver Earnings'">
            <app-driver-earnings></app-driver-earnings>
        </ng-container> -->

    </div>
</mat-drawer-container>