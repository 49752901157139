import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../environment';

@Injectable({
  providedIn: 'root'
})
export class AwsUploadService {
  uploadError:string="";
  progress:any;
  uploading:boolean=false
  backendURL: string = environment.apiUrl;

  constructor(public http: HttpClient) { }

  uploadDocument(documentDetails:any){
    return this.http.post(this.backendURL + `/aws/upload`, documentDetails)
  }

  async onFileSelected(event: any): Promise<Response> {
    let response = new Response();
    this.progress = 1;
    let file:File=event.target.files[0];
    if(file){
      const fileSizeInMB=file.size/(1024 * 1024);
      if(fileSizeInMB>2){
        response.errorMessage = "Your File size exceeds 5MB";
        this.progress=0;
        this.uploading = false;
      }
    }
    let fileType = event.target.files[0].type;
    let fileName = file.name.split('.')[0]+ Date.now()+`.${fileType.split('/')[1]}`;
    let formData = new FormData();
    console.log("AWS File :: ",file.name)
    formData.append('file',event.target.files[0]);
    formData.append('fileName',fileName)
    this.uploading=true;
    try {
      const res: any = await new Promise((resolve, reject) => {
        this.uploadDocument(formData).subscribe(resolve, reject);
      });

      console.log("Uploaded successfully", res);
      this.progress = 0;
      this.uploading = false;
      response.fileUrl = res.fileUrl;
    } catch (error) {
      response.errorMessage = "File upload failed. Please try again later.";
      this.progress = 0;
      this.uploading = false;
      console.log("aws error ", error);
    }

    return response;
  }
}

class Response {
  fileUrl: string = "";
  errorMessage: string = "";
}
