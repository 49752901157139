import { Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { AdminService } from '../../services/admin.service';
import { CustomerService } from '../../services/customer.service';
@Component({
  selector: 'app-explore-cabs',
  templateUrl: './explore-cabs.component.html',
  styleUrls: ['./explore-cabs.component.scss']
})
export class ExploreCabsComponent {
  @ViewChild(MatAccordion) accordion: MatAccordion | undefined;
  constructor(private adminservice: AdminService, private customerService: CustomerService) { }
  pricing: any
  showLoader = true;
  smallScreen: boolean = false;
  @Input() formData: any = '';
  @Input() availableCabs: any = '';
  @Input() selected_trip: any = '';
  @Input() ride_km: number = 0;
  @Input() locations_data: any = '';
  @Output() getDetails: EventEmitter<any> = new EventEmitter<any>();
  @Output() goback: EventEmitter<any> = new EventEmitter<any>();
  @Output() modifySelectedTrip: EventEmitter<any> = new EventEmitter<any>();
  expandCarDetails: Map<number, boolean> = new Map<number, boolean>;
  @HostListener('window:resize', ['$event'])
  rideDistance = 0;
  onResize(event: Event) {
    this.smallScreen = window.innerWidth < 768 ? true : false;
  }
  ngOnInit() {
    this.onResize(event!);
    this.adminservice.getPricingValue().subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.pricing = res.pricingValue[0];
      }
    })
    setTimeout(() => {
      this.showLoader = false;
    }, 2000)
    if (this.formData) {
      console.log(this.formData)
      this.customerService.rideDistance(this.formData['from'], this.formData['to']).subscribe((res) => {
        console.log(res);
        if (res) {
          this.rideDistance = res.distance.replace(" km", "");
          this.formData['kmsOfRide'] = this.rideDistance;
        }
      })
    }
  }

  toggleDetails(cab_id: any) {
    this.expandCarDetails.forEach((value, key) => {
      if (key != cab_id) {
        this.expandCarDetails.set(key, false);
      }
    })
    this.expandCarDetails.set(cab_id, !this.expandCarDetails.get(cab_id));
  }

  selectCar(cabs: any) {
    let data = { cab: cabs, trip: this.formData, total_fare: 0 };
    if (this.formData['trip_type'] !== 'ROUND TRIP') {
      data['total_fare'] = Math.floor(parseFloat((cabs["perKmCharge"] * 2 * this.rideDistance * ((1 + this.pricing["driverAllowance"] / 100) * (1 + this.pricing["tax"] / 100))).toFixed(2)));
    } else {
      data['total_fare'] = Math.floor(((2 * this.rideDistance) * (cabs["perKmCharge"] * (1 + (this.pricing['twoWayFactor'] / 100))) *
        ((1 + this.pricing["driverAllowance"] / 100) * (1 + (this.pricing['twoWayFactor'] / 100))) +
        (this.totalNights * this.pricing['nightCharges'])) * (1 + this.pricing["tax"] / 100))
    }
    this.getDetails.emit(data);
  }

  modifyTrip(formData: any, selected_trip: any) {
    this.goback.emit(formData);
    this.modifySelectedTrip.emit(selected_trip);
  }

  floorTheValue(value: any): any {
    return Math.floor(value);
  }

  totalNights = 0;
  twoWayRide(perKmCharge: any) {
    this.totalNights = this.countTotalNights(this.formData['pickUpDate'], this.formData['pickUpTime'], this.formData['returnDate'], this.formData['returnTime'])
    let charge = ((2 * this.rideDistance) * (perKmCharge * (1 + (this.pricing['twoWayFactor'] / 100))) *
      ((1 + this.pricing["driverAllowance"] / 100) * (1 + (this.pricing['twoWayFactor'] / 100))) +
      (this.totalNights * this.pricing['nightCharges'])) * (1 + this.pricing["tax"] / 100)
    return Math.floor(charge);
  }

  countTotalNights(pickupDateTime1: Date, pickupTime1: Date, pickupDateTime2: Date, pickupTime2: Date): number {
    const dateTime1 = new Date(pickupDateTime1);
    const dateTime2 = new Date(pickupDateTime2);

    const diffInMillis = dateTime2.getTime() - dateTime1.getTime();

    const millisInOneDay = 24 * 60 * 60 * 1000;
    const nights = Math.ceil(diffInMillis / millisInOneDay);

    return nights;
  }
  //  {totalDistance * (perKMCharge * twoWayFactor) * (driverAllowance * twoWayFactor)  + (TotalNights * NightCharges) + (ExtraKMS * ExtraKMSCharge * twoWayFactor) } * (tax) 

}
