<h2 mat-dialog-title style="text-align: center;" class="dialog-title">Add Service</h2>
<div mat-dialog-content style="display: flex; justify-content: center;">
  <mat-form-field>
    <input matInput placeholder="From Location" [(ngModel)]="fromLocation">
  </mat-form-field>
  <mat-form-field class="ms-2">
    <input matInput placeholder="To Location" [(ngModel)]="toLocation">
  </mat-form-field>
  <mat-form-field class="ms-2">
    <input matInput placeholder="To Location" [(ngModel)]="kilometer">
  </mat-form-field>
</div>
<div mat-dialog-actions style="justify-content: center;">
  <button mat-raised-button class="btnAction" (click)="onNoClick()">Cancel</button>
  <button mat-raised-button class="btnAction" (click)="onSaveClick()">Save</button>
</div>
