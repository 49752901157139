<mat-card class="example-card">
    <mat-card-header class="justify-content-around ">
        <mat-card-title>Home</mat-card-title>
    </mat-card-header>
</mat-card>


<ng-container *ngIf="showLoader; else content" class="loader-container">
    <div class="row">
        <div class="loader"></div>
    </div>
</ng-container>
<ng-template #content>
    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">electric_car</mat-icon>
                Ongoing Rides
            </ng-template>
            
            <mat-card class="mat-card">
                <mat-card-content>
                  <ng-container *ngIf="driver_ongoing_rides[0]; else noData">
                    <div class="flex-container">
                      <div class="flex-item">
                        <div class="d-flex">
                          <span class="title">From: </span>
                          <p>{{driver_ongoing_rides[0]?.['from']}}</p>
                        </div>
                        <div class="d-flex">
                          <span class="title">Date: </span>
                          <p>{{driver_ongoing_rides[0]?.['date'] | date}}</p>
                        </div>
                        <div class="d-flex">
                          <span class="title">Distance: </span>
                          <p>{{driver_ongoing_rides[0]?.['distance']}}</p>
                        </div>
                        <div class="d-flex">
                          <span class="title">Car Model: </span>
                          <p>{{driver_ongoing_rides[0]?.['car_model']}}</p>
                        </div>
                        <div class="d-flex">
                          <span class="title">Odo Meter Start Reading: </span>
                          <p>{{driver_ongoing_rides[0]?.['odoMeterReadingAtStart'] || 0}}</p>
                        </div>
                        <div class="d-flex">
                          <span class="title">Customer Name: </span>
                          <p>{{driver_ongoing_rides[0]?.['customerName']}}</p>
                        </div>
                      </div>
                      <div class="flex-item">
                        <div class="d-flex">
                          <span class="title">To: </span>
                          <p>{{driver_ongoing_rides[0]?.['to']}}</p>
                        </div>
                        <div class="d-flex">
                          <span class="title">Time: </span>
                          <p>{{driver_ongoing_rides[0]?.['time']}}</p>
                        </div>
                        <div class="d-flex">
                          <span class="title">Fare: </span>
                          <p>{{driver_ongoing_rides[0]?.['fare']}}</p>
                        </div>
                        <div class="d-flex">
                          <span class="title">Car Number: </span>
                          <p>{{driver_ongoing_rides[0]?.['car_no']}}</p>
                        </div>
                        <div class="d-flex">
                          <span class="title">Odo Meter End Reading: </span>
                          <p>{{driver_ongoing_rides[0]?.['odoMeterReadingAtEnd'] || 0}}</p>
                        </div>
                        <div class="d-flex">
                          <span class="title">Customer PhoneNumber: </span>
                          <p>{{driver_ongoing_rides[0]?.['customerPhoneNumber']}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="btns">
                      <ng-container *ngIf="driver_ongoing_rides[0]?.odoMeterReadingAtStart === ''">
                          <button mat-flat-button (click)="start_ride('start_ride')" [disabled]="disable_start_ride"
                              [class]="disable_start_ride ? 'disable' : ''">Start Ride</button>
                      </ng-container>
                      <ng-container *ngIf="driver_ongoing_rides[0]?.odoMeterReadingAtStart !== '' && driver_ongoing_rides[0]?.odoMeterReadingAtEnd === ''">
                          <button mat-flat-button (click)="start_ride('end_ride')" [disabled]="disable_end_ride"
                              [class]="disable_end_ride ? 'disable' : ''">End Ride</button>
                      </ng-container>
                    </div>
                  </ng-container>
                  <ng-template #noData>
                    Sorry, there are currently no ongoing rides available.
                  </ng-template>
                </mat-card-content>
            </mat-card>
              
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">electric_car</mat-icon>
                Scheduled Rides
            </ng-template>
            <app-driver-table *ngIf="driver_scheduled_rides.length > 0; else noScheduledData" [data]="driver_scheduled_rides"
              [columnData]="driver_scheduled_column"
                [flag]="'Scheduled'" (changeStatus)="changeStatus($event)"></app-driver-table>
                <ng-template #noScheduledData>
                  <mat-card style="margin: 1rem; border-radius: 1rem;">
                    <mat-card-content>
                      Sorry, there are currently no Scheduled rides available.
                    </mat-card-content>
                  </mat-card>
                </ng-template>
        </mat-tab>
    </mat-tab-group>
</ng-template>