
  <ng-container *ngIf="showLoader; else content" class="loader-container">
    <div class="row">
      <div class="loader"></div>
    </div>
  </ng-container>
  <ng-template #content>
<div class="container">
    <h2 mat-dialog-title style="text-align: center;" class="dialog-title">{{title}} {{_id}}</h2>

    <div class="caseOfError" *ngIf="!populateData || populateData.length == 0">
        <span *ngIf="!populateData">Fetching Records</span>
        <span *ngIf="populateData && populateData.length == 0">No Records found</span>
    </div>
    <mat-card class="rideHistory" *ngIf="populateData && populateData.length > 0">
        <app-table [data]="populateData" [columnData]="columnData" [flag]="'RideHistory'"></app-table>
    </mat-card>
    </div>
  </ng-template>