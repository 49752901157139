import { Component, EventEmitter, Input, Output, SimpleChange, ViewChild,ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DataDialogComponent } from './data-dialog/data-dialog.component';
import { AdminService } from 'src/app/services/admin.service';
import { TransactionDetailsComponent } from 'src/app/transaction-details/transaction-details.component';
import { DocumentListComponent } from 'src/app/popupWindows/document-list/document-list.component';
import { AgentServiceService } from 'src/app/services/agent-service.service';
import { ForwardRideComponent } from 'src/app/popupWindows/forward-ride/forward-ride.component';
import { CollectPaymentComponent } from 'src/app/popupWindows/collect-payment/collect-payment.component';
import { ConfirmationWindowComponent } from 'src/app/popupWindows/confirmation-window/confirmation-window.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortModule, Sort, SortDirection } from '@angular/material/sort';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { BookingDetailsComponent } from 'src/app/popupWindows/booking-details/booking-details.component';
import { ProfileDetailsComponent } from 'src/app/popupWindows/profile-details/profile-details.component';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent {
  @Input() popUpData: any
  @Input() titledata: any
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @Input() data: any[] = [];
  @Input() columnData: any[] = [];
  @Input() flag: any = '';
  @Input() source: any = '';
  @Output() editClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() driverStatusUpdated: EventEmitter<void> = new EventEmitter<void>();
  @Output() agentStatusUpdated: EventEmitter<void> = new EventEmitter<void>();
  @Output() vehicleStatusUpdated: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateRideDetails: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateTransactionDetails: EventEmitter<void> = new EventEmitter<void>();
  


  constructor(public dialog: MatDialog,public adminservice:AdminService,private _liveAnnouncer: LiveAnnouncer, private _service : AgentServiceService, private snackBar: MatSnackBar) { }
  Math = Math;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  displayedColumns: string[] = [];

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (this.columnData.length) {
      this.displayedColumns = this.columnData.map(({ field }) => field)
    }
    if (changes['data']) {
      this.dataSource.data = this.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      
    }
  }

  announceSortChange(sortState: any) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  async viewDocumentList(source: string, data: any){
    console.log("Data: ", data);
    let dataForPopUp: any[] = [];
    if(source == 'Driver'){
      this.adminservice.getDriverDocumentList(data.contact).subscribe((res)=> {
        console.log("DocumentsList: ", res);
        dataForPopUp = [...res];
        console.log("DataForPopup: ", dataForPopUp)
        const dialog = this.dialog.open(DocumentListComponent, {
          width: '80%',
          height: '80%',
          data: {
            source: source,
            content: dataForPopUp,
            UID: data.contact
        }
        })
      })
    }
    else if(source == 'Cab'){
      this.adminservice.getCabDocumentList(data.Vehicle_Number).subscribe((res)=> {
        console.log("DocumentsList: ", res, data);
        dataForPopUp = [...res];
        console.log("DataForPopup: ", dataForPopUp)
        const dialog = this.dialog.open(DocumentListComponent, {
          width: '80%',
          height: '80%',
          data: {
            source: source,
            userType: this.source,
            content: dataForPopUp,
            UID: data.Vehicle_Number
        }
        })
      })
    }
    else if(source == 'Agent'){
      this.adminservice.getAgentDocumentList(data.agentId).subscribe((res)=> {
        console.log("DocumentsList: ", res, data);
        dataForPopUp = [...res];
        console.log("DataForPopup: ", dataForPopUp)
        const dialog = this.dialog.open(DocumentListComponent, {
          width: '80%',
          height: '80%',
          data: {
            source: source,
            content: dataForPopUp,
            UID: data.contact
        }
        })
      })
    }
    
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  changeStatus(status: any) {
    console.log(status);
  }
  editAction(data: any) {
    this.editClicked.emit(data);
    console.log(data)
  }
  deleteAction(data: any) {
    console.log(data)
    this.adminservice.getpromoDeleted(data.promo_code).subscribe((res)=>{
      this,this.dataSource.data = this.dataSource.data.filter(promo=>promo.promo_code !== data.promo_code)
    },
    error => {
      console.error("Error deleting promo:", error);
    })
  }

  delinkCab(element: any){
    let agentId = localStorage.getItem("agent_auth") || '';
    console.log(element.Vehicle_Number, agentId);
    this._service.delinkCab(agentId, element.Vehicle_Number).subscribe(
      (res) => {
        console.log(res);
        this.snackBar.open(res.message, 'Close', {duration: 3000});
        this.vehicleStatusUpdated.emit();
      },
      (error) => {
        console.log(error);
        this.snackBar.open(error.error.message, 'Close', {duration: 3000});
      }
    )
  }

  ShowRideHistory(source: string,entity: string, data: any) {
    console.log(data)
    const dialog = this.dialog.open(DataDialogComponent, {
      width: '80%',
      height: '80%',
      data: {
        source: source,
        content: data,
        entity: entity,
    }
    })
  }

  ShowDriverList(source: string,  entity: string,data: any){
    console.log("Show driver list data: ",source, entity,data)
    this._service.getAllDriverLinked(data.agentId).subscribe((res) => {
      console.log(res);
      const dialog = this.dialog.open(DataDialogComponent, {
        width: '80%',
        height: '80%',
        data: {
          source: source,
          content: {id: data.agentId, data: res.data},
          entity: entity,
      }
      })
    })
  }
  ShowCabList(source: string,  entity: string,data: any){
    console.log("Show Cab list data: ",source, entity,data)
    this._service.getAllCarsLinked(data.agentId).subscribe((res) => {
      console.log("Show Cab list: ",res);
      const dialog = this.dialog.open(DataDialogComponent, {
        width: '80%',
        height: '80%',
        data: {
          source: source,
          content: {id: data.agentId, data: res.data},
          entity: entity,
      }
      })
    })
  }
  ShowWorkLocation(source: string,  entity: string,data: any){
    console.log("Show Work Location data: ",source, entity,data)
    const dialog = this.dialog.open(DataDialogComponent, {
      width: '80%',
      height: '80%',
      data: {
        source: source,
        content: {id: data.agentId, data: data.work_location, preferredLocation: data.preferredLocation},
        entity: entity,
    }
    })
  }

  ShowTransactionDetails(source: string, data: any){
    console.log("Transaction Details: ", source, data);
    const dialog = this.dialog.open(TransactionDetailsComponent, {
      width: '80%',
      height: '80%',
      data: {
        source: source,
        content: data,
      }
    })
    dialog.afterClosed().subscribe(() => {
      this.updateTransactionDetails.emit();
    });
  }

  CollectAdvance(data: any){
    console.log("Transaction Details: ", data);
    const dialog = this.dialog.open(CollectPaymentComponent, {
      width: '80%',
      height: '80%',
      data: {
        source: "Admin",
        content: data,
      }
    })
    dialog.afterClosed().subscribe(() => {
      this.updateRideDetails.emit();
    });
  
  }

  ForwardRide(data: any){
    console.log("Transaction Details: ", data);
    const dialog = this.dialog.open(ForwardRideComponent, {
      width: '80%',
      height: '80%',
      data: {
        source: "Admin",
        content: data,
      }
    })
    dialog.afterClosed().subscribe(() => {
      this.updateRideDetails.emit();
    });
  
  }

  approveEntity(entity: string, element: any){
    if (this.source != 'Admin') {
      this.snackBar.open("You are not authorized for approval", "Close", { duration: 5000 });
      return;

    }
    const dialogRef = this.dialog.open(ConfirmationWindowComponent, {
      data: {
        type: "approval"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'approve') {
        // Handle approve action
        if(entity=='Driver'){
          this.updateDriver(element.driver_id,"Approved");
         
        }
        else if(entity=='Agent'){
          this.updateAgent(element.agentId,"Approved")
        }
        else if(entity=='Vehicle'){
          this.updateVehicle(element.Vehicle_Number,"Approved")
        }
        console.log('Approved');
      } else if (result === 'reject') {
        // Handle reject action
        if(entity=='Driver'){
          this.updateDriver(element.driver_id,"Rejected")
       
        }
        else if(entity=='Agent'){
          this.updateAgent(element.agentId,"Rejected")
        }
        else if(entity=='Vehicle'){
          this.updateVehicle(element.Vehicle_Number,"Rejected")
        }
        console.log('Rejected');
      } else {
        console.log('Cancelled');
      }
    });
  }


  updateDriver(id: string, status: string){
    this.adminservice.updateDriver(id, status).subscribe((res) => {
      this.snackBar.open(res.Message, 'Close', { duration: 5000 });
      this.driverStatusUpdated.emit();
    },(error) => {
      console.log(error);
      this.snackBar.open(error.error.Message, 'Close', { duration: 5000 });
    });
  }  
  updateAgent(id: string, status: string){
    this.adminservice.updateAgent(id, status).subscribe((res) => {
      this.snackBar.open(res.Message, 'Close', { duration: 5000 });
      this.agentStatusUpdated.emit();
    },(error) => {
      console.log(error);
      this.snackBar.open(error.Message, 'Close', { duration: 5000 });
    });
  }  
  updateVehicle(id: string, status: string){
    this.adminservice.updateCab(id, status).subscribe((res) => {
      console.log(res);
      this.snackBar.open(res.Message, 'Close', { duration: 5000 });
      this.vehicleStatusUpdated.emit()
    },(error) => {
      console.log(error);
      this.snackBar.open(error.Message, 'Close', { duration: 5000 });
    });
  }  

  ViewDetailsForBooking(data: any){
    console.log("Booking Details: ", data);
    const dialog = this.dialog.open(BookingDetailsComponent, {
      width: '80%',
      height: '80%',
      data: {
        source: "Admin",
        content: data,
      }
    })
    dialog.afterClosed().subscribe(() => {
      this.updateRideDetails.emit();
    });
  }

  showDetails(data: any, type: string) {
    console.log("Show details: ", data);
    let id = '';
    const dialog = this.dialog.open(ProfileDetailsComponent, {
      width: '80%',
      height: '80%',
      data: {
        source: "Admin",
        id: id,
        type: type
      }
    })
  }

}
