import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { AdminService } from 'src/app/services/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AwsUploadService } from 'src/app/services/aws-upload.service';

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.scss']
})
export class DriverComponent {
  driverList:any[]=[];
  tableData: any[] = [];
  filterDriverData:any[]=[];
  currentStep: number = 0;
  selectedFilter:string = '';
  showLoader: boolean = true;
  otpSent: boolean = false;
  otp: string='';
  orderId: any;
  isOTPVerified:boolean = false;
  admin:string = "admin"

  ride_filter: any[] = ['All','Approved', 'Unapproved', 'Rejected']

  driverInformation: Driver = new Driver();
  constructor(private router: Router, private route: ActivatedRoute,public adminservice:AdminService,private snackBar: MatSnackBar,public docUpload:AwsUploadService) {
  }
  @ViewChild(MatStepper) stepper!: MatStepper;
  ngOnInit(){
   this.fetchAllDriver()
  }

  fetchAllDriver(){
    this.adminservice.getAllDriverForDashboard().subscribe((res)=>{
      if (Array.isArray(res) && res.length > 0) {
        this.driverList = res;
        this.filterDriverData = res;
        this.tableData =this.driverList.map(driver=>{

        return  {
            driver_id: driver.driver_id,
            driver_name: driver.driverName,
            email_id: driver.emailId,
            contact: driver.mobileNumber,
            // work_location: driver.workLocation,
            statement: '',
            ride_history: '',
            document_list:" ",
            status:driver.status
          }
        });
        } else {
        console.log("Invalid response from the backend");
      }
      this.showLoader  = false; 

    })
  }

  columnData = [
    { field: "driver_name", label: "Driver Name" },
    { field: "email_id", label: "Email ID" },
    { field: "contact", label: "Contact Number" },
    { field: "status", label: "Status" },
    { field: "ride_history", label: "Ride History" },
    // { field: "Approve", label: "Statement" },
    { field: "document_list", label: "Document List" },
  ];

  onStepChange(event: StepperSelectionEvent): void {
    this.currentStep = event.selectedIndex;
  }

  isFormValid(): boolean {
   let validation = (
      // Example validation condition
      this.driverInformation.driverName != '' &&
      this.driverInformation.profilePic != '' &&
      this.driverInformation.aadharNumber != '' &&
      this.driverInformation.mobileNumber != '' &&
      this.driverInformation.profilePic != '' &&
      this.driverInformation.emailId != '' &&
      this.driverInformation.panNumber != '' &&
      this.driverInformation.panUpload != '' &&
      this.driverInformation.dlNumber != '' &&
      this.driverInformation.dlUpload != '' &&
      this.driverInformation.aadharNumber != '' &&
      this.driverInformation.aadharUpload != '' &&
     this.driverInformation.policeVerification != '' &&
      this.isOTPVerified
    );
    return validation
  }
  addDriver() {
    if (this.isFormValid()) {
      this.adminservice.driverRegister(this.driverInformation, this.admin).subscribe((res) => {
      console.log("Driver Added : ",res)
      this.driverInformation = new Driver();
        this.otpSent = false;
        this.isOTPVerified = false;
        this.fetchAllDriver()
        this.stepper.selectedIndex = 0;
        this.snackBar.open(res.message, 'Close', { duration: 3000 });

    },
  (error)=>{
    console.log("Error : ",error.error)
    this.snackBar.open(error.error, 'Close', { duration: 3000 });

  })
    }
  }

  onFileSelected(event: any, source: string) {
    const file = event.target.files[0].name
    if (file) {
      this.docUpload.onFileSelected(event).then((res: any) => {
        if (this.driverInformation.hasOwnProperty(source)) {
          this.driverInformation[source] = res.fileUrl;
          console.log("Driver Info: ", this.driverInformation);
        } else {
          console.error("File URL is not available.");
        }
      }).catch((error) => {
        console.log("Error in ts: ", error);
      });
    }
  }

  cancleForm() {
    this.stepper.selectedIndex = 0;
    this.driverInformation = new Driver();
  }
 
  changeFilterValue() {
    if(this.selectedFilter == 'All'){
      this.tableData =this.driverList.map(driver=>{
        return  {
            driver_name: driver.driverName,
            email_id: driver.emailId,
            contact: driver.mobileNumber,
            work_location: driver.workLocation,
            statement: '',
            ride_history: '',
            document_list:" ",
            status:driver.status
          }
        });
    }
    else{
    this.tableData = this.filterDriverData.filter(element =>this.selectedFilter==element.status).map(driver=>{
      return  {
        driver_name: driver.driverName,
        email_id: driver.emailId,
        contact: driver.mobileNumber,
        work_location: driver.workLocation,
        statement: '',
        ride_history: '',
        document_list:" ",
        status:driver.status
      }
    })
  }
}
//OTP


SendOTP() {
  this.otpSent = true;
  let phoneNumber = parseInt(this.driverInformation.mobileNumber)
  this.adminservice.getOTPValue(phoneNumber).subscribe((res: any) => {
    this.orderId = res.orderId;
    this.snackBar.open("OTP sent successfully !", 'Close', { duration: 3000 });
  }, (err) => {
    this.snackBar.open(err.error.error, 'Close', { duration: 3000 });
  })
}
async verifyOtp(): Promise<boolean> {
  let phoneNumber = parseInt(this.driverInformation.mobileNumber)
  return this.adminservice.getOTPVerified(this.orderId, parseInt(this.otp), phoneNumber).toPromise()
      .then((res: any) => {
          this.isOTPVerified = res.isOTPVerified;
          this.snackBar.open("OTP verified successfully !", 'Close', { duration: 3000 });
          return res.isOTPVerified;
      })
    
      .catch(error => {
          this.snackBar.open(error, 'Close', { duration: 3000 });
          return false;
      });
    
}
ResendOTP(){
  this.adminservice.getReSendOTP(this.orderId).subscribe((res:any)=>{
    this.orderId = res.orderId;
    this.snackBar.open("OTP resend successfull !", 'Close', { duration: 3000 });
  },(err)=>{
    this.snackBar.open(err.error.error, 'Close', { duration: 3000 })
  })
}
Cancel(){
  this.driverInformation.mobileNumber ="";
  this.otpSent=false;
}
isMobileNumberValid(): boolean {
  const phoneNumberString: string = this.driverInformation.mobileNumber; // Convert to string
  return phoneNumberString.length === 10;
}
activateLoginBtn():boolean{
  return this.otp.length ===6
}


}

class Driver {
  driverName: string = '';
  mobileNumber: string = '';
  workLocation: string = '';
  profilePic: string = '';
  emailId: string = '';
  panNumber: string = '';
  panUpload: string = '';
  dlNumber: string = '';
  dlUpload: string = '';
  aadharNumber: string = '';
  aadharUpload: string = '';
  policeVerification: string = '';

  [key: string]: string; // Index signature allowing dynamic property access
}
