import { Component } from '@angular/core';

@Component({
  selector: 'app-driver-earnings',
  templateUrl: './driver-earnings.component.html',
  styleUrls: ['./driver-earnings.component.scss']
})
export class DriverEarningsComponent {
  infoType = [
    { info: "Total Trip", icon: "electric_car", totalValue: "120" },
    { info: "Total Earnings", icon: "toys", totalValue: "1000" },
    { info: "Cash Payment Received", icon: "block", totalValue: "12" },
    { info: "Settled Payment", icon: "monetization_on", totalValue: "120k" },
    { info: "Pending Payment", icon: "monetization_on", totalValue: "120k" }
  ]
  
}
