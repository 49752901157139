import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { DriverService } from 'src/app/services/driver.service';
@Component({
  selector: 'app-data-dialog',
  templateUrl: './data-dialog.component.html',
  styleUrls: ['./data-dialog.component.scss']
})
export class DataDialogComponent {

  populateData : any[] = [];
  showLoader:boolean=true
  rideHistoryColumnData= [
    { field: "booking_id", label: "Booking ID" },
    { field: "from", label: "From" },
    { field: "to", label: "To" },
    { field: "distance", label: "Distance" },
    { field: "fare", label: "Fare" },
    { field: "status", label: "Status" },
  ]
  driverListColumnData= [
    { field: "DriverName", label: "Driver Name" },
    { field: "PhoneNumber", label: "Phone Number" },
    { field: "Status", label: "Status"},
  ]
  cabListColumnData= [
    { field: "CarBrand", label: "Car Brand" },
    { field: "CarName", label: "Car Name" },
    { field: "CarNumber", label: "Car Number" },
    { field: "Status", label: "Status"},
  ]

  workLocationColumnData = [
    { field: "City", label: "City" },
    { field: "State", label: "State" },
    { field: "Preferred", label: "Preferred Location" },
  ]

  columnData : any[]= [];

  _id: string = '';
  title: string = '';

  constructor(public dialogRef: MatDialogRef<DataDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _service: AdminService, private _driverService: DriverService) {
    console.log(data);
    if(data.source == 'RideHistory'){
      this.title = "Ride History for";
      this.columnData = this.rideHistoryColumnData;
      if(data.entity == 'Customer'){
        this._id = data.content.customer_id;
        
        this._service.getAllCustomerRides(data.content.customer_id).subscribe((res) => {
          console.log("Ride history fetched: ", res);
          this.populateData = res.result;
          this.showLoader  = false; 

        })
      }
      else if(data.entity == 'Driver'){
        console.log("Ride history for driver: ",data.content.driver_id);
        this._id = data.content.driver_id;
        this._driverService.driverScheduleRides(data.content.driver_id).subscribe((res) => {
          console.log("Driver Ride history fetched: ", res);
          this.populateData = res.result;
          this.showLoader  = false; 

        })
      }
      else if(data.entity == 'Agent'){
        console.log("Ride history for Agent: ",data.content.agentId);
        this._id = data.content.agentId;
        this._service.getAllAgentRides(data.content.agentId).subscribe((res) => {
          console.log("Driver Ride history fetched: ", res);
          this.populateData = res.result;
          this.showLoader  = false; 

        })
      }
      //make an api call to find ride history
    }
    else if(data.source=='Vehicle'){
      // this.populateData.push(d);
      this.populateData = data.content.filter((element: any) => element != null);
      console.log("Dialogue content : ",this.populateData)
      this.showLoader  = false; 

    }
    else if(data.source=='DriverList'){
      this.title = "Driver List for";
      this.columnData = [...this.driverListColumnData];
      this._id = data.content.id;
      console.log("Driver List: ", data.content.data) 
      if(data.entity == 'Agent'){
        this.populateData = data.content.data;
        this.showLoader  = false; 
      }
    }
    else if(data.source=='CabList'){
      this.title = "Cab List for";
      this.columnData = [...this.cabListColumnData];
      console.log("Data Dialog cab: ", data.content);
      this._id = data.content.id;
      if(data.entity == 'Agent'){
        this.populateData = data.content.data.filter((element: any) => element != null);
        this.showLoader  = false; 
      }
    }
    else if(data.source=='WorkLocation'){
      this.title = "Work Location List for";
      this.columnData = [...this.workLocationColumnData];
      console.log("Data Dialog WorkLocation: ", data.content);
      this._id = data.content.id;
      if(data.entity == 'Agent'){
        this.populateData = data.content.data.map((element: any) => {
          return {
            Preferred:data.content.preferredLocation == element?"Preferred":" ",
            State: element.split(",")[1],
            City:element.split(",")[0]
          }
        });
        this.showLoader  = false; 
        console.log("Populate data: ", this.populateData);
      } 
    }
  }


  closeDialog(): void {
    this.dialogRef.close();
  }

}
