<mat-card>
    <h2 mat-dialog-title>Cancel Ride</h2>
    <mat-card class="main-class">
        <span>Are you sure you want to cancel ride?</span>
        <mat-form-field class="input-field">
            <textarea rows="3" [(ngModel)]="cancellationMessage" placeholder="Enter the reason for cancel"></textarea>
        </mat-form-field>
        <mat-hint>Minimum 10 characters</mat-hint>
    </mat-card>
    <mat-dialog-actions align="end" class="login_btn">
        <div class="d-flex p-2">
            <button class="btn login" [disabled]="cancellationMessage.length < 10" (click)="CancelRide()">Cancel
                Ride</button>
            <button class="btn login" (click)="Cancel()">Back</button>
        </div>
    </mat-dialog-actions>

</mat-card>