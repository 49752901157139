import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environment';

@Injectable({
  providedIn: 'root'
})
export class CabServiceService {
  backendURL: string = environment.apiUrl;

  constructor(public http: HttpClient) { }

  cab_registration(data: any) : Observable<any>{
    return this.http.post(this.backendURL + "/cab/cabRegistration", data);
  }
  fetchData(id: string): Observable<any> {
    return this.http.get(this.backendURL + '/cab/fetchData/' + id);
  }

  fetchCabWithCabNumber(cabNumber: string): Observable<any> {
    return this.http.get(this.backendURL + '/cab/getCabData/' + cabNumber)
  }
  cabDocumentsUpdate(reqObj:any): Observable<any>{
    return this.http.put(this.backendURL + '/cab/updateCabDetails' , reqObj)
  }
}
