import { Component, Input } from '@angular/core';
import { AgentServiceService } from 'src/app/services/agent-service.service';
import { CabServiceService } from 'src/app/services/cab-service.service';
import { DriverService } from 'src/app/services/driver.service';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss']
})
export class ProfileDetailsComponent {

  @Input() id: string = '';
  @Input() type: string = '';

  profile: any;

  constructor(private agentService: AgentServiceService, private driverService: DriverService, private cabService: CabServiceService) { }

  ngOnInit() {
    if (this.type == 'Driver') {
      //get driver details
      this.driverService.fetchData(this.id).subscribe(
        (res) => {
          this.profile = res;
          console.log(this.profile);
        }
      )
    }
    else if (this.type == 'Agent') {
      //get agent details
      this.agentService.agent_profile(this.id).subscribe(
        (res) => {
          this.profile = res;
          console.log(this.profile);
        }
      )
    }
    else if (this.type == 'Cab') {
      //get cab details
      this.cabService.fetchData(this.id).subscribe(
        (res) => {
          this.profile = res;
          console.log(this.profile);
        }
      )
    }
  }

}
