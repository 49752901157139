<mat-card class="example-card">
    <mat-card-header class="justify-content-around ">
        <mat-card-title>Website Links Settings</mat-card-title>
    </mat-card-header>
  </mat-card>
<div class="steeper ">
    <div class="form-group col-sm-12">
        <mat-card class="example-card row pt-5 m-2 mb-4">
            <div class="d-flex row flex-column flex-md-row align-items-baseline justify-content-start">

                <div class="col-sm-4 fw-bold  label">
                    Company Logo :
                </div>
                <mat-form-field class="col-sm-8 input-field">
                    <input matInput placeholder="https://example.com/logo.png" [disabled]="!enableEditing"
                        [(ngModel)]="siteSettings.companyLogo">
                </mat-form-field>
            </div>
            <div class="d-flex row flex-column flex-md-row align-items-baseline justify-content-start">

                <div class="col-sm-4 fw-bold  label">
                    Fevicon Logo :
                </div>
                <mat-form-field class="col-sm-8 input-field">
                    <input matInput placeholder="https://example.com/logo.png" [disabled]="!enableEditing"
                        [(ngModel)]="siteSettings.faviconLogo">
                </mat-form-field>
            </div>
            <div class="d-flex row flex-column flex-md-row align-items-baseline justify-content-start">

                <div class="col-sm-4 fw-bold  label">
                    Playstore Link :
                </div>
                <mat-form-field class="col-sm-8 input-field">
                    <input matInput placeholder="https://example.com/logo.png" [disabled]="!enableEditing"  [(ngModel)]="siteSettings.playStoreLink">
                </mat-form-field>
            </div>
            <div class="d-flex row flex-column flex-md-row align-items-baseline justify-content-start">

                <div class="col-sm-4 fw-bold  label">
                    App Store Link :
                </div>
                <mat-form-field class="col-sm-8 input-field">
                    <input matInput placeholder="https://example.com/logo.png" [disabled]="!enableEditing"  [(ngModel)]="siteSettings.appStoreLink">
                </mat-form-field>
            </div>
            <div class="d-flex row flex-column flex-md-row align-items-baseline justify-content-start">

                <div class="col-sm-4 fw-bold  label">
                    Contact Number :
                </div>
                <mat-form-field class="col-sm-8 input-field">
                    <input matInput placeholder="https://example.com/logo.png" [disabled]="!enableEditing"  [(ngModel)]="siteSettings.contactNumber">
                </mat-form-field>
            </div>
            <div class="d-flex row flex-column flex-md-row align-items-baseline justify-content-start">

                <div class="col-sm-4 fw-bold  label">
                    Contact Email :
                </div>
                <mat-form-field class="col-sm-8 input-field">
                    <input matInput placeholder="https://example.com/logo.png" [disabled]="!enableEditing"  [(ngModel)]="siteSettings.contactEmail">
                </mat-form-field>
            </div>
            <div class="d-flex row flex-column flex-md-row align-items-baseline justify-content-start">

                <div class="col-sm-4 fw-bold  label">
                    Facebook Link :
                </div>
                <mat-form-field class="col-sm-8 input-field">
                    <input matInput placeholder="https://example.com/logo.png" [disabled]="!enableEditing"  [(ngModel)]="siteSettings.facebookLink">
                </mat-form-field>
            </div>
            <div class="d-flex row flex-column flex-md-row align-items-baseline justify-content-start">

                <div class="col-sm-4 fw-bold  label">
                    Instagram Link :
                </div>
                <mat-form-field class="col-sm-8 input-field">
                    <input matInput placeholder="https://example.com/logo.png" [disabled]="!enableEditing"  [(ngModel)]="siteSettings.instagramLink">
                </mat-form-field>
            </div>
            <div class="d-flex row flex-column flex-md-row align-items-baseline justify-content-start">

                <div class="col-sm-4 fw-bold  label">
                    Twitter Link :
                </div>
                <mat-form-field class="col-sm-8 input-field">
                    <input matInput placeholder="https://example.com/logo.png" [disabled]="!enableEditing"  [(ngModel)]="siteSettings.twitterLink">
                </mat-form-field>
            </div>
            <div class="d-flex row flex-column flex-md-row align-items-baseline justify-content-start">

                <div class="col-sm-4 fw-bold  label">
                    Whatsapp Link :
                </div>
                <mat-form-field class="col-sm-8 input-field">
                    <input matInput placeholder="https://example.com/logo.png" [disabled]="!enableEditing"  [(ngModel)]="siteSettings.whatsappLink">
                </mat-form-field>
            </div>
            <div class="d-flex row flex-column flex-md-row align-items-baseline justify-content-start">

                <div class="col-sm-4 fw-bold  label">
                    Photos Link :
                </div>
                <mat-form-field class="col-sm-8 input-field">
                    <input matInput placeholder="https://example.com/logo.png" [disabled]="!enableEditing"  [(ngModel)]="siteSettings.photoLink">
                </mat-form-field>
            </div>
            <div class="d-flex row flex-column flex-md-row align-items-baseline justify-content-start">

                <div class="col-sm-4 fw-bold  label">
                    Videos Link :
                </div>
                <mat-form-field class="col-sm-8 input-field">
                    <input matInput placeholder="https://example.com/logo.png" [disabled]="!enableEditing"  [(ngModel)]="siteSettings.videoLink">
                </mat-form-field>
            </div>
            <div class="btn">
                <button type="submit" class="btn btn-success col-sm m-3" (click)="saveSettings()">Submit</button>
                <button type="submit" class="btn btn-danger col-sm m-3" (click)="editForm()" *ngIf="!enableEditing">Edit</button>
                <button type="submit" class="btn btn-danger col-sm m-3" (click)="cancel()" *ngIf="enableEditing">Cancel</button>
            </div>
        </mat-card>
    </div>
<!-- </form> -->